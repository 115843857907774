const P2PUtil = {
  getP2PInboxTopic(base) {
    return (`${base}/_`);
  },
  getP2PTopicSubscription(base) {
    return (`${base}/>`);
  },
};

module.exports.P2PUtil = P2PUtil;
