const { Enum } = require('solclient-eskit');

/**
 * Represents a log level enumeration.
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const LogLevel = {
  /**
   * Fatal. Rserved for unrecoverable errors.
   * @type {Number}
   */
  FATAL: 0,
  /**
   * Error. An internal error to the API or Solace Message Router.
   * @type {Number}
   */
  ERROR: 1,
  /**
   * Warn. An external error which may be caused by the application passing invalid
   * arguments or objects to the API. Often accompanied by an thrown exception.
   * @type {Number}
   */
  WARN:  2,
  /**
   * Info. An unexpected event or occurrence that does not affect the sane
   * operation of the SDK or application.
   * @type {Number}
   */
  INFO:  3,
  /**
   * Debug. The highest (least) level of debug logs. Debug logs provide an overview of
   * the API operation.
   * @type {Number}
   */
  DEBUG: 4,
  /**
   * Trace. The loweest (most verbose) level of debug logs.
   * @type {Number}
   */
  TRACE: 5,
};

module.exports.LogLevel = Enum.new(LogLevel);
