const { AuthenticationScheme, CapabilityType, ClientCapabilityType, MutableSessionProperty, SessionProperties } = require('solclient-session');
const { BaseMessage } = require('./base-message');
const { Bits, Convert } = require('solclient-convert');
const { DestinationType, DestinationUtil } = require('solclient-destination');
const { ErrorSubcode, OperationError } = require('solclient-error');
const { Process, StringUtils, Version } = require('solclient-util');
const { SMFClientCtrlMessageType } = require('../smf-client-ctrl-message-types');
const { SMFClientCtrlParam, SMFClientCtrlAuthType } = require('../smf-client-ctrl-params');
const { SMFHeader } = require('./smf-header');
const { SMFParameter } = require('./smf-parameter');
const { SMFProtocol } = require('../smf-protocols');

const { LOG_TRACE } = require('solclient-log');

const {
  get: bits,
  set: setBits,
} = Bits;
const {
  int8ToStr, strToInt8,
  int16ToStr, int32ToStr,
  strToInt16, strToInt32,
} = Convert;
const {
  nullTerminate,
  stripNullTerminate,
} = StringUtils;
const {
  validateAndEncode,
} = DestinationUtil;

const BOOLEAN_CAPS_BITS = [
  CapabilityType.JNDI,
  CapabilityType.COMPRESSION,
  CapabilityType.GUARANTEED_MESSAGE_CONSUME,
  CapabilityType.TEMPORARY_ENDPOINT,
  CapabilityType.GUARANTEED_MESSAGE_PUBLISH,
  CapabilityType.GUARANTEED_MESSAGE_BROWSE,
  CapabilityType.ENDPOINT_MGMT,
  CapabilityType.SELECTOR,
  CapabilityType.ENDPOINT_MESSAGE_TTL,
  CapabilityType.QUEUE_SUBSCRIPTIONS,
  null, // skip obsolete FLOW_RECOVER
  CapabilityType.SUBSCRIPTION_MANAGER,
  CapabilityType.MESSAGE_ELIDING,
  CapabilityType.TRANSACTED_SESSION,
  CapabilityType.NO_LOCAL,
  CapabilityType.ACTIVE_CONSUMER_INDICATION,
  CapabilityType.PER_TOPIC_SEQUENCE_NUMBERING,
  CapabilityType.ENDPOINT_DISCARD_BEHAVIOR,
  CapabilityType.CUT_THROUGH,
  null, // skip OPENMAMA
  CapabilityType.MESSAGE_REPLAY,
  CapabilityType.COMPRESSED_SSL,
  null, // skipping LONG_SELECTORS
  CapabilityType.SHARED_SUBSCRIPTIONS,
  CapabilityType.BR_REPLAY_ERRORID,
];

const CLIENT_CAPS_VALUES = new Map([
  [ClientCapabilityType.UNBIND_ACK, 0x80],
  [ClientCapabilityType.BR_ERRORID, 0x40],
]);

/**
 * @classdesc ClientCtrlMessage
 * Represents a ClientCtrl request or reply message
 * @private
 */
class ClientCtrlMessage extends BaseMessage {
  constructor(messageType = 0) {
    super(new SMFHeader(SMFProtocol.CLIENTCTRL, 1));

    // Field: msgtype
    this.msgType = messageType;

    // Field: version
    this.version = 1;
  }

  getP2PTopicValue() {
    const p2pParam = this.getParameter(SMFClientCtrlParam.P2PTOPIC);
    if (!p2pParam) {
      return null;
    }
    return stripNullTerminate(p2pParam.getValue());
  }

  getVpnNameInUseValue() {
    const vpnParam = this.getParameter(SMFClientCtrlParam.MSGVPNNAME);
    if (!vpnParam) {
      return null;
    }
    return stripNullTerminate(vpnParam.getValue());
  }

  getVridInUseValue() {
    const vridParam = this.getParameter(SMFClientCtrlParam.VRIDNAME);
    if (!vridParam) {
      return null;
    }
    return stripNullTerminate(vridParam.getValue());
  }

  getUserIdValue() {
    const userIdParam = this.getParameter(SMFClientCtrlParam.USERID);
    if (!userIdParam) {
      return null;
    }
    return stripNullTerminate(userIdParam.getValue());
  }

  getRouterCapabilities() {
    let caps = [];

    // Parse the composite capabilities parameter
    let capParam = this.getParameter(SMFClientCtrlParam.ROUTER_CAPABILITIES);
    if (capParam) {
      caps = ClientCtrlMessage.prmParseCapabilitiesValue(capParam.getValue(), caps);
    }

    // Parse out the router status strings
    capParam = this.getParameter(SMFClientCtrlParam.SOFTWAREVERSION);
    if (capParam) {
      caps[CapabilityType.PEER_SOFTWARE_VERSION] = stripNullTerminate(capParam.getValue());
    }
    capParam = this.getParameter(SMFClientCtrlParam.SOFTWAREDATE);
    if (capParam) {
      caps[CapabilityType.PEER_SOFTWARE_DATE] = stripNullTerminate(capParam.getValue());
    }
    capParam = this.getParameter(SMFClientCtrlParam.PLATFORM);
    if (capParam) {
      caps[CapabilityType.PEER_PLATFORM] = stripNullTerminate(capParam.getValue());
    }
    capParam = this.getParameter(SMFClientCtrlParam.PHYSICALROUTERNAME);
    if (capParam) {
      caps[CapabilityType.PEER_ROUTER_NAME] = stripNullTerminate(capParam.getValue());
    }
    return caps;
  }


  static prmGetDtoPriorityValue(dto) {
    if (dto.local === undefined || dto.network === undefined) {
      return false;
    }
    let twobyte = 0;
    twobyte = setBits(twobyte, dto.local, 8, 8);
    twobyte = setBits(twobyte, dto.network, 0, 8);
    return int16ToStr(twobyte);
  }

  static prmParseDtoPriorityValue(strDtoPriority) {
    const dto = {};
    const twobyte = strToInt16(strDtoPriority.substr(0, 2));
    dto.local = bits(twobyte, 8, 8);
    dto.network = bits(twobyte, 0, 8);
    return dto;
  }

  /*
  strCapabilities: parameter value
  caps: an already existing hash array of CapabilityType
   */
  static prmParseCapabilitiesValue(strCapabilities, capsIn) {
    const caps = capsIn;
    if (!(strCapabilities && caps)) {
      return false;
    }
    const CT = CapabilityType;
    let pos = 0;

    // parse boolean capabilities
    const boolCapCount = strToInt8(strCapabilities[pos]);
    ++pos;

    // The boolean caps are listed in order as in the documentation,
    // that is from MSB to LSB for each caps byte.
    let capsByte;
    for (let bitIndex = 0; bitIndex < boolCapCount; ++bitIndex) {
      const msbIndex = bitIndex & 0x7;
      if (msbIndex === 0) { // Consume a byte
        capsByte = strToInt8(strCapabilities[pos]);
        ++pos;
      }
      const capsKey = BOOLEAN_CAPS_BITS[bitIndex];
      if (!capsKey) continue; // We don't know about this cap
      // so set caps bits from MSB (bit 7) to LSB (bit 0)
      caps[capsKey] = !!bits(capsByte, 7 - msbIndex, 1);
    }
    // parse non-boolean capabilities
    const sanityLoop = 500;
    for (let i = 0; pos < strCapabilities.length && i < sanityLoop; ++i) {
      const onebyte = strToInt8(strCapabilities[pos]); // type
      pos++;
      const capLen = strToInt32(strCapabilities.substr(pos, 4)) - 5;
      pos += 4;
      const strValue = strCapabilities.substr(pos, capLen);
      pos += capLen;
      switch (onebyte) {
        case 0x00:
          caps[CT.PEER_PORT_SPEED] = (strValue.length === 4) ? strToInt32(strValue) : 0;
          break;
        case 0x01:
          caps[CT.PEER_PORT_TYPE] = (strValue.length === 1) ? strToInt8(strValue) : 0;
          break;
        case 0x02:
          caps[CT.MAX_GUARANTEED_MSG_SIZE] = (strValue.length === 4) ? strToInt32(strValue) : 0;
          break;
        case 0x03:
          caps[CT.MAX_DIRECT_MSG_SIZE] = (strValue.length === 4) ? strToInt32(strValue) : 0;
          break;
        default:
          // NOOP (unknown cap)
          break;
      }
    }
    return caps;
  }


  static getLogin(sprop, compressedTLS, plaintextTLS, correlationTag) {
    function clientCapsToStr(clientCapList) {
      const highestCap = Math.max.apply(null, clientCapList) + 1;
      let capBits = 0;
      clientCapList.forEach((cap) => { capBits += CLIENT_CAPS_VALUES.get(cap); });
      return int8ToStr(highestCap) + int8ToStr(capBits);
    }
    if (!(sprop instanceof SessionProperties)) {
      return false;
    }
    const cc = new ClientCtrlMessage(SMFClientCtrlMessageType.LOGIN);
    const smfHeader = cc._smfHeader;
    const isClientCert = sprop.authenticationScheme === AuthenticationScheme.CLIENT_CERTIFICATE;
    smfHeader.pm_corrtag = correlationTag;
    if (sprop.password && !isClientCert) {
      smfHeader.pm_password = sprop.password;
    }
    if (sprop.userName) {
      smfHeader.pm_username = sprop.userName;
    }
    if (sprop.subscriberLocalPriority && sprop.subscriberNetworkPriority) {
      cc.addParameter(new SMFParameter(0,
                                       SMFClientCtrlParam.DELIVERTOONEPRIORITY,
                                       ClientCtrlMessage.prmGetDtoPriorityValue({
                                         local:   sprop.subscriberLocalPriority,
                                         network: sprop.subscriberNetworkPriority })));
    }
    if (sprop.vpnName && sprop.vpnName.length > 0) {
      cc.addParameter(new SMFParameter(1,
                                       SMFClientCtrlParam.MSGVPNNAME,
                                       nullTerminate(sprop.vpnName)));
    }

    if (sprop.applicationDescription && sprop.applicationDescription.length > 0) {
      cc.addParameter(new SMFParameter(0,
                                       SMFClientCtrlParam.CLIENTDESC,
                                       nullTerminate(sprop.applicationDescription)));
    }

    if (sprop.userIdentification && sprop.userIdentification.length > 0) {
      cc.addParameter(new SMFParameter(0,
                                       SMFClientCtrlParam.USERID,
                                       nullTerminate(sprop.userIdentification)));
    }

    if (sprop.authenticationScheme === AuthenticationScheme.OAUTH2) {
      cc.addParameter(new SMFParameter(1,
                                       SMFClientCtrlParam.AUTHENTICATION_SCHEME,
                                       SMFClientCtrlAuthType.OAUTH2));

      if (sprop.idToken) {
        smfHeader.pm_oidc_id_token = nullTerminate(sprop.idToken);
      }

      if (sprop.accessToken) {
        smfHeader.pm_oauth2_access_token = nullTerminate(sprop.accessToken);
      }

      if (sprop.issuerIdentifier) {
        smfHeader.pm_oauth2_issuer_identifier = nullTerminate(sprop.issuerIdentifier);
      }
    }

    cc.addParameter(new SMFParameter(0,
                                     SMFClientCtrlParam.CLIENTNAME,
                                     nullTerminate(sprop.clientName)));
    cc.addParameter(new SMFParameter(0,
                                     SMFClientCtrlParam.PLATFORM,
                                     nullTerminate(`${Process.platform} - JS API (${Version.mode})`)));

    if (sprop.noLocal) {
      cc.addParameter(new SMFParameter(0,
                                       SMFClientCtrlParam.NO_LOCAL,
                                       '\x01'));
    }

    if (isClientCert) {
      cc.addParameter(new SMFParameter(1,
                                       SMFClientCtrlParam.AUTHENTICATION_SCHEME,
                                       SMFClientCtrlAuthType.CLIENT_CERTIFICATE));
    }

    cc.addParameter(new SMFParameter(0,
                                     SMFClientCtrlParam.SOFTWAREDATE,
                                     nullTerminate(Version.formattedDate)));
    cc.addParameter(new SMFParameter(0,
                                     SMFClientCtrlParam.SOFTWAREVERSION,
                                     nullTerminate(Version.version)));

    if (compressedTLS && plaintextTLS) {
      LOG_TRACE('Adding SslDowngrade=1 to login.');
      cc.addParameter(new SMFParameter(1,
                                       SMFClientCtrlParam.SSL_DOWNGRADE,
                                       '\x01'));
    } else if (compressedTLS) {
      LOG_TRACE('Adding SslDowngrade=2 to login.');
      cc.addParameter(new SMFParameter(1,
                                       SMFClientCtrlParam.SSL_DOWNGRADE,
                                       '\x02'));
    } else if (plaintextTLS) {
      LOG_TRACE('Adding SslDowngrade=0 to login.');
      cc.addParameter(new SMFParameter(1,
                                       SMFClientCtrlParam.SSL_DOWNGRADE,
                                       '\x00'));
    }
    const clientCaps = clientCapsToStr([ClientCapabilityType.UNBIND_ACK,
      ClientCapabilityType.BR_ERRORID]);
    cc.addParameter(new SMFParameter(0,
                                     SMFClientCtrlParam.CLIENT_CAPABILITIES,
                                     clientCaps));
                                     //'\x02\xc0'));
                                     //'\x01\x80'));
    const keepaliveVal = int32ToStr((sprop.keepAliveIntervalInMsecs) / 1000);
    cc.addParameter(new SMFParameter(0,
                                    SMFClientCtrlParam.KEEP_ALIVE_INTERVAL,
                                    keepaliveVal));

    return cc;
  }

  /**
   * Get a CC update message.
   *
   * @param {MutableSessionProperty} mutableSessionProperty The property to update
   * @param {String} newValue The new value for the property
   * @param {String} correlationTag The correlation tag for the request
   * @returns {ClientCtrlMessage} The new UPDATE message
   *
   * @private
   */
  static getUpdate(mutableSessionProperty, newValue, correlationTag) {
    const cc = new ClientCtrlMessage(SMFClientCtrlMessageType.UPDATE);
    const smfHeader = cc.smfHeader;
    smfHeader.pm_corrtag = correlationTag;
    if (mutableSessionProperty === MutableSessionProperty.CLIENT_DESCRIPTION) {
      const appdesc = (newValue || '').toString().substr(0, 250);
      cc.addParameter(new SMFParameter(0,
                                       SMFClientCtrlParam.CLIENTDESC,
                                       nullTerminate(appdesc)));
    } else if (mutableSessionProperty === MutableSessionProperty.CLIENT_NAME) {
      const error =
        ClientCtrlMessage.validateClientName(
          newValue,
          errorMessage =>
            new OperationError(`Invalid clientName: ${errorMessage}`,
                               ErrorSubcode.PARAMETER_OUT_OF_RANGE));
      if (error) {
        throw error;
      }
      cc.addParameter(new SMFParameter(0,
                                       SMFClientCtrlParam.CLIENTNAME,
                                       nullTerminate(newValue)));
    }
    return cc;
  }

  static validateClientName(strName, exceptionCreator) {
    const encodeResult = validateAndEncode(DestinationType.TOPIC, strName, exceptionCreator);
    if (encodeResult.error) {
      return encodeResult.error;
    }
    // Add 1: bytes includes terminator, 160 excludes terminator
    if (encodeResult.bytes.length > 161) {
      return exceptionCreator('Client Name too long (max length: 160).');
    }
    return null;
  }
}


module.exports.ClientCtrlMessage = ClientCtrlMessage;
