const { Destination } = require('./lib/destination');
const { DestinationFromNetwork } = require('./lib/destination-from-network');
const { DestinationType } = require('./lib/destination-type');
const { DestinationUtil } = require('./lib/destination-util');
const { Parameter } = require('solclient-validate');
const { Queue } = require('./lib/queue');
const { SolclientFactory } = require('solclient-factory');
const { Topic } = require('./lib/topic');


/**
 * Creates a topic {@link solace.Destination} instance. When the returned Destination is set as
 * the destination of a message via {@link solace.Message#setDestination}, the message will be
 * delivered to direct subscribers or topic endpoints subscribed to the given topic.
 *
 * @param {String} topicName The topic string for the new topic.
 * @returns {solace.Destination} The newly created topic destination.
 * @method
 * @name solace.SolclientFactory.createTopicDestination
 */
SolclientFactory.createTopicDestination = SolclientFactory.createFactory((topicName) => {
  Parameter.isString('topicName', topicName);
  return Topic.createFromName(topicName);
});
/* @deprecated @*/
SolclientFactory.createTopic = SolclientFactory.createFactory(topicName => new Topic(topicName));

/**
 * Creates a durable queue {@link solace.Destination} instance. When the returned Destination is
 * set as the destination of a message via {@link solace.Message#setDestination}, the message will
 * be delivered to the Guaranteed Message queue on the Solace Message Router of the same name.
 *
 * @since 10.0.0
 * @param {String} queueName The queueName of the queue
 * @returns {solace.Destination} The newly created queue destination.
 * @method
 * @name solace.SolclientFactory.createDurableQueueDestination
 */
SolclientFactory.createDurableQueueDestination = SolclientFactory.createFactory((queueName) => {
  Parameter.isString('queueName', queueName);
  return Queue.createFromLocalName(queueName);
});

module.exports.Destination = Destination;
module.exports.DestinationFromNetwork = DestinationFromNetwork;
module.exports.DestinationType = DestinationType;
module.exports.DestinationUtil = DestinationUtil;
module.exports.Queue = Queue;
module.exports.Topic = Topic;
