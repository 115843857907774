/* eslint-disable no-restricted-syntax */

function isSuperset(set, subset) {
  return Array.from(subset).every(elem => set.has(elem));
}

function inplaceUnion(setA, setB) {
  setB.forEach((elem) => {
    setA.add(elem);
  });
  return setA;
}

function union(setA, setB) {
  return inplaceUnion(new Set(setA), setB);
}

function inplaceIntersection(setA, setB) {
  setA.forEach((elem) => {
    if (!setB.has(elem)) {
      setA.delete(elem);
    }
  });
  return setA;
}

function intersection(setA, setB) {
  return new Set(Array.from(setA).filter(elem => setB.has(elem)));
}

function inplaceDifference(setA, setB) {
  setB.forEach((elem) => {
    setA.delete(elem);
  });
  return setA;
}

function difference(setA, setB) {
  return new Set(Array.from(setA).filter(elem => !setB.has(elem)));
}

module.exports = {
  SetOperations: {
    inplaceDifference,
    inplaceIntersection,
    inplaceUnion,
    isSuperset,
    difference,
    intersection,
    union,
  },
}
;
