const { Bits, Convert } = require('solclient-convert');
const { ClientCtrlMessage, SMFParameter } = require('../message-objects');
const { LOG_ERROR } = require('solclient-log');

const {
  get: bits,
  set: setBits,
} = Bits;

const {
  int8ToStr, /*strToInt8,*/
  int16ToStr, /*strToInt16,*/
  int32ToStr, /*strToUInt32,*/
} = Convert;

function parseCCAt(dataBuf, offset, payloadLen) {
  const ccMsg = new ClientCtrlMessage();
  if (payloadLen < 6 || offset + 6 > dataBuf.length) {
      // not enough data! Return empty.
      // This is required because we can get an empty CC payload as a router response
    return ccMsg;
  }

  let pos = offset;
  const twobytes = dataBuf.readUInt16BE(pos);
  pos += 2;
    // var uh = bits(twobytes, 15, 1);
  const version = bits(twobytes, 8, 3);
  const msgType = bits(twobytes, 0, 8);
  const len = dataBuf.readUInt32BE(pos);
  pos += 4;

    // Sanity check: we support ClientCtrl v1
  if (version !== 1) {
    LOG_ERROR(`Unsupported ClientCtrl version ${version}`);
    return false;
  }

  if (len <= 0 || (offset + len) > dataBuf.length) {
    return false;
  }

  ccMsg.msgType = msgType;
  ccMsg.version = version;
  while (pos < (offset + len)) {
    const onebyte = dataBuf.readUInt8(pos);
    pos++;
    const paramUh = bits(onebyte, 7, 1);
    const paramType = bits(onebyte, 0, 7);
    const paramLen = dataBuf.readUInt32BE(pos);
    if (paramLen <= 0) {
      return false; // SMF parsing fail
    }

    pos += 4;
    const paramValueLen = paramLen - 5;
    const smfP = new SMFParameter(paramUh, paramType, null, dataBuf, pos, pos + paramValueLen);
    ccMsg.addParameter(smfP);
    pos += paramValueLen;
  }
  return ccMsg;
}

function encCC(ccMsg) {
  const paramSpace = [];
  const paramArray = ccMsg.getParameterArray();
    /*
    ClientCtrl Parameter formatting:
        1 byte uh/type
        4 bytes length
        N bytes value
     */
  for (let p = 0, n = paramArray.length; p < n; ++p) {
    const currentParam = paramArray[p];
      // It's not a flat array, we have gaps!
    if (currentParam === undefined) {
      continue;
    }
    let currentParamOneByte = 0;
    currentParamOneByte = setBits(currentParamOneByte, currentParam.getUh(), 7, 1);
    currentParamOneByte = setBits(currentParamOneByte, currentParam.getType(), 0, 7);
    paramSpace.push(int8ToStr(currentParamOneByte));
    paramSpace.push(int32ToStr(currentParam.getValue().length + 5));
    paramSpace.push(currentParam.getValue());
  }

  const paramData = paramSpace.join('');
  let twobytes = 0;
  twobytes = setBits(twobytes, 0, 15, 1); // uh
  twobytes = setBits(twobytes, 0, 11, 4); // RFU
  twobytes = setBits(twobytes, 1, 8, 3); // version
  twobytes = setBits(twobytes, ccMsg.msgType, 0, 8); // msgtype

  const data = [];
  data.push(int16ToStr(twobytes)); // first 2B (uh, version, msgtype)
  data.push(int32ToStr(6 + paramData.length)); // length: 6B header + params
  data.push(paramData);
  return data.join('');
}

module.exports.parseCCAt = parseCCAt;
module.exports.encCC = encCC;
