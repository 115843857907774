const { Bits, Convert } = require('solclient-convert');

const {
  get: bits,
} = Bits;
const {
  int8ToStr,
  int24ToStr,
} = Convert;

/**
 * @classdesc
 *
 * BinaryMetaBlock represents a binary metadata block in a TrMsg
 *
 * @private
 */
class BinaryMetaBlock {
  constructor(type, payload) {
    this.type = type;
    this.payload = payload;
  }

  asEncodedSmf() {
    const smf = [];
    smf.push(int8ToStr(1));
    smf.push(int8ToStr(this.type));
    smf.push(int24ToStr(this.payload.length));
    smf.push(this.payload.toString('latin1'));
    return smf.join('');
  }

  static fromEncodedSmf(bufSmf, offset = 0) {
    if ((bufSmf.length - offset) < 6) {
      return null; // not enough data
    }
    const chunkCount = bufSmf.readUInt8(offset);
    const fourbyte = bufSmf.readInt32BE(offset + 1);
    const type = bits(fourbyte, 24, 8);
    const payloadLen = bits(fourbyte, 0, 24);
    const payloadOffset = chunkCount * 4 + 1;
    const payload = bufSmf.slice(offset + payloadOffset, offset + payloadOffset + payloadLen);
    return new BinaryMetaBlock(type, payload);
  }

}


module.exports.BinaryMetaBlock = BinaryMetaBlock;
