const { Enum } = require('solclient-eskit');

const FlowOperation = {
  CONNECT:         'FlowOperation_CONNECT',
  DISCONNECT:      'FlowOperation_DISCONNECT',
  START:           'FlowOperation_START',
  STOP:            'FlowOperation_STOP',
  DISPOSE:         'FlowOperation_DESTROY',
  GET_STATS:       'FlowOperation_GET_STATS',
  RESET_STATS:     'FlowOperation_RESET_STATS',
  GET_PROPERTIES:  'FlowOperation_GET_PROPERTIES',
  GET_DESTINATION: 'FlowOperation_GET_DESTINATION',
};

module.exports.FlowOperation = Enum.new(FlowOperation);
