const {
  ErrorSubcode,
  NotImplementedError,
  OperationError,
} = require('solclient-error');
const { EventEmitter } = require('solclient-events');
const { FlowOperation } = require('./flow-operation');
const { LogFormatter } = require('solclient-log');
const { PrivateFlowEventName } = require('./private-flow-event-names');
const { Stats } = require('solclient-stats');

// Unless flow is disposed, these operations are always allowed
const ALWAYS_OPS = [
  FlowOperation.DISPOSE,
  FlowOperation.GET_STATS,
  FlowOperation.GET_PROPERTIES,
  FlowOperation.RESET_STATS,
];


/**
 * @classdesc Flow
 * * <b>This class is not exposed for construction by API users.</b>
 * A Flow is an abstract base class. A Flow represents a guaranteed message connection to the
 * Solace Message Router. There may be many Guaranteed Message
 * Consumers on a {@link solace.Session}, each
 * instantiated as a {@link solace.MessageConsumer}.
 * @memberof solace
 * @extends {solace.EventEmitter}
 * @private
 */
class Flow extends EventEmitter {

  /**
   * Creates a Flow instance.
   * @constructor Flow
   * @param {APIProperties} flowProperties The properties object for this flow.
   * @param {Object} sessionInterfaceFactory Function that creates session interface methods
   * @param {Object} [emitterOptions] The options to pass to the EventEmitter constructor.
   * @private
   */
  constructor(flowProperties, sessionInterfaceFactory, emitterOptions) {
    const emitterOptionsFull = Object.assign({}, emitterOptions);
    emitterOptionsFull.emits = (emitterOptionsFull.emits || []).concat(
      PrivateFlowEventName.values
    );
    super(emitterOptionsFull);
    const sessionInterface = sessionInterfaceFactory(this);
    const self = this;
    this.logger = new LogFormatter((...args) =>
      [`[session=${sessionInterface.sessionIdHex}]`, `[flow=${self.flowIdDec}]`, ...args]);
    this.log = this.logger.wrap(this.log, this);
    // dispose() was called on this flow.
    // It is not always executed immediately, leaving time for the auto-ack.
    this._disposing = false;
    // The flow truly was destroyed, no more delays.
    this._disposed = false;
    this._userDisconnected = true;
    this._properties = flowProperties;
    this._sessionInterface = sessionInterface;
    this._stats = new Stats(sessionInterface);
    this._privateEventEmitter = new EventEmitter(emitterOptionsFull);
  }

  // Private event emitter functions, hidden from the public interface.
  _emit(type, ...args) {
    this._privateEventEmitter.emit(type, ...args);
    this.emit(type, ...args);
  }
  _on(type, listener) {
    this._privateEventEmitter.on(type, listener);
  }
  _once(type, listener) {
    this._privateEventEmitter.once(type, listener);
  }
  _removeListener(type, listener) {
    this._privateEventEmitter.removeListener(type, listener);
  }

  /**
   * Clears all statistics for this Guaranteed Message Connection. All previous Guaranteed
   * Message Connection statistics are lost
   * when this is called.
   * @throws {solace.OperationError}
   *  * if the Message Consumer is disposed. subcode = {@link solace.ErrorSubcode.INVALID_OPERATION}
   */
  clearStats() {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Clearing stats');
    this._operationCheck(FlowOperation.RESET_STATS);
    this._stats.resetStats();
  }

  /**
   * Establish a Guaranteed Messaging connection.
   * The Messge Consumer may immediately begin emitting events. The application is expected to
   * add listeners for events on this Message Consumer before calling this method.
   */
  connect() {
    const { LOG_DEBUG } = this.logger;
    LOG_DEBUG('Connecting');
    this.userDisconnected = false;
    this._operationCheck(FlowOperation.CONNECT);
  }

  /**
   * Disposes the Guaranteed Message connection, removing all listeners and releasing references.
   */
  dispose() {
    const { LOG_TRACE, LOG_DEBUG } = this.logger;
    // The session may dispose the publisher or consumer, so be tolerant of
    // multiple attempts to do so.
    if (this._disposed || this._disposing) {
      LOG_TRACE('Ignoring #dispose on disposed Guaranteed Message connection');
      return;
    }
    LOG_TRACE('Disposing');

    this._operationCheck(FlowOperation.DISPOSE);

    this._disposing = true;

    const terminate = () => {
      this._disposed = true;
      this._properties = null;
      this._userDisconnected = true;
      this._emit(this.getDisposedEvent());
      this.disableEmitter();
      this._privateEventEmitter.disableEmitter();
      LOG_DEBUG('Disposed');
    };
    if (this._fsm._midDispatch) {
      // weird case, dispose was called by the user from a message callback:
      // Guard this in a timeout
      const terminateWithRunningFSM = () => {
        this._fsm.terminateFsm();
        terminate();
      };
      //setImmediate(() => terminateWithRunningFSM());
      setTimeout(() => terminateWithRunningFSM(), 0);
    } else {
      // normal case:
      terminate();
    }
  }

  /**
   * Disconnects the Message Consumer in such a way that it can be reconnected.
   */
  disconnect() {
    this._operationCheck(FlowOperation.DISCONNECT);
    const { LOG_DEBUG } = this.logger;
    LOG_DEBUG('Disconnecting');
    this.userDisconnected = true;
  }

  /**
   *
   * Begins a disconnect that is not user-initiated.
   *
   * Must be overridden.
   *
   * @private
   * @memberof Flow
   */
  _disconnectSession() {
    this._operationCheck(FlowOperation.DISCONNECT);
  }

  /**
   * Gets the event to be returned when the Consumer or Publisher is disposed.
   *
   * Must be overridden.
   *
   * @private
   * @memberof Flow
   */
  getDisposedEvent() { // eslint-disable-line class-methods-use-this
    throw new NotImplementedError('Abstract method');
  }

  /**
   * Creates and returns copy of the properties for this object.
   * @returns {?} The properties object
   * @internal
   */
  getProperties() {
    this._operationCheck(FlowOperation.GET_PROPERTIES);
    return this._properties.clone();
  }

  /**
   * Returns a statistic for this Guaranteed Message connection.
   *
   * @param {solace.StatType} statType The statistic to return.
   * @returns {Number} The value for the statistic.
   */
  getStat(statType) {
    this._operationCheck(FlowOperation.GET_STATS);
    return this._stats.getStat(statType);
  }

  /**
   * @param {AdMessage} message The message to be handled by this Consumer or Publisher
   * @private
   */
  handleUncorrelatedControlMessage(message) { // eslint-disable-line class-methods-use-this
    throw new NotImplementedError('Guaranteed Message Connection does not implement a control message handler', message);
  }

  /**
   * @param {StatType} statType The stat to increment
   * @param {Number} [value] The value to add to the statistic.
   * @private
   */
  incStat(statType, value) {
    this._stats.incStat(statType, value);
  }

  /**
   * @param {any} event The event to be handled by this objects's FSM
   * @private
   */
  processFSMEvent(event) {
    this._fsm.processEvent(event);
  }

  /**
   * @returns {String} An inspection of this object's properties
   * @private
   */
  [util_inspect_custom]() {
    return {
      'flowId': this.flowIdDec,
    };
  }

  /**
   * @returns {String} A description of this Guaranteed Message Connection
   */
  toString() {
    return this[util_inspect_custom]();
  }

  /**
   * @returns {Boolean} Whether this Publisher or Consumer can be connected.
   * @readonly
   * @private
   */
  get canAck() {
    return !this.disposed;
  }

  /**
   * Returns true if this Guaranteed Message Consumer was disposed.
   */
  get disposed() {
    return this._disposed;
  }

  get flowIdDec() {
    return this.flowId || '(N/A)';
  }

  /**
   * @returns {Number} The ID for this flow
   * @readonly
   * @private
   */
  get flowId() { // eslint-disable-line class-methods-use-this
    return new NotImplementedError('Flow does not implement ID accessor');
  }

  /**
   * @returns {solace.Session} The owning session for this MessageConsumer.
   * @readonly
   */
  get session() {
    return this._session;
  }

  get userDisconnected() {
    return this._userDisconnecte;
  }
  set userDisconnected(value) {
    this._userDisconnected = value;
  }

  /**
   * @param {FlowOperation} operation The operation to check
   * @returns {Boolean} `true` if the operation is allowed.
   * @throws {@link solace.OperationError} if the operation is not allowed.
   * @private
   */
  _operationCheck(operation) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE(`Checking operation ${FlowOperation.describe(operation)}`);
    if (this._disposed) {
      throw new OperationError('Operation is invalid for Message Consumer in disposed state',
                               ErrorSubcode.INVALID_OPERATION);
    }

    // Any read-only operation is always valid unless the object was disposed
    // (which purges properties)
    if (ALWAYS_OPS.some(v => v === operation)) return true;

    if (operation === FlowOperation.DISCONNECT && this._isDisconnected()) {
      throw new OperationError(
        'Operation is invalid for Message Consumer in disconnected state',
        ErrorSubcode.INVALID_OPERATION
      );
    }

    return undefined;
  }

  _isDisconnected() { // eslint-disable-line class-methods-use-this
    throw new NotImplementedError('Flow#_isDisconnected not implemented');
  }

}

module.exports.Flow = Flow;
