const { BaseMessage } = require('./base-message');

/**
 * @classdesc
 * Transport SMF Message
 * @memberof solace
 * @private
 */
class TransportSMFMessage extends BaseMessage {
  /**
   * @constructor
   */
  constructor() {
    super(null, null);
    this.uh = 0;
    this.messageType = null;
    this.sessionId = null;
    this.routerTag = null;
    this.payload = null;
    this.payloadLength = 0;
    this.tsHeaderLength = 0;
  }
}

module.exports.TransportSMFMessage = TransportSMFMessage;
