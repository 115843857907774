const { OperationError } = require('solclient-error');

let binding = null;

const ProfileBinding = {
  get value() {
    if (binding === null) {
      throw new OperationError('Profile binding not initialized. Call solace.SolclientFactory.init');
    }
    return binding;
  },
  set value(value) {
    // private, so we'll allow it for testing
    binding = value;
  },
};

module.exports.ProfileBinding = ProfileBinding;
