const { BaseChecks } = require('./base-checks');

/**
 *
 * Generates a then closure.
 *
 * @param {Boolean} doOp Whether the returned then function should call its argument.
 * @returns {Object} An object { then(fn, elseFn=undefined) }. If doOp was true, then(fn) will
 *                      call fn. If not, elseFn will be called if supplied.
 * @private
 */
function thenGen(doOp) {
  return {
    then: doOp ? f => f() : (_, elseFn) => elseFn(),
  };
}

/**
 *
 * none, anything, boolean, ... @returns {Boolean}
 * when(condition) @returns then(fn, elseFn) => if condition fn() else elseFn && elseFn()
 * unless(condition, else) @returns then(fn, elseFn) => if !condition fn() else elseFn && elseFn
 * whenNone, whenAnything, whenBoolean, ... @returns {function} then(fn, elseFn)
 * unlessNone, unlessAnything, unlessBoolean ... @returns {function} unless(fn, elseFn)
 * @private
 */
const Check = (() => {
  const result = Object.assign({}, BaseChecks);
  result.when = condition => thenGen(condition);
  result.unless = condition => thenGen(!condition);
  return result;
})();

module.exports.Check = Check;
