const { Enum } = require('solclient-eskit');
/**
 * An enumeration of queue browser event names. A {@link solace.QueueBrowser} will emit
 * these events as part of its lifecycle.  Applications, having created a QueueBrowser can
 * choose to listen to all of the events described here, or any subset of these events. For Example:
 * <pre>
 *   <code>
 *     qb = solace.Session.createQueueBrowser(...);
 *     qb.on(solace.QueueBrowserEventName.CONNECT_FAILED_ERROR,
 *           function connectFailedErrorEventCb(error) {
 *             // details is an OperationError object
 *           });
 *   </code>
 * </pre>
 *
 * @memberof solace
 * @enum {string}
 * @namespace
 */
const QueueBrowserEventName = {
  /**
   * The queue browser is established.
   * @event solace.QueueBrowserEventName#UP
   */
  UP:                   'QueueBrowserEventName_up',
  /**
   * The queue browser is successfully disconnected.
   * The queue browser is disabled.
   *
   * @event solace.QueueBrowserEventName#DOWN
   */
  DOWN:                 'QueueBrowserEventName_down',
 /**
   * The queue browser was established and then disconnected by the router,
   * likely due to operator intervention. The queue browser is disabled.
   *
   * @event solace.QueueBrowserEventName#DOWN_ERROR
   * @param {solace.OperationError} error Details of the error.
   */
  DOWN_ERROR:           'QueueBrowserEventName_downError',
  /**
   * The queue browser attempted to connect but was unsuccessful.
   * The queue browser is disabled.
   *
   * @event solace.QueueBrowserEventName#CONNECT_FAILED_ERROR
   * @param {solace.OperationError} error Details of the error.
   */
  CONNECT_FAILED_ERROR: 'QueueBrowserEventName_connectFailedError',
  /**
   * The queue browser will not connect because the current session is incompatible
   * with Guaranteed Messaging. The queue browser is disabled until a compatible session
   * is available.
   *
   * @event solace.QueueBrowserEventName#GM_DISABLED
   */
  GM_DISABLED:          'QueueBrowserEventName_GMDisabled',
  /**
   * The queue browser is being disposed. No further events will be emitted.
   *
   * @event solace.QueueBrowserEventName#DISPOSED
   */
  DISPOSED:             'QueueBrowserEventName_disposed',
  /**
   * A message was received on the queue browser.
   *
   * If the application did not retain a reference to the message, it
   * may be redelivered by calling {@link solace.QueueBrowser#disconnect} followed by
   * {@link solace.QueueBrowser#connect} depending on the configuration of the queue.
   *
   * When there is no listener for <i>MESSAGE</i> on a QueueBrowser, messages are queued
   * internally until a listener is added.
   *
   * @event solace.QueueBrowserEventName#MESSAGE
   * @param {solace.Message} message The received message being delivered in this event.
   */
  MESSAGE:              'QueueBrowserEventName_message',
};

module.exports.QueueBrowserEventName = Enum.new(QueueBrowserEventName);
