const { Enum } = require('solclient-eskit');
const { LOG_TRACE,
        LOG_DEBUG } = require('solclient-log');
const { Long } = require('solclient-convert');

const TransportAckResult = Enum.new({
  OK:           0,
  DUPLICATE:    1,
  OUT_OF_ORDER: 2,
});

class TransportAcks {
  constructor(id = 0) {
    const base = typeof id === 'number' ? Long.fromNumber(id, true) : Long.fromValue(id);
    this.lastAcked = base;
    this._acksPending = 0;
  }

  reset() {
    this._acksPending = 0;
    this.lastAcked = Long.ZERO;
  }

  tryReceive(messageID, prevMessageID) {
    if (this._lastReceived.lt(prevMessageID)) {
      LOG_DEBUG(`Rejecting out of order message: ${prevMessageID} (last received: ${this._lastReceived})`);
      return TransportAckResult.OUT_OF_ORDER;
    }
    if (this._lastReceived.gte(messageID)) {
      LOG_DEBUG(`Rejecting duplicate message: ${messageID} (last received: ${this._lastReceived})`);
      // duplicate messages indicate the router is retransmitting because it expects a transport ack
      this._acksPending++;
      return TransportAckResult.DUPLICATE;
    }
    LOG_TRACE(`Accepting message: ${messageID}`);
    this._lastReceived = messageID;
    this._acksPending++;
    return TransportAckResult.OK;
  }

  setAcked() {
    this._lastAcked = Long.fromValue(this._lastReceived);
    this._acksPending = 0;
  }

  get acksPending() {
    return this._acksPending;
  }

  get lastAcked() {
    return this._lastAcked;
  }

  /**
   * Resets the beginning of the ack sequence to the given value.
   * @param {Long} value The value to set as last acknowledged ID.
   */
  set lastAcked(value) {
    LOG_TRACE('Setting last acked:', value.toString());
    Object.assign(this, {
      _lastAcked:    Long.fromValue(value),
      _lastReceived: Long.fromValue(value),
    });
  }

  get lastReceived() {
    return this._lastReceived;
  }

  toString() {
    return util_inspect(this);
  }
}

module.exports = {
  TransportAcks,
  TransportAckResult,
};
