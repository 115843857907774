const { Enum, assert } = require('solclient-eskit');
const { LOG_DEBUG, LOG_ERROR } = require('solclient-log');

/**
 * @private
 */
const ApplicationAckState = Enum.new({
  UNACKED:        'UNACKED',
  ACKED_NOT_SENT: 'ACKED_NOT_SENT',
  ACKED_SENT:     'ACKED_SENT',
});

// For the ring buffer update index, we have two key-value options:
//  Map() - generally faster where supported
//  Object - seems to automatically coerce keys to string, which is what we want in this case
// By using Map and explicly creating a .key attribute and using it whenever possible,
// we should get performance that is no worse than Object, which may have to perform that
// coercion more often.

/**
 * @private
 */
class ApplicationAck {
  constructor(id, state) {
    if (id) {
      this.exists = true;
      this.id = id;
      this.key = id.toString();
      this.state = state || ApplicationAckState.UNACKED;
    } else {
      this.exists = false;
    }
  }

  set(id, state) {
    this.exists = true;
    this.id = id;
    this.key = id.toString();
    this.state = state || ApplicationAckState.UNACKED;
  }

  clear() {
    this.exists = false;
    this.id = null;
    this.key = null;
    this.state = null;
  }
}

/**
 * @private
 */
class ApplicationAckRingBuffer {
  /**
   * Creates an instance of ApplicationAckRingBuffer. A standard ring buffer except that
   * it reserves an empty entry so that an operation can occur during insert that includes
   * both the new element and any element to be evicted. Also includes an ID-to-index map
   * to speed updates.
   *
   * @param {Number} size The number of entries in the ring. Effective size is (size - 1).
   * @memberof ApplicationAckRingBuffer
   */
  constructor(size) {
    LOG_DEBUG('Creating application ack ring buffer with size ', size, ' retained size', size - 1);
    assert(size >= 2); // one always free doesn't work with < 2 elements
    this._size = size;
    this._insertIndex = 0;
    /**
     * @property {Array.<?ApplicationAck>}
     */
    this._buffer = Array(size).fill(null).map(() => new ApplicationAck());
    /**
     * @property {Map.<String, Number>}
     */
    this._index = new Map();
  }

  reset() {
    this._insertIndex = 0;
    this._buffer.forEach((ack) => { ack.exists = false; });
    this._index.clear();
  }

  /**
   * @param {Long} id The id of the new entry
   * @param {function(ApplicationAck)} beforeEvictCallback Call this before operation completes
   * @returns {any} Return value of beforeEvictCallback
   * @private
   */
  insert(id, beforeEvictCallback) {
    assert(beforeEvictCallback);
    assert(id);

    const size = this._size;
    const buffer = this._buffer;
    const index = this._index;
    const insertIndex = this._insertIndex;

    /*
     * Because of the postprocessing step, we are not re-entrant safe. It is expected
     * that we are called from TCP -> Transport -> Session -> acceptMessage().
     * This assert verifies that the postprocess cleanup has happened.
     */
    assert(!buffer[insertIndex].exists, 'Invariant not enforced (before): insert index not empty');

    // First, insert.
    const inserting = buffer[insertIndex];
    inserting.set(id, ApplicationAckState.UNACKED);
    if (index.has(inserting.key)) {
      LOG_ERROR(`Duplicate ID: ${index.get(inserting.key)} insertIndex: ${insertIndex}`);
    }
    index.set(inserting.key, insertIndex);

    const evictingIndex = (insertIndex + 1) % size;
    const evicting = buffer[evictingIndex];
    let result;
    try {
      result = beforeEvictCallback(evicting.exists ? evicting : null);
    } finally {
      // Always clean up to keep the RB in a consistent state.
      // If inProgressCallback threw, this runs before the exception propagates.

      // Update insert index for next operation.
      this._insertIndex = (insertIndex + 1) % size;

      if (evicting.exists) {
        index.delete(evicting.key);
        evicting.clear();
      }
    }

    assert(!buffer[this._insertIndex].exists, 'Invariant not enforced (after): insert index not empty');
    return result;
  }

  /**
   * Returns the number of elements in this ringbuffer. Will increase to
   * one less than the size provided to the constructor as the buffer is used.
   *
   * @readonly
   * @memberof ApplicationAckRingBuffer
   */
  get length() {
    return this._index.size;
  }

  /**
   * Returns the first element in the ring buffer.
   *
   * This could be called from the evict callback, so insert needs to be sure
   * that internal state is correct for that scenario.
   *
   * The expected case is that the first element is at insertIndex + 1, but if the
   * buffer has not been filled yet, we will iterate the buffer and skip nonexistent
   * elements to find it.
   *
   * This is a little inefficient until we have received this._size messages, but after
   * that it is more efficient than maintaining a read pointer.
   *
   * @returns {?ApplicationAck} The first ack in the buffer.
   */
  front() {
    if (this.length === 0) return null;

    const buffer = this._buffer;
    const insertIndex = this._insertIndex;
    const size = this._size;
    const firstIndex = (insertIndex + 1) % size;
    // Cannot assert invariant here: insert is usually in progress.

    // Buffer full case
    if (buffer[firstIndex].exists) return buffer[firstIndex];

    // Not yet filled case
    // The last checked element for the iteration is the start point,
    //  plus size, a full lap including the start index,
    //  minus 1, to exclude the start
    //  minus 1, to exclude the invariant null entry
    for (let rawIndex = firstIndex, lastIndex = firstIndex + size - 1;
         rawIndex <= lastIndex;
         ++rawIndex) {
      const readIndex = rawIndex % size;
      const element = buffer[readIndex];
      if (element.exists) {
        return element;
      }
    }

    // Buffer is completely empty
    assert(this._index.size === 0, '#front() failed so buffer must be empty');
    return null;
  }

  /**
   * Rather than implementing the iterator protocol, which requires Symbol support,
   * we'll implement a forEach that behaves as though this is an array.
   *
   * @param {function(ApplicationAppState, index, collection)} callback The iteration callback
   */
  forEach(callback) {
    if (this.length === 0) return;

    const buffer = this._buffer;
    const size = this._size;
    let index = 0;

    for (let rawIndex = this._insertIndex + 1, lastIndex = this._insertIndex + size;
         rawIndex <= lastIndex;
         ++rawIndex) {
      const readIndex = rawIndex % size;
      const element = buffer[readIndex];
      if (element.exists) {
        callback(element, index++, this);
      }
    }

    assert(index > 0, 'Not empty but did not dispatch');
  }

  /**
   * @param {Long} id The ID to update
   * @param {ApplicationAckState} state The new state for the ID
   * @private
   */
  updateAckState(id, state) {
    const key = id.toString(); assert(this._index.has(key), 'Ack key not found');
    const buffer = this._buffer;
    const updateIndex = this._index.get(key);
    const existing = buffer[updateIndex]; assert(existing, 'Ack key has no entry');
    existing.state = state;
  }

  /**
   * @param {Long} id The ID to look up
   * @returns {Boolean} `true` if this ID exists in the ringbuffer
   */
  has(id) {
    const key = id.toString();
    return this._index.has(key);
  }
}

Object.assign(module.exports, {
  ApplicationAckState,
  ApplicationAck,
  ApplicationAckRingBuffer,
});
