const { Enum } = require('solclient-eskit');

/**
 * Represents an enumeration of message delivery modes.
 *
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const MessageDeliveryModeType = {
  /**
   * This mode provides at-most-once message delivery. Direct messages have
   * the following characteristics:
   *   * They are not retained for clients that are not connected to a Solace Message Router.
   *   * They can be discarded when congestion or system failures are encountered.
   *   * They can be reordered in the event of network topology changes.
   *
   * Direct messages are most appropriate for messaging applications that require very
   * high-rate or very low-latency message transmission. Direct Messaging enables
   * applications to efficiently publish messages to a large number of clients
   * with matching subscriptions.
   */
  DIRECT:         0,
  /**
   * A Persistent delivery mode is used for Guaranteed Messaging, and this delivery mode
   * is most appropriate for applications that require persistent storage of the messages
   * they send or intend to receive. Persistent messages have the following characteristics:
   *
   *  * They cannot be discarded or lost (once they are acknowledged by the Solace Message Router).
   *  * They cannot be reordered in the event of network topology changes.
   *  * They cannot be delivered more than once to a single client (unless the redelivered
   *    message flag is applied).
   *  * When they match subscriptions on durable endpoints, they are retained for a client
   *    when that client is not connected.
   *
   * Persistent messages are most appropriate for applications that require persistent storage
   * of the messages they send or intend to receive.
   */
  PERSISTENT:     1,
  /**
   * This mode is functionally the same as Persistent. It exists to facilitate interaction
   * with JMS applications. In most situations where you want to use Guaranteed Messaging,
   * it is recommended that you use {@link solace.MessageDeliveryModeType.PERSISTENT}.
   */
  NON_PERSISTENT: 2,
};

module.exports.MessageDeliveryModeType = Enum.new(MessageDeliveryModeType);
