const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SMFSMPMessageType = {
  ADDSUBSCRIPTION:              0x00,
  REMSUBSCRIPTION:              0x01,
  ADDQUEUESUBSCRIPTION:         0x02,
  REMQUEUESUBSCRIPTION:         0x03,
  ADDSUBSCRIPTIONFORCLIENTNAME: 0x04,
  REMSUBSCRIPTIONFORCLIENTNAME: 0x05,
};

module.exports.SMFSMPMessageType = Enum.new(SMFSMPMessageType);
