const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SMFAdProtocolMessageType = {
  OPENPUBFLOW:      0x00,
  // INTERROUTERACK:          0x01,
  // INTERROUTERHANDSHAKEACK: 0x02,
  CLIENTACK:        0x03,
  BIND:             0x04,
  UNBIND:           0x05,
  UNSUBSCRIBE:      0x06,
  CLOSEPUBFLOW:     0x07,
  CREATE:           0x08,
  DELETE:           0x09,
  // FLOWRECOVER:  0x0a,
  TRANSACTIONCTRL:  0x0b,
  FLOWCHANGEUPDATE: 0x0c,
  // EXTERNALACK:      0x0d,
  XACTRL:           0x0e,
  CLIENTNACK:       0x0f,
  // TXNCTRL:          0x10,
};

module.exports.SMFAdProtocolMessageType = Enum.new(SMFAdProtocolMessageType);
