const { CacheGetResultCode } = require('./cache-get-result-codes');

const DEFAULTS = {
  messageID:         null,
  version:           0,
  responseCode:      CacheGetResultCode.INVALID,
  responseString:    '',
  matchTopic:        '',
  sessionID:         null,
  isSuspect:         null,
  hasMore:           null,
  hasTimestamps:     null,
  replyTo:           null,
  messageStream:     null,
  clusterNameStream: null,
};

/**
 * @classdesc CacheGetResult encapsulates the result of a cache get request
 * @private
 */
class CacheGetResult {
  /*
   * @constructor
   */
  constructor(options = DEFAULTS) {
    Object.assign(this, options);
  }

  readFromStream(stream) {
    this.messageID = stream.getNext().getValue();
    this.version = stream.getNext().getValue();
    this.responseCode = stream.getNext().getValue();
    this.responseString = stream.getNext().getValue();
    // SD claims instanceName is present here but empirically, it's not

    this.matchTopic = stream.getNext().getValue();
    this.sessionID = stream.getNext().getValue();
    this.isSuspect = stream.getNext().getValue();
    this.hasMore = stream.getNext().getValue();

    this.hasTimestamps = stream.getNext().getValue();

    if (stream.hasNext()) { // first stream
      this.messageStream = stream.getNext().getValue();
    }
    if (stream.hasNext()) { // if two streams, first was cluster name stream
      this.clusterNameStream = this.messageStream;
      this.messageStream = stream.getNext().getValue();
    }
  }
}

module.exports.CacheGetResult = CacheGetResult;
