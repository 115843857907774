const HTTPLib = require('./http');
const { WebSocketTransportSession } = require('./websocket-transport-session');

const { HTTPConnection } = HTTPLib;

const WebTransportCapabilities = {
  webSocket() { return WebSocketTransportSession.browserSupportsBinaryWebSockets(); },
  xhrBinary() { return HTTPConnection.browserSupportsXhrBinary(); },
  streaming() { return HTTPConnection.browserSupportsStreamingResponse(); },
};

module.exports.WebTransportCapabilities = WebTransportCapabilities;
