const {
  LOG_TRACE,
  LOG_DEBUG,
  LOG_INFO,
  LOG_WARN,
  LOG_ERROR } = require('solclient-log');

const { ArrayUtils } = require('solclient-util');
const { Convert,
        Hex } = require('solclient-convert');
const { ErrorSubcode } = require('solclient-error');
const { mixin } = require('solclient-eskit');
const { TransportError } = require('../transport-error');
const { TransportReturnCode } = require('../transport-return-codes');
const { TransportSessionEvent } = require('../transport-session-event');
const { TransportSessionEventCode } = require('../transport-session-event-codes');
const { TransportSessionState } = require('../transport-session-states');
const { WebSocketCloseCodes } = require('./websocket-close-codes');
const { WebTransportSessionBase } = require('./web-transport-session-base');

const http = require('http');
const https = require('https');

const {
  stringToArrayBuffer,
} = Convert;
const {
  formatHexString,
} = Hex;
const {
  includes,
} = ArrayUtils;

/* eslint-env browser */
// closure mangles window.WebSocket
const globalContext = (typeof window === 'undefined' ? global : window);
let MyWebSocket = globalContext.WebSocket;
if (BUILD_ENV.TARGET_NODE) {
  /* eslint-disable no-global-assign */
  /* eslint-disable global-require */
  MyWebSocket = /** @type {WebSocket} */ (require('ws'));
  /* eslint-enable no-global-assign */
  /* eslint-enable global-require */
}
/* eslint-env shared-browser-node */

function adaptURL(url) {
  const v = url.match(/(ws|http)(s?:\/\/.+)/);
  return `ws${v[2]}`;
}


/**
 * @classdesc
 * @private
 * @memberof solace
 */
class WebSocketTransportSession extends WebTransportSessionBase {

  /**
   * @constructor
   * @param {String} url The url to connect to
   * @param {function} eventCB The callback for transport events
   * @param {SMFClient} client The SMF client for data events
   * @param {Object} props Properties for the transport session
   */
  constructor(url, eventCB, client, props) {
    super(url, eventCB, client, props);
    /**
     * @type {String}
     */
    this._url = adaptURL(url);

    /**
     * @type {?WebSocket}
     */
    this._socket = null;

    /**
     * @type {?string}
     */
    this._sessionId = new Date().getTime();

    if (BUILD_ENV.TARGET_BROWSER) {
      /**
       * @type {Number}
       */
      this._bufferedAmountQueryIntervalInMsecs = props.bufferedAmountQueryIntervalInMsecs;
      /**
       * @type {?number}
       */
      this._bufferedAmountQueryTimer = null;
      /**
       * @type {Number}
       */
      this._bufferedAmountQueryIntervalDelayMultiplier = 1;
    }
  }


  /**
   * @private
   */
  onOpen() {
    this.cancelConnectTimeout();
    this._state = TransportSessionState.SESSION_UP;
      // Send the event to the application letting it know that the session is up
    this._eventCB(
          new TransportSessionEvent(
              TransportSessionEventCode.UP_NOTICE,
              'Connected',
              0,
              null,
              this._sessionId));
  }

  /**
   * @param {Socket} originalSocket bound at the time of callback registration,
   * safety against stray calls after socket is destroyed.
   * @param {Event} event The websocket event causing the close
   * @private
   */
  onClose(originalSocket, event) {
    if (originalSocket !== this._socket) {
      LOG_DEBUG('Websocket Transport Session stray onClose for previous socket, ignoring.');
      return;
    }
    if (this._state === TransportSessionState.WAITING_FOR_DESTROY) {
      LOG_TRACE('WebSocket transport is being destroyed, ignore error');
      return;
    }
    const msgBuf = [];
    const code = WebSocketCloseCodes[event.code] || WebSocketCloseCodes[0];
    msgBuf.push(`${event.code} ${code.name} (${code.description})`);
    if (event.wasClean !== undefined) {
      msgBuf.push(`clean closure: ${event.wasClean}`);
    }
    if (event.reason) {
      msgBuf.push(`reason: ${event.reason}`);
    }
    const msg = msgBuf.join(', ');
    LOG_DEBUG(`WebSocket transport connection is closed ${msg}`);
    LOG_TRACE(`Event { type: ${event.type} wasClean: ${event.wasClean} code: ${event.code} reason: ${event.reason} }`);
    this._state = TransportSessionState.CONNECTION_FAILED;
    this.destroy(`Connection closed: ${msg}`, ErrorSubcode.COMMUNICATION_ERROR);
  }

  onDrain() {
    LOG_TRACE('Drained socket');
    this.maybeEmitCanSend();
    this.maybeEmitFlush();
  }

  onBufferedAmountPoll() {
    if (this.getBufferedAmount() === 0) {
      this.onDrain();
    } else if (this.scheduleQuery) {
      this.scheduleQuery();
    }
  }

  /**
   * @param {Socket} originalSocket bound at callback registration.
   * @param {TransportSessionEvent} event The event causing the error
   * @private
   */
  onError(originalSocket, event) {
    LOG_INFO(`Websocket Transport Session onError for socket ${originalSocket} while socket is ${this._socket}`);
    if (originalSocket !== this._socket) {
      LOG_INFO('Websocket Transport Session stray onError for previous socket, ignoring.');
      return;
    }
    if (this._state === TransportSessionState.WAITING_FOR_DESTROY) {
      LOG_INFO('WebSocket transport is being destroyed, ignore error');
      return;
    }
    const msg = (event.message) ? (`: ${event.message}`) : '';
    LOG_INFO(`WebSocket transport connection error ${msg} while in state ${this._state}`);
    // There won't be an onClose call to clean up unless we are connected already.
    if (this._state === TransportSessionState.WAITING_FOR_CONNECT) {
      this.cancelConnectTimeout();
      this._state = TransportSessionState.CONNECTION_FAILED;
      this.destroy(`Connection failed: ${msg}`, ErrorSubcode.CONNECTION_ERROR);
    } else { // Sending error event only, onClose will clean up in due time.
      this._eventCB(
            new TransportSessionEvent(
                TransportSessionEventCode.SEND_ERROR,
                `Connection error${msg}`,
                null,
                ErrorSubcode.CONNECTION_ERROR, null));
    }
  }

  /**
   * @param {TransportSessionEvent} event The data event
   * @private
   */
  onMessage(event) {
    if (this._client) {
      this._client.rxDataArrayBuffer(event.data);
    }
  }

  /**
   * @override
   * @private
   */
  connectTimerExpiry() {
    LOG_INFO('WebSocket transport connect timeout');
    this.state = TransportSessionState.CONNECTION_FAILED;
    this._eventCB(new TransportSessionEvent(
      TransportSessionEventCode.CONNECT_TIMEOUT,
      'Connection timed out',
      null,
      ErrorSubcode.TIMEOUT
    ));
  }

  /**
   * @override
   * @private
   */
  connect() {
    if (this._state !== TransportSessionState.DOWN) {
      LOG_ERROR(`Invalid state for operation: ${TransportSessionState.nameOf(this._state)}`);
      return TransportReturnCode.INVALID_STATE_FOR_OPERATION;
    }
    if (!this._url) {
      LOG_WARN('Cannot connect to null URL');
      return TransportReturnCode.CONNECTION_ERROR;
    }
    if (this._socket) {
      this.onError('Socket already connected');
    }

    LOG_INFO('Establishing WebSocket transport session');
    try {
      this.createConnectTimeout();
      this._state = TransportSessionState.WAITING_FOR_CREATE;
      if (BUILD_ENV.TARGET_NODE && !this._socketOptions) {
        LOG_DEBUG('Creating Node websocket options');
        this._socketOptions = this.createWebSocketOptions(this.onDrain.bind(this));
      }
      LOG_INFO('Constructing socket');
      if (BUILD_ENV.TARGET_BROWSER) {
        /**
         * @type {WebSocket}
         */
        this._socket = new MyWebSocket(this._url, 'smf.solacesystems.com');
      } else {
        this._socket = new MyWebSocket(this._url, 'smf.solacesystems.com', this._socketOptions);
        this._socket.ondrain = this.onDrain.bind(this);
      }
      // Closure is generally faster than bind
      LOG_TRACE('Assigning properties to socket');
      // Closure using Browser profile to avoid mangling this
      this._socket.binaryType = 'arraybuffer';
      this._socket.onopen = this.onOpen.bind(this);
      this._socket.onmessage = this.onMessage.bind(this);
      this._socket.onclose = this.onClose.bind(this, this._socket);
      this._socket.onerror = this.onError.bind(this, this._socket);
      LOG_TRACE('Prepared socket');
    } catch (error) {
      LOG_INFO(`Error connecting: ${error.message}`);
      LOG_TRACE('Error details', error.stack || error);
      this._state = TransportSessionState.CONNECTION_FAILED;
      this.cancelConnectTimeout();
      if (error instanceof TransportError) {
        this._connError = error;
      } else {
        throw new TransportError(`Could not create WebSocket: ${error.message}`,
                                 error.subcode || ErrorSubcode.CONNECTION_ERROR);
      }
      return TransportReturnCode.CONNECTION_ERROR;
    }

    LOG_INFO('WebSocket is connecting');
    return TransportReturnCode.OK;
  }

  /**
   * @override
   * @param {String} data The binary string data to send.
   * @param {Boolean} [force=false] If true, send even if buffer is full.
   */
  send(data, force = false) {
    if (this._state !== TransportSessionState.SESSION_UP) {
      return TransportReturnCode.INVALID_STATE_FOR_OPERATION;
    }

    const dataLen = data.length;
    const bufferAllow = (this._sendBufferMaxSize - this.getBufferedAmount()) >= 0;
    // LOG_DEBUG('Websocket send:', dataLen, force, bufferAllow);
    if (!(force || bufferAllow)) {
      this._canSendNeeded = true;
      if (this.scheduleQuery) this.scheduleQuery();
      return TransportReturnCode.NO_SPACE;
    }

    // LOG_TRACE(`websocket.send: len=${data.length}`);
    // Slice messages per maxPayloadBytes
    const maxPayloadBytes = this._maxPayloadBytes;
    const ab = stringToArrayBuffer(data);
    if (dataLen > maxPayloadBytes) {
      for (let i = 0; i < dataLen; i += maxPayloadBytes) {
        this._socket.send(ab.slice(i, i + maxPayloadBytes));
      }
    } else {
      this._socket.send(ab);
    }
    // LOG_TRACE(`websocket.send: after send getBufferedAmount=${this.getBufferedAmount()}`);
    this._clientstats.bytesWritten += dataLen;
    ++this._clientstats.msgWritten;
    return TransportReturnCode.OK;
  }

  /**
   * @returns {Number} The number of already buffered bytes in this transport.
   * @private
   */
  getBufferedAmount() {
    // Access as string property because this is a defineProperty on node websocket,
    // meaning it is not mangled by Closure Compiler
    return this._socket ? this._socket['bufferedAmount'] : 0; // eslint-disable-line dot-notation
  }

  /**
   * @override
   */
  flush(callback) {
    this._flushCallback = callback;
    this.maybeEmitFlush();
  }

  maybeEmitCanSend() {
    if (this._canSendNeeded && this.getBufferedAmount() < this._sendBufferMaxSize) {
      LOG_TRACE('Transport emitting CAN_ACCEPT_DATA');
      this._canSendNeeded = false;
      this._eventCB(
        new TransportSessionEvent(TransportSessionEventCode.CAN_ACCEPT_DATA,
                                  '', null, 0, this._sessionId));
    }
  }

  maybeEmitFlush() {
    if (!this._flushCallback) return;
    if (BUILD_ENV.TARGET_BROWSER && this.getBufferedAmount() > 0) {
      // Ensure we have a query scheduled, but don't interrupt a query in progress
      if (!this._bufferedAmountQueryTimer) {
        this.scheduleQuery();
      }
      return;
    }

    // Node is automatically flushed by socket.end(), which is called in non-error cases
    LOG_DEBUG('Transport emitting FLUSH');
    const cb = this._flushCallback;
    this._flushCallback = null;
    cb();
  }

  /**
   * @override
   */
  destroy(message, subcode) {
    // We can destroy (and get a notice) even if the socket is freshly created.
    // But this will only work once.
    if (this._state !== TransportSessionState.DOWN) {
      LOG_INFO(`Destroy WebSocket transport: ${message}`);

      // Set state for connection teardown.
      this._state = TransportSessionState.WAITING_FOR_DESTROY;

      if (this._socket) {
        this._socket.close();
        this._socket.onopen = null;
        this._socket.onmessage = null;
        this._socket.onclose = null;
        this._socket.onerror = function onerrorStub() { }; // Prevent unhandled errors
        this._socket = null;
      }

      if (this._connectTimer) {
        clearTimeout(this._connectTimer);
        this._connectTimer = undefined;
      }

      if (BUILD_ENV.TARGET_BROWSER) {
        this.cancelQuery();
        this._bufferedAmountQueryIntervalDelayMultiplier = 1;
      }

      this._canSendNeeded = false;

      this._state = TransportSessionState.DOWN;

      this._client = null; // Don't accept data.
    }
    // always send a DESTROYED_NOTICE in response to a destroy() even
    // if we think the transport is already destroyed
    if (this._eventCB) {
      // Fire this *almost* instantly, but follow the HTTP
      // transport pattern by guaranteeing an async callback.
      this._eventCB(new TransportSessionEvent(TransportSessionEventCode.DESTROYED_NOTICE,
                                              message || 'Session is destroyed',
                                              null,
                                              subcode || 0,
                                              this._sessionId));
      // Release references to other components
      this._eventCB = null;
    }

    return TransportReturnCode.OK;
  }

  /**
   * @override
   * @returns {String} A description of this object.
   */
  getInfoStr() {
    const str = `WebSocketTransportSession; sid=${formatHexString(this._sessionId)}`;
    return str;
  }

  static browserSupportsBinaryWebSockets() {
    LOG_DEBUG('websocket browserSupportBinaryCheck - ' +
              'if WebSocket, ArrayBuffer and Uint8Array are supported');

    const exists = ['function', 'object'];
    if (!includes(exists, typeof MyWebSocket) ||
        !includes(exists, typeof ArrayBuffer) ||
        !includes(exists, typeof Uint8Array)) {
      LOG_INFO('websocket browserSupportBinaryCheck: false - some required classes not supported');
      return false;
    }

    LOG_DEBUG('websocket browserSupportBinaryCheck - if WebSocket supports binaryType');
    if ('binaryType' in MyWebSocket.prototype) {
      LOG_INFO('websocket browserSupportBinaryCheck: true - WebSocket supports binaryType');
      return true;
    }

    LOG_INFO('websocket browserSupportBinaryCheck: false - WebSocket does not support binaryType');
    return false;
  }

}

if (BUILD_ENV.TARGET_BROWSER) {
  mixin(WebSocketTransportSession, class WebSocketTransportSessionBrowser {
    /**
     * @private
     */
    scheduleQuery() {
      const bufferedAmount = this.getBufferedAmount();
      if (bufferedAmount > 0 && this._bufferedAmountQueryIntervalInMsecs > 0) {
        this.cancelQuery();
        if (this._bufferedAmountQueryIntervalDelayMultiplier > 1) {
          LOG_DEBUG(`$$ schedule bufferedAmount query timer in ${
                    this._bufferedAmountQueryIntervalInMsecs *
                    this._bufferedAmountQueryIntervalDelayMultiplier} ms`);
        }
        const timeout = this._bufferedAmountQueryIntervalInMsecs *
                        this._bufferedAmountQueryIntervalDelayMultiplier;
        this._bufferedAmountQueryTimer = setTimeout(() => {
          this.cancelQuery();
          try {
            this.onBufferedAmountPoll();
          } catch (e) {
            LOG_ERROR(`Error occurred in onBufferedAmountPoll: ${e.message}`);
            LOG_TRACE('Error details:', e.stack || e);
          }
        }, timeout);
      }
    }

    cancelQuery() {
      if (this._bufferedAmountQueryTimer) {
        clearTimeout(this._bufferedAmountQueryTimer);
        this._bufferedAmountQueryTimer = null;
      }
    }
  });
} else {
  // Node TLS socket options formulation shared with TCP transport
  // eslint-disable-next-line global-require
  const { NodeTLSOptsMixin } = require('../node-tls-opts-mixin');
  mixin(WebTransportSessionBase, NodeTLSOptsMixin);
  // Node-specific methods of this class go here
  mixin(WebTransportSessionBase, class WebTransportSessionBaseNode {

    /**
     * @name solace.WebTransportSession#createWebSocketOptions
     * @param {Function} onDrainCallback handler function for socket onDrain event
     * @returns {Object} A WebSocket options object for the current configuration
     * @private
     */
    createWebSocketOptions(onDrainCallback) {
      let options = {};
      if (this._ssl) {
        options = this.createTLSOptions();
        // use custom agent for client certificate support, ssl resume session support
        // eslint-disable-next-line dot-notation
        options['agent'] = new https.Agent({
          keepAlive: false,
        });
      } else { // else no TLS:
        // eslint-disable-next-line dot-notation
        options['agent'] = new http.Agent();
      }
      // eslint-disable-next-line dot-notation
      const wsAgent = options['agent'];
      const origCreateConnection = wsAgent.createConnection;
      wsAgent.createConnection = function newCreateConnection(opts, callback) {
        const socket = origCreateConnection.call(this, opts, callback);
        socket.on('drain', onDrainCallback);
        return socket;
      };
      LOG_DEBUG('WebSocket options', options);
      return options;
    }
  });
}

module.exports.WebSocketTransportSession = WebSocketTransportSession;
