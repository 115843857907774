const DebugLib = require('solclient-debug');
const {
  Convert,
  Base64,
} = require('solclient-convert');
const {
  LOG_DEBUG,
  LOG_INFO,
  LOG_WARN,
  LOG_ERROR,
} = require('solclient-log');
const {
  sendXhrBinary,
  sendXhrText,
} = require('./send-xhr');
const { StringBuffer, TimingBucket } = require('solclient-util');
const { TransportReturnCode } = require('../../transport-return-codes');
const { XHRFactory } = require('./xhr-factory');

const { arrayBufferToString } = Convert;

const SOL_CONNECTION_DEBUG = false;

/**
 * A URI starting with a "/" is a "path-absolute" URI, and those aren't
 * allowed to have a query component (starting with "?").
 *
 * If an origin isn't defined in the url, tack on the one from the page.
 *
 * @param {URL} url The URL to connect to
 * @returns {URL} Adjusted URL if incoming URL was relative
 * @private
 */
function prependOrigin(url) {
  if (!url.match(/^(http|ws)(s?):/i) && window.location && window.location.origin) {
    return window.location.origin + ((url.charAt(0) !== '/') ? '/' : '') + url;
  }
  return url;
}

function getTs() {
  return new Date().getTime();
}

class Stats {
  constructor() {
    this.WaitedToken = new TimingBucket('WaitedToken', 100);
    this.HadToken = new TimingBucket('HadToken', 100);
    this.ReturnedToken = new TimingBucket('ReturnedToken', 100);
  }
  toString() {
    let s = '';
    [this.WaitedToken, this.HadToken, this.ReturnedToken].forEach((b) => {
      if (b && b.bucketCount() > 0) {
        s += `${b.name} >> ${b}\n`;
      }
    });
    return s;
  }
}

/**
 * @classdesc
 * This class contains all state for a single HTTP connection (XHR).
 *
 * @private
 */
class HTTPConnection {
  constructor(url, base64Enc, streamProgressEvents, rxDataCb,
              connectionErrorCb, contentType, connectionClose) {
    this.Options = {
      url: prependOrigin(url),
      contentType,
      base64Enc,
      streamProgressEvents,
      connectionClose,
    };

    this._streamProgressBytes = 0;
    this._xhr = null;
    this._rxDataCb = rxDataCb;
    this._connErrorCb = connectionErrorCb;
    this._reqActive = false;
    this._REQCOUNTER = 0;
    this._REQBASE = Math.floor(Math.random() * 1000);

    this._xhr = XHRFactory.create();
    // older browser ie9
    this._handleAbortedReq = !HTTPConnection.browserSupportsXhrBinary();

    this.stats = new Stats();
  }

  recStat(s) {
    if (!SOL_CONNECTION_DEBUG) {
      return;
    }
    const stats = this.stats;
    if (s === 'GotToken') {
      stats.LastGotToken = getTs();
      if (stats.LastSendMsg) {
        const waitedTok = stats.LastGotToken - stats.LastSendMsg;
        stats.WaitedToken.log(waitedTok);
        if (waitedTok > 100) {
          LOG_WARN(`Abnormally long waitToken, last request: ${this._REQBASE}_${this._REQCOUNTER}`);
        }
      }
    }
    if (s === 'SendMsg') {
      stats.LastSendMsg = getTs();
      const hadToken = stats.LastSendMsg - stats.LastGotToken;
      stats.HadToken.log(hadToken);
    }
    if (s === 'GotData') {
      stats.LastGotData = getTs();
    }
    if (s === 'ReturnToken') {
      stats.LastReturnToken = getTs();
      if (stats.LastGotData) {
        const returnedToken = stats.LastReturnToken - stats.LastGotData;
        stats.ReturnedToken.log(returnedToken);
      }
    }
  }

  /*
   * Send data over the connection - this requires a send token
   */
  send(data, attempt = 0, maxRetry = 1) {
    if (attempt > 0) {
      this._xhr.abort();
      this._xhr = XHRFactory.create();
    }
    this._xhr.open('POST', this.Options.url, true);

    this._streamProgressBytes = 0;
    // We pass the write data to the CB so we can retry when it mysteriously fails.
    this._xhr.onreadystatechange = () => this.xhrStateChange(data, attempt, maxRetry);

    this._reqActive = true;

    if (SOL_CONNECTION_DEBUG) {
      this._REQCOUNTER++;
      this._xhr.setRequestHeader('sol-request-track', `${this._REQBASE}_${this._REQCOUNTER}`);
    }
    if (this.Options.base64Enc) {
      sendXhrText(this._xhr, data, this.Options.contentType, this.Options.connectionClose);
    } else {
      sendXhrBinary(this._xhr, data, this.Options.contentType, this.Options.connectionClose);
    }
    this.recStat('SendMsg');
  }


  // XmlHTTPRequest Callback
  xhrStateChange(sentdata, attempt, maxRetry) {
    const readyState = this._xhr.readyState;
    const RS_LOADING = this._xhr.LOADING;
    const RS_DONE = this._xhr.DONE;

    if (!((this.Options.streamProgressEvents && readyState === RS_LOADING)
          || readyState === RS_DONE)) {
      // we proceed with notifications if we're LOADING and we requested streaming events,
      // or we're DONE.
      return;
    }

    if (!this._reqActive) {
          // request aborted, DO NOT propagate event
      return;
    }

    let status = null;
    if (this._handleAbortedReq) {
      // To avoid the following IE9 error when request is aborted by server or client and
      // application tries to access any property in the XHR other than readyState whose value is
      // 4 (XMLHTTPRequest.DONE):
      // - The data necessary to complete this operation is not yet available
      // See https://groups.google.com/forum/#!topic/websync/ysBEvtvMyb0 for details
      // _requestActive is used to handle client initiated abort, but it does not handle
      // the case when the request is aborted on the server side or proxy server
      try {
        status = this._xhr.status;
      } catch (e) {
        LOG_INFO(`Error trying to access status in XHR due to request aborted: ${e.message}`);
        return;
      }
    } else {
      status = this._xhr.status;
    }

    if (status === 200 || status === 304) {
      // Success status code
      let data = null;
      if (this._xhr.responseType && this._xhr.responseType === 'arraybuffer') {
        data = arrayBufferToString(this._xhr.response);
      } else {
        data = this._xhr.responseText;
      }
      data = data.substring(this._streamProgressBytes, data.length);
      this._streamProgressBytes += data.length;

      if (data.length === 0 && readyState === RS_LOADING) {
        // we are streaming LOADING events but have no data
        return;
      }

      if (this.Options.base64Enc) {
        try {
          data = Base64.decode(data);
        } catch (e) {
          // Failed the decode - call the error callback
          LOG_ERROR(`Data decode error on: ${data}`);
          LOG_ERROR(`Data decode error is: ${e.message}`);
          this._rxDataCb(TransportReturnCode.DATA_DECODE_ERROR, data);
          return;
        }
      } else {
        // take lower-8 bits
        const decodedData = [];
        const dataLength = data.length;
        for (let i = 0; i < dataLength; i++) {
          decodedData.push(String.fromCharCode(data.charCodeAt(i) & 0xFF));
        }
        data = decodedData.join('');
      }
      if (readyState === RS_DONE) {
        // MUST do this BEFORE the callback invocation, because the callback can trigger a new send.
        this._reqActive = false;
      }
      this._rxDataCb(TransportReturnCode.OK, data);
      if (readyState === RS_DONE && data.length > 0) {
        this._rxDataCb(TransportReturnCode.OK, ''); // indicate end of stream
      }

      return;
    }

    // Failure status code.
    const statusText = this._xhr.statusText;
    let responseText = '';
    if (this._xhr.responseType && this._xhr.responseType === 'arraybuffer') {
      responseText = arrayBufferToString(this._xhr.response);
    } else {
      responseText = this._xhr.responseText || '';
    }

    const responseTextLen = responseText.length;
    const requestUrl = this.Options.url;
    const sentdataLen = sentdata ? sentdata.length : 0;
    const { formatDumpBytes } = DebugLib.Debug;
    const responseTextDump = formatDumpBytes(
      responseText.substr(0, Math.min(responseTextLen, 64)), true, 0);
    const sentTextDump = formatDumpBytes(
      (sentdata || '').substr(0, Math.min(sentdataLen, 256)), true, 0);
    if (BUILD_ENV.MODE_DEBUG) {
      const stmt = new StringBuffer(
        `Http request failed.  url=${requestUrl}, status=${status}, statusText=${statusText}, `,
        `responseText length=${responseTextLen}, `,
        'responseText (first 64 bytes or fewer)=\n',
        `${responseTextDump}, `,
        `XHR errorCode=${this._xhr._error ? this._xhr._error.code : ''}, `,
        `attempt=${attempt}, reqActive=${this._reqActive}, readyState=${readyState}, `,
        `sent data length=${sentdataLen}, `,
        'sent data (first 256 bytes or fewer)=\n',
        `${sentTextDump}`).toString();
      LOG_DEBUG(stmt);
    }

    const nextMaxRetry = maxRetry;
    if (this._reqActive
          && status !== 400
          && responseText.length === 0
          && (attempt === 0 || attempt < nextMaxRetry)) {
      LOG_INFO(`XHR failed while request active, will retry send, retry=${attempt + 1}`);
        // RETRY (could be a transient browser connection problem)
      this.send(sentdata, attempt + 1, nextMaxRetry);
    } else {
      this._reqActive = false;
      this._connErrorCb(
          status,
          new StringBuffer(
            `HTTP request failed(status=${status} statusText=${statusText}, `,
            `responseText length=${responseTextLen}, responseText[0..64]=\n`,
            responseTextDump,
            `XHR errorCode=${this._xhr._error ? this._xhr._error.code : ''})`).toString());
    }
  }

  isUsingBase64() {
    return this.Options.base64Enc;
  }

  // This function will abort the current xhr request if it is active
  abort() {
      // mark request as inactive, so we won't process statechange events
    this._reqActive = false;
    if (this._xhr && this._xhr.abort) {
      this._xhr.abort();
    }
  }

  /**
   * Check if we can try binary XHR on this browser.
   * @returns {Boolean} `true` if XHR binary should work; `false` otherwise
   * @static
   */
  static browserSupportsXhrBinary() {
    return sendXhrBinary !== sendXhrText;
  }

  /**
   * Check if browser supports streaming responses (progressive reading of XHR).
   * @returns {Boolean} `true` if feature was detected, `false` otherwise
   * @static
   */
  static browserSupportsStreamingResponse() {
    const xhr = XHRFactory.create();
    // A conforming XHR2 implementation must include progress events.
    // Can we assume that the event property will be null instead of undefined?
    // A conforming XHR2 implementation must also include withCredentials.
    const check = xhr && xhr.onprogress === null; // xhr.withCredentials === false;
    LOG_INFO(`http browserStreamingCheck - if XMLHTTPRequest supported and XMLHTTPRequest support onprogress: ${check}`);
    return check;
  }
}

module.exports.HTTPConnection = HTTPConnection;
