const { APIPropertiesValidators } = require('solclient-util');
const { QueueAccessType } = require('./queue-access-types');
const { QueueDiscardBehavior } = require('./queue-discard-behaviors');
const { QueuePermissions } = require('./queue-permissions');

const {
  validateInstance,
  valBoolean,
  valIsMember,
  valNumber,
  valRange,
} = APIPropertiesValidators;


const QueuePropertiesValidator = {
  validate(instance) {
    const v = validateInstance.bind(null, 'QueueProperties', instance);
    if (instance.permissions !== undefined && instance.permissions !== null) {
      v('permissions', [valIsMember, QueuePermissions, 'QueuePermissions']);
    }
    if (instance.accessType !== undefined && instance.accessType !== null) {
      v('accessType', [valIsMember, QueueAccessType, 'QueueAccessType']);
    }
    if (instance.quotaMB !== undefined && instance.quotaMB !== null) {
      v('quotaMB', [valNumber], [valRange, 0, Number.POSITIVE_INFINITY]);
    }
    if (instance.maxMessageSize !== undefined && instance.maxMessageSize !== null) {
      v('maxMessageSize', [valNumber], [valRange, 0, Number.POSITIVE_INFINITY]);
    }
    if (instance.respectsTTL !== undefined && instance.respectsTTL !== null) {
      v('respectsTTL', [valBoolean]);
    }
    if (instance.discardBehavior !== undefined && instance.discardBehavior !== null) {
      v('discardBehavior', [valIsMember, QueueDiscardBehavior, 'QueueDiscardBehavior']);
    }
    if (instance.maxMessageRedelivery !== undefined && instance.maxMessageRedelivery !== null) {
      v('maxMessageRedelivery', [valNumber], [valRange, 0, 255]);
    }
  },
};

module.exports.QueuePropertiesValidator = QueuePropertiesValidator;
