const {
  LOG_DEBUG,
  LOG_TRACE,
} = require('solclient-log');
const { TransportBase } = require('../transport-base');
const { TransportClientStats } = require('../transport-client-stats');
const { TransportReturnCode } = require('../transport-return-codes');
const { TransportSessionState } = require('../transport-session-states');

const MAX_BUFFERED_AMOUNT_QUERY_INTERVAL_MS = 4000;

/**
 * Base class for web related transports
 * @extends TransportBase
 * @private
 */
class WebTransportSessionBase extends TransportBase {
  /**
   * @constructor
   * @param {URL} url The URL to connect to
   * @param {function} eventCB The function to call on events
   * @param {SMFClient} client The function to call on data received.
   * @param {Object} props Additional object properties for this transport session
   */
  constructor(url, eventCB, client, props) {
    // instanitate instance properties defined in TransportBase
    super(url, eventCB, client, props);

    /**
     * @type {Number}
     */
    this._connectTimeout = props.transportDowngradeTimeoutInMsecs;

    /**
     * @type {?number}
     */
    this._connectTimer = null;

    /**
     * @type {TransportClientStats}
     */
    this._clientstats = new TransportClientStats();

    /**
     * Maximum amount of send data than can be queued
     * @type {Number}
     */
    this._sendBufferMaxSize = props.sendBufferMaxSize;

    /**
     * Maximum payload chunk size in web transport
     * @type {Number}
     */
    this._maxPayloadBytes = props.maxWebPayload;

    /**
     * Queue to hold data to be sent to the Solace Message Router when we get back a
     * data token
     * @type {array}
     */
    this._queuedData = [];

    /**
     * Number of bytes of queued data
     * @type {Number}
     */
    this._queuedDataSize = 0;

    /**
     * Remember if we have to send an event when there is room in the queue
     * @type {Boolean}
     */
    this._canSendNeeded = false;

    /**
     * @type {TransportSessionState}
     */
    this._state = TransportSessionState.DOWN;

    /**
     * Any connection error that needs to be propagated up
     * @type {?Error}
     */
    this._connError = null;
  }

  // override
  getClientStats() {
    return this._clientstats;
  }

  createConnectTimeout() {
    if (this._connectTimeout > 0) {
      this._connectTimer = setTimeout(() => {
        this.connectTimerExpiry();
      }, this._connectTimeout);
    }
  }

  cancelConnectTimeout() {
    if (this._connectTimer) {
      clearTimeout(this._connectTimer);
      this._connectTimer = null;
    }
  }

  /* override me */
  connectTimerExpiry() { // eslint-disable-line class-methods-use-this
    return undefined;
  }

  allowEnqueue(datalen) {
      // Bug 32006: if there's no queued data, we always accept at least one message, even if it
      // exceeds the sendBufferMaxSize.
      // If we reject enqueueing something too large because we already have queued data,
      // that guarantees when the data is flushed we will emit the alertOnDequeue event.
    return (this._queuedDataSize === 0 ||
            ((datalen + this._queuedDataSize) <= this._sendBufferMaxSize));
  }

  enqueueFailNoSpace() {
    this._canSendNeeded = true;
    return TransportReturnCode.NO_SPACE;
  }

  /* override me */
  flush(callback) { // eslint-disable-line class-methods-use-this
    callback();
  }

  getQueuedDataToSend() {
    // Track messages dequeued.
    let data = '';

    // Start by trying to fill a complete payload.
    let bytesAllowed = this._maxPayloadBytes;
    LOG_TRACE(`getQueuedDataToSend: bytesAllowed=${bytesAllowed}, bufferedAmount=${this.getBufferedAmount ? this.getBufferedAmount() : 'undefined'}`);
    if (this.getBufferedAmount) {
      bytesAllowed = this._maxPayloadBytes - this.getBufferedAmount();
      if (bytesAllowed <= 0) {
        LOG_DEBUG(`$$ bytesAllowed=${bytesAllowed}, maxPayloadBytes=${this._maxPayloadBytes
                  }, bufferedAmount=${this.getBufferedAmount()}`);
        if (BUILD_ENV.TARGET_BROWSER &&
            (this._bufferedAmountQueryIntervalInMsecs *
              this._bufferedAmountQueryIntervalDelayMultiplier <=
              MAX_BUFFERED_AMOUNT_QUERY_INTERVAL_MS)) {
          this._bufferedAmountQueryIntervalDelayMultiplier *= 2;
        }
        return data;
      }
      if (BUILD_ENV.TARGET_BROWSER) {
        this._bufferedAmountQueryIntervalDelayMultiplier = 1;
      }
    }

    if (this._queuedDataSize > bytesAllowed) {
      let payloadSize = bytesAllowed;
      // Slow path: dequeue and append until we fill the payload.
      while (payloadSize && this._queuedDataSize) {
        // Is this element larger than the payload?
        const elem = this._queuedData[0];
        const elemLength = elem.length;
        if (elemLength > payloadSize) {
          // This element is larger than the payload.
          data += elem.substr(0, payloadSize);
          this._queuedData[0] = elem.substr(payloadSize);

          // The rest of the payload space was consumed.
          this._queuedDataSize -= payloadSize;
          payloadSize = 0;
        } else {
          data += this._queuedData.shift();
          payloadSize -= elemLength;
          this._queuedDataSize -= elemLength;
          this._clientstats.msgWritten++;
        }
      }
    } else {
      // Shortcut: use the whole buffer, increase the message sent count by the length of the size
      // queue, and reset the buffer.
      data = this._queuedData.join('');
      this._clientstats.msgWritten += this._queuedData.length;

      this._queuedData = [];
      this._queuedDataSize = 0;
    }

    LOG_DEBUG(`Sending ${data.length} bytes from queued data`);
    return data;
  }
}

module.exports.WebTransportSessionBase = WebTransportSessionBase;
