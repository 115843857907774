/**
 * @private
 */
class Timer {
  /**
   * Creates an instance of Timer.
   * @param {function} cancel The action to perform on cancellation
   */
  constructor(cancel) {
    this.cancel = () => {
      this.cancel = () => {}; // change to no-op
      cancel();
    };
  }

  /**
   * @static
   * @param {Number} interval The interval for the timer, in milliseconds.
   * @param {function} onInterval The function to invoke on expiration.
   * @param {any} [args] Any arguments to pass to the onExpire callback.
   * @returns {Timer} A new timer object.
   */
  static newInterval(interval, onInterval, ...args) {
    const timerRef = setInterval(onInterval, interval, ...args);
    return new Timer(() => clearInterval(timerRef));
  }

  /**
   * @static
   * @param {Number} timeout The timeout for the timer, in milliseconds.
   * @param {function} onExpire The function to invoke on expiration.
   * @param {any} [args] Any arguments to pass to the onExpire callback.
   * @returns {Timer} A new timer object.
   */
  static newTimeout(timeout, onExpire, ...args) {
    const timerRef = setTimeout(onExpire, timeout, ...args);
    return new Timer(() => clearTimeout(timerRef));
  }
}

module.exports.Timer = Timer;
