const { Enum } = require('solclient-eskit');

/**
 * Enumeration for smf replay start location value type
 *@private
 */
const ReplayStartType = Enum.new({
  // the value of the Enum is the value of the smf type
  BEGINNING: 0x00,
  DATE:      0x01,
  RGMID:     0x02,
});

/**
 * @classdesc
 *  * <b>This class is not exposed for construction by API users.
 *  Users should obtain an instances from one of the following:</b>
 * * {@link solace.SolclientFactory.createReplayStartLocationBeginning}
 * * {@link solace.SolclientFactory.createReplayStartLocationDate}
 *
 * Defines the ReplayStartLocation class.
 * The ReplayStartLocation is set in the corresponding
 * MessageConsumer property {@link solace.MessageConsumerProperties#replayStartLocation}
 * The single member variable, _replayStartTime is undefined in ReplayStartLocationBeginning and
 * contains the elapsed time in milliseconds since the epoch in ReplayStartLocationDate
 *
 * @hideconstructor
 * @memberof solace
 */

class ReplayStartLocation {
  /**
   * @constructor
   * @param {SObject} spec The ReplayStartLocation spec
   * Use the factory methods on {@link solace.SolclientFactory}.
   * @private
   */
  constructor(spec) {
    Object.assign(this, spec);
    if (this._type === undefined) {
      // use default replay start location type when not specified
      this._type = ReplayStartType.BEGINNING;
    }
  }
  /**
   * @returns {String} A generic description of the Destination.
   */
  toString() {
    return util_inspect(this);
  }
}

module.exports.ReplayStartLocation = ReplayStartLocation;
module.exports.ReplayStartType = ReplayStartType;
