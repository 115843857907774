const { Enum } = require('solclient-eskit');

/********************************************************************************
 * @private
 * @enum {string}
 * State names on the Publisher FSM
 ********************************************************************************/
const PublisherStateNames = {
  UNBOUND:         'PublisherUnbound',
  OPENFLOWSENT:    'PublisherOpenFlowSent',
  UP:              'PublisherUp',
  FAILED:          'PublisherFailed',
  CLOSEFLOWSENT:   'PublisherCloseFlowSent',
  DATA_XFER:       'PublisherDataXfer',
  FLOW_CONTROLLED: 'MessagePublisherFlowControlled',
  RETRANSMITTING:  'PublisherRetransmitting',
};

module.exports.PublisherStateNames = Enum.new(PublisherStateNames);
