/**
 * @private
 */
class OutstandingDataRequest {
  constructor(correlationId, timer, replyReceivedCBFunction, reqFailedCBFunction, userObject) {
    this.correlationId = correlationId;
    this.timer = timer;
    this.replyReceivedCBFunction = replyReceivedCBFunction;
    this.reqFailedCBFunction = reqFailedCBFunction;
    this.userObject = userObject;
  }
}

module.exports.OutstandingDataRequest = OutstandingDataRequest;
