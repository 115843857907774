const { DestinationType } = require('solclient-destination');
const { Enum } = require('solclient-eskit');

/**
 * An enumeration of all SDT data types.
 * @enum {number}
 * @namespace
 * @memberof solace
 * @private
 */
const SDTDestType = {
  [DestinationType.TOPIC]:           0x00,
  [DestinationType.QUEUE]:           0x01,
  [DestinationType.TEMPORARY_QUEUE]: 0x01,
};

module.exports.SDTDestType = Enum.new(SDTDestType);
