const { LOG_ERROR } = require('solclient-log');
const { State } = require('./state');
const { StateContext } = require('./state-context');

/**
 * @classdesc
 * This is a state machine that can host states.  States themselves can also
 * host inner states in a hierarchical manner.  This class also provides the
 * basic interface for manipulating the current state via processing of
 * events, and querying the current state through 'getActiveState' methods.
 *
 * @private
 * @memberof solace
 */
class StateMachine extends StateContext {
  /**
   * @constructor
   *
   * @param {Object} spec The object specifier for the FSM.
   * @param {String} spec.name The name of the FSM, used in debug logs.
   */
  constructor(spec) {
    if (spec.parentContext) {
      throw new Error(`State machine cannot have parent state: ${spec.parentContext}`);
    }
    super(spec);
    this.impl.ancestorList = [this];
    this.impl.eventQueue = [];
    this.impl.finalState = new State({ name: 'impl.final', parentContext: this });
    this.impl.handleUncaughtException = (ev, exc) => {
      LOG_ERROR(`Uncaught exception in ${this} while processing ${ev}: ${exc.stack}`);
      return this.terminate();
    };
  }

  /**
   * This method enqueues the given function, and begins execution of queued functions if
   * they aren't already executing.
   * @param {Function} func The function to enqueue and execute.
   * @returns {Boolean} `true` if the supplied function has completed; false if it was deferred.
   */
  process(func) {
    const { impl } = this;
    const { eventQueue } = impl;

    eventQueue.push(func);
    if (impl.processingEvents) {
      return false;
    }
    impl.processingEvents = true;

    while (eventQueue.length) {
      const evt = eventQueue.shift();
      evt.apply(this);
    }

    impl.processingEvents = false;
    this._onEventCompletion();
    return true;
  }

  /**
   * This method starts a state machine after it has been created and states
   * have been associated and configured any time getCurrentState() returns
   * undefined.  This would be after initial creation and after the state
   * machine has terminated (i.e. transitioned to the final state).
   */
  start() {
    if (this.getCurrentState()) {
      throw new Error(`Cannot start ${this.getName()}; it is already started.`);
    }

    this.process(() => {
      const result = this.onInitial();

      // A state machine's onInitial must specify a destState, the state
      // cannot be the state machine itself, and the state must have the
      // state machine as the top ancestor.
      if (result.destState === undefined) {
        throw new Error(`Missing destination state from initial transition for ${this}`);
      }

      if (result.destState === this) {
        throw new Error(`Destination state for initial transition for ${this} cannot be the FSM.`);
      }

      const destAncestorList = result.destState.getAncestorList();
      if (destAncestorList[0] !== this) {
        throw new Error(`Invalid destination state (${result.destState
          }) from initial transition for state machine (${this
          }); destState ancestor (${destAncestorList[0]})`);
      }

      this.impl.currentState = this.processReactionResult(result);
    });
  }

  /**
   * Used to query whether the state machine is currently running
   * @returns {Boolean} `true` if the state machine is processing events; false otherwise.
   */
  isRunning() {
    return this.impl.processingEvents;
  }

  /**
   * This is the main function to invoke an FSM with an event.
   * @param {FsmEvent} evt The event to be processed by the FSM.
   */
  processEvent(evt) {
    const { impl } = this;
    if (!this.process(() => {
      this.log(`Processing event ${evt}`);
      let result;
      if (impl.currentState) {
        try {
          result = impl.currentState.handleEvent(evt);
          impl.currentState = impl.currentState.processReactionResult(result, evt);
        } catch (exc) {
          this.log(`Caught exception ${exc}, continuing`);
          result = impl.handleUncaughtException.call(impl.currentState,
                                                     evt,
                                                     exc);
          impl.currentState = impl.currentState.processReactionResult(result, evt);
        }
      }
    })) {
      // Didn't run immediately
      this.log(`Deferring event ${evt}`);
    }
  }

  /**
   * Terminates the FSM by transitioning the FSM to its final state. After
   * this returns, the FSM may be started again with @link start.  This method
   * should only be called externally from the FSM, not from within a reaction.
   * To terminate the FSM within a reaction, use the
   * {@link StateContext.ReactionResult} returned by {@link State#terminate}.
   */
  terminateFsm() {
    const curState = this.getCurrentState();
    if (!curState) {
      return;
    }
    if (this.impl.processingEvents) {
      throw new Error('Cannot terminate state machine while FSM is processing ' +
            'events. To terminate the FSM from within a reaction, return ' +
            'State~terminate() from a reaction.');
    }
    this.process(() => {
      const result = curState.terminate();
      this.impl.currentState = curState.processReactionResult(result);
    });
  }

  /**
   * This should only be called from within the context of a reaction
   * function, which is to say a function called by the FSM infrastructure
   * that returns {@link StateContext~ReactionResult}.  The purpose of this
   * function is to set a function to be called after the currently
   * executing event is completely handled, which includes the execution of
   * any events that have been or will be queued as a result of processing
   * the current event.
   *
   * Typical uses of this would be to set a function that does one of:
   * 1. Throws an exception to the caller.
   * 2. Calls an application callback.
   * @param {Function} postEventAction function to call when the FSM has finished
   *  processing events.  The context (i.e. 'this') will be the FSM when it
   *  is called.
   */
  setPostEventAction(postEventAction) {
    if (!this.impl.processingEvents) {
      throw new Error('Cannot set post event hook unless FSM is processing events.');
    }
    if (!postEventAction || typeof postEventAction !== 'function') {
      // TBD: Do we need to allow clearing of the hook?  Not that
      // I'm aware of for the known use cases.  If we want to allow
      // it, I propose adding a 'clearPostEventAction' rather than
      // allowing null or undefined as the argument.
      throw new Error(`postEventAction must be a function; got (${postEventAction})`);
    }
    this.impl.postEventAction = postEventAction.bind(this);
  }

  /**
   * Called by the FSM when it finishes processing events.  If a
   * postEventAction had been set, it will be called, then cleared.
   * @private
   */
  _onEventCompletion() {
    const action = this.impl.postEventAction;
    if (action) {
      this.impl.postEventAction = undefined;
      this.log('Running post event action');
      action.apply(this);
    }
  }

  /**
   * Returns the innermost active state.
   *
   * If regions were implemented, this would return an iterator to innermost
   * active states.
   *
   * @returns {StateContext} The innermost active state.
   */
  getCurrentState() {
    return this.impl.currentState;
  }

  /**
   * Gets an active state by name.  Returns undefined if the named state is
   * not currently active.
   *
   * Note the returned state may not be the innermost state as returned by
   * {@link StateMachine#getCurrentState}.  If the innermost active state is not
   * returned, the returned state is guaranteed to contain the innermost active
   * state.
   *
   * @param {String} name The name of the state to be retrieved.
   * @returns {?StateContext} The state with the specified name, if the
   *      state is active; undefined otherwise.  Note the returned state may not
   *      be the innermost active state.
   */
  getActiveState(name) {
    const activeStates = this.impl.currentState.getAncestorList();
    // Don't include the first ancestor in the loop since it is the state
    // machine itself, not a state.
    for (let i = 1; i < activeStates.length; ++i) {
      if (activeStates[i].getName() === name) {
        return activeStates[i];
      }
    }
    return undefined;
  }

  /**
   * Checks if the named state is currently active.
   * @param {String} name The name of the state to be queried.
   * @returns {Boolean} Whether or not the named state is active.
   */
  isStateActive(name) {
    return this.getActiveState(name) !== undefined;
  }

  /**
   * Allows a reaction to be registered for unhandled events in the FSM.  The
   * default reaction is to log the unhandled event at debug and remain in the
   * current state with no other side effects.
   * @param {StateContext.reactionCallback} r The reaction to be invoked when an
   *      event is unhandled by the FSM.
   * @returns {StateMachine} This StateMachine object.
   */
  unhandledEventReaction(r) {
    if (typeof r !== 'function') {
      throw new Error(`In ${this}: unhandled event reaction must be a function; got ${r}`);
    }
    this.impl.handleUnhandledEvent = r.bind(this);
    return this;
  }

  /**
   * This allows a reaction to be registered for uncaught exceptions while
   * processing events.  Generally, it is preferred to catch exceptions from
   * within reaction functions.  However, this is a good way to safeguard
   * against missed exceptions.  Usually an error should be logged here,
   * which is the default behaviour.
   *
   * Also note that this is only called for either:
   * - an exception thrown from an event reaction;
   * - an exception thrown from a transition action
   * If exceptions are thrown from within state entry, exit, initials, etc,
   * this function is not invoked.  These functions may end up getting
   * invoked from the transition taken as a result of the exception in the
   * first place.  The generate another exception would be difficult to
   * handle in a sensible way.  Users of this infrastructure must always
   * catch exceptions from these functions.
   *
   * @param {fsm.StateContext.reactionCallback} r The reaction to be
   *      invoked when an exception has not been caught by another of the
   *      FSM's reactionCallbacks.  When this function is invoked, 'this'
   *      will be the FSM's current state.
   * @returns {StateMachine} `this`, for method chaining.
   */
  uncaughtExceptionReaction(r) {
    if (typeof r !== 'function') {
      throw new Error(`In ${this}: Uncaught exception reaction must be a function; got ${r}`);
    }
    this.impl.handleUncaughtException = r;
    return this;
  }

  /**
   * Used by the implementation to get the final state.  This should never be
   * used by applications.  Their only need to reference this state should be
   * indirectly via the {@link State#terminate} function.
   * @returns {State} The FSM's final state, which is a hidden implementation
   *      detail of the FSM.
   * @protected
   */
  getFinalState() {
    return this.impl.finalState;
  }
}

module.exports.StateMachine = StateMachine;
