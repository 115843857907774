const { Enum } = require('solclient-eskit');

/**
 * Represents the possible endpoint access types. The corresponding endpoint property is
 * {@link solace.QueueProperties#accessType}.
 *
 * @enum {string}
 * @namespace
 * @memberof solace
 */
const QueueAccessType = {
  /**
   * @description An exclusive endpoint. The first client to bind
   * receives the stored messages on the Endpoint.
   * @type {String}
   */
  EXCLUSIVE:    'EXCLUSIVE',
  /**
   * @description A non-exclusive (shared) Queue. Each client to bind
   * receives messages in a round robin fashion.
   * @type {String}
   */
  NONEXCLUSIVE: 'NONEXCLUSIVE',
};

module.exports.QueueAccessType = Enum.new(QueueAccessType);
