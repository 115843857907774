
const EncodeInteger = {};

EncodeInteger.int48ToStr = function int48ToStr(vIn) {
  let v = vIn;
  const bytes = [];
  for (let i = 0; i < 6; i++) {
    const byteI = (v % 256);
    v = Math.floor(v / 256);
    bytes.push(String.fromCharCode(byteI));
  }
  bytes.reverse();
  return bytes.join('');
};

module.exports.EncodeInteger = EncodeInteger;
