const { SDTField } = require('../sdt-field');
const { SDTFieldType } = require('../sdt-field-types');

const ParseFloat = {};
ParseFloat.parseFloatField = function parseFloatField(buf, pos, len) {
  switch (len) {
    case 4:
      return SDTField.create(SDTFieldType.FLOATTYPE, buf.readFloatBE(pos));
    case 8:
      return SDTField.create(SDTFieldType.DOUBLETYPE, buf.readDoubleBE(pos));
    default:
      return SDTField.create(SDTFieldType.UNKNOWN, buf.toString('latin1', pos, pos + len));
  }
};

module.exports.ParseFloat = ParseFloat;
