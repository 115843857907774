// Preferred configuration for the profile system:

// End user:
//  opaque profiles, property-style access
// API implementation:
//  inherited properties, property-style access
// A root, forward compatible profile
// A legacy, backwards compatible profile
// Feature/milestone profiles that inherit from one of these two configurations

// The lowest-friction way of doing this is using prototype inheritance.
// Also considered:
//  getProperty() with child->parent pointers -- mixes string
//    and property access
//  ES6 proxies -- not universal and not shimmable

// Necessary to abuse prototype inheritance for static property inheritance:
/* eslint-disable class-methods-use-this */

/**
 * @classdesc
 * <b>This class is not exposed for construction by API users. Users should obtain an instance from
 * {@link solace.SolclientFactoryProfiles}.</b>
 *
 * A factory profile determines the default functionality of the API. Profiles are predefined
 * combinations of capabilities that represent a mutually-consistent, valid configuration state
 * for API features.
 *
 * The factory may be initialized exactly once, with a single factory profile.
 *
 * Changing which factory profile is used by {@link solace.SolclientFactory.init} may result in
 * different default property values, validation, function signatures and implementations.
 *
 * Changing the factory profile will not change relationships between API methods. That is,
 * assuming all properties and arguments are explicitly set, an application may call the
 * API with the same values as before to cause the same result, unless explicitly noted.
 *
 * If no profile is specified, the default will provide equivalent functionality to
 * SolclientJS version 7.x. New applications are encouraged to use the
 * {@link solace.SolclientFactoryProfiles.version10_5} profile for the best compatibility with
 * other APIs (excluding 7.x JavaScript APIs).
 *
 * @memberof solace
 * @private
 */
class FactoryProfile {
  /**
   * Returns whether Guaranteed Messaging is enabled for the API. When `true`,
   * AD publishing capability is enabled by default.
   *
   * @type {Boolean}
   * @readonly
   */
  get guaranteedMessagingEnabled() { return undefined; }

  /**
   * Returns whether the API will allow Comet (long-held HTTP request) transports
   * to be used. If this is `true`, transport protocols may fall back to HTTP
   * if a connection cannot be established with a preferred protocol.
   *
   *  * NOTE: Guaranteed Messaging cannot be enabled if Comet is enabled.
   *
   * @type {Boolean}
   * @readonly
   * @target browser
   */
  get cometEnabled() { return undefined; }

  /**
   * Returns whether the API performs UTF-8 encoding on user strings where the SMF
   * spec requires it.
   *  * When this is `true`, the API will correctly interoperate with other Solace APIs that send
   *    UTF-8 encoded topics.
   *  * When this is `false`, the API will interoperate correctly with 7.x and earlier JavaScript
   *    APIs, that used an arbitrary encoding for such strings.
   *
   * @type {Boolean}
   * @readonly
   */
  get topicUtf8Encode() { return undefined; }

  /**
   * Returns whether the API returns BYTEARRAY SDT values as Strings.
   *
   * In older versions (before 10.5) of the API,
   * an SDTField with type BYTEARRAY returned a String from getValue().
   * Newer versions (since 10.5),
   * getValue() for the same type returns an actual ByteArray, unless this property is `true`.
   *
   *  * When this is `true`, SDTField.getValue() returns a String for a BYTEARRAY.
   *  * When this is `false`, SDTField.getValue() returns a ByteArray for a BYTEARRAY.
   *
   * @type {Boolean}
   * @readonly
   */
  get byteArrayAsString() { return undefined; }

  [util_inspect_custom]() {
    return {
      'guaranteedMessagingEnabled': this.guaranteedMessagingEnabled,
      'cometEnabled':               this.cometEnabled,
      'topicUtf8Encode':            this.topicUtf8Encode,
      'byteArrayAsString':          this.byteArrayAsString,
    };
  }

  toString() {
    return util_inspect(this);
  }
}

/**
 * The Version 7 profile for SolclientJS. This profile sets factory and session default values
 * that provide SolclientJS 7.x behaviour, and interoperability with SolclientJS 7.x clients.
 *
 * @namespace
 * @type {solace.FactoryProfile}
 * @name version7
 * @memberof solace.SolclientFactoryProfiles
 * @private
 * @FactoryProfile
 */
class Version7Profile extends FactoryProfile {
  /**
   * Returns `false`: Guaranteed Messaging is disabled by default to maintain availability of Comet
   * HTTP transports by default.
   *
   * @name solace.SolclientFactoryProfiles.version7.guaranteedMessagingEnabled
   * @type {Boolean}
   * @readonly
   * @target browser
   */
  /**
   * Returns `true`: Guaranteed Messaging is enabled for the API. Note that version 7.x SolclientJS
   * clients cannot send or receive any messages using Guaranteed Messaging features.
   *
   * @name solace.SolclientFactoryProfiles.version7.guaranteedMessagingEnabled
   * @type {Boolean}
   * @readonly
   * @target node
   */
  get guaranteedMessagingEnabled() { return !!BUILD_ENV.TARGET_NODE; }

  /**
   * Returns true: Comet HTTP transports are enabled for the API. The API user may, by default,
   * select Comet HTTP transport protocol, and the API may fall back to these protocols during a
   * connection attempt.
   *
   * @name solace.SolclientFactoryProfiles.version7.cometEnabled
   * @type {Boolean}
   * @readonly
   * @target browser
   */
  /**
   * Returns false: Comet HTTP transports are not present in Node.js.
   *
   * @name solace.SolclientFactoryProfiles.version7.cometEnabled
   * @type {Boolean}
   * @readonly
   * @target node
   */
  get cometEnabled() { return !!BUILD_ENV.TARGET_BROWSER; }
  /**
   * Returns false: topic UTF-8 encoding is disabled for the API. The network encoding of topics is
   * compatible with other SolclientJS 7.x clients, and may not be interoperable with other
   * Solace Messaging APIs.
   *
   * @name solace.SolclientFactoryProfiles.version7.topicUtf8Encode
   * @type {Boolean}
   * @readonly
   */
  get topicUtf8Encode() { return false; }

  /**
   * Returns true: getValue() of SDTFields of type BYTEARRAY return a string,
   * and so does getBinaryAttachment().
   *
   * @name solace.SolclientFactoryProfiles.version7.byteArrayAsString
   * @type {Boolean}
   * @readonly
   */
  get byteArrayAsString() { return true; }
}
const legacyInstance = new Version7Profile();

/**
 * The Version 10 profile for SolclientJS. This profile sets factory and session default values
 * that provide the full capability of the API as of version 10.0, including interoperability with
 * other Solace Messaging products.
 *
 * @namespace
 * @name version10
 * @memberof solace.SolclientFactoryProfiles
 * @private
 * @FactoryProfile
 */
class Version10Profile extends FactoryProfile {
  /**
   * Returns `true`: Guaranteed Messaging is enabled for the API,
   * with the result that {@link solace.MessagePublisherProperties#enabled} is `true` by default
   * on platforms that support Guaranteed Messaging publishing.
   *
   * Where the {@link solace.TransportType.WS_BINARY} transport is unavailable,
   * {@link solace.MessagePublisherProperties#enabled} is `false` by
   * default.
   *
   * @name solace.SolclientFactoryProfiles.version10.guaranteedMessagingEnabled
   * @type {Boolean}
   * @readonly
   * @target browser
   */
  /**
   * Returns `true`: Guaranteed Messaging is enabled by default for the API.
   *
   * @name solace.SolclientFactoryProfiles.version10.guaranteedMessagingEnabled
   * @type {Boolean}
   * @readonly
   * @target node
   */
  get guaranteedMessagingEnabled() {
    return true;
  }
  /**
   * Returns `false`: Comet HTTP transports are disabled for the API. The API will not select or
   * fall back to HTTP Comet transport protocols, which are mutually exclusive of Guaranteed
   * Messaging features.
   *
   * @name solace.SolclientFactoryProfiles.version10.cometEnabled
   * @type {Boolean}
   * @readonly
   * @target browser
   */
  /**
   * Returns false: Comet HTTP transports are not present in Node.js.
   *
   * @name solace.SolclientFactoryProfiles.version10.cometEnabled
   * @type {Boolean}
   * @readonly
   * @target node
   */
  get cometEnabled() {
    return false;
  }
  /**
   * Returns `true`: the API will encode topics for interoperability with other Solace Messaging
   * products. SolclientJS 7.x clients may fail to correctly decode when topic names that include
   * multi-byte UTF-8 code points.
   *
   * @name solace.SolclientFactoryProfiles.version10.topicUtf8Encode
   * @type {Boolean}
   * @readonly
   */
  get topicUtf8Encode() {
    return true;
  }

  /**
   * Returns true: getValue() of SDTFields of type BYTEARRAY return a string,
   * and so does getBinaryAttachment().
   *
   * @name solace.SolclientFactoryProfiles.version10.byteArrayAsString
   * @type {Boolean}
   * @readonly
   */
  get byteArrayAsString() { return true; }
}

const v10Instance = new Version10Profile();

/**
 * The Version 10.5 profile for SolclientJS. This profile sets factory and session default values
 * that provide the full capability of the API as of version 10.5, including interoperability with
 * other Solace Messaging products.
 *
 * @namespace
 * @name version10_5
 * @memberof solace.SolclientFactoryProfiles
 * @private
 * @FactoryProfile
 */
class Version105Profile extends FactoryProfile {
  /**
   * Returns `true`: Guaranteed Messaging is enabled for the API,
   * with the result that {@link solace.MessagePublisherProperties#enabled} is `true` by default
   * on platforms that support Guaranteed Messaging publishing.
   *
   * Where the {@link solace.TransportType.WS_BINARY} transport is unavailable,
   * {@link solace.MessagePublisherProperties#enabled} is `false` by
   * default.
   *
   * @name solace.SolclientFactoryProfiles.version10_5.guaranteedMessagingEnabled
   * @type {Boolean}
   * @readonly
   * @target browser
   */
  /**
   * Returns `true`: Guaranteed Messaging is enabled by default for the API.
   *
   * @name solace.SolclientFactoryProfiles.version10_5.guaranteedMessagingEnabled
   * @type {Boolean}
   * @readonly
   * @target node
   */
  get guaranteedMessagingEnabled() {
    return true;
  }
  /**
   * Returns `false`: Comet HTTP transports are disabled for the API. The API will not select or
   * fall back to HTTP Comet transport protocols, which are mutually exclusive of Guaranteed
   * Messaging features.
   *
   * @name solace.SolclientFactoryProfiles.version10_5.cometEnabled
   * @type {Boolean}
   * @readonly
   * @target browser
   */
  /**
   * Returns false: Comet HTTP transports are not present in Node.js.
   *
   * @name solace.SolclientFactoryProfiles.version10_5.cometEnabled
   * @type {Boolean}
   * @readonly
   * @target node
   */
  get cometEnabled() {
    return false;
  }
  /**
   * Returns `true`: the API will encode topics for interoperability with other Solace Messaging
   * products. SolclientJS 7.x clients may fail to correctly decode when topic names that include
   * multi-byte UTF-8 code points.
   *
   * @name solace.SolclientFactoryProfiles.version10_5.topicUtf8Encode
   * @type {Boolean}
   * @readonly
   */
  get topicUtf8Encode() {
    return true;
  }

  /**
   * Returns false: getValue() of SDTFields of type BYTEARRAY return a Uint8Array,
   * and so does getBinaryAttachment().
   *
   * @name solace.SolclientFactoryProfiles.version10_5.byteArrayAsString
   * @type {Boolean}
   * @readonly
   */
  get byteArrayAsString() { return false; }
}


const forwardInstance = new Version105Profile();

/**
 * The collection of predefined factory profiles available for application use.
 *
 * See each member for a description of its configuration.
 *
 * @namespace
 * @memberof solace
 * @FactoryProfile
 */
const SolclientFactoryProfiles = {
  _legacy:  legacyInstance,
  _v10:     v10Instance,
  _forward: forwardInstance,
  _default: legacyInstance,

  /**
   * The version 7 profile for browsers. {@link solace.SolclientFactoryProfiles.version7}
   *
   * The version 7 profile configures API defaults for interoperability with the
   * SolclientJS 7.x API, and applications that use it.
   *
   * {@link solace.SolclientFactoryProfiles.version7}
   *
   * @name version7
   * @memberof solace.SolclientFactoryProfiles
   * @type {solace.FactoryProfile}
   * @target browser
   * @FactoryProfile
   */
  /**
   * The version 7 profile for Node.JS. {@link solace.SolclientFactoryProfiles.version7}
   *
   * A version 7 profile configures API defaults for interoperability with the
   * SolclientJS 7.x API, and applications that use it.
   *
   * {@link solace.SolclientFactoryProfiles.version7}
   *
   * @name version7
   * @memberof solace.SolclientFactoryProfiles
   * @type {solace.FactoryProfile}
   * @target node
   * @FactoryProfile
   */
  version7: legacyInstance,

  /**
   * The version 10 profile for Node.JS.
   *
   * The version 10 profile configures API defaults for use with Guaranteed Messaging, and other
   * Solace Messaging APIs.
   * It provides a backwards-compatibility mode for existing applications
   * expecting SDTField.getValue() to return a string for BYTEARRAYs.
   *
   * {@link solace.SolclientFactoryProfiles.version10}
   *
   * @name version10
   * @memberof solace.SolclientFactoryProfiles
   * @type {solace.FactoryProfile}
   * @target node
   * @FactoryProfile
   */
  /**
   * The version 10 profile for browsers.
   *
   * The version 10 profile configures API defaults for use with Guaranteed Messaging, and other
   * Solace Messaging APIs.
   * It provides a backwards-compatibility mode for existing applications
   * expecting SDTField.getValue() to return a string for BYTEARRAYs.
   *
   * {@link solace.SolclientFactoryProfiles.version10}
   *
   * @name version10
   * @memberof solace.SolclientFactoryProfiles
   * @type {solace.FactoryProfile}
   * @target browser
   * @FactoryProfile
   */
  version10: v10Instance,

  /**
   * The version 10.5 profile for browsers.
   *
   * The version 10.5 profile configures API defaults for use with Guaranteed Messaging, and other
   * Solace Messaging APIs.
   *
   * {@link solace.SolclientFactoryProfiles.version10_5}
   *
   * @name version10_5
   * @memberof solace.SolclientFactoryProfiles
   * @type {solace.FactoryProfile}
   * @target browser
   * @FactoryProfile
   */
  /**
   * The version 10.5 profile for Node.JS.
   *
   * The version 10.5 profile configures API defaults for use with Guaranteed Messaging, and other
   * Solace Messaging APIs.
   *
   * {@link solace.SolclientFactoryProfiles.version10_5}
   *
   * @name version10_5
   * @memberof solace.SolclientFactoryProfiles
   * @type {solace.FactoryProfile}
   * @target node
   * @FactoryProfile
   */
  version10_5: forwardInstance,

  [util_inspect_custom]() {
    return {
      'version7':    this.version7,
      'version10':   this.version10,
      'version10_5': this.version10_5,
    };
  },

  toString() {
    return util_inspect(this);
  },
};

module.exports.FactoryProfile = FactoryProfile;
module.exports.SolclientFactoryProfiles = SolclientFactoryProfiles;
