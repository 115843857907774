const { Enum } = require('solclient-eskit');

/**
 * An enumeration of all SDT data types.
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const SDTFieldType = {
  /**
   * @type {Number}
   * @description Maps to a boolean.
   */
  BOOL:        0,
  /**
   * @type {Number}
   * @description Maps to a number.
   */
  UINT8:       1,
  /**
   * @type {Number}
   * @description Maps to a number.
   */
  INT8:        2,
  /**
   * @type {Number}
   * @description Maps to a number.
   */
  UINT16:      3,
  /**
   * @type {Number}
   * @description Maps to a number.
   */
  INT16:       4,
  /**
   * @type {Number}
   * @description Maps to a number.
   */
  UINT32:      5,
  /**
   *@type {Number}
   * @description Maps to a number.
   */
  INT32:       6,
  /**
   * @type {Number}
   * @description Maps to a number. <br>
   * <strong>Warning:</strong> Supports 48-bit integers (range: 0 to 2<sup>48</sup>-1).
   * When decoding, only the lower 48 bits are considered significant.
   */
  UINT64:      7,
  /**
   * @type {Number}
   * @description Maps to a number. <br>
   * <strong>Warning:</strong> Supports 48-bit integers + sign (range: -(2<sup>48</sup>-1) to
   * 2<sup>48</sup>-1). When decoding, only the lower 48 bits are considered significant.
   */
  INT64:       8,
  /**
   * @type {Number}
   * @description A single character; maps to a string.
   */
  WCHAR:       9,
  /**
   * @type {Number}
   * @description Maps to a string.
   */
  STRING:      10,
  /**
   * @type {Number}
   * @description Maps to a Uint8Array.
   *
   * Backward compatibility note:
   * Using the version_10 factory profile or older, the getValue() method of a BYTEARRAY sdtField
   * returns the byte array in 'latin1' String representation.
   * Later profiles return a Uint8Array (in the form of a nodeJS Buffer instance in fact)
   *
   * When creating a field of type BYTEARRAY, the following datatypes are all accepted as value:
   *   Buffer (the nodeJS native type or equivalent)
   *   ArrayBuffer,
   *   Any DataView or TypedArray,
   *   'latin1' String for backwards compatibility:
   *     each character has a code in the range 0-255
   *     representing exactly one byte in the attachment.
   */
  BYTEARRAY:   11,
  /**
   * @type {Number}
   * @description Single-precision float; maps to a number.
   */
  FLOATTYPE:   12,
  /**
   * @type {Number}
   * @description Double-precision float; maps to a number.
   */
  DOUBLETYPE:  13,
  /**
   * @type {Number}
   * @description Maps to {@link SDTMapContainer}.
   */
  MAP:         14,
  /**
   * @type {Number}
   * @description Maps to {@link SDTStreamContainer}.
   */
  STREAM:      15,
  /**
   * @type {Number}
   * @description Maps to {@link Destination}.
   */
  DESTINATION: 16,
  /**
   * @type {Number}
   * @description Maps to <code>null</code>.
   */
  NULLTYPE:    17,
  /**
   * @type {Number}
   * @description Maps to an unknown type.
   */
  UNKNOWN:     18,
  /**
   * @type {Number}
   * @description Maps to an encoded SMF message.
   */
  SMF_MESSAGE: 19,
};

module.exports.SDTFieldType = Enum.new(SDTFieldType);
