const DebugLib = require('solclient-debug');
const MessageDumpUtilLib = require('./message-dump-util');
const { Destination } = require('solclient-destination');
const { LOG_ERROR } = require('solclient-log');
const { Long } = require('solclient-convert');
const { MessageCacheStatus } = require('./message-cache-status');
const { MessageDeliveryModeType } = require('./message-delivery-mode-types');
const { MessageDumpFlag } = require('./message-dump-flags');
const { MessageUserCosType } = require('./message-user-cos-types');
const { SDTMapContainer, SDTFieldType } = require('solclient-sdt');
const { StringUtils } = require('solclient-util');


const MessageDumpStandardProvider = {

  fpDestination(message/* , flags*/) {
    const dest = message.getDestination();
    if (dest !== null && dest instanceof Destination) {
      return ['Destination', true, dest.toString(), null];
    }
    return ['Destination', false, '', null];
  },

  fpSenderId(message/* , flags*/) {
    return ['SenderId', (message.getSenderId() !== undefined && message.getSenderId() !== null), message.getSenderId(), null];
  },

  fpAppmsgType(message/* , flags*/) {
    return ['AppMessageType', (message.getApplicationMessageType() !== undefined && message.getApplicationMessageType() !== null),
      message.getApplicationMessageType(), null];
  },

  fpAppMsgId(message/* , flags*/) {
    return ['AppMessageID', (message.getApplicationMessageId() !== undefined && message.getApplicationMessageId() !== null),
      message.getApplicationMessageId(), null];
  },

  fpSequenceNumber(message/* , flags*/) {
    const sequenceNum = message.getSequenceNumber();
    if (typeof sequenceNum === 'number') {
      return ['SequenceNumber', true, sequenceNum, null];
    }
    return ['SequenceNumber', false, '', null];
  },

  fpTopicSequenceNumber(message/*, flags */) {
    const topicSeqNo = message.getTopicSequenceNumber();
    if (Long.isLong(topicSeqNo)) {
      return ['TopicSequenceNumber', true, topicSeqNo.toString(), null];
    }
    return ['TopicequenceNumber', false, '', null];
  },

  fpCorrelationId(message/* , flags*/) {
    return ['CorrelationId', (message.getCorrelationId() !== undefined && message.getCorrelationId() !== null),
      message.getCorrelationId(), null];
  },

  fpHttpContentType(/*message*/ /*, flags*/) {
    return ['HTTP Content Type', false, undefined, null];
  },

  fpHttpContentEncoding(/*message*/ /*, flags*/) {
    return ['HTTP Content Encoding', false, undefined, null];
  },

  fpSendTimestamp(message/* , flags*/) {
    const timestamp = message.getSenderTimestamp();
    if (typeof timestamp === 'number') {
      const mdu = MessageDumpUtilLib.MessageDumpUtil;
      return ['SendTimestamp', true,
        `${timestamp} (${mdu.formatDate(timestamp)})`, null];
    }
    return ['SendTimestamp', false, '', null];
  },

  fpRcvTimestamp(message/* , flags*/) {
    const timestamp = message.getReceiverTimestamp();
    if (typeof timestamp === 'number') {
      const mdu = MessageDumpUtilLib.MessageDumpUtil;
      return ['RcvTimestamp', true,
        `${timestamp} (${mdu.formatDate(timestamp)})`, null];
    }
    return ['RcvTimestamp', false, '', null];
  },

  fpClassOfService(message/* , flags*/) {
    const cos = message.getUserCos();
    if (typeof cos === 'number') {
      return ['Class Of Service', true, MessageUserCosType.nameOf(message.getUserCos()), null];
    }
    return ['Class Of Service', false, '', null];
  },

//  fpPriority(message/* , flags*/) {
//    const prio = message.getPriority() !== undefined ? message.getPriority() : 'undefined';
//    return ['Message Priority', true, prio, null];
//  },

  fpDeliveryMode(message/* , flags*/) {
    const mode = message.getDeliveryMode();
    if (typeof mode === 'number') {
      return ['DeliveryMode', true, MessageDeliveryModeType.nameOf(message.getDeliveryMode()), null];
    }
    return ['DeliveryMode', false, '', null];
  },

  fpGuaranteedMsgId(message/*, flags*/) {
    const msgId = message.getGuaranteedMessageId();
    if (Long.isLong(msgId)) {
      return ['Message Id', true, msgId.toString(10), null];
    }
    return ['Message Id', false, '', null];
  },

  fpReplicationGroupMessageId(message/* , flags*/) {
    const rgmid = message.getReplicationGroupMessageId();
    if (rgmid === undefined) {
      return ['Replication Group Message Id', false, '', null];
    }
    return ['Replication Group Message Id', true, rgmid.toString(), null];
  },

  fpTimeToLive(message/* , flags */) {
    const timeToLive = message.getTimeToLive();
    if (typeof timeToLive === 'number') {
      const mdu = MessageDumpUtilLib.MessageDumpUtil;
      const d = new Date();
      return ['TimeToLive', true,
        `${timeToLive} (${mdu.formatDate(d.getTime() + timeToLive)})`, null];
    }
    return ['TimeToLive', false, '', null];
  },

  fpExpiration(message/*, flags*/) {
    const timestamp = message.getGMExpiration();
    if (typeof timestamp === 'number') {
      const mdu = MessageDumpUtilLib.MessageDumpUtil;
      return ['Expiration', true,
        `${timestamp} (${mdu.formatDate(timestamp)})`, null];
    }
    return ['Expiration', false, '', null];
  },

  fpMessageDMQEligible(message/* , flags*/) {
    return ['DMQ Eligible', message.isDMQEligible(), '', null];
  },

  fpMessageRedelivered(message/* , flags*/) {
    return ['Message Re-delivered', message.isRedelivered(), '', null];
  },

  fpMessageDeliveryCount(message/* , flags*/) {
    //TODO: exception handling!
    try {
      return ['Message Delivery Count', true, message.getDeliveryCount(), null];
    } catch (e) {
      return ['Message Delivery Count', false, '', null];
    }
  },

  fpDiscardIndication(message/* , flags*/) {
    return ['Discard Indication', message.isDiscardIndication(), '', null];
  },

  fpAckImmediately(message/* , flags*/) {
    return ['ACK Immediately', message.isAcknowledgeImmediately(), '', null];
  },

  fpElidingEligible(message/* , flags*/) {
    return ['Eliding Eligible', message.isElidingEligible(), '', null];
  },

  fpReplyMessage(message/* , flags*/) {
    return ['Reply Message', message.isReplyMessage(), '', null];
  },

  fpReplyTo(message/* , flags*/) {
    const replyTo = message.getReplyTo();
    if (replyTo !== null && replyTo instanceof Destination) {
      return ['ReplyTo', true, replyTo.toString(), null];
    }
    return ['ReplyTo', false, '', null];
  },

  fpDeliverToOne(message/* , flags*/) {
    return ['Deliver To One', message.isDeliverToOne(), '', null];
  },

  fpCacheMessage(message/* , flags*/) {
    return ['Message from cache', message.getCacheStatus() !== MessageCacheStatus.LIVE, '', null];
  },

  fpCacheRequestId(message/*, flags */) {
    const cacheRequestId = message.getCacheRequestId();
    if (Long.isLong(cacheRequestId)) {
      return ['Cache Request Id', true, cacheRequestId.toString, null];
    }
    return ['Cache Request Id', false, '', null];
  },

  fpUserPropertyMap(message, flags) {
    const propMap = message.getUserPropertyMap();
    if (propMap !== null && propMap instanceof SDTMapContainer) {
      const value = `${propMap.getKeys().length} entries`;
      let detailValue = null;
      if (flags === MessageDumpFlag.MSGDUMP_FULL) {
        try {
          const mdu = MessageDumpUtilLib.MessageDumpUtil;
          detailValue = mdu.printMap(propMap, 2);
        } catch (e) {
          LOG_ERROR(e.message, e.stack);
          detailValue = 'Error';
        }
      }
      return ['User Property Map', true, value, detailValue];
    }
    return ['User Property Map', false, '', null];
  },

  fpCorrelationTag(message/*, flags*/) {
    const strValue = message.getCorrelationKey();
    return ['Correlation Tag Pointer', (strValue !== undefined && strValue !== null),
      strValue, null];
  },

  fpUserData(message/* , flags*/) {
    if (StringUtils.notEmpty(message.getUserData())) {
      return ['User Data', true, `len=${message.getUserData().length}`,
        DebugLib.Debug.formatDumpBytes(message.getUserData(), true, 2)];
    }
    return ['User Data', false, '', null];
  },

  fpSdtStream(message, flags) {
    const sdtFieldValue = message.getSdtContainer();
    if (sdtFieldValue !== null && sdtFieldValue.getType() === SDTFieldType.STREAM) {
      const mdu = MessageDumpUtilLib.MessageDumpUtil;
      const value = `${mdu.countItems(sdtFieldValue.getValue())} entries`;
      let detailValue = null;
      if (flags === MessageDumpFlag.MSGDUMP_FULL) {
        try {
          detailValue = mdu.printStream(sdtFieldValue.getValue(), 2);
        } catch (e) {
          LOG_ERROR(e.message, e.stack);
          detailValue = 'Error';
        }
      }
      return ['SDT Stream', true, value, detailValue];
    }
    return ['SDT Stream', false, '', null];
  },

  fpSdtMap(message, flags) {
    const sdtFieldValue = message.getSdtContainer();
    if (sdtFieldValue !== null && sdtFieldValue.getType() === SDTFieldType.MAP) {
      const value = `${sdtFieldValue.getValue().getKeys().length} entries`;
      let detailValue = null;
      if (flags === MessageDumpFlag.MSGDUMP_FULL) {
        try {
          const mdu = MessageDumpUtilLib.MessageDumpUtil;
          detailValue = mdu.printMap(sdtFieldValue.getValue(), 2);
        } catch (e) {
          LOG_ERROR(e.message, e.stack);
          detailValue = 'Error';
        }
      }
      return ['SDT Map', true, value, detailValue];
    }
    return ['SDT Map', false, '', null];
  },

  fpBinaryAttachment(message, flags) {
    if (!message._binaryAttachment || message._binaryAttachment.length < 1) {
      return ['Binary Attachment', false, '', null];
    }
    const att = message._binaryAttachment.toString('latin1');
    const value = `len=${att.length}`;
    let detailValue = null;
    if (flags === MessageDumpFlag.MSGDUMP_FULL) {
      detailValue = DebugLib.Debug.formatDumpBytes(att, true, 2);
    }
    return ['Binary Attachment', true, value, detailValue];
  },

  fpXmlContent(message, flags) {
    const xml = message.getXmlContent();
    if (StringUtils.notEmpty(xml)) {
      const value = `len=${xml.length}`;
      let detailValue = null;
      if (flags === MessageDumpFlag.MSGDUMP_FULL) {
        detailValue = DebugLib.Debug.formatDumpBytes(xml, true, 2);
      }
      return ['XML', true, value, detailValue];
    }
    return ['XML', false, '', null];
  },

  fpXmlMetadata(message, flags) {
    const xmlMetadata = message.getXmlMetadata();
    if (StringUtils.notEmpty(xmlMetadata)) {
      const value = `len=${xmlMetadata.length}`;
      let detailValue = null;
      if (flags === MessageDumpFlag.MSGDUMP_FULL) {
        detailValue = DebugLib.Debug.formatDumpBytes(xmlMetadata, true, 2);
      }
      return ['XML Metadata', true, value, detailValue];
    }
    return ['XML Metadata', false, '', null];
  },
};

module.exports.MessageDumpStandardProvider = MessageDumpStandardProvider;
