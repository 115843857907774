const { Check } = require('./check');
const { ErrorSubcode, OperationError } = require('solclient-error');

const NO_OBJ = {}; // anonymous object

function objectValues(object) {
  return Object.keys(object).map(k => object[k]);
}

function subcodeSummary(subcode, expected = null, got = NO_OBJ) {
  const expectedStr = expected ? `; expected: ${expected}` : '';
  const gotStr = got !== NO_OBJ ? `; got: ${got}` : '';
  switch (subcode) {
    case ErrorSubcode.PARAMETER_INVALID_TYPE:
      return `Parameter type was invalid${expectedStr}${gotStr}`;
    default:
      return ErrorSubcode.nameOf(subcode).toLowerCase().replace(/_/, ' ') + expectedStr;
  }
}

function validateError(name, subcode, message) {
  throw new OperationError(`Parameter ${name} failed validation`, subcode, message);
}

function validate(name, subcode, message, check, value, ...validatorArgs) {
  if (!check(value, ...validatorArgs)) {
    return validateError(name, subcode, message);
  }
  return value;
}

const typename = x => x && x.constructor && x.constructor.name || typeof x;

/**
 * The Check object is a powerful validation API but it is partially built at runtime by composing
 * functions, and it requires significant boilerplate for parameter validation.
 *
 * The Parameter API expresses concrete validations using the Check API, returning values on success
 * and throwing the appropriate SDK exception on failure. It would be tempting to try to
 * automatically build a Parameter method for every Check method, but this would make Parameter
 * difficult to understand and use.
 *
 * @private
 */
const Parameter = {};

Parameter.isArray = function isArray(name, value, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, 'array', value)) {
  return validate(name, subcode, message, Check.isArray, value);
};

Parameter.isBoolean =
function isBoolean(name, value, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, 'boolean', value)) {
  return validate(name, subcode, message, Check.boolean, value);
};

Parameter.isBooleanOrNothing =
function isBooleanOrNothing(name, value, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, 'boolean or nothing', value)) {
  return validate(name, subcode, message, Check.boolean.orNothing, value);
};

Parameter.isEnumMember =
function isEnumMember(name, value, memberOfEnum, subcode = ErrorSubcode.PARAMETER_OUT_OF_RANGE, message = subcodeSummary(subcode, `one of [${memberOfEnum.names.join(', ')}]`, value)) {
  return validate(name, subcode, message, Check.member, value, memberOfEnum);
};

Parameter.isEnumMemberOrNothing =
function isEnumMemberOrNothing(name, value, memberOfEnum, subcode = ErrorSubcode.PARAMETER_OUT_OF_RANGE, message = subcodeSummary(subcode, `one of [${memberOfEnum.names.join(', ')}]`, value)) {
  return validate(name, subcode, message, Check.member.orNothing, value, memberOfEnum);
};

Parameter.isFunction =
function isFunction(name, value, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, 'function', value)) {
  return validate(name, subcode, message, Check.function, value);
};

Parameter.isFunctionOrNothing =
function isFunctionOrNothing(name, value, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, 'function or nothing', value)) {
  return validate(name, subcode, message, Check.function.orNothing, value);
};

Parameter.isInstanceOf =
function isInstanceOf(name, value, instanceOf, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE,
                      message = subcodeSummary(subcode, instanceOf.name, typename(value))) {
  return validate(name, subcode, message, Check.instanceOf, value, instanceOf);
};

Parameter.isInstanceOfOrNothing =
function isInstanceOfOrNothing(name, value, instanceOf, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, `${instanceOf.name} or nothing`, typename(value))) {
  return validate(name, subcode, message, Check.instanceOf.orNothing, value, instanceOf);
};

Parameter.isInstanceOfOrNull =
function isInstanceOfOrNull(name, value, instanceOf, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, `${instanceOf.name} or null`, typename(value))) {
  return validate(name, subcode, message, Check.instanceOf.orNull, value, instanceOf);
};

Parameter.isInstanceOfOrUndefined =
function isInstanceOfOrUndefined(name, value, instanceOf, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, `${instanceOf.name} or undefined`, typename(value))) {
  return validate(name, subcode, message, Check.instanceOf.orUndefined, value, instanceOf);
};

Parameter.isMember =
function isMember(name, value, memberOf, subcode = ErrorSubcode.PARAMETER_OUT_OF_RANGE, message = subcodeSummary(subcode, `one of ${memberOf.name}.[${objectValues(memberOf).join(', ')}]`, value)) {
  return validate(name, subcode, message, Check.member, value, memberOf);
};

Parameter.isNumber =
function isNumber(name, value, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, 'number', value)) {
  return validate(name, subcode, message, Check.number, value);
};

Parameter.isNumberOrNothing =
function isNumberOrNothing(name, value, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, 'number or nothing', value)) {
  return validate(name, subcode, message, Check.number.orNothing, value);
};

Parameter.isNumberOrNull =
function isNumberOrNull(name, value, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, 'number or null', value)) {
  return validate(name, subcode, message, Check.number.orNull, value);
};

Parameter.isRangeCompare =
function isRangeCompare(name, value, operator, operand, subcode = ErrorSubcode.PARAMETER_OUT_OF_RANGE, message = subcodeSummary(subcode, `${operator} ${operand}`, value)) {
  return validate(name, subcode, message, Check.rangeCompare, value, operator, operand);
};

Parameter.isRangeCompareOrNothing = function isRangeCompareOrNothing(name, value, operator, operand, subcode = ErrorSubcode.PARAMETER_OUT_OF_RANGE, message = subcodeSummary(subcode, `${operator} ${operand} or nothing`, value)) {
  return validate(name, subcode, message, Check.rangeCompare.orNothing, value, operator, operand);
};

Parameter.isStringOrNull =
function isStringOrNull(name, value, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, 'string or null', value)) {
  return validate(name, subcode, message, Check.string.orNull, value);
};


Parameter.isString =
function isString(name, value, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, 'string', value)) {
  return validate(name, subcode, message, Check.string, value);
};

Parameter.isStringOrNothing =
function isStringOrNothing(name, value, subcode = ErrorSubcode.PARAMETER_INVALID_TYPE, message = subcodeSummary(subcode, 'string or nothing', value)) {
  return validate(name, subcode, message, Check.string.orNothing, value);
};

Parameter.isValue =
function isValue(name, value, expected, subcode = ErrorSubcode.PARAMETER_OUT_OF_RANGE, message = subcodeSummary(subcode, `must be ${value}`)) {
  return validate(name, subcode, message, Check.equal, value, expected);
};


module.exports.Parameter = Parameter;
