const { TransportProtocol } = require('../transport-protocols');
const { TSHState } = require('../tsh-state');
const { WebTransportCapabilities } = require('./web-transport-capabilities');

/**
 * @classdesc
 * WebSocket transport
 * @memberof solace
 * @private
 */
class StateWebSocketBinary extends TSHState {
  /**
   * @constructor
   * @extends TSHState
   * @param {Boolean} useSsl True if SSL should be used
   * @param {Function} exitCb Callback to notify on exit
   * @param {?TSHState} nextState Downgrade target
   */
  constructor(useSsl, exitCb, nextState) {
    super(useSsl, TransportProtocol.WS_BINARY, exitCb, nextState);
  }

  validateLegal() { // eslint-disable-line class-methods-use-this
    return WebTransportCapabilities.webSocket();
  }
}

module.exports.StateWebSocketBinary = StateWebSocketBinary;
