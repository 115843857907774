const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SMFClientCtrlMessageType = {
  LOGIN:  0x00,
  UPDATE: 0x01,
};

module.exports.SMFClientCtrlMessageType = Enum.new(SMFClientCtrlMessageType);
