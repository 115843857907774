const MessageLib = require('solclient-message');
const { BidiMap } = require('solclient-eskit');

class PriorityUserCosMap extends BidiMap {
  constructor() {
    super([MessageLib.MessageUserCosType.COS1, 0],
          [MessageLib.MessageUserCosType.COS2, 1],
          [MessageLib.MessageUserCosType.COS3, 2]);
  }
}

module.exports.PriorityUserCosMap = PriorityUserCosMap;
