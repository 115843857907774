const { EncodeSingleElement } = require('./encode-single-element');
const { IEEE754LIB } = require('./ieee754lib');
const { ParseSingleElement, StringToBuffer } = require('./parse-single-element');

const { encodeSingleElement } = EncodeSingleElement;
const { parseSingleElement } = ParseSingleElement;
const { stringToBuffer } = StringToBuffer;

const Codec = {
  encodeSingleElement,
  parseSingleElement,
  stringToBuffer,
  IEEE754LIB,
};

module.exports.Codec = Codec;
