const { Enum } = require('solclient-eskit');

// WebTransport states, used by WebTransport
const WebTransportState = {
  DOWN:        'WebTransportDown',
  CONNECTING:  'WebTransportConnecting',
  DOWNGRADING: 'WebTransportDowngrading',
  DESTROYING:  'WebTransportDestroying',
  UP:          'WebTransportUp',
};

module.exports.WebTransportState = Enum.new(WebTransportState);
