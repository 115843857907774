class CorrelatedRequest {
  /**
   * Creates an instance of CorrelatedRequest.
   * @param {String} correlationTag The associated tag
   * @param {?} timer The opaque timer handle
   * @param {String} correlationKey The associated key
   * @param {function} respRecvdCallback The associated callback
   * @memberof CorrelatedRequest
   * @private
   */
  constructor(correlationTag, timer, correlationKey, respRecvdCallback) {
    this.correlationTag = correlationTag;
    this.timer = timer;
    this.correlationKey = correlationKey;
    this.respRecvdCallback = respRecvdCallback;
  }
}

module.exports.CorrelatedRequest = CorrelatedRequest;
