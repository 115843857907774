const { Enum } = require('solclient-eskit');

/**
 * Enumerates the behavior options when a message cannot be added to an endpoint
 * (for example, the maximum quota {@link solace.QueueProperties#quotaMB} was exceeded).
 *
 * @enum {string}
 * @namespace
 * @memberof solace
 */
const QueueDiscardBehavior = {
  /**
   * @description Send the publisher a message reject notification.
   * @type {String}
   */
  NOTIFY_SENDER_ON:  'NOTIFY_SENDER_ON',
  /**
   * @description Discard the message and acknowledge it.
   * @type {String}
   */
  NOTIFY_SENDER_OFF: 'NOTIFY_SENDER_OFF',
};

module.exports.QueueDiscardBehavior = Enum.new(QueueDiscardBehavior);
