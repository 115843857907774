const { Enum } = require('solclient-eskit');

/**
 * Enumeration of CacheReturnSubcode types.
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const CacheReturnSubcode = {
  /**
   * @type {Number}
   * @description
   * The cache request completed successfully.
   */
  REQUEST_COMPLETE:            0,
  /**
   * @type {Number}
   * @description
   * The cache request completed when live data arrived on the topic requested.
   */
  LIVE_DATA_FULFILL:           1,
  /**
   * @type {Number}
   * @description
   * The cache instance or session returned an error response to the cache request.
   */
  ERROR_RESPONSE:              2,
  /**
   * @type {Number}
   * @description
   * The cache request failed because the {@link Session} used to construct it has been
   * disposed.
   */
  INVALID_SESSION:             3,
  /**
   * @type {Number}
   * @description
   * The cache request failed because the request timeout expired.
   */
  REQUEST_TIMEOUT:             4,
  /**
   * @type {Number}
   * @description
   * The cache request was made on the same topic as an existing request, and
   * {@link CacheLiveDataAction.FLOW_THRU} was not set.
   */
  REQUEST_ALREADY_IN_PROGRESS: 5,
  /**
   * @type {Number}
   * @description
   * The cache reply returned no data.
   */
  NO_DATA:                     6,
  /**
   * @type {Number}
   * @description
   * The cache reply returned suspect data.
   */
  SUSPECT_DATA:                7,
  /**
   * @type {Number}
   * @description
   * The request was terminated because the cache session was disposed.
   */
  CACHE_SESSION_DISPOSED:      8,
  /**
   * @type {Number}
   * @description
   * The request was terminated because the subscription request for the specified topic failed.
   */
  SUBSCRIPTION_ERROR:          9,
};

module.exports.CacheReturnSubcode = Enum.new(CacheReturnSubcode);
