const HTTPLib = require('./http');
const { StateBase64 } = require('./state-base64');
const { StateBinary } = require('./state-binary');
const { StateStreamingAndBinary } = require('./state-streaming-and-binary');
const { StateWebSocketBinary } = require('./state-websocket-binary');
const { WebTransport } = require('./web-transport');
const { WebTransportCapabilities } = require('./web-transport-capabilities');

const { HTTPConnection, HTTPTransportSession } = HTTPLib;

module.exports.HTTPConnection = HTTPConnection;
module.exports.HTTPTransportSession = HTTPTransportSession;
module.exports.StateBase64 = StateBase64;
module.exports.StateBinary = StateBinary;
module.exports.StateStreamingAndBinary = StateStreamingAndBinary;
module.exports.StateWebSocketBinary = StateWebSocketBinary;
module.exports.WebTransport = WebTransport;
module.exports.WebTransportCapabilities = WebTransportCapabilities;
