const { Enum } = require('solclient-eskit');

/**
 * Enumeration of Web Transport events.
 * @enum {string}
 * @namespace
 * @memberof solace
 * @private
 */
const WebTransportEvent = {
  /**
   * The Session Layer has issued a connect request
   */
  CONNECT:          'Connect',
  /**
   * The Session Layer has issued a destroy request
   */
  DESTROY:          'Destroy',
  /**
   * A downgrade request has been received from an FSM.
   */
  DOWNGRADE:        'Downgrade',
  /**
   * The underlying transport has been destroyed. Seen in
   * response to calling destroy() and in some cases when
   * the transport is destroyed by the peer.
   */
  DESTROYED_NOTICE: 'DestroyedNotice',
  /**
   * The underlying transport timed out in its connection attempt.
   * The managing component should abort this connection attempt
   * and begin a downgrade if available.
   */
  CONNECT_TIMEOUT:  'ConnectTimeout',
  /**
   * The underlying transport is active and ready for traffic.
   */
  UP_NOTICE:        'UpNotice',
  /**
   * The transport encountered an error sending data.
   */
  SEND_ERROR:       'SendError',
};

module.exports.WebTransportEvent = Enum.new(WebTransportEvent);
