/**
 * @classdesc
 * Control messages wrap an SMFHeader instance
 * @private
 */
class SMFHeader {
  constructor(protocol = 0, ttl = 0) {
    // header properties
    // header block
    // SMF parameters
    // payload
    this._parameters = [];

    // Common SMF header field values
    this.smf_version = 3;
    this.smf_uh = 0;
    this.smf_protocol = protocol;
    this.smf_priority = 0;
    this.smf_ttl = ttl;
    this.smf_msgLen = 0;
    this.smf_di = 0;
    this.smf_tqd = 0;
    this.smf_elidingEligible = 0;
    this.smf_dto = 0;
    this.smf_adf = 0;                  // AD
    this.smf_deadMessageQueueEligible = 0; //DMQ Eligible

    // Common SMF protocol parameters
    this.pm_userdata = null;
    this.pm_respcode = 0;
    this.pm_respstr = null;
    this.pm_username = null;
    this.pm_password = null;
    this.pm_tr_topicname_bytes = null;
    this.pm_deliverymode = null;

    this.pm_ad_msgid = undefined;      // AD
    this.pm_ad_prevmsgid = undefined;  // AD
    this.pm_ad_redelflag = 0;          // AD
    this.pm_ad_flowredelflag = 0;      // AD
    this.pm_ad_ttl = undefined;        // AD
    this.pm_ad_ackimm = undefined;     // AD
    this.pm_ad_flowid = 0;             // AD
    this.pm_ad_publisherid = 0;
    this.pm_ad_publishermsgid = 0;

    this.pm_content_summary = null;
    this.pm_corrtag = null;
    this.pm_topic_offset = 0;
    this.pm_topic_len = 0;
    this.pm_queue_offset = 0;
    this.pm_queue_len = 0;
    this.pm_msg_priority = null;        // {number}

    // extended optional parameters
    // these fields are header but may not be present
    // see ../codec/parse-smf.js for details
    // this.pm_ad_redeliveryCount;
    // this.pm_ad_spooler_unique_id;
    // this.pm_ad_local_spooler_message_id;
    // this.pm_ad_replication_mate_ack_message_id;
    this.pm_oauth2_access_token = null;
    this.pm_oidc_id_token = null;
    this.pm_oauth2_issuer_identifier = null;

    // housekeeping
    this.unknownProtoFlag = false;
    this.messageLength = 0;
    this.payloadLength = 0;
    this.headerLength = 0;
    this.payload = null;

    // This message is invalid, but the framing (messageLength) is fine.
    // set by the parser when UH==2 on an unknown parameter.
    // The SMF fsm can then discard and increment the stats.
    this.discardMessage = false;
  }

  setMessageSizes(headerLength, payloadLength) {
    this.headerLength = headerLength;
    this.payloadLength = payloadLength;
    this.messageLength = headerLength + payloadLength;
  }

  setPayloadSize(payloadLength) {
    this.payloadLength = payloadLength;
  }
}

module.exports.SMFHeader = SMFHeader;
