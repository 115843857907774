const { ConsoleLogImpl } = require('./lib/console-log-impl');
const { GlobalBinding } = require('./lib/global-binding');
const { LogImpl } = require('./lib/log-impl');
const { LogLevel } = require('./lib/log-levels');

const { Parameter } = require('solclient-validate');
const { SolclientFactory } = require('solclient-factory');

const {
  isEnumMember,
  isFunction,
} = Parameter;

const {
  getImpl,
  getLogLevel,
  setImpl,
  setLogLevel,
} = GlobalBinding;

const forwarder = {};
function buildLogForwarder() {
  Object.assign(forwarder, {
    trace(...args) {
      const impl = getImpl();
      if (impl && impl.trace && getLogLevel() >= LogLevel.TRACE) {
        impl.trace.apply(null, ['solclientjs: ', ...args]);
      }
    },

    debug(...args) {
      const impl = getImpl();
      if (impl && impl.debug && getLogLevel() >= LogLevel.DEBUG) {
        impl.debug.apply(null, ['solclientjs: ', ...args]);
      }
    },

    info(...args) {
      const impl = getImpl();
      if (impl && impl.info && getLogLevel() >= LogLevel.INFO) {
        impl.info.apply(null, ['solclientjs: ', ...args]);
      }
    },

    warn(...args) {
      const impl = getImpl();
      if (impl && impl.warn && getLogLevel() >= LogLevel.WARN) {
        impl.warn.apply(null, ['solclientjs: ', ...args]);
      }
    },

    error(...args) {
      const impl = getImpl();
      if (impl && impl.error && getLogLevel() >= LogLevel.ERROR) {
        impl.error.apply(null, ['solclientjs: ', ...args]);
      }
    },

    fatal(...args) {
      const impl = getImpl();
      if (impl && impl.fatal) {
        impl.fatal.apply(null, ['solclientjs: ', ...args]);
      }
    },
  });
}
buildLogForwarder(); // stripped by production build

function addGlobalFuncs(source, target) {
  Object.keys(forwarder).forEach((k) => {
    target[`LOG_${k.toUpperCase()}`] = source[k];
  });
}

class LogFormatter {
  constructor(formatter) {
    this._formatter = (() => {
      if (typeof formatter === 'function') return formatter;
      if (typeof formatter === 'string') return function prepend(...args) { return [formatter, ...args]; };
      if (!formatter) return function passthrough(...args) { return [...args]; };
      return formatter;
    })();
    const self = this;
    Object.keys(forwarder).forEach((key) => {
      this[key] = function forward(...args) {
        return forwarder[key].apply(null, self._formatter(...args));
      };
    });
    addGlobalFuncs(this, this);
  }

  get formatter() {
    return this._formatter;
  }
  set formatter(func) {
    this._formatter = func;
  }

  wrap(genericFunction, targetSelf) {
    const self = this;
    return function genericLogWrapper(...args) {
      return genericFunction.apply(targetSelf, self._formatter(...args));
    };
  }

}

/**
 * Gets the current log level, which was set by {@link solace.SolclientFactory.init} or a
 * subsequent call to {@link solace.SolclientFactory.setLogLevel}.
 *
 * @returns {solace.LogLevel} The current log level.
 */
SolclientFactory.getLogLevel = () => getLogLevel();

  /**
   * This method changes the current log level from the level set when
   * {@link solace.SolclientFactory.init} was called.
   *
   * @param {solace.LogLevel} newLevel The new log level to set.
   * @throws {solace.OperationError} Invalid log level
   */
SolclientFactory.setLogLevel = (newLevel) => {
  isEnumMember('logLevel', newLevel, LogLevel);
  setLogLevel(newLevel);
};

SolclientFactory.addInitializer((props) => {
  setLogLevel(props.logLevel);

  const logger = props.logger || getImpl() || new ConsoleLogImpl();
  // Validate that the supplied log implementation is a superset of LogImpl
  Object.keys(new LogImpl()).forEach(key => isFunction(`logger.${key}`, logger[key]));

  setImpl(logger);
});

addGlobalFuncs(forwarder, module.exports);
module.exports.LogImpl = LogImpl;
module.exports.LogLevel = LogLevel;
module.exports.Binding = GlobalBinding;
module.exports.ConsoleLogImpl = ConsoleLogImpl;
GlobalBinding.setImpl(new ConsoleLogImpl());
module.exports.LogFormatter = LogFormatter;
