function apiEnv(envObject) {
  if (typeof BUILD_ENV !== 'undefined' && !BUILD_ENV.TEST) {
    throw new Error('Test environment will not override build environment');
  }
  global.BUILD_ENV = Object.assign({}, envObject, { TEST: true });
  return global.BUILD_ENV;
}

if (typeof BUILD_ENV !== 'undefined' && BUILD_ENV.TEST === false) {
  apiEnv.target = () => apiEnv();
} else {
  const configs = {
    node: {
      TARGET_NAME: 'node',
      TARGET_NODE: true,
    },
    browser: {
      TARGET_NAME:    'browser',
      TARGET_BROWSER: true,
    },
    debug: {
      MODE_NAME:    'debug',
      MODE_DEBUG:   true,
      MODE_RELEASE: false,
    },
    release: {
      MODE_NAME:    'release',
      MODE_DEBUG:   false,
      MODE_RELEASE: true,
    },
  };

  apiEnv.target = function named(...args) {
    const objects = args.map((arg) => {
      if (typeof arg === 'string') {
        const obj = configs[arg];
        if (!obj) {
          throw new Error(`Environment ${arg} is not defined; use ${Object.keys(configs).join(', ')}`);
        }
        return obj;
      } else if (arg && typeof arg === 'object') {
        return arg;
      }

      throw new Error(`Unrecognized argument ${arg}`);
    });
    return apiEnv(Object.assign(...objects));
  };
}
module.exports = apiEnv;
