const { ErrorSubcode, OperationError } = require('solclient-error');
const { Long } = require('solclient-convert');
const { Parameter } = require('solclient-validate');
const { ReplayStartLocation, ReplayStartType } = require('solclient-replaystart');
// eslint-disable-next-line global-require
const BufferImpl = require('buffer').Buffer;

const RMID_VERSION_1_PREFIX = 'rmid1:';
/* prefix length + 3 '-' + 16 bytes of hex (32) */
const RMID_LEN = RMID_VERSION_1_PREFIX.length + 3 + 16 * 2; // 41
/* regex for testing if data component of the rmid string parsing is valid */
const validRmidHexRegex = /^[0-9a-fA-F]{32}$/g;
const INVALID_SUID = Long.UZERO;

const {
  isString,
  isValue,
  isInstanceOf,
} = Parameter;

/**
 * @classdesc
 * <b>This class is not exposed for construction by API users. Users should obtain an instance from
 *  {@link solace.SolclientFactory.createReplicationGroupMessageId} or from
 *  {@link solace.Message.getReplicationGroupMessageId}</b>
 * <p>
 * ReplicationGroupMessageId specifies a Replication Group Message ID.
 * Can be used to specify a {@link solace.ReplayStartLocation} for the message after this id.
 * The ReplayStartLocation is set in the corresponding
 * MessageConsumer property {@link solace.MessageConsumerProperties#replayStartLocation}.
 *
 * @extends solace.ReplayStartLocation
 * @memberof solace
 * @hideconstructor
 */
class ReplicationGroupMessageId extends ReplayStartLocation {
  /**
   *@param {Long} spoolerUniqueId spooler id component of rgmid
   *@param {Long} effectiveMessageId message id component of rgmid
   *@private
   */
  constructor(spoolerUniqueId, effectiveMessageId) {
    super({
      _replayStartValue: {
        suid:      spoolerUniqueId,
        messageId: effectiveMessageId,
      },
      _type: ReplayStartType.RGMID,
    });
    /**
     * _suid {Long} spoolerUniqueId component of the replication group message id
     *              this should be unique to the replaication group
     * @private
     */
    this._suid = spoolerUniqueId;
    /**
     * _messageId {Long} effectiveMessageId component of the replication group message id
     *              this should be unique to the message in the replication group
     * @private
     */
    this._messageId = effectiveMessageId;
  }
  /**
   * Compares with other ReplicationGroupMessageId
   * @param {ReplicationGroupMessageId} otherReplicationGroupMessageId the other id to compare
   * @returns {Number} 0 if the ReplicationGroupMessageId is equal to the other
   *   ReplicationGroupMessageId.
   *   < 0 if the ReplicationGroupMessageId is less than the other ReplicationGroupMessageId.
   *   \> 0 if the ReplicationGroupMessageId is greater than the other ReplicationGroupMessageId.
   * @throws {solace.OperationError}
   * * if the otherReplicationGroupMessageId is not a ReplicationGroupMessageId type.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   * * if the otherReplicationGroupMessageId is not comparable as it is from different origins
   *   and can not be compared.
   *   Subcode: {@link solace.ErrorSubcode.MESSAGE_ID_NOT_COMPARABLE}.
   */
  compare(otherReplicationGroupMessageId) {
    isInstanceOf('otherReplicationGroupMessageId', otherReplicationGroupMessageId, ReplicationGroupMessageId);
    const oId = otherReplicationGroupMessageId;
    if (!this._suid.equals(oId._suid)) {
      const message = 'Unable to compare Replication Group Message ID from different origins';
      throw new OperationError(`Parameter otherReplicationGroupMessageId[${oId.toString()
        }] failed validation`,
        ErrorSubcode.MESSAGE_ID_NOT_COMPARABLE, message);
    }
    if (this._messageId.gt(oId._messageId)) {
      return 1;
    } else if (this._messageId.lt(oId._messageId)) {
      return -1;
    }
    return 0;
  }
  [util_inspect_custom]() {
    return `[Replication Group Message Id: ${this.toString()}]`;
  }
  /**
   * @override
   * @description
   * The ReplicationGroupMessageId toString() method returns a string that can later be passed to
   * {@link solace.SolclientFactory.createReplicationGroupMessageId} to create a
   * ReplicationGroupMessageId object.
   * @returns {String} serialized string of the ReplicationGroupMessageId
   */
  toString() {
    const idBuffer = BufferImpl.from(this._suid.toBytesBE().concat(this._messageId.toBytesBE()));
    const hexString = idBuffer.toString('hex');
    const sep = '-';
    return `${RMID_VERSION_1_PREFIX}${hexString.substring(0, 5)}${sep
    }${hexString.substring(5, 16)}${sep}${hexString.substring(16, 24)}${sep
    }${hexString.substring(24, 32)}`;
  }
}

/**
 * factory method for creating Replication Group Message Ids
 * @param {Object} spec object specification for building
 * @returns {ReplicationGroupMessageId} id instance for spec.
 * @private
 */
function createReplicationGroupMessageId(spec) {
  /* later this can be enhanced for other implementations of rgmids */
  return new ReplicationGroupMessageId(spec.suid, spec.msgid);
}

function fromString(rgmidStr) {
  /* check parameter type */
  isString('id', rgmidStr);
  /* validate rmid string to represent 128 bits of id data
   * must be of format:
   * rmid1:ttttt-rrrrrrrrrrr-mmmmmmmm-llllllll
   *
   * Where:
   *
   * * rmid1: This indicates it is a Replication Group Message ID (allows for some sanity
   *    checking that the bytes to follow are in fact a Replication Group Message ID).
   *    The '1' is a version to differentiate from a new type of Replication Group Message ID
   *    that may be introduced later.
   * * ttttt: Timestamp portion of SUID, 20 bits.
   * * rrrrrrrrrrr: Random portion of SUID, 44 bits.
   * * mmmmmmmm: Most significant 32-bits of ack message ID.
   * * llllllll: Least significant 32-bits of ack message ID.
   */
  isValue('id', rgmidStr.length, RMID_LEN, ErrorSubcode.PARAMETER_OUT_OF_RANGE, `length expected: ${RMID_LEN} but is ${rgmidStr.length}`);
  if (!rgmidStr.startsWith(RMID_VERSION_1_PREFIX)) {
    /* raise invalid format error */
    throw new OperationError('Parameter id has invalid Replication Group Message ID format',
      ErrorSubcode.PARAMETER_OUT_OF_RANGE,
      `id: ${rgmidStr}, does not start with ${RMID_VERSION_1_PREFIX}`);
  }
  const rmidBufs = rgmidStr.substring(RMID_VERSION_1_PREFIX.length).split('-');
  if (rmidBufs.length !== 4 ||
      rmidBufs[0].length !== 5 ||
      rmidBufs[1].length !== 11 ||
      rmidBufs[2].length !== 8 ||
      rmidBufs[3].length !== 8) {
    /* raise invalid format error */
    throw new OperationError('Parameter id has invalid Replication Group Message ID format',
      ErrorSubcode.PARAMETER_OUT_OF_RANGE,
      `id: ${rgmidStr}, does not have valid separation of components`);
  }
  /* convert rmid data string (hex) to bytes
   * note use Buffer to read hex string to bytes as Long.from(str, 16) uses inaccurate
   *  calculation and loses precision, while Buffer.from(str, 'hex') does not.
   * Browser BufferImpl is dependent on parseInt reading the string text 2 characters
   *  at a time letting a hex string chuck of [0-9][notHex], eg '9G', is parsed as a
   *  value 9 not NaN leading to a "valid" read. This means data integrity is lost
   *  on browser buffer parsing of hex. An independent string check is required to avoid this.
   */
  /* valid rmid component concatenated are hex only string with 32 character length */
  const rmidHex = rmidBufs.join('').trim();
  /* validRmidHexRegex uses the global flag and must be reset to advance the global regex
   * lastIndex.
   * reset regex
   */
  validRmidHexRegex.test('');
  if (!validRmidHexRegex.test(rmidHex)) {
    throw new OperationError('Parameter id has invalid Replication Group Message ID format',
      ErrorSubcode.PARAMETER_OUT_OF_RANGE,
      `id: ${rgmidStr}, invalid data string value`);
  }
  /* convert hex string to bytes */
  let rmidBuffer;
  try {
    rmidBuffer = BufferImpl.from(rmidHex, 'hex');
  } catch (ex) {
    /* error parsing hex string into buffer */
    throw new OperationError('Parameter id has invalid Replication Group Message ID format',
      ErrorSubcode.PARAMETER_OUT_OF_RANGE,
      `id: ${rgmidStr}, failed to read data, cause: ${ex.message}`);
  }
  /* validate read data len is expected for 128 bits of data */
  const buflen = rmidBuffer ? rmidBuffer.length : 0;
  if (buflen !== 16) {
    /* error occured during read */
    throw new OperationError('Parameter id has invalid Replication Group Message ID format',
      ErrorSubcode.PARAMETER_OUT_OF_RANGE,
      `id: ${rgmidStr}, failed to read data from id expected length of 16 got ${buflen}`);
  }
  /* extract components into long storage */
  const suid = Long.fromBits(rmidBuffer.readUInt32BE(4), rmidBuffer.readUInt32BE(0), true);
  if (suid.eq(INVALID_SUID)) {
    /* invalid suid detected raise out of range parameter OperationError */
    throw new OperationError('Parameter id has invalid Replication Group Message ID format',
      ErrorSubcode.PARAMETER_OUT_OF_RANGE,
      `id: ${rgmidStr}, has invalid origin`);
  }
  const msgid = Long.fromBits(rmidBuffer.readUInt32BE(12), rmidBuffer.readUInt32BE(8), true);
  /* return created id object with Long values */
  return createReplicationGroupMessageId({ suid, msgid });
}

const RgmidFactory = {};
RgmidFactory.fromString = fromString;
RgmidFactory.from = createReplicationGroupMessageId;
RgmidFactory.INVALID_SUID = INVALID_SUID;

module.exports.ReplicationGroupMessageId = ReplicationGroupMessageId;
module.exports.RgmidFactory = RgmidFactory;
