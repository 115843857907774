const { Long } = require('solclient-convert');

const DEFAULTS = {
  _lastAcked: Long.fromNumber(0, true),
  _lastSent:  Long.fromNumber(0, true),
  _next:      Long.fromNumber(1, true),
};

const longToString = v => v.toString(10);

class MessageIds {
  constructor(attrs) {
    Object.assign(this, DEFAULTS, attrs);
  }

  get lastAcked() {
    return this._lastAcked;
  }
  set lastAcked(value) {
    this._lastAcked = Long.fromValue(value);
  }

  get lastSent() {
    return this._lastSent;
  }
  setLastSent(id) {
    this._lastSent = Long.fromValue(id);
    this._next = this._lastSent.add(1);
  }

  get next() {
    return this._next;
  }

  [util_inspect_custom]() {
    return {
      'lastAcked': longToString(this.lastAcked),
      'lastSent':  longToString(this.lastSent),
      'next':      longToString(this.next),
    };
  }

  toString() {
    return util_inspect(this);
  }
}

module.exports.MessageIds = MessageIds;
