const SMFLib = require('solclient-smf');
const { AdProtocolMessage, SMFParameter } = require('../message-objects');
const { Bits, Convert } = require('solclient-convert');
const { LOG_DEBUG, LOG_INFO, LOG_ERROR } = require('solclient-log');
const { ReplayStartType } = require('solclient-replaystart');
// const { SMFAdProtocolParam } = require('solclient-smf');

const {
  get: bits,
  set: setBits,
} = Bits;

const {
  int8ToStr,
  int16ToStr,
  int24ToStr,
  int32ToStr,
} = Convert;

function parseAdpAt(dataBuf, offset) {
  if ((offset + 3) > dataBuf.length) {
    //not enough data
    LOG_DEBUG('Not enough data to read an ADP message.');
    return false;
  }
  let pos = offset;
  let onebyte = dataBuf.readUInt8(pos);
  pos++;

  // var msgRFU = bits(onebyte, 6, 2);
  const adpVersion = bits(onebyte, 0, 6);
  let msgLength;
  let msgType;
  if (adpVersion < 3) {
    const twobyte = dataBuf.readUInt16BE(pos);
    pos += 2;
    msgType = bits(twobyte, 12, 4);
    // length in 32 bit words is in the lower 12 bits
    msgLength = bits(twobyte, 0, 12);
    // convert length to number of bytes
    msgLength <<= 2;
  } else if (adpVersion === 3) {
    onebyte = dataBuf.readUInt8(pos);
    pos++;
    msgType = bits(onebyte, 0, 8);
    msgLength = dataBuf.readUInt32BE(pos);
    pos += 4;
  } else {
    LOG_ERROR('Found unsupported ADP Version', adpVersion);
    return false; //unsupported type
  }
  //
  // Verify that the ADP header length does not exceed the entire
  // contents of the SMF message.
  if (offset + msgLength > dataBuf.length) {
    LOG_ERROR(`Invalid Asssured Control Protocol length=${msgLength
      } exceeds remaining message buffer = ${dataBuf.length - offset}`);
    return false; // invalid message format
  }
  const adpMsg = new AdProtocolMessage(msgType, adpVersion);
  while (pos < (offset + msgLength)) {
    onebyte = dataBuf.readUInt8(pos);
    pos++;
    const paramUH = bits(onebyte, 6, 2);
    const paramType = bits(onebyte, 0, 6);

    // Look for and skip padding bytes
    if (paramType === 0) {
      continue;
    }
    if (pos >= (offset + msgLength)) {
      LOG_ERROR(`Invalid Asssured Control Protocol parameter=${paramType} at position =${pos}`);
      return false; // Assured Control parsing fail
    }
    let paramLen = dataBuf.readUInt8(pos);
    let paramValueLen;
    pos++;
    // If paramLen == 0, then this is an extended length format
    // and there is a 4 byte length following the '0'
    if (paramLen === 0) {
      // need at least 5 more bytes in the buffer
      if (pos + 5 > (offset + msgLength)) {
        LOG_ERROR(`Invalid Asssured Control Protocol parameter=${paramType} at position =${pos}`);
        return false; // Assured Control parsing fail
      }
      paramLen = dataBuf.readUInt32BE(pos);
      pos += 4;
      paramValueLen = paramLen - 5;
    } else {
      paramValueLen = paramLen - 2;
    }
    if (paramLen <= 0) {
      return false; // Assured Control parsing fail
    }
    //
    // make sure there is enough buffer for paramValueLen
    if (pos + paramValueLen > offset + msgLength) {
      LOG_ERROR(`Invalid Asssured Control Protocol parameter=${paramType
        } length =${paramValueLen} invalid at position =${pos}`);
      return false; // Assured Control parsing fail
    }
    const smfParam = new SMFParameter(paramUH, paramType, null, dataBuf, pos, pos + paramValueLen);
    adpMsg.addParameter(smfParam);
    pos += paramValueLen;
  }
  return adpMsg;
}

function encAdp0Param(uh, paramtype) {
  const data = [];
  let byte1 = 0;

  byte1 = Bits.set(byte1, uh, 6, 2);
  byte1 = Bits.set(byte1, paramtype, 0, 6);
  data.push(Convert.int8ToStr(byte1));
  data.push(Convert.int8ToStr(2)); // length
  return data.join('');
}
function encAdp8Param(uh, paramtype, value) {
  const data = [];
  let byte1 = 0;

  byte1 = Bits.set(byte1, uh, 6, 2);
  byte1 = Bits.set(byte1, paramtype, 0, 6);
  data.push(Convert.int8ToStr(byte1));
  data.push(Convert.int8ToStr(3)); // length
  data.push(Convert.int8ToStr(value));
  return data.join('');
}

function encAdp16Param(uh, paramtype, value) {
  const data = [];
  let byte1 = 0;

  byte1 = Bits.set(byte1, uh, 6, 2);
  byte1 = Bits.set(byte1, paramtype, 0, 6);
  data.push(Convert.int8ToStr(byte1));
  data.push(Convert.int8ToStr(4)); // length
  data.push(Convert.int16ToStr(value));
  return data.join('');
}

function encAdp32Param(uh, paramtype, value) {
  const data = [];
  let byte1 = 0;

  byte1 = Bits.set(byte1, uh, 6, 2);
  byte1 = Bits.set(byte1, paramtype, 0, 6);
  data.push(Convert.int8ToStr(byte1));
  data.push(Convert.int8ToStr(6)); // length
  data.push(Convert.int32ToStr(value));
  return data.join('');
}

function encAdp64Param(uh, paramtype, value) {
  const data = [];
  let byte1 = 0;

  byte1 = Bits.set(byte1, uh, 6, 2);
  byte1 = Bits.set(byte1, paramtype, 0, 6);
  data.push(Convert.int8ToStr(byte1));
  data.push(Convert.int8ToStr(10)); // length
  data.push(Convert.int64ToStr(value));
  return data.join('');
}

// map of replay start value types to parameter lengths
const RSValueLenMap = {};
RSValueLenMap[ReplayStartType.BEGINNING] = 3; // 2 (TLV) + 1 (replay start type)
RSValueLenMap[ReplayStartType.DATE] = 11; // 2 (TLV) + 1 (replay start type) + 8 (date value)
RSValueLenMap[ReplayStartType.RGMID] = 19; //2 (TLV) + 1 (replay start type) + 16 (rgmid value)

// special case for message replay value, a 1 byte type
// followed by 0 bytes of begining, 8 bytes of date or 16 bytes of rgmid
// Note replay Start type 0 is handled using encAdp8Param as there is no value
function encAdpReplayParam(uh, paramtype, value) {
  const data = [];
  const valType = value.type;
  const valObj = value.value;
  let byte1 = 0;

  byte1 = Bits.set(byte1, uh, 6, 2);
  byte1 = Bits.set(byte1, paramtype, 0, 6);
  data.push(Convert.int8ToStr(byte1));
  data.push(Convert.int8ToStr(RSValueLenMap[valType])); // length
  data.push(Convert.int8ToStr(valType)); // replay start type, 1 == date, 2 == RGMID
  switch (valType) {
    case ReplayStartType.DATE:
      // encode 64 bit date
      data.push(Convert.int64ToStr(valObj)); // value is Long type
      break;
    case ReplayStartType.RGMID:
      // encode 128 bit replication group message id
      // expected value object of
      // {
      //   suid (Long),
      //   messageId (Long),
      // }
      data.push(Convert.int64ToStr(valObj.suid)); // get Long suid
      data.push(Convert.int64ToStr(valObj.messageId)); // get Long messageId
      break;
    case ReplayStartType.BEGINNING:
      // in this case there is no value to encode
      break;
    default:
      // should not happen until there are more replay start type but those
      // should be handled in a case above
      break;
  }
  return data.join('');
}

function encAdp64AckPairParam(uh, paramtype, min, max) {
  const data = [];
  let byte1 = 0;
  byte1 = Bits.set(byte1, uh, 6, 2);
  byte1 = Bits.set(byte1, paramtype, 0, 6);
  data.push(Convert.int8ToStr(byte1));
  data.push(Convert.int8ToStr(18)); // length
  data.push(Convert.int64ToStr(min));
  data.push(Convert.int64ToStr(max));
  return data.join('');
}

function encAdpUTF8NTParam(uh, paramtype, value) {
  // value is already UTF8 encoded and null terminated.
  const data = [];
  let byte1 = 0;
  byte1 = Bits.set(byte1, uh, 6, 2);
  byte1 = Bits.set(byte1, paramtype, 0, 6);
  data.push(Convert.int8ToStr(byte1));

  let byte2 = 0;
  if (value.length <= 253) {
    byte2 = value.length + 2; // full length of param
    data.push(Convert.int8ToStr(byte2));
  } else {
    byte2 = 0; // extended-length
    data.push(Convert.int8ToStr(byte2));
    data.push(Convert.int32ToStr(value.length + 5));
  }
  data.push(value);
  return data.join('');
}

function encAdp(adpMsg) {
  const paramspace = [];
  const paramarray = adpMsg.getParameterArray();

  let p;
  for (p = 0; p < paramarray.length; p++) {
    const param = paramarray[p];
    // It's not a flat array, we have gaps!
    if (param === undefined) {
      continue;
    }

    switch (param.getType()) {
      /*
       * 8 bit parameters
       */
      case SMFLib.SMFAdProtocolParam.WINDOW:
      case SMFLib.SMFAdProtocolParam.EP_DURABLE:
      case SMFLib.SMFAdProtocolParam.ACCESSTYPE:
      case SMFLib.SMFAdProtocolParam.FLOWTYPE:
      case SMFLib.SMFAdProtocolParam.EP_RESPECTS_TTL:
      case SMFLib.SMFAdProtocolParam.TRANSACTION_CTRL_MESSAGE_TYPE:
      case SMFLib.SMFAdProtocolParam.TRANSACTED_SESSION_STATE:
      case SMFLib.SMFAdProtocolParam.ACTIVE_FLOW_INDICATION:
      case SMFLib.SMFAdProtocolParam.WANT_FLOW_CHANGE_NOTIFY:
      case SMFLib.SMFAdProtocolParam.MAX_REDELIVERY:
        paramspace.push(encAdp8Param(param.getUh(), param.getType(), param.getValue()));
        break;
      /*
       * 16 bit parameters
       */
      case SMFLib.SMFAdProtocolParam.EP_BEHAVIOUR:
        paramspace.push(encAdp16Param(param.getUh(), param.getType(), param.getValue()));
        break;
      /*
       * 32 bit parameters
       */
      case SMFLib.SMFAdProtocolParam.FLOWID:
      case SMFLib.SMFAdProtocolParam.TRANSPORT_WINDOW:
      case SMFLib.SMFAdProtocolParam.EP_ALLOTHER_PERMISSION:
      case SMFLib.SMFAdProtocolParam.EP_QUOTA:
      case SMFLib.SMFAdProtocolParam.EP_MAX_MSGSIZE:
      case SMFLib.SMFAdProtocolParam.GRANTED_PERMISSION:
      case SMFLib.SMFAdProtocolParam.TRANSACTED_SESSION_ID:
      case SMFLib.SMFAdProtocolParam.PUBLISHER_ID:
        paramspace.push(encAdp32Param(param.getUh(), param.getType(), param.getValue()));
        break;
      /*
       * 64 bit parameters
       */
      case SMFLib.SMFAdProtocolParam.LASTMSGIDSENT:
      case SMFLib.SMFAdProtocolParam.LASTMSGIDACKED:
      case SMFLib.SMFAdProtocolParam.LASTMSGIDRECEIVED:
      case SMFLib.SMFAdProtocolParam.TRANSACTION_ID:
      case SMFLib.SMFAdProtocolParam.ENDPOINT_ERROR_ID:
        paramspace.push(encAdp64Param(param.getUh(), param.getType(), param.getValue()));
        break;
      /*
       * Replay start location can be either a 0 bit, 64 bit or 128 bit parameter.
       */
      case SMFLib.SMFAdProtocolParam.REPLAY_START_LOCATION:
        {
          const replayParamVal = param.getValue();
          if (undefined === replayParamVal.value) {
            // replay start location with 0 bit values, type only
            paramspace.push(encAdp8Param(param.getUh(),
                                         param.getType(),
                                         replayParamVal.type));  // start location type 0
          } else {
            // replay start location with > 0 bit values, type + value
            paramspace.push(encAdpReplayParam(param.getUh(), param.getType(), replayParamVal));
          }
          break;
        }
      /*
       * application ack
       */
      case SMFLib.SMFAdProtocolParam.APPLICATION_ACK:
        {
          // Unpack this to multiple parameters
          const ranges = param.getValue();
          const uh = param.getUh();
          const type = param.getType();
          for (let i = 0; i < ranges.length; ++i) {
            const range = ranges[i];
            paramspace.push(encAdp64AckPairParam(uh,
                                                 type,
                                                 range[0],
                                                 range[1]));
          }
          break;
        }
      /*
       * string and other variable length parameters
       */
      case SMFLib.SMFAdProtocolParam.QUEUENAME:
      case SMFLib.SMFAdProtocolParam.DTENAME:
      case SMFLib.SMFAdProtocolParam.TOPICNAME:
      case SMFLib.SMFAdProtocolParam.FLOWNAME:
      case SMFLib.SMFAdProtocolParam.SELECTOR:
      case SMFLib.SMFAdProtocolParam.TRANSACTED_SESSION_NAME:
        paramspace.push(encAdpUTF8NTParam(param.getUh(), param.getType(), param.getValue()));
        break;
      /*
       * Transaction Publisher Notify
       */
      case SMFLib.SMFAdProtocolParam.TRANSACTION_FLOW_DESCRIPTOR_PUB_NOTIFY:
        break;
      /*
       * Transaction Publisher Ack
       */
      case SMFLib.SMFAdProtocolParam.TRANSACTION_FLOW_DESCRIPTOR_PUB_ACK:
        break;
      /*
       * Transaction Subscriber Ack
       */
      case SMFLib.SMFAdProtocolParam.TRANSACTION_FLOW_DESCRIPTOR_SUB_ACK:
        break;
      /*
       * No Local Parameter has no data
       * Cut Through Parameter has no data
       */
      case SMFLib.SMFAdProtocolParam.NOLOCAL:
      case SMFLib.SMFAdProtocolParam.CUT_THROUGH:
        paramspace.push(encAdp0Param(param.getUh(), param.getType()));
        break;
      /*
       * Application Publisher Acknowledge
       */
      case SMFLib.SMFAdProtocolParam.APPLICATION_PUB_ACK:
        break;
      default:
        LOG_INFO('Unrecognized ADProtocol Parameter in Message');
        break;
    }
  }

  const paramdata = paramspace.join('');

  const data = [];
  if (adpMsg.version === 2) {
    let threebytes = 0;
    threebytes = setBits(threebytes, 0, 22, 2); // RFU
    threebytes = setBits(threebytes, adpMsg.version, 16, 6); // RFU
    threebytes = setBits(threebytes, adpMsg.msgType, 12, 4); // msgtype
    // length in 32 bit words means the real length must always be a multiple of 4, so pad as
    // necessary

    // 4 - how many bytes passed a 4 byte boundary
    let padBytes = 4 - ((3 + paramdata.length) & 0x3);
    // calculate the total length, 3 bytes header + params, in 32 bit words
    const length = (3 + paramdata.length + padBytes) >> 2;
    threebytes = setBits(threebytes, length, 0, 12);
    data.push(int24ToStr(threebytes)); // first 3B (RFU, version, msgtype, length)
    data.push(paramdata);

    if (padBytes === 4) padBytes = 0; // don't add 4 pad bytes
    while (padBytes > 0) {
      data.push(int8ToStr(0));
      padBytes--;
    }
  } else if (adpMsg.version === 3) {
    let twobytes = 0;
    twobytes = setBits(twobytes, 0, 14, 2); // RFU
    twobytes = setBits(twobytes, adpMsg.version, 8, 6); // version
    twobytes = setBits(twobytes, adpMsg.msgType, 0, 8); // msgtype
    data.push(int16ToStr(twobytes)); // first 2B (RFU, version, msgtype)
    data.push(int32ToStr(6 + paramdata.length)); //length: 6B header + params
    data.push(paramdata);
  } else {
    LOG_ERROR(`Invalid Version ${adpMsg.version} found while encoding`);
  }

  return data.join('');
}

module.exports.parseAdpAt = parseAdpAt;
module.exports.encAdp = encAdp;
