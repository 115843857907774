const StateLib = require('./state');
const { FsmObject } = require('./object');

/**
 * @classdesc
 * This object type represents an entryPoint for a particular FSM state.
 *
 * Note this initial implementation isn't particularly efficient, but it is
 * very simple by making use of two state objects (one just inside of and
 * one just outside of) the state the entryPoint belongs to.  If necessary, we
 * could optimize this if we wish.
 * @extends FsmObject
 * @private
 */
class EntryPoint extends FsmObject {

  /**
  * @constructor
  * @param {Object} spec The object specifier used to implement the named
  *      parameter idiom.
  * @param {fsm.State} spec.state The state that the entryPoint belongs
  *      to.
  * @param {String} spec.entryPointName The name of the entryPoint.
  * @param {fsm.StateContext~reactionCallback} spec.func The reaction
  *      function for the entryPoint, which defines where to transition to
  *      after state has been entered.
  */
  constructor(spec) {
    super({ name: spec.entryPointName });

    let innerState;
    this.impl = this.impl || {};
    // The outer portion of the entryPoint accepts incoming transitions before
    // entering the entryPoint's state.  Then the entryPoint's state is
    // entered through the use of the outerEntryPoint's initial transition.
    this.impl.outerState = new StateLib.State({
      name:          `${spec.state.getName()} outerEntryPoint: ${spec.entryPointName}`,
      parentContext: spec.state.getParent(),
    }).initial(() => spec.state.transitionTo(innerState));

    // The job of the inner portion of the entryPoint is to follow a
    // transition as specified by the application's reaction function.
    innerState = new StateLib.State({
      name:          `${spec.state.getName()} innerEntryPoint: ${spec.entryPointName}`,
      parentContext: spec.state,
    }).initial(spec.func);
  }

  /**
   * @returns {fsm.State} The destination state for a transition to the
   *      EntryPoint.
   */
  getDestState() {
    return this.impl.outerState;
  }
}

module.exports.EntryPoint = EntryPoint;
