const { Enum } = require('solclient-eskit');

/**
 * Specifies the type of remote resource to which an
 * {@link solace.AbstractQueueDescriptor} refers.
 *
 * @enum {string}
 * @namespace
 * @memberof solace
 */
const QueueType = {
  /**
   * @description The queue descriptor refers to a queue endpoint.
   * @type {String}
   */
  QUEUE:          'QUEUE',
  /**
   * @description The queue descriptor refers to a topic endpoint.
   * @type {String}
   */
  TOPIC_ENDPOINT: 'TOPIC_ENDPOINT',
};

module.exports.QueueType = Enum.new(QueueType);
