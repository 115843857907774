const Long = require('long');
const {
  LOG_DEBUG,
  LOG_ERROR,
  LOG_INFO,
  LOG_TRACE,
} = require('solclient-log');
const { Base64, Bits } = require('solclient-convert');
const { ParamParse } = require('./param-parse');
const { SMFHeader } = require('../message-objects');
const { SMFParameterType } = require('../smf-parameter-types');

const {
  decode: base64Decode,
} = Base64;
const {
  get: bits,
} = Bits;

function isSMFHeaderAvailable(dataBuf, offset) {
  const remaining = dataBuf.length - offset;
  if (remaining < 12) {
    return false;
  }

  return true;
}

function isSMFHeaderValid(dataBuf, offset) {
  if (!isSMFHeaderAvailable(dataBuf, offset)) {
    return false;
  }
  const version = dataBuf.readUInt8(offset) & 0x7;
  if (version !== 3) {
    LOG_ERROR(`Invalid smf version in smf header, version=${version}`);
    return false;
  }
  return true;
}

function isSMFAvailable(dataBuf, offset) {
  if (!isSMFHeaderValid(dataBuf, offset)) {
    return false;
  }
  const remaining = dataBuf.length - offset;
  const totalLen = dataBuf.readUInt32BE(offset + 8);
  return (totalLen <= remaining);
}

/**
 * Parse the Extended Parameters from an Extended Type Stream.
 * See section "4.2.4 Extended Parameter Types" in the SMF spec.
 * @param {SMFHeader} smfHeader The SMF header to add the extended parameters to.
 * @param {Buffer} dataBuf The binary data to parse
 * @param {Number} offset The offset in the data to begin parsing (ETS payload)
 * @param {Number} streamLen ETS length
 * @returns {Boolean} false if framing is lost, true otherwise.
 * @private
 */
function parseSMFExtendedStream(smfHeader, dataBuf, offset, streamLen) {
  /* eslint-disable no-unused-vars */
  let pos = offset;
  while (pos < offset + streamLen) {
    if (pos + 2 > offset + streamLen) {
      LOG_ERROR('Extended parameter stream had padding inside.');
      break;
    }

    // The first 2 bytes of Extended Parameters is fixed (MSB order):
    // 1 bit UH
    // 3 bits length mode selector (0-, 1-, 2-, 4-, 8-byte, or variable)
    // 12 bits Type
    const byte1 = dataBuf.readUInt8(pos);
    const byte2 = dataBuf.readUInt8(pos + 1);
    const pUH = bits(byte1, 7, 1);
    const lengthMode = bits(byte1, 4, 3);
    const type = (bits(byte1, 0, 4) << 8) + byte2; //FIGURE OUT WHAT bits does
    pos += 2;
    const lengthModeMap = { 0: 0, 1: 1, 2: 2, 3: 4, 4: 8 };
    let valueLen = 0;
    // EsLint made me do it
    if (Object.prototype.hasOwnProperty.call(lengthModeMap, lengthMode)) {
      valueLen = lengthModeMap[lengthMode];
    } else if (lengthMode === 5) {
      // 1-byte variable length value
      valueLen = dataBuf.readUInt8(pos) - 3;
      pos++;
    } else if (lengthMode === 6) {
      // 2-byte variable length value
      valueLen = dataBuf.readUInt16BE(pos) - 4;
      pos += 2;
    } else {
      LOG_ERROR(`Invalid length mode ${lengthMode} in Extended Parameter type ${type}`);
      // We must be reading garbage, disconnect:
      return false;
    }

    switch (type) {
      case SMFParameterType.AD_REDELIVERY_COUNT:
        smfHeader.pm_ad_redeliveryCount = dataBuf.readUInt32BE(pos);
        break;
      case SMFParameterType.AD_SPOOLER_UNIQUE_ID:
        smfHeader.pm_ad_spooler_unique_id = Long.fromBits(dataBuf.readUInt32BE(pos + 4),
                                                          dataBuf.readUInt32BE(pos),
                                                          true);
        break;
      case SMFParameterType.AD_ACK_MESSAGE_ID:
        smfHeader.pm_ad_local_spooler_message_id = Long.fromBits(dataBuf.readUInt32BE(pos + 4),
                                                                 dataBuf.readUInt32BE(pos),
                                                                 true);
        break;
      case SMFParameterType.AD_REPL_MATE_ACK_MSGID:
        smfHeader.pm_ad_replication_mate_ack_message_id =
           Long.fromBits(dataBuf.readUInt32BE(pos + 4),
                         dataBuf.readUInt32BE(pos),
                         true);
        break;
      default:
        if (pUH === 0) {
          LOG_TRACE('Dropping unrecognised extended parameter ' +
                    `type ${type} value length ${valueLen} UH ${pUH}`);
        } else {
          smfHeader.discardMessage = true;
          LOG_TRACE('Dropping whole message due to unrecognised extended parameter ' +
                    `type ${type} value length ${valueLen} UH ${pUH}`);
        }
        break;
    }
    pos += valueLen;
  }
  if (pos > offset + streamLen) {
    LOG_ERROR(`Last extended parameter ran beyond extended stream length by ${pos - (offset + streamLen)}.`);
  }
  return true;
  /* eslint-enable no-unused-vars */
}

/**
 * Parse SMF in the given data buffer at the supplied offset.
 * @param {Buffer} dataBuf The binary data to parse
 * @param {Number} offset The offset in the data to begin parsing
 * @param {Boolean} readHeaderOnly If true, stop parsing at end of header
 * @returns {SMFHeader|null} The SMF header, if possible
 * @private
 */
function parseSMFAt(dataBuf, offset, readHeaderOnly = false) {
  if (!isSMFHeaderValid(dataBuf, offset)) {
    LOG_DEBUG('Valid SMF header not available');
    return null;
  }
  let pos = offset;

  // Reading fixed header block (12 bytes)
  const word1 = dataBuf.readUInt32BE(pos);
  const headerLen = dataBuf.readUInt32BE(pos + 4);
  const word3 = dataBuf.readUInt32BE(pos + 8);

  const smfHeader = new SMFHeader();
  smfHeader.smf_di = bits(word1, 31, 1);
  smfHeader.smf_elidingEligible = bits(word1, 30, 1);
  smfHeader.smf_dto = bits(word1, 29, 1);
  smfHeader.smf_adf = bits(word1, 28, 1);
  smfHeader.smf_deadMessageQueueEligible = bits(word1, 27, 1);
  smfHeader.smf_version = bits(word1, 24, 3);
  smfHeader.smf_uh = bits(word1, 22, 2);
  smfHeader.smf_protocol = bits(word1, 16, 6);
  smfHeader.smf_priority = bits(word1, 12, 4);
  smfHeader.smf_ttl = bits(word1, 0, 8);

  const payloadLen = word3 - headerLen;
  if (payloadLen < 0) {
    LOG_ERROR('SMF parse error: lost framing');
    return null; // SMF parse error: lost framing
  }
  smfHeader.setMessageSizes(headerLen, payloadLen);
  if (readHeaderOnly) {
    return smfHeader;
  }
  pos += 12;

  // Reading variable-length params
  const end = offset + headerLen;
  while (pos < end) {
    const paramByte1 = dataBuf.readUInt8(pos);
    ++pos;

    const prmUh = bits(paramByte1, 6, 2);
    const paramIsLightweight = (bits(paramByte1, 5, 1) !== 0);
    if (paramIsLightweight) {
      // LIGHTWEIGHT param
      const lwpType = bits(paramByte1, 2, 3);
      const lwpLen = bits(paramByte1, 0, 2) + 1;
      const lwpValueLen = lwpLen - 1;
      if (lwpLen <= 0) {
        LOG_ERROR('Invalid lightweight parameter length');
        return null; // Invalid parameter
      }

      switch (lwpType) {
        case SMFParameterType.LIGHT_CORRELATION:
          smfHeader.pm_corrtag = dataBuf.readUIntBE(pos, 3);
          break;
        case SMFParameterType.LIGHT_TOPIC_NAME_OFFSET:
          {
            const parsedQueueOffsets = ParamParse.parseTopicQueueOffsets(dataBuf, pos);
            smfHeader.pm_queue_offset = parsedQueueOffsets[0];
            smfHeader.pm_queue_len = parsedQueueOffsets[1];
            break;
          }
        case SMFParameterType.LIGHT_QUEUE_NAME_OFFSET:
          {
            const parsedTopicOffsets = ParamParse.parseTopicQueueOffsets(dataBuf, pos);
            smfHeader.pm_topic_offset = parsedTopicOffsets[0];
            smfHeader.pm_topic_len = parsedTopicOffsets[1];
            break;
          }
        case SMFParameterType.LIGHT_ACK_IMMEDIATELY:
          smfHeader.pm_ad_ackimm = !!dataBuf.readUInt8(pos);
          break;
        default:
          if (prmUh === 0) {
            // Ignore, and silently discard the parameter.
            LOG_TRACE(`Unhandled LIGHTWEIGHT parameter type: ${lwpType} UH is ${prmUh} discarding parameter.`);
          } else {
            // Ignore, and silently discard the entire message.
            LOG_TRACE(`Unhandled LIGHTWEIGHT parameter type: ${lwpType} UH is ${prmUh} discarding message.`);
            smfHeader.discardMessage = true;
          }
          break;
      }
      pos += lwpValueLen;
    } else {
      // REGULAR encoded param (including breakout for Extended Type Stream)
      const pStart = pos;
      const pType = bits(paramByte1, 0, 5);
      if (pType === 0) {
        break; // PADDING (break while: header finished)
      }

      let pLen = dataBuf.readUInt8(pos);
      pos++;
      let pValueLen;
      if (pLen === 0) {
        // extended-length parameter (32-bit)
        // Works for Extended Parameters too.
        pLen = dataBuf.readUInt32BE(pos);
        pos += 4;
        pValueLen = pLen - 6;
      } else {
        pValueLen = pLen - 2;
      }

      if (pLen <= 0) {
        LOG_ERROR(`Invalid regular parameter length ${pLen}/${pValueLen
                  } with suspect type ${SMFParameterType.describe(pType)} at parameter at position ${pStart}`);
        return null; // Invalid parameter
      }

      switch (pType) {
        case SMFParameterType.PUBLISHER_ID:
          smfHeader.pm_ad_publisher_id = Long.fromBits(dataBuf.readUInt32BE(pos + 4),
                                                       dataBuf.readUInt32BE(pos),
                                                       true);
          break;
        case SMFParameterType.PUBLISHER_MSGID:
          smfHeader.pm_ad_publishermsgid = Long.fromBits(dataBuf.readUInt32BE(pos + 4),
                                                         dataBuf.readUInt32BE(pos),
                                                         true);
          break;
        case SMFParameterType.MESSAGEPRIORITY:
          smfHeader.pm_msg_priority = dataBuf.readUInt8(pos);
          break;
        case SMFParameterType.USERDATA:
          smfHeader.pm_userdata = dataBuf.toString('latin1', pos, pos + pValueLen);
          break;
        case SMFParameterType.USERNAME:
          // only useful on API -> router
          smfHeader.pm_username = base64Decode(dataBuf.toString('latin1', pos, pos + pValueLen));
          break;
        case SMFParameterType.PASSWORD:
          // only useful on API -> router
          smfHeader.pm_password = base64Decode(dataBuf.toString('latin1', pos, pos + pValueLen));
          break;
        case SMFParameterType.RESPONSE:
          {
            const parsedResponse = ParamParse.parseResponseParam(dataBuf, pos, pValueLen); //CHECK
            smfHeader.pm_respcode = parsedResponse[0];
            smfHeader.pm_respstr = parsedResponse[1];
            break;
          }
        case SMFParameterType.SUB_ID_LIST:
        case SMFParameterType.GENERIC_ATTACHMENT:
        case SMFParameterType.BINARY_ATTACHMENT:
          LOG_INFO('Skipping deprecated parameter type');
          // deprecated
          break;
        case SMFParameterType.DELIVERY_MODE:
          // DeliveryMode is DIRECT unless the AD flag is set. The
          // deliveryMode parameter (and all other guaranteed messaging
          // parameters may be present in demoted messages reflecting how
          // the message was published.
          // IF ever solClientJS must support the horror that is cut-through
          // persistence, then we must defer setting deliveryMode back to
          // DIRECT until the session decides whether it is a true direct
          // message or a cut-through direct message.  But until that is
          // forced upon us, the cleanest place to set deliveryMode is always
          // here in the parser.
          if (smfHeader.smf_adf) {
            smfHeader.pm_deliverymode = ParamParse.parseDeliveryMode(dataBuf, pos);
          }
          break;
        case SMFParameterType.ASSURED_MESSAGE_ID:
          smfHeader.pm_ad_msgid = Long.fromBits(dataBuf.readUInt32BE(pos + 4),
                                                dataBuf.readUInt32BE(pos),
                                                true);
          break;
        case SMFParameterType.ASSURED_PREVMESSAGE_ID:
          smfHeader.pm_ad_prevmsgid = Long.fromBits(dataBuf.readUInt32BE(pos + 4),
                                                    dataBuf.readUInt32BE(pos),
                                                    true);
          break;
        case SMFParameterType.ASSURED_REDELIVERED_FLAG:
          smfHeader.pm_ad_redelflag = true;
          break;
        case SMFParameterType.AD_TIMETOLIVE:
          smfHeader.pm_ad_ttl = Long.fromBits(dataBuf.readUInt32BE(pos + 4),
                                              dataBuf.readUInt32BE(pos),
                                              true);
          break;
        case SMFParameterType.AD_TOPICSEQUENCE_NUMBER:
          smfHeader.pm_ad_topicSequenceNumber = Long.fromBits(dataBuf.readUInt32BE(pos + 4),
                                                              dataBuf.readUInt32BE(pos),
                                                              true);
          break;
        case SMFParameterType.MESSAGE_CONTENT_SUMMARY:
          {
            const contentSummary = ParamParse.parseContentSummary(dataBuf, pos, pValueLen);
            if (!contentSummary) {
              LOG_ERROR(`Invalid message content summary at ${pos}, len ${pValueLen}`);
              return false; // invalid message content summary parameter
            }
            smfHeader.pm_content_summary = contentSummary;
            break;
          }
        case SMFParameterType.ASSURED_FLOWID:
          smfHeader.pm_ad_flowid = dataBuf.readUInt32BE(pos);
          break;
        case SMFParameterType.TR_TOPICNAME:
          // copy bytes. Don't strip null terminator
          smfHeader.pm_tr_topicname_bytes = dataBuf.toString('latin1', pos, pos + pValueLen);
          break;
        case SMFParameterType.AD_FLOWREDELIVERED_FLAG:
          smfHeader.pm_ad_flowredelflag = true;
          break;
        case SMFParameterType.EXTENDED_TYPE_STREAM: {
          const extSuccess = parseSMFExtendedStream(smfHeader, dataBuf, pos, pValueLen);
          if (!extSuccess) { return null; }
          break;
        }
        default:
          if (prmUh === 0) {
            // Ignore, and silently discard the parameter.
            LOG_TRACE(`Unhandled SMF parameter type: ${pType} UH is ${prmUh} discarding parameter.`);
          } else {
            // Ignore, and silently discard the entire message.
            LOG_TRACE(`Unhandled SMF parameter type: ${pType} UH is ${prmUh} discarding message.`);
            smfHeader.discardMessage = true;
          }
          break;
      } // end param type switch block

      pos += pValueLen;
    } // end (regular param)
  } // end while

  return smfHeader;
}

const ParseSMF = {
  isSMFHeaderAvailable,
  isSMFHeaderValid,
  isSMFAvailable,
  parseSMFAt,
};

module.exports.ParseSMF = ParseSMF;
