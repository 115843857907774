const { Enum } = require('solclient-eskit');

/**
 * An attribute of {@link solace.RequestError}. This enumeration represents the
 * different errors emitted by
 * {@link solace.Session.requestFailedCallback}
 * when a {@link solace.Session#sendRequest} fails.
 *
 * The client application receives a request error with event code
 * {@link solace.RequestEventCode.REQUEST_ABORTED}
 * when the underlying connection is successfully closed, or closed as a result
 * of a communication error.
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const RequestEventCode = {
  /**
   * A request was aborted because the session is disconnected.
   */
  REQUEST_ABORTED: 8,
  /**
   * The event represents a timed-out request API call.
   */
  REQUEST_TIMEOUT: 9,
};

module.exports.RequestEventCode = Enum.new(RequestEventCode);
