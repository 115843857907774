const DestinationLib = require('solclient-destination');
const SDTMapContainerLib = require('./sdt-map-container');
const SDTStreamContainerLib = require('./sdt-stream-container');
const ValidateLib = require('solclient-validate');
const { ErrorSubcode, OperationError } = require('solclient-error');
const { SDTFieldType } = require('./sdt-field-types');

const baseTypes = (() => {
  const result = [];
  result[SDTFieldType.BOOL] = 'boolean';
  result[SDTFieldType.UINT8] = 'number';
  result[SDTFieldType.INT8] = 'number';
  result[SDTFieldType.UINT16] = 'number';
  result[SDTFieldType.INT16] = 'number';
  result[SDTFieldType.UINT32] = 'number';
  result[SDTFieldType.INT32] = 'number';
  result[SDTFieldType.UINT64] = 'number';
  result[SDTFieldType.INT64] = 'number';
  result[SDTFieldType.WCHAR] = 'string';
  result[SDTFieldType.STRING] = 'string';
  result[SDTFieldType.BYTEARRAY] = 'object'; // Uint8Array
  result[SDTFieldType.FLOATTYPE] = 'number';
  result[SDTFieldType.DOUBLETYPE] = 'number';
  return result;
})();

function failInvalidParameter(valueType) {
  return new OperationError(
            `Invalid SDT type:value combination, expected value type ${valueType}`,
            ErrorSubcode.PARAMETER_INVALID_TYPE);
}

function validateSdtField(type, value) {
  if (baseTypes[type]) {
    if ((baseTypes[type] === 'boolean' && typeof value !== 'boolean') ||
            (baseTypes[type] === 'number' && typeof value !== 'number') ||
            (baseTypes[type] === 'string' && typeof value !== 'string')) {
      return failInvalidParameter(baseTypes[type]);
    }
  }
  if (type === SDTFieldType.MAP &&
      !ValidateLib.Check.instanceOf(value, SDTMapContainerLib.SDTMapContainer)) {
    return failInvalidParameter('SDTMapContainer');
  }
  if (type === SDTFieldType.STREAM &&
      !ValidateLib.Check.instanceOf(value, SDTStreamContainerLib.SDTStreamContainer)) {
    return failInvalidParameter('SDTStreamContainer');
  }
  if (type === SDTFieldType.DESTINATION &&
      !ValidateLib.Check.instanceOf(value, DestinationLib.Destination)) {
    return failInvalidParameter('Destination');
  }
  return null;
}

module.exports.validateSdtField = validateSdtField;
