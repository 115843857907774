const { Enum } = require('solclient-eskit');

const ConsumerStateNames = {
  UNBOUND:                       'UNBOUND',
  UNBOUND_AWAIT_SESSION_UP:      'UNBOUND_AWAIT_SESSION_UP',
  UNBOUND_AWAIT_FLOWOPEN:        'UNBOUND_AWAIT_FLOWOPEN',
  UNBOUND_AWAIT_ANY:             'UNBOUND_AWAIT_ANY',
  BIND_SENT:                     'BIND_SENT',
  FLOW_UP:                       'FLOW_UP',
  FLOW_UP_XFER:                  'FLOW_UP_XFER',
  FLOW_UP_XFER_INACTIVE:         'FLOW_UP_XFER_INACTIVE',
  UNBIND_SENT:                   'UNBIND_SENT',
  RECONNECTING:                  'RECONNECTING',
  RECONNECTING_BIND_SENT:        'RECONNECTING_BIND_SENT',
  RECONNECTING_AWAIT_SESSION_UP: 'RECONNECTING_AWAIT_SESSION_UP',
  RECONNECTING_AWAIT_TIMER:      'RECONNECTING_AWAIT_TIMER',
  CREATE_SENT:                   'CREATE_SENT',
};

module.exports.ConsumerStateNames = Enum.new(ConsumerStateNames);
