// This code was written by Tyler Akins and has been placed in the
// public domain.  It would be nice if you left this header intact.
// Base64 code from Tyler Akins -- http://rumkin.com

// It has been modified by me (Edward Funnekotter) to improve its
// efficiency

// It has been modified by me (Justin Bowes) to avoid using it whenever
// possible in favour of browser or buffer implementations.

const KEY_STR = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const ENC_LUT = [
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, 99, -1, -1, 99, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  99, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, 62, -1, -1, -1, 63,
  52, 53, 54, 55, 56, 57, 58, 59,
  60, 61, -1, -1, -1, 64, -1, -1,

    // 64
  -1, 0, 1, 2, 3, 4, 5, 6,
  7, 8, 9, 10, 11, 12, 13, 14,
  15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, -1, -1, -1, -1, -1,
  -1, 26, 27, 28, 29, 30, 31, 32,
  33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, 48,
  49, 50, 51, -1, -1, -1, -1, -1,

    // 128
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,

    // 192
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1];

class DecodeError extends Error {

}

const LegacyEncoder = {
  /**
   * Encodes a string in base64
   * @param {String} input The string to encode in base64.
   * @returns {String} base64 encoded input
   * @private
   */
  base64_encode(input) {
    let output = '';
    let i = 0;
    do {
      const chr1 = input.charCodeAt(i++);
      const chr2 = input.charCodeAt(i++);
      const chr3 = input.charCodeAt(i++);

      const enc1 = chr1 >> 2;
      const enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      let enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      let enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output += KEY_STR.charAt(enc1);
      output += KEY_STR.charAt(enc2);
      output += KEY_STR.charAt(enc3);
      output += KEY_STR.charAt(enc4);
    } while (i < input.length);

    return output;
  },

  /**
   * Decodes a base64 string.
   * @param {String} input The base64 string to decode.
   * @returns {String} binary output
   * @private
   */
  base64_decode(input) {
    let output = '';
    let i = 0;

    do {
      while (ENC_LUT[input.charCodeAt(i)] > 64) {
        i++;
      }
      const enc1 = ENC_LUT[input.charCodeAt(i++)];
      const enc2 = ENC_LUT[input.charCodeAt(i++)];
      const enc3 = ENC_LUT[input.charCodeAt(i++)];
      const enc4 = ENC_LUT[input.charCodeAt(i++)];

      if (enc1 < 0 || enc2 < 0 || enc3 < 0 || enc4 < 0) {
        // Invalid character in base64 text
        // alert("enc at " + i + ": " + enc1 + ", " + enc2 + ", " + enc3 + ", " + enc4);
        throw new DecodeError('Invalid base64 character');
      }

      const chr1 = (enc1 << 2) | (enc2 >> 4);
      const chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      const chr3 = ((enc3 & 3) << 6) | enc4;

      output += String.fromCharCode(chr1);

      if (enc3 !== 64) {
        output += String.fromCharCode(chr2);
      }
      if (enc4 !== 64) {
        output += String.fromCharCode(chr3);
      }
    } while (i < input.length - 3);

    return output;
  },
};

const isNode = typeof window === 'undefined'; // !node
const hasBuffer = typeof Buffer !== 'undefined';
const hasBlob = typeof Blob !== 'undefined'; // !node, !ie9
const BufferEncoder = (hasBuffer && (hasBlob || isNode))
  ? {
    base64_encode: str => Buffer.from(str, 'binary').toString('base64'),
    base64_decode: str => Buffer.from(str, 'base64').toString('binary'),
  }
  : {};
/* eslint-env browser */
/* eslint-disable dot-notation */
const WindowEncoder = (typeof window !== 'undefined')
  ? {
    base64_encode: window['btoa'] ? b => window['btoa'](b) : null,
    base64_decode: window['atob'] ? a => window['atob'](a) : null,
  }
  : {};
/* eslint-enable dot-notation */

const Base64 = {
  encode: (WindowEncoder.base64_encode ||
           BufferEncoder.base64_encode ||
           LegacyEncoder.base64_encode),
  decode: (WindowEncoder.base64_decode ||
           BufferEncoder.base64_decode ||
           LegacyEncoder.base64_decode),
};

module.exports.Base64 = Base64;
