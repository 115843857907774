const { APIPropertiesValidators } = require('solclient-util');
const { QueueDescriptor } = require('./queue-descriptor');
const { QueueType } = require('./queue-types');

const {
  validateInstance,
  valBoolean,
  valIsMember,
  valTopicString,
} = APIPropertiesValidators;


const QueueDescriptorValidator = {
  validate(instance) {
    const v = validateInstance.bind(null, 'QueueDescriptor', instance);
    if (instance instanceof QueueDescriptor) {
      v('name', [valTopicString]);
    } // else is abstract; don't check name
    v('type', [valIsMember, QueueType, 'QueueType']);
    v('durable', [valBoolean]);
  },
};

module.exports.QueueDescriptorValidator = QueueDescriptorValidator;
