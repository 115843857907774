const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SDTDataTypes = {
  Null:            0x00,
  Boolean:         0x01,
  Integer:         0x02,
  UnsignedInteger: 0x03,
  Float:           0x04,
  Char:            0x05,
  ByteArray:       0x06,
  String:          0x07,
  Destination:     0x08,
  SMFMessage:      0x09,
  Map:             0x0A,
  Stream:          0x0B,
};

module.exports.SDTDataTypes = Enum.new(SDTDataTypes);
