const StateLib = require('./state');
const { FsmObject } = require('./object');
const { Iterator } = require('solclient-eskit');
const { LOG_TRACE } = require('solclient-log');

const { makeIterator } = Iterator;

/**
 * React to an event.
 * @callback StateContext.reactionCallback
 * @param {FsmEvent} event The event to react to.
 * @returns {StateContext.ReactionResult} One of the many possible
 *      reaction results that can be created by one of the following methods:
 *          - {@link StateContext#transitionTo}
 *          - {@link FsmState#internalTransition}
 *          - {@link FsmState#externalTransitionTo}
 *          - {@link FsmState#terminate}
 *          - {@link FsmState#eventUnhandled}
 */


/**
 * @classdesc
 * This abstract class can host one or more states.  Concrete examples would
 * be a state, which can host one or more inner states, or a state machine,
 * which would typically have multiple top-level states.  It also serves as a
 * context in which transitions can occur.
 * @memberof solace
 * @private
 */
class StateContext extends FsmObject {
  /**
   * @constructor
   * @param {Object} spec Object specifier used to implement the named parameter
   *  idiom.
   * @param {String} spec.name The name of the context.
   */
  constructor(spec) {
    super(spec);
    this.impl = this.impl || {};
    this.impl.logPadding = '';
  }

  /**
   * Gets the state-machine that hosts this state context.
   * @returns {StateMachine} The FSM that hosts this context.
   */
  getStateMachine() {
    return this.impl.ancestorList[0];
  }

  /**
   * Sets the initial reaction for the state context.
   * @param {StateContext.reactionCallback} func The reaction callback to be
   *      called after the state is entered as the deepest state of a
   *      transition, or for a state machine when it is started.
   * @returns {StateContext} The object this function was called on
   */
  initial(func) {
    if (this.impl.initialReaction) {
      this.log(`Replacing ${this} initialReaction ${this.impl.initialReaction} with ${func}`);
    }
    this.impl.initialReaction = func.bind(this);
    return this;
  }

  /**
   * This is used for 'local' transitions.  However, we extend the formal
   * definition of local transitions here.  The formal definition is that
   * the dest state is within the src state, and the src state is not
   * exited.  We extend this definition to include states where the src
   * state is within dest. In this case, a local transition means that dest
   * will not be exited and entered and the transition will occur in the
   * context of dest before executing dest's initial transition.  Note that
   * this definition of local transition matches that of
   * {@link https://en.wikipedia.org/wiki/UML_state_machine#Local_versus_external_transitions|Wikipedia}.
   * But it does not match figure 14.34 or section 14.5.12 of the
   * {@link http://www.omg.org/spec/UML/2.5/PDF/|Formal UML Specification v2.5}.
   * Nevertheless, it does seem helpful to be able to express a transition
   * that does leave the destination state vs. a transition that does not.
   *
   * If neither the source nor the dest states are within the other, the
   * behaviour is the same as an external transition -- src is always exited
   * and dest is always entered.
   *
   * This is included in the StateContext instead of within the State itself
   * since this can be used by the initial reaction for a state machine.
   *
   * @param {State} state The state to transition to.
   * @param {StateContext.actionCallback} [action] Optional The action to perform as
   *      part of the transition, if desired.
   * @returns {StateContext.ReactionResult} the result object used
   *      internally for further processing of the event.
   */
  transitionTo(state, action) {
    return new StateContext.ReactionResult({
      caller:    this,
      destState: state,
      action,
    });
  }

  /**
   * Used to terminate the FSM.
   * @param {StateContext.actionCallback} action An optional action to
   *      take within the FSM context after all states have been exited.
   * @returns {StateContext.ReactionResult} The reaction result for the termination.
   */
  terminate(action) {
    return new StateContext.ReactionResult({
      caller:    this,
      destState: this.getStateMachine().getFinalState(),
      action,
    });
  }

  /**
   * A callback to execute an action as part of a transition in the
   * appropriate context.
   *
   * To be used only by the FSM infrastructure or unit tests.
   *
   * @callback StateContext.actionCallback
   * @param {StateContext} context The context within which the action is
   *      executed.  When the active state changes, this is done after
   *      states are exited and before states are entered.  It is the
   *      deepest context that contains the last exited and first entered
   *      state as part of the transition.
   * @param {FsmEvent} event The event that triggered the transition.
   * @returns {Array.<StateContext>} The context's ancestor list.
   * @protected
   */
  getAncestorList() {
    return this.impl.ancestorList;
  }

  /**
   * Perform a debug log with appropriate padding for the context.  The padding
   * helps to visualize the level within the hierarchical state machine.
   * @protected
   */
  log(...args) {
    LOG_TRACE(this.impl.logPadding, ...args);
  }

  /**
   * Called when the initial transition for the context needs to be taken.
   * @param {FsmEvent} [event] The event causing this transition; undefined for the
   *      FSM's initial transition.
   * @returns {StateContext.ReactionResult} the result to be processed by
   *      the FSM infrastructure.
   * @protected
   */
  onInitial(event) {
    let result;

    if (this.impl.initialReaction) {
      this.log(`Initial: for ${this}`);
      result = this.impl.initialReaction(event);
      if (result.external) {
        throw new Error(`Initial reaction for ${this} returned external transitions`);
      }
      return result;
    }

    if (!(this instanceof (StateLib.State))) {
      throw new Error(`Missing initial reaction for ${this}`);
    }

    // If there is no initial reaction, then we just enter this state.
    // Technically this is a malformed FSM if there are inner states and
    // this state has no initial reaction.  We won't police this since it
    // isn't easily done with the data we are otherwise maintaining (we only
    // know about parent states, not children), and such a problem would be
    // easily caught by testing of the FSM.
    return this.transitionTo(this);
  }

  /**
   * After a reaction function has been called, this function processes the
   * returned {@link StateContext.ReactionResult}.
   * @param {StateContext.ReactionResult} result The result of a reaction.
   * @param {FsmEvent|undefined} e The event that triggered the reaction;
   *      undefined if this was due to the initial reaction.
   * @returns {State} The active state of the FSM after the ReactionResult was
   *      processed.
   * @protected
   */
  processReactionResult(result, e) {
    let curContext = this;

    if (!result.destState) {
      return this;
    }

    const destStateIter = this.lowestCommonAncestor(result);

    // exit states until we get to the LCA
    while (curContext !== destStateIter.deref()) {
      curContext.onExit();
      curContext = curContext.getParent();
    }

    // perform the transition
    if (result.action) {
      result.action(curContext, e);
    }

    curContext.log(`Action: transition to ${result.destState} in context ${curContext}`);

    // Start by incrementing the iterator so we don't enter the
    // context, which we are already in.  Then enter remaining states
    // in the list.
    for (destStateIter.incr(); !destStateIter.end(); destStateIter.incr()) {
      curContext = destStateIter.deref();
      curContext.onEntry();
    }

    // execute the initial transition in the destState.
    const destInitial = curContext.onInitial(e);
    if (destInitial.destState !== curContext) {
      return curContext.processReactionResult(destInitial, e);
    }
    return curContext;
  }

  /**
   * For a given reactionResult, this function returns an iterator to the
   * context in which to process a transition from 'self' to
   * 'reactionResult.destState'.  Advancing the iterator provides the states
   * that need to be entered after the transition is processed.
   * @param {ReactionResult} reactionResult An object created with one of the
   *                                        reaction result methods defined in
   *                                        either a state context or a state.
   * @returns {Iterator} The iterator where the first element is the
   * context in which to execute the transaction, and subsequent elements are
   * to be entered after executing the transaction.
   * @protected
   */
  lowestCommonAncestor(reactionResult) {
    const ancestorList = this.impl.ancestorList;
    const destAncestorList = reactionResult.destState.getAncestorList();
    let i;

    // Make sure the states belong to the same state machine
    if (ancestorList[0] !== destAncestorList[0]) {
      throw new Error(`No common ancestor between (${this} in ${ancestorList[0]}) and (${reactionResult.destState} in ${destAncestorList[0]})`);
    }

    // Optimize case where the two states are the same.  This would be the
    // case for internal and self-transitions.
    if (this === reactionResult.destState) {
      i = ancestorList.length;
      if (reactionResult.external) {
        // self-transition, must exit then re-enter state.  Therefore,
        // the context is our parent.
        --i;
      }
    } else {
      for (i = 1; i < ancestorList.length; ++i) {
        if (ancestorList[i] !== destAncestorList[i]) {
          break;
        }
      }

      // Check if one state is within the other state.
      if ((i === ancestorList.length) || (i === destAncestorList.length)) {
        // One state within the other. Check whether this is a local
        // or an external transition.
        if (reactionResult.external) {
          --i;    // Need to exit/re-enter the outermost state
        }
      }
    }

    // Here 'i' points to the first state to be entered after executing the
    // transition.  We make the iterator with 'i-1' so that the first element
    // is the context within which to execute the transition.
    return makeIterator(destAncestorList, i - 1);
  }

  setLogPadding(padding) {
    this.impl.logPadding = padding;
  }
}

/**
 * @classdesc
 * A ReactionResult is suitable as a return value from a reaction function
 * or an initial reaction.
 * @private
 */
StateContext.ReactionResult = class {
  /**
   * The ReactionResult constructor should never be invoked by users of the
   * infrastructure. It should only be used by various public methods of
   * StateContext or State, which return a ReactionResult.
   *
   * @param {Object} spec Defined according to members described below for
   *      events that are handled by the reaction function; undefined if the
   *      event was not handled by the reaction function.  For initial
   *      reactions, spec must NOT be undefined.
   * @param {StateContext} spec.caller The state context from which
   *      {StateContext.ReactionResult} is being constructed.
   * @param {State} spec.destState The destination state to
   *      transition to.
   * @param {StateContext.actionCallback} [spec.action] The function to call in
   *      the transition context after the appropriate states have been
   *      exited, if desired; undefined if no action is to be performed as a
   *      result of the transition.
   * @param {Boolean} spec.external True if the transition is an external
   *      transition; false or undefined otherwise.
   * @constructor
   */
  constructor(spec) {
    if (!spec || !spec.caller || !(spec.caller instanceof StateContext)) {
      throw new Error('spec.caller is required to be a StateContext');
    }

    if (!spec.caller.getStateMachine().isRunning()) {
      throw new Error('ReactionResult objects can only be created while processing events');
    }
    if (spec.destState) {
      if (!(spec.destState instanceof StateLib.State)) {
        throw new Error('destState must be a State object');
      }
      if (spec.action && (typeof (spec.action) !== 'function')) {
        throw new Error('action must be a function');
      }
      this.destState = spec.destState;
      this.action = spec.action;
      this.external = spec.external;
    }
  }
};

module.exports.StateContext = StateContext;
