const Mixin = {};

  /**
   * @param  {Object} target The class to augment
   * @param  {Object} add The class to mix in.
   * @returns {Object} The augmented class
   */
Mixin.mixin = function mixin(target, add) {
  const targetProto = target.prototype;
  const addProto = add.prototype;
  Object.getOwnPropertyNames(addProto).forEach((name) => {
    if (name === 'constructor') return;
    Object.defineProperty(targetProto, name, Object.getOwnPropertyDescriptor(addProto, name));
  });
  return target;
};

module.exports.Mixin = Mixin;
