const DestinationLib = require('solclient-destination');
const MessageLib = require('solclient-message');
const SolclientFactoryLib = require('solclient-factory');
const { assert } = require('solclient-eskit');
const { CacheSession,
        CACHE_REQUEST_PREFIX } = require('solclient-solcache-session');
const { CapabilityType } = require('./capability-types');
const { Check,
        Parameter } = require('solclient-validate');
const { DefaultCapabilities } = require('./default-capabilities');
const { ErrorResponseSubcodeMapper,
        ErrorSubcode,
        OperationError,
        RequestEventCode } = require('solclient-error');
const { EventEmitter } = require('solclient-events');
const { GlobalContext } = require('./global-context');
const { HostList } = require('./host-list');
const { LogFormatter } = require('solclient-log');
const { MessageRxCBInfo } = require('./message-rx-cb-info');
const { MutableSessionProperty } = require('./mutable-session-properties');
const { OutstandingDataRequest } = require('./outstanding-data-request');
const { P2PUtil } = require('./p2p-util');
const { QueueDescriptor,
        QueueType } = require('solclient-queue');
const { SDTField,
        SDTFieldType } = require('solclient-sdt');
const { SessionEvent } = require('./session-event');
const { SessionEventCBInfo } = require('./session-event-cb-info');
const { SessionEventCode } = require('./session-event-codes');
const { SessionEventName } = require('./session-event-names');
const { SessionFSM } = require('./session-fsm');
const { SessionFSMEvent } = require('./session-fsm-event');
const { SessionOperation } = require('./session-operations');
const { SessionProperties } = require('./session-properties');
const { SessionPropertiesValidator } = require('./session-properties-validator');
const { SessionRequestType } = require('./session-request-types');
const { SessionState } = require('./session-states');
const { SessionStateName } = require('./session-state-names');
const { Stats,
        StatType } = require('solclient-stats');
const { StringUtils } = require('solclient-util');
const { TransportCapabilities,
        TransportProtocol,
        TransportReturnCode } = require('solclient-transport');

function transportProtocolDefaultList() {
  const { ProfileBinding } = SolclientFactoryLib;

  if (BUILD_ENV.TARGET_NODE) {
    return [TransportProtocol.WS_BINARY];
  }

  const result = [];
  if (TransportCapabilities.web.webSocket()) {
    result.push(TransportProtocol.WS_BINARY);
  }
  const profile = ProfileBinding.value;
  if (profile.cometEnabled) {
    if (TransportCapabilities.web.xhrBinary()) {
      if (TransportCapabilities.web.streaming()) {
        result.push(TransportProtocol.HTTP_BINARY_STREAMING);
      }
      result.push(TransportProtocol.HTTP_BINARY);
    }
    result.push(TransportProtocol.HTTP_BASE64);
  }
  return result;
}

function isValidADTransport(transportProtocol) {
  return (transportProtocol && (
          transportProtocol !== TransportProtocol.HTTP_BINARY_STREAMING &&
          transportProtocol !== TransportProtocol.HTTP_BINARY &&
          transportProtocol !== TransportProtocol.HTTP_BASE64));
}

function formatEventName(eventName) {
  return `SessionEventCode.${SessionEventCode.describe(eventName)}`;
}

/**
 * @private
 */
const SOLCLIENT_REQUEST_PREFIX = '#REQ';

/**
 * A callback that returns replies to requests sent via {@link solace.Session#sendRequest}.
 * The replyReceivedCallback <b>must</b> be provided to the API as the third argument of
 * {@link solace.Session#sendRequest}.
 * @callback
 * @function
 * @name solace.Session.replyReceivedCallback
 * @param {solace.Session} session The session object that received the reply.
 * @param {solace.Message} message The reply message received.
 * @param {Object} userObject The user object associated with the callback. 'undefined' when
 * not provided to <i>sendRequest</i>
 */

/**
 * A callback that returns errors associated with requests sent via
 * {@link solace.Session#sendRequest}. The requestFailedCallback <b>must</b> be
 * provided to the API as the fourth argument of
 * {@link solace.Session#sendRequest}
 * @callback
 * @function
 * @name solace.Session.requestFailedCallback
 * @param {solace.Session} session The session object associated with the event.
 * @param {solace.RequestError} error The event associated with the failure.
 * @param {Object} userObject The user object associated with the callback. 'undefined' when
 * not provided to <i>sendRequest</i>
 */

/**
 * @classdesc
 * <b>This class is not exposed for construction by API users.</b>
 * Applications must use {@link solace.SolclientFactory.createSession} to create a session.
 *
 * Represents a client Session.
 *
 * Session provides these major functions:
 *  * Subscriber control, such as updating subscriptions;
 *  * Publishes both Direct and Guaranteed Messages to the router;
 *  * Receives direct messages from the router.
 *
 * The Session object is an
 * {@link https://nodejs.org/api/events.html#events_class_eventemitter|EventEmitter}, and will emit
 * events with event names from {@link solace.SessionEventCode} when Session events occur.
 * Each session event can be subscribed using {@link solace.Session#on} with the corresponding
 * {@link solace.SessionEventCode}. If any of the registered event listeners throw an exception,
 * the exception will be emitted on the 'error' event.
 *
 * @fires solace.SessionEventCode#ACKNOWLEDGED_MESSAGE
 * @fires solace.SessionEventCode#CAN_ACCEPT_DATA
 * @fires solace.SessionEventCode#CONNECT_FAILED_ERROR
 * @fires solace.SessionEventCode#DISCONNECTED
 * @fires solace.SessionEventCode#DOWN_ERROR
 * @fires solace.SessionEventCode#GUARANTEED_MESSAGE_PUBLISHER_DOWN
 * @fires solace.SessionEventCode#MESSAGE
 * @fires solace.SessionEventCode#PROPERTY_UPDATE_ERROR
 * @fires solace.SessionEventCode#PROPERTY_UPDATE_OK
 * @fires solace.SessionEventCode#RECONNECTED_NOTICE
 * @fires solace.SessionEventCode#RECONNECTING_NOTICE
 * @fires solace.SessionEventCode#REJECTED_MESSAGE_ERROR
 * @fires solace.SessionEventCode#REPUBLISHING_UNACKED_MESSAGES
 * @fires solace.SessionEventCode#SUBSCRIPTION_ERROR
 * @fires solace.SessionEventCode#SUBSCRIPTION_OK
 * @fires solace.SessionEventCode#UNSUBSCRIBE_TE_TOPIC_ERROR
 * @fires solace.SessionEventCode#UNSUBSCRIBE_TE_TOPIC_OK
 * @fires solace.SessionEventCode#UP_NOTICE
 * @fires solace.SessionEventCode#VIRTUALROUTER_NAME_CHANGED
 *
 * @hideconstructor
 * @memberof solace
 */
class Session extends EventEmitter {

  /*
   * Applications must use {@link solace.SolclientFactory.createSession} to create a session.
   *
   * @param {solace.SessionProperties} properties Properties to use for constructing
   *        the session.
   * @param {solace.MessageRxCBInfo} [messageCallback] Message callback info. The application can
   *    also receive message events via
   *    `session.on(solace.SessionEventCode.MESSAGE, (message) => { ... });`
   * @param {solace.SessionEventCBInfo} [eventCallback] Event callback info. The application can
   *    also receive session events via
   *    `session.on(solace.SessionEventCode.<code>, (event) => { ... });`
   *
   * @throws {solace.OperationError} if the parameters have an invalid type or value.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   * @private
   * @constructor
   */
  constructor(properties, messageCallback, eventCallback) {
    super({
      emits:  SessionEventCode.values,
      direct: SessionEventCode.MESSAGE,
      formatEventName,
    });
    const self = this;
    this.logger = new LogFormatter();
    this.logger.formatter = function formatter(...args) {
      return [`[session=${self._sessionFSM ? self._sessionFSM.sessionIdHex : '(N/A)'}]`, ...args];
    };
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session constructor called.');
    if ((properties !== undefined) && (properties !== null)) {
      const { LOG_DEBUG } = this.logger;
      LOG_DEBUG('Incoming session properties:\n', properties);
    }

    // Default error handler prints the exception:
    this.on('error', (error) => {
      const { LOG_ERROR } = self.logger;
      LOG_ERROR(error.info.error);
    });

    const sessionProperties = new SessionProperties(properties);
    {
      const { LOG_DEBUG } = this.logger;
      LOG_DEBUG('Eventual session properties:\n', sessionProperties);
    }
    // Callbacks to client application.
    // Get/set for these is private, so they can only be changed in-API (e.g. by CacheSession)
    // The user-supplied one cannot be changed.
    // The configuration of the exceptions generated by these needs to match EventEmitter, so that
    // the handleGenericErrorEvent above can behave consistently
    this._messageCallbackInfo = this.wrapMessageCallback(messageCallback);
    this._eventCallbackInfo = this.wrapEventCallback(eventCallback);

    // client name generation is applicable
    if (Check.empty(sessionProperties.clientName)) {
      // Auto-gen clientName
      sessionProperties.clientName = GlobalContext.GenerateClientName();
    }

    // generate userIdentification
    sessionProperties._setUserIdentification(GlobalContext.GenerateUserIdentification());

    // client description generation is applicable
    if (Check.empty(sessionProperties.applicationDescription)) {
      // Auto-gen applicationDescription
      sessionProperties.applicationDescription = GlobalContext.GenerateClientDescription();
    }

    // Set webTransportProtocolList after validation so we're not basing it on an
    // invalid transport selection...so we're not checking transportProtocol/
    // webTransportProtocolList except for parameter conflict.

    // Now that we have touched up the properties as much as possible, validate
    // This also validates the subordinate MessagePublisherProperties
    SessionPropertiesValidator.validate(sessionProperties);

    // We want a protocol list, but historically we also accept a single protocol.
    // Create a web transport protocol list from the transport protocol
    // option if that is all that was set. (If both were set, we failed validation)
    if (Check.nothing(sessionProperties.webTransportProtocolList)) {
      // Create a fallback list that starts with the selected protocol.
      // If the selected protocol is not in the fallback list, it is not
      // a valid protocol for the platform so return an empty list.
      const selectedProtocol = sessionProperties.transportProtocol;
      const defaultList = transportProtocolDefaultList();
      const sliceIndex = selectedProtocol ? defaultList.indexOf(selectedProtocol) : 0;
      if (sliceIndex < 0) {
        // The user explicitly selected a protocol that wasn't valid
        throw new OperationError(`Selected transport protocol ${
                                 TransportProtocol.describe(selectedProtocol)
                                 } is disabled or invalid for this platform`,
                                 ErrorSubcode.PARAMETER_CONFLICT);
      }
      // Slice index is valid
      sessionProperties.webTransportProtocolList = defaultList.slice(sliceIndex);

      if (sessionProperties.webTransportProtocolList.length === 0) {
        // Even before AD restrictions, no valid protocols.
        // User error.
        throw new OperationError(`No usable transport protocol or fallback from ${
          TransportProtocol.describe(selectedProtocol)}`,
          ErrorSubcode.PARAMETER_CONFLICT);
      }

      // Check that the generated list is compatible with AD.
      const validForAD = sessionProperties.webTransportProtocolList.filter(x =>
        isValidADTransport(x));
      if (validForAD.length === 0) {
        this._adDisabledReason = 'Guaranteed messaging not compatible with any available ' +
          `transport protocol: ${
            sessionProperties.webTransportProtocolList.map(k =>
              TransportProtocol.describe(k)).join(', ')}`;
      }

      // Don't fold this into the check below -- for that one, every
      // protocol must be valid because the user explicitly requested all of them.
      // In this case, we ensure that at least one of our generated list is valid.
      if (sessionProperties.publisherProperties.enabled) {
        if (this._adDisabledReason) {
          throw new OperationError(
            'Invalid transport protocol(s) for session with Guaranteed Messaging Publisher',
            ErrorSubcode.PARAMETER_CONFLICT,
            this._adDisabledReason
          );
        }
        // AD was not disabled by having no protocols available
        sessionProperties.webTransportProtocolList = validForAD;
      }
      // A valid protocol list is ready to use.
    } else {
      // User provided transport protocol list
      // Check for parameter conflict between session and publisher
      // Already checked these for parameter conflict; only one will be set
      // Already checked that user list was not empty
      const transportProtocols = sessionProperties.webTransportProtocolList;
      const validForAD = transportProtocols.every(isValidADTransport);
      if (!validForAD) {
        const invalid = transportProtocols.filter(x => !isValidADTransport(x));
        this._adDisabledReason = `Guaranteed messaging incompatible with selected transport protocols: ${
          invalid.map(k => TransportProtocol.describe(k)).join(', ')}`;
        if (sessionProperties.publisherProperties.enabled) {
          throw new OperationError(
            'Invalid transport protocol(s) for session with Guaranteed Messaging Publisher',
            ErrorSubcode.PARAMETER_CONFLICT,
            this._adDisabledReason
          );
        }
      }
    }


    // Assign the final properties and start the state machine.
    this._sessionProperties = sessionProperties;
    this._sessionStats = new Stats();
    this._hosts = new HostList(sessionProperties);
    this._sessionFSM = new SessionFSM(
      this._sessionProperties,
      this,
      this._sessionStats,
      this._hosts
    );
    this._sessionFSM.start();
    this._sessionFSM.createMessagePublisher();

    /**
     * The following fields are destroyed when disconnect is called
     * and recreated when connect is called again.
     * @private
     */
    this._outstandingDataReqs = {};
    this._capabilities = DefaultCapabilities.createDefaultCapabilities(sessionProperties);
    this._seqNum = 1;
  }

  /**
   * Connects the session to the Solace Message Router as configured in
   * the {@link solace.SessionProperties#url}.
   *
   * When the session is successfully connected to the Solace Message Router, the
   * {@link solace.SessionEventCode#UP_NOTICE} event is emitted if a listener has been registered.
   *
   * If {@link solace.SessionProperties#reapplySubscriptions} is set to true, this operation
   * re-registers previously registered subscriptions. The connected session event
   * ({@link solace.SessionEventCode#event:UP_NOTICE}) is emitted only when all the subscriptions
   * are successfully added to the router.
   *
   * If the API is unable to connect within {@link solace.SessionProperties#connectTimeoutInMsecs}
   * or due to login failures, the session's state transitions back to 'disconnected' and an event
   * is generated.
   *
   * **Note:** Before the session's state transitions to 'connected', a client
   * application cannot use the session; any attempt to call functions will throw
   * {@link solace.OperationError}.
   *
   * @throws {solace.OperationError}
   * * if the session is disposed, already connected or connecting.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the underlying transport cannot be established.
   *   Subcode: {@link solace.ErrorSubcode.CONNECTION_ERROR}.
   */
  connect() {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session connect called.');
    const result = this.allowOperation(SessionOperation.CONNECT);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    const sEvent = new SessionFSMEvent({ name: SessionEventName.CONNECT });
    this._sessionFSM.processEvent(sEvent);
  }

  /**
   * @returns {Boolean} True if the session can be used to acknolwedge a message
   * @readonly
   * @private
   */
  get canAck() {
    /*
     * If the user wants to ack a message, it was received on a session (else ack will throw),
     * and so we know that the session was connected at some point.
     *
     * If the session is in any of the following states, we know it is either connecting or
     * connected.
     *
     * Since we know the session was previously connected, this implies the session is either
     * RECONNECTING or connected.
     *
     * We allow acks when the session is reconnecting or connected.
     */
    const statesCanAck = [
      SessionStateName.CONNECTING,
      SessionStateName.TRANSPORT_UP,
      SessionStateName.DISCONNECTING,
    ];
    // Allow ack if there is some acceptable state name such that getActiveState returns the state.
    return statesCanAck.some(stateName => !!this._sessionFSM.getActiveState(stateName));
  }

  /**
   * Disconnects the session. The session attempts to disconnect cleanly, concluding all operations
   * in progress. The disconnected session event {@link solace.SessionEventCode#event:DISCONNECTED}
   * is emitted when these operations complete and the session has completely disconnected.
   *
   * @throws {solace.OperationError} if the session is disposed, or has never been connected.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   */
  disconnect() {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session disconnect called.');
    const result = this.allowOperation(SessionOperation.DISCONNECT);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    const sEvent = new SessionFSMEvent({ name: SessionEventName.DISCONNECT });
    this._sessionFSM.processEvent(sEvent);
  }

  /**
   * Release all resources associated with the session.
   * It is recommended to call disconnect() first for proper handshake with the message-router.
   */
  dispose() {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session dispose called.');
    if (this._disposed) return;

    //setImmediate(() => {
    setTimeout(() => {
      this._sessionFSM.processEvent(
        new SessionFSMEvent({ name: SessionEventName.DISPOSE })
      ); // yield for disconnect if any
      this._sessionFSM.terminateFsm();
      this.disableEmitter();
      this._disposed = true;
    }, 0);
  }

  /**
   * Subscribe to a topic, optionally requesting a confirmation from the router.
   *
   * If requestConfirmation is set to true:
   * {@link solace.SessionEventCode.SUBSCRIPTION_OK} is generated when subscription is
   * added successfully; otherwise, session event
   * {@link solace.SessionEventCode.SUBSCRIPTION_ERROR} is generated.
   *
   * If requestConfirmation is set to false, only session event
   * {@link solace.SessionEventCode.SUBSCRIPTION_ERROR} is generated upon failure.
   *
   * When the application receives session event
   * {@link solace.SessionEventCode.SUBSCRIPTION_ERROR}, it
   * can obtain the failed topic subscription by calling
   * {@link solace.SessionEvent#reason}.
   * The returned string is in the format of "Topic: <failed topic subscription>".
   *
   * @param {solace.Destination} topic The topic destination subscription to add.
   * @param {Boolean} requestConfirmation true, to request a confirmation; false otherwise.
   * @param {Object} correlationKey If specified, and if requestConfirmation is true, this value is
   *                                echoed in the session event within {@link SessionEvent}.
   * @param {Number} requestTimeout The request timeout period (in milliseconds). If specified, this
   *                                value overwrites readTimeoutInMsecs property in
   *                                {@link SessionProperties}.
   *
   * @throws {solace.OperationError}
   * * if the session is disposed or disconnected.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the parameters have an invalid type.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   * * if the parameters have an invalid value.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_OUT_OF_RANGE}.
   * * if the topic has invalid syntax.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_TOPIC_SYNTAX}.
   * * if there's no space in the transport to send the request.
   *   Subcode: {@link solace.ErrorSubcode.INSUFFICIENT_SPACE}.  See:
   *   {@link solace.SessionEventCode#event:CAN_ACCEPT_DATA}.
   * * if the topic is a shared subscription and the peer router does not support Shared
   *   Subscriptions.
   *   Subcode: {@link solace.ErrorSubcode.SHARED_SUBSCRIPTIONS_NOT_SUPPORTED}.
   * * if the topic is a shared subscription and the client does not allowed Shared
   *   Subscriptions.
   *   Subcode: {@link solace.ErrorSubcode.SHARED_SUBSCRIPTIONS_NOT_ALLOWED}.
   */
  subscribe(topic, requestConfirmation, correlationKey, requestTimeout) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session subscribe called for topic ', (topic && topic.toString && topic.toString()));
    const result = this.allowOperation(SessionOperation.CTRL);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    Parameter.isInstanceOf('topic', topic, DestinationLib.Destination);
    topic.validate();
    if (topic.getType() !== DestinationLib.DestinationType.TOPIC) {
      throw new OperationError(`Topic is required for subscribe; ${
                               DestinationLib.DestinationType.describe(topic.getType())}`,
                               ErrorSubcode.INVALID_TOPIC_SYNTAX);
    }

    Parameter.isBooleanOrNothing('requestConfirmation', requestConfirmation);
    Parameter.isNumberOrNothing('requestTimeout', requestTimeout);
    Parameter.isRangeCompareOrNothing('requestTimeout', requestTimeout, '>', 0);

    this._sessionFSM.subscriptionUpdate(
      topic,
      !!requestConfirmation,
      correlationKey,
      requestTimeout,
      SessionRequestType.ADD_SUBSCRIPTION,
      (rxMsgObj, cancelledRequest) =>
        this.handleSubscriptionUpdateResponse(rxMsgObj,
                                              cancelledRequest,
                                              requestConfirmation));
  }

  updateQueueSubscription(topic, queue, add, messageConsumer, callback, requestTimeout) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE(
      'Queue subscribe called for topic ', (topic && topic.toString && topic.toString()),
      ' for queue ', (queue && queue.toString && queue.toString()));

    LOG_TRACE('queue: ', queue);

    const result = this.allowOperation(SessionOperation.CTRL);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    Parameter.isInstanceOf('topic', topic, DestinationLib.Destination);
    topic.validate();
    if (topic.getType() !== DestinationLib.DestinationType.TOPIC) {
      throw new OperationError(`Topic is required for queue subscribe; ${
                               DestinationLib.DestinationType.describe(topic.getType())}`,
                               ErrorSubcode.INVALID_TOPIC_SYNTAX);
    }
    Parameter.isInstanceOf('queue', queue, DestinationLib.Destination);
    queue.validate();
    if ((queue.getType() !== DestinationLib.DestinationType.QUEUE) &&
    (queue.getType() !== DestinationLib.DestinationType.TEMPORARY_QUEUE)) {
      throw new OperationError(`Queue is required for queue subscribe; ${
                               DestinationLib.DestinationType.describe(queue.getType())}`,
                               ErrorSubcode.PARAMETER_INVALID_TYPE);
    }

    Parameter.isNumberOrNothing('requestTimeout', requestTimeout);
    Parameter.isRangeCompareOrNothing('requestTimeout', requestTimeout, '>', 0);

    this._sessionFSM.queueSubscriptionUpdate(
      topic,
      queue,
      requestTimeout,
      add,
      (rxMsgObj, cancelledRequest) =>
        this.handleQueueSubscriptionUpdateResponse(
          rxMsgObj,
          cancelledRequest,
          callback));
  }

  /**
   * Unsubscribe from a topic, and optionally request a confirmation from the router.
   *
   * If requestConfirmation is set to true, session event
   * {@link solace.SessionEventCode.SUBSCRIPTION_OK} is generated when subscription is removed
   * successfully; otherwise, session event
   * {@link solace.SessionEventCode.SUBSCRIPTION_ERROR} is generated.
   *
   * If requestConfirmation is set to false, only session event
   * {@link solace.SessionEventCode.SUBSCRIPTION_ERROR} is generated upon failure.
   *
   * When the application receives session event
   * {@link solace.SessionEventCode.SUBSCRIPTION_ERROR}, it
   * can obtain the failed topic subscription by calling
   * {@link solace.SessionEvent#reason}. The returned
   * string is in the format "Topic: <failed topic subscription>".
   *
   * @param {solace.Destination} topic The topic destination subscription to remove.
   * @param {Boolean} requestConfirmation true, to request a confirmation; false otherwise.
   * @param {Object} correlationKey If <code>null</code> or undefined, a Correlation Key is not set
   *                                in the confirmation session event.
   * @param {Number} requestTimeout The request timeout period (in milliseconds). If specified, this
   *                                value overwrites readTimeoutInMsecs property in
   *                                {@link SessionProperties}.
   *
   * @throws {solace.OperationError}
   * * if the session is disposed or disconnected.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the parameters have an invalid type.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   * * if the parameters have an invalid value.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_OUT_OF_RANGE}.
   * * if the topic has invalid syntax.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_TOPIC_SYNTAX}.
   * * if there's no space in the transport to send the request.
   *   Subcode: {@link solace.ErrorSubcode.INSUFFICIENT_SPACE}.  See:
   *   {@link solace.SessionEventCode#event:CAN_ACCEPT_DATA}.
   * * if the topic is a shared subscription and the peer router does not support Shared
   *   Subscriptions.
   *   Subcode: {@link solace.ErrorSubcode.SHARED_SUBSCRIPTIONS_NOT_SUPPORTED}.
   * * if the topic is a shared subscription and the client does not allowed Shared
   *   Subscriptions.
   *   Subcode: {@link solace.ErrorSubcode.SHARED_SUBSCRIPTIONS_NOT_ALLOWED}.
   */
  unsubscribe(topic, requestConfirmation, correlationKey, requestTimeout) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session unsubscribe called for topic ', (topic && topic.toString && topic.toString()));
    const result = this.allowOperation(SessionOperation.CTRL);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    Parameter.isInstanceOf('topic', topic, DestinationLib.Destination);
    topic.validate();
    if (topic.getType() !== DestinationLib.DestinationType.TOPIC) {
      throw new OperationError(`Topic is required for unsubscribe; ${
                               DestinationLib.DestinationType.describe(topic.getType())}`,
                               ErrorSubcode.INVALID_TOPIC_SYNTAX);
    }

    Parameter.isBooleanOrNothing('requestConfirmation', requestConfirmation);
    Parameter.isNumberOrNothing('requestTimeout', requestTimeout);
    Parameter.isRangeCompareOrNothing('requestTimeout', requestTimeout, '>', 0);

    this._sessionFSM.subscriptionUpdate(
      topic,
      !!requestConfirmation,
      correlationKey,
      requestTimeout,
      SessionRequestType.REMOVE_SUBSCRIPTION,
      (rxMsgObj, cancelledRequest) =>
        this.handleSubscriptionUpdateResponse(rxMsgObj,
                                              cancelledRequest,
                                              requestConfirmation));
  }

  /**
   * Request that a Durable Topic Endpoint stop receiving data on a topic. Unsubscribe
   * requests are only allowed by the router when no clients are bound to the DTE.
   * If the unubscribe request is successful, the DTE will stop attracting messages,
   * and all messages spooled to the DTE will be deleted.
   *
   * {@link solace.SessionEventCode.UNSUBSCRIBE_TE_TOPIC_OK} is generated when the
   * subscription is removed successfully; otherwise,
   * {@link solace.SessionEventCode.UNSUBSCRIBE_TE_TOPIC_ERROR} is generated.
   *
   * When the application receives session event
   * {@link solace.SessionEventCode.UNSUBSCRIBE_TE_TOPIC_ERROR}, it
   * can obtain the failed topic subscription by calling
   * {@link solace.SessionEvent#reason}.
   *
   * @param {solace.AbstractQueueDescriptor|solace.QueueDescriptor} queueDescriptor A description
   *  of the queue to which the topic is subscribed.
   *
   * @throws {solace.OperationError}
   * * if the session is disposed or disconnected.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the parameters have an invalid type.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   * * if the parameters have an invalid value.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_OUT_OF_RANGE}.
   * * if there's no space in the transport to send the request.
   *   Subcode: {@link solace.ErrorSubcode.INSUFFICIENT_SPACE}.  See:
   *   {@link solace.SessionEventCode#event:CAN_ACCEPT_DATA}.
   */
  unsubscribeDurableTopicEndpoint(queueDescriptor) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session unsubscribeDurableTopicEndpoint called for queue descriptor ', (queueDescriptor && queueDescriptor.toString && queueDescriptor.toString()));
    const result = this.allowOperation(SessionOperation.CTRL);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    // emulate subscription
    const destination = this.createDestinationFromDescriptor(
      QueueDescriptor.createFromSpec(queueDescriptor));
    const requestConfirmation = true;
    this._sessionFSM.subscriptionUpdate(
      destination,
      requestConfirmation,
      undefined,
      undefined,
      SessionRequestType.REMOVE_DTE_SUBSCRIPTION,
      (rxMsgObj, cancelledRequest) =>
        this.handleDTEUnsubscribeResponse(rxMsgObj,
                                          cancelledRequest));
  }


  /**
   * Modify a session property after creation of the session.
   *
   * This method only works for a select few properties,
   * and updates their value on the live broker session.
   *
   * @param {MutableSessionProperty} mutableSessionProperty The property key to modify.
   * @param {Object} newValue The new property value.
   * @param {Number} requestTimeout The request timeout period (in milliseconds). If specified, it
   *                                overwrites readTimeoutInMsecs
   * @param {Object} correlationKey If specified, this value is echoed in the session event within
   *                                {@link SessionEvent} property in {@link SessionProperties}
   *
   * @throws {solace.OperationError}
   * * if the session is disposed or disconnected.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the parameters have an invalid type.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   * * if the parameters have an invalid value.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_OUT_OF_RANGE}.
   * * if there's no space in the transport to send the request.
   *   Subcode: {@link solace.ErrorSubcode.INSUFFICIENT_SPACE}.  See:
   *   {@link solace.SessionEventCode#event:CAN_ACCEPT_DATA}.
   */
  updateProperty(mutableSessionProperty, newValue, requestTimeout, correlationKey) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session updateProperty called: ', mutableSessionProperty, newValue);
    const result = this.allowOperation(SessionOperation.CTRL);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }

    const { Topic } = DestinationLib;

    LOG_TRACE('Updating property ', mutableSessionProperty, newValue);

    Parameter.isEnumMember('mutableSessionProperty', mutableSessionProperty, MutableSessionProperty);

    Parameter.isNumberOrNothing('requestTimeout', requestTimeout);
    Parameter.isRangeCompareOrNothing('requestTimeout', requestTimeout, '>', 0);

    let sessionEvent;
    /*
     Response CB to the CLIENTCTRL UPDATE response

     This is pretty complicated: we define the whole process in here
     using callbacks to preserve state such as the correlationKey of the user
     request.
     That is, this entire multi-step process executes under the context of that one call to
     updateProperty with a single correlationKey value.
     */
    const responseCallback = (respMsg) => {
      const response = respMsg.getResponse();
      if (response.responseCode === 200) {
        if (mutableSessionProperty === MutableSessionProperty.CLIENT_DESCRIPTION) {
          // update property and notify client
          this._sessionProperties.applicationDescription = newValue;
          sessionEvent = SessionEvent.build(SessionEventCode.PROPERTY_UPDATE_OK,
                                            response.responseString,
                                            response.responseCode,
                                            0,
                                            correlationKey,
                                            null);
          this.sendEvent(sessionEvent);
        } else if (mutableSessionProperty === MutableSessionProperty.CLIENT_NAME) {
          // replace P2P subscription: REM and ADD
          const oldP2pTopicName = P2PUtil.getP2PTopicSubscription(
            this._sessionProperties.p2pInboxBase);
          const oldP2pTopic = Topic.createFromName(oldP2pTopicName);

          const newP2pTopicName = P2PUtil.getP2PTopicSubscription(
            respMsg.getP2PTopicValue());
          const newP2pTopic = Topic.createFromName(newP2pTopicName);

          const afterAddCallback = (smpResp) => {
            const resp = smpResp.getResponse();
            if (resp.responseCode === 200) {
              // notify client
              this._sessionProperties._setP2pInboxBase(respMsg.getP2PTopicValue() || '');
              this._sessionProperties._setP2pInboxInUse(
                P2PUtil.getP2PInboxTopic(this._sessionProperties.p2pInboxBase));
              this._sessionProperties.clientName = newValue;
              sessionEvent = SessionEvent.build(SessionEventCode.PROPERTY_UPDATE_OK,
                                                resp.responseString,
                                                resp.responseCode,
                                                0,
                                                correlationKey,
                                                null);
              this.sendEvent(sessionEvent);
            } else {
              const errorSubcode = ErrorResponseSubcodeMapper.getErrorSubcode(resp.responseCode,
                                                                              resp.responseString);
              if (errorSubcode === ErrorSubcode.SUBSCRIPTION_ALREADY_PRESENT &&
                              this._sessionProperties.ignoreDuplicateSubscriptionError) {
                // notify client
                sessionEvent = SessionEvent.build(SessionEventCode.PROPERTY_UPDATE_OK,
                                                  resp.responseString,
                                                  resp.responseCode,
                                                  0,
                                                  correlationKey,
                                                  null);
                this.sendEvent(sessionEvent);
              } else if (errorSubcode === ErrorSubcode.SUBSCRIPTION_ALREADY_PRESENT ||
                         errorSubcode === ErrorSubcode.SUBSCRIPTION_ATTRIBUTES_CONFLICT ||
                         errorSubcode === ErrorSubcode.SUBSCRIPTION_INVALID ||
                         errorSubcode === ErrorSubcode.SUBSCRIPTION_ACL_DENIED ||
                         errorSubcode === ErrorSubcode.SUBSCRIPTION_TOO_MANY) {
                // notify client
                sessionEvent = SessionEvent.build(SessionEventCode.PROPERTY_UPDATE_ERROR,
                                                  resp.responseString,
                                                  resp.responseCode,
                                                  errorSubcode,
                                                  correlationKey,
                                                  null);
                this.sendEvent(sessionEvent);
              } else {
                // notify client
                sessionEvent = SessionEvent.build(SessionEventCode.PROPERTY_UPDATE_ERROR,
                                                  resp.responseString,
                                                  resp.responseCode,
                                                  ErrorSubcode.SUBSCRIPTION_ERROR_OTHER,
                                                  correlationKey,
                                                  null);
                this.sendEvent(sessionEvent);
              }
            }
          };

          const afterRemoveCallback = (smpResp) => {
            const resp = smpResp.getResponse();
            if (resp.responseCode === 200) {
              // second add new P2P
              this._sessionFSM.subscriptionUpdate(newP2pTopic,
                                                  true,       // request confirm
                                                  correlationKey,
                                                  this._sessionProperties.readTimeoutInMsecs,
                                                  SessionRequestType.ADD_P2PINBOX,
                                                  afterAddCallback);
            } else {
              const errorSubcode = ErrorResponseSubcodeMapper.getErrorSubcode(resp.responseCode,
                                                                              resp.responseString);
              if (errorSubcode === ErrorSubcode.SUBSCRIPTION_NOT_FOUND &&
                  this._sessionProperties.ignoreSubscriptionNotFoundError) {
                // add new P2P anyway: the error is simply the old P2P
                // was not found on remove.  It's notable though.
                this._sessionFSM.subscriptionUpdate(newP2pTopic,
                                                    true,       // request confirm
                                                    correlationKey,
                                                    this._sessionProperties.readTimeoutInMsecs,
                                                    SessionRequestType.ADD_P2PINBOX,
                                                    afterAddCallback);
              } else if (errorSubcode === ErrorSubcode.SUBSCRIPTION_ATTRIBUTES_CONFLICT ||
                         errorSubcode === ErrorSubcode.SUBSCRIPTION_INVALID ||
                         errorSubcode === ErrorSubcode.SUBSCRIPTION_NOT_FOUND ||
                         errorSubcode === ErrorSubcode.SUBSCRIPTION_ACL_DENIED) {
                // notify client
                sessionEvent = SessionEvent.build(SessionEventCode.PROPERTY_UPDATE_ERROR,
                                                  resp.responseString,
                                                  resp.responseCode,
                                                  errorSubcode,
                                                  null,
                                                  null);
                this.sendEvent(sessionEvent);
              } else {
                // notify client
                sessionEvent = SessionEvent.build(SessionEventCode.PROPERTY_UPDATE_ERROR,
                                                  resp.responseString,
                                                  resp.responseCode,
                                                  ErrorSubcode.SUBSCRIPTION_ERROR_OTHER,
                                                  null,
                                                  null);
                this.sendEvent(sessionEvent);
              }
            }
          };

          // first remove old P2P
          this._sessionFSM.subscriptionUpdate(oldP2pTopic,
                                              true,       // request confirm
                                              correlationKey,
                                              this._sessionProperties.readTimeoutInMsecs,
                                              SessionRequestType.REMOVE_P2PINBOX,
                                              afterRemoveCallback);
        }
      } else {
        // notify client error
        const errorSubcode = ErrorResponseSubcodeMapper.getErrorSubcode(response.responseCode,
                                                                        response.responseString);
        sessionEvent = SessionEvent.build(SessionEventCode.PROPERTY_UPDATE_ERROR,
                                          response.responseString,
                                          response.responseCode,
                                          errorSubcode,
                                          correlationKey,
                                          null);
        this.sendEvent(sessionEvent);
      }
    }; // end CB (response to UPDATE request)

    const returnCode = this._sessionFSM.sendUpdateProperty(mutableSessionProperty,
                                                           newValue,
                                                           correlationKey,
                                                           requestTimeout,
                                                           responseCallback);
    if (returnCode !== TransportReturnCode.OK) {
          // do not change session state

      if (returnCode === TransportReturnCode.NO_SPACE) {
        sessionEvent = SessionEvent.build(SessionEventCode.PROPERTY_UPDATE_ERROR,
                                          'Property update failed - no space in transport',
                                          null,
                                          ErrorSubcode.INSUFFICIENT_SPACE,
                                          null,
                                          null);
      } else {
        sessionEvent = SessionEvent.build(SessionEventCode.PROPERTY_UPDATE_ERROR,
                                          'Property update failed',
                                          null,
                                          ErrorSubcode.INVALID_OPERATION,
                                          null,
                                          null);
      }
      this.sendEvent(sessionEvent);
    }
  }
  /**
   * Modify (some) authentication-related session properties.
   * The modifications take effect the next time the session connects or reconnects to the broker.
   * There is no change to the active connection.
   * Calling this method does not in itself trigger any kind of reconnection, reauthentication or renegotiation.
   *
   * **Note:** the update of "accessToken" and "idToken" properties is currently supported.
   * Authentication Properties
   * - accessToken to update previously set access token required for OAUTH2 authentication.
   * - idToken to update previously set ID token required for OIDC authentication
   * Example: updateAuthenticationOnReconnect({accessToken : “my_new_token”});
   *
   *
   * @param {Object} authenticationProperties to be set
   *
   * @throws {solace.OperationError}
   * * if the session is disposed or disconnected.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the parameters have an invalid type.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   * * if the parameters have an invalid value.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_OUT_OF_RANGE}.
   * * if unsupported properties attempted to be set.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_CONFLICT}.
   */
  updateAuthenticationOnReconnect(authenticationProperties) {
    const { LOG_TRACE } = this.logger;
    const allowedProperties = ['accessToken', 'idToken'];
    const result = this.allowOperation(SessionOperation.QUERY_OPERATION);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    if (!authenticationProperties || typeof authenticationProperties !== 'object') {
      throw new OperationError('updateAuthenticationOnReconnect parameter must be a non-empty object.', ErrorSubcode.PARAMETER_INVALID_TYPE, null);
    }
    var key;
    for (key in authenticationProperties) {
      if (!allowedProperties.includes(key)) {
        throw new OperationError('Invalid property in updateAuthenticationOnReconnect parameter.', ErrorSubcode.PARAMETER_CONFLICT, null);
      }
    }
    const newProps = this.getSessionProperties();
    Object.assign(newProps, authenticationProperties);
    SessionPropertiesValidator.validate(newProps);
    this._sessionProperties = newProps;
    Object.assign(this._sessionFSM._sessionProperties, authenticationProperties);
    LOG_TRACE('updateAuthenticationOnReconnect applied new token(s).');
  }

  /**
   * Publish (send) a message over the session. The message is sent to its set destination.
   *
   * This method is used for sending both direct and Guaranteed Messages.  If the message's
   * {@link solace.MessageDeliveryModeType} is {@link solace.MessageDeliveryModeType.DIRECT}, the
   * message is a direct message; otherwise, it is a guaranteed message.
   *
   * @param {solace.Message} message The message to send. It must have a destination set.
   *
   * @throws {solace.OperationError}
   * * if the session is disposed or disconnected.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the parameters have an invalid type.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   * * if the message does not have a topic.
   *   Subcode: {@link solace.ErrorSubcode.TOPIC_MISSING}.
   * * if there's no space in the transport to send the request.
   *   Subcode: {@link solace.ErrorSubcode.INSUFFICIENT_SPACE}.  See:
   *   {@link solace.SessionEventCode#event:CAN_ACCEPT_DATA}.
   * * if no Guaranteed Message Publisher is available and the message deliveryMode is
   *   {@link solace.MessageDeliveryModeType.PERSISTENT} or
   *   {@link solace.MessageDeliveryModeType.NON_PERSISTENT}.
   *   Subcode: {@link solace.ErrorSubcode.GM_UNAVAILABLE}.
   * * if the message deliveryMode is
   *   {@link solace.MessageDeliveryModeType.PERSISTENT} or
   *   {@link solace.MessageDeliveryModeType.NON_PERSISTENT},
   *   and the message payload size is above the broker's limit.
   *   Subcode: {@link solace.ErrorSubcode.MESSAGE_TOO_LARGE}.
   * 
   */
  send(message) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session send() called.');
    const result = this.allowOperation(SessionOperation.SEND, message);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    Parameter.isInstanceOf('message', message, MessageLib.Message);
    this.validateAndSendMessage(message);
  }

  /**
   * Sends a request using user-specified callback functions.
   * <br>
   * <strong>Note:</strong>
   * The API sets the correlationId and replyTo fields of the message being sent;
   * this overwrites any existing correlationId and replyTo values on the message.
   *
   * @param {solace.Message} message The request message to send.
   * @param {Number} [timeout] The timeout value (in milliseconds). The minimum value is 100 msecs.
   * @param {solace.Session.replyReceivedCallback} [replyReceivedCBFunction] The callback to notify
   *    when a reply is received.
   * @param {solace.Session.requestFailedCallback} [requestFailedCBFunction] The callback to notify
   *    when the request failed.
   * @param {Object} [userObject] An optional correlation object to use in the response callback.
   *
   * @throws {solace.OperationError}
   * * if the session is disposed or disconnected.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the parameters have an invalid type.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   * * if the parameters have an invalid value.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_OUT_OF_RANGE}.
   * * if the message does not have a topic.
   *   Subcode: {@link solace.ErrorSubcode.TOPIC_MISSING}.
   * * if there's no space in the transport to send the request.
   *   Subcode: {@link solace.ErrorSubcode.INSUFFICIENT_SPACE}.  See:
   *   {@link solace.SessionEventCode#event:CAN_ACCEPT_DATA}.
   * * if no Guaranteed Message Publisher is available and the message deliveryMode is
   *   {@link solace.MessageDeliveryModeType.PERSISTENT} or
   *   {@link solace.MessageDeliveryModeType.NON_PERSISTENT}.
   *   Subcode: {@link solace.ErrorSubcode.GM_UNAVAILABLE}.
   * * if the message deliveryMode is
   *   {@link solace.MessageDeliveryModeType.PERSISTENT} or
   *   {@link solace.MessageDeliveryModeType.NON_PERSISTENT},
   *   and the message payload size is above the broker's limit.
   *   Subcode: {@link solace.ErrorSubcode.MESSAGE_TOO_LARGE}.
   */
  sendRequest(message,
              timeout = undefined,
              replyReceivedCBFunction = undefined,
              requestFailedCBFunction = undefined,
              userObject = undefined
              ) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session sendRequest called.');
    const result = this.allowOperation(SessionOperation.SEND, message);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    Parameter.isInstanceOf('message', message, MessageLib.Message);
    Parameter.isNumberOrNothing('timeout', timeout);
    Parameter.isRangeCompareOrNothing('timeout', timeout, '>=', 100);
    Parameter.isFunctionOrNothing('replyReceivedCBFunction', replyReceivedCBFunction);
    Parameter.isFunctionOrNothing('requestFailedCBFunction', requestFailedCBFunction);

    // set correlationId and replyTo fields if not set by the application
    const correlationId = message.getCorrelationId();
    if (correlationId === null || correlationId === undefined) {
      message.setCorrelationId(SOLCLIENT_REQUEST_PREFIX + GlobalContext.NextId());
    }
    const replyTo = message.getReplyTo();
    if (replyTo === null || replyTo === undefined) {
      const replyToTopic = DestinationLib.Topic.createFromName(
        this._sessionProperties.p2pInboxInUse);
      message.setReplyTo(replyToTopic);
    }

    this.validateAndSendMessage(message);

      // enqueue request
    this.enqueueOutstandingDataReq(message.getCorrelationId(),
                                   requestFailedCBFunction,
                                   timeout,
                                   replyReceivedCBFunction,
                                   userObject);
  }

  /**
   * Sends a reply message to the destination specified in messageToReplyTo.
   *
   * If `messageToReplyTo` is non-null:
   *  * {@link solace.Message#getReplyTo} is copied from `messageToReplyTo` to
   *    {@link solace.Message#setDestination} on `replyMessage`, unless `replyTo` is null.
   *  * {@link solace.Message#setCorrelationId} is copied from `messageToReplyTo` to
   *    {@link solace.Message#setCorrelationId} on `replyMessage`, unless `correlationId` is null.
   *
   * If `messageToReplyTo` is null, the application is responsible for setting
   * the `destination` and `correlationId` on the `replyMessage`.
   *
   * @param {solace.Message} messageToReplyTo The message to which a reply will be sent.
   * @param {solace.Message} replyMessage The reply to send.
   *
   * @throws {solace.OperationError}
   * * if the session is disposed or disconnected.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the parameters have an invalid type.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   * * if the parameters have an invalid value.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_OUT_OF_RANGE}.
   * * if the message does not have a topic.
   *   Subcode: {@link solace.ErrorSubcode.TOPIC_MISSING}.
   * * if there's no space in the transport to send the request.
   *   Subcode: {@link solace.ErrorSubcode.INSUFFICIENT_SPACE}.  See:
   *   {@link solace.SessionEventCode#event:CAN_ACCEPT_DATA}.
   * * if no Guaranteed Message Publisher is available and the message deliveryMode is
   *   {@link solace.MessageDeliveryModeType.PERSISTENT} or
   *   {@link solace.MessageDeliveryModeType.NON_PERSISTENT}.
   *   Subcode: {@link solace.ErrorSubcode.GM_UNAVAILABLE}.
   * * if the message deliveryMode is
   *   {@link solace.MessageDeliveryModeType.PERSISTENT} or
   *   {@link solace.MessageDeliveryModeType.NON_PERSISTENT},
   *   and the message payload size is above the broker's limit.
   *   Subcode: {@link solace.ErrorSubcode.MESSAGE_TOO_LARGE}.
   */
  sendReply(messageToReplyTo, replyMessage) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session sendReply called.');
    const result = this.allowOperation(SessionOperation.SEND, replyMessage);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }

    Parameter.isInstanceOfOrNothing('messageToReplyTo', messageToReplyTo, MessageLib.Message);
    Parameter.isInstanceOf('replyMessage', replyMessage, MessageLib.Message);

    replyMessage.setAsReplyMessage(true);
    if (messageToReplyTo) {
      replyMessage.setCorrelationId(messageToReplyTo.getCorrelationId());
      const replyTo = messageToReplyTo.getReplyTo();
      if (replyTo === null || replyTo === undefined) {
        throw new OperationError('ReplyTo destination may not be null.',
                                 ErrorSubcode.PARAMETER_OUT_OF_RANGE);
      }
      replyMessage.setDestination(messageToReplyTo.getReplyTo());
    }
    this.validateAndSendMessage(replyMessage);
  }

  /**
   * Returns the value of a given {@link solace.StatType}.
   *
   * @param {solace.StatType} statType The statistic to query.
   * @returns {Number} The value of the requested statistic.
   *
   * @throws {solace.OperationError}
   * * if the session is disposed.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the StatType is invalid.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_OUT_OF_RANGE}.
   */
  getStat(statType) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session getStat called.');
    const result = this.allowOperation(SessionOperation.QUERY_OPERATION);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }

    Parameter.isEnumMember('statType', statType, StatType);
    return this._sessionFSM.getStat(statType);
  }

  /**
   * Reset session statistics to initial values.
   *
   * @throws {solace.OperationError} if the session is disposed.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   */
  resetStats() {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session resetStats called.');
    const result = this.allowOperation(SessionOperation.QUERY_OPERATION);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    this._sessionFSM.resetStats();
  }

  /**
   * Returns a clone of the properties for this session.
   *
   * @returns {solace.SessionProperties} A clone of this session's properties.
   * @throws {solace.OperationError} if the session is disposed.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   */
  getSessionProperties() {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session getSessionProperties called.');
    const result = this.allowOperation(SessionOperation.QUERY_OPERATION);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }

    const properties = this._sessionProperties.clone();
    const sessionState = this.getSessionState();
    if ((sessionState !== SessionStateName.DISCONNECTED) && this._sessionFSM._transport) {
      properties._setWebTransportProtocolInUse(this._sessionFSM._transport.getTransportProtocol());
    }

    return properties;
  }

  /**
   * Check the value of a boolean router capability.
   *
   * This function is a shortcut for {@link solace.Session#getCapability}. It performs the same
   * operation, but instead of returning a {@link solace.SDTField} wrapping a capability value, it
   * just returns the boolean value.
   *
   *  Attempting to query a non-boolean capability will return `null`.
   *
   * @param {solace.CapabilityType} capabilityType The capability to check.
   *
   * @returns {Boolean} the value of the capability queried.
   *
   * @throws {solace.OperationError}
   * * if the session is disposed.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the parameters have an invalid type or value.
   *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   */
  isCapable(capabilityType) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session isCapable called.');
    const result = this.allowOperation(SessionOperation.QUERY_OPERATION);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    Parameter.isNumber('capabilityType', capabilityType);

    const caps = this._capabilities;
    if (!caps) {
      return false;
    }

    // Guard for undefined OR non-boolean capability
    return (typeof caps[capabilityType] === 'boolean') ? caps[capabilityType] : false;
  }

  /**
   * Get the value of an router capability, or null if unknown. This function must
   * be called after connecting the session.
   *
   * SDT Type conversions:
   *
   *  * {string} values are returned as {@link solace.SDTFieldType.STRING}.
   *  * {boolean} values are returned as {@link solace.SDTFieldType.BOOL}.
   *  * All numeric values are returned as {@link solace.SDTFieldType.INT64}.
   *
   * @param {solace.CapabilityType} capabilityType The router capability to query.
   * @returns {solace.SDTField} The result of the capability query.
   *
   * @throws {solace.OperationError}
   * * if the session is disposed
   *    Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * * if the parameters have an invalid type or value.
   *    Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
   */
  getCapability(capabilityType) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session getCapability called.');
    const result = this.allowOperation(SessionOperation.QUERY_OPERATION);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }

    Parameter.isNumber('capabilityType', capabilityType);

    const val = this._getCapability(capabilityType);
    if (typeof val === 'boolean') {
      return SDTField.create(SDTFieldType.BOOL, val);
    }
    if (typeof val === 'number') {
      return SDTField.create(SDTFieldType.INT64, val);
    }
    if (typeof val === 'string') {
      return SDTField.create(SDTFieldType.STRING, val);
    }

    return null;
  }

  _getCapability(capabilityType) {
    const caps = this._capabilities;
    if (!caps) return null;

    const value = caps[capabilityType];
    return (value === undefined) ? null : value;
  }

  /**
   * Returns the session's state. This is a third-choice method to determine session
   * state; the first is notifications on FSM transitions, and the second choice is
   * the finer-grained states of the FSM that are used in this mapping.
   *
   * @returns {SessionState} The current state of the session.
   * @throws {solace.OperationError} if the session is disposed.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}.
   * @internal
   */
  getSessionState() {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session getSessionState called.');
    const result = this.allowOperation(SessionOperation.QUERY_OPERATION);
    if (result) {
      throw new OperationError(result, ErrorSubcode.INVALID_OPERATION, null);
    }
    const sessionStateName = this.getFSMState();
    switch (sessionStateName) {
      case SessionStateName.FULLY_CONNECTED:
        return SessionState.CONNECTED;
      case SessionStateName.DISCONNECTING:
        return SessionState.DISCONNECTING;
      case SessionStateName.DISCONNECTED:
        return SessionState.DISCONNECTED;
      case SessionStateName.SESSION_ERROR:
        return SessionState.SESSION_ERROR;
      case SessionStateName.CONNECTING:
      case SessionStateName.WAITING_FOR_INTERCONNECT_TIMEOUT:
      case SessionStateName.WAITING_FOR_DNS:
      case SessionStateName.WAITING_FOR_TRANSPORT_UP:
      case SessionStateName.WAITING_FOR_SESSION_UP:
      case SessionStateName.WAITING_FOR_LOGIN:
      case SessionStateName.WAITING_FOR_P2PINBOX_REG:
      case SessionStateName.WAITING_FOR_PUBFLOW:
      case SessionStateName.REAPPLYING_SUBSCRIPTIONS:
        return SessionState.CONNECTING;
      default:
        {
          // State names unaccounted for --
          // WAITING_FOR_SUBCONFIRM
          // WAITING_FOR_CAN_ACCEPT_DATA
          // DISCONNECTING_FLOWS
          // FLUSHING_TRANSPORT
          // DESTROYING_TRANSPORT
          // RECONNECTING
          // TRANSPORT_FAIL
          //
          // Alternatively, we could use StateMachine#isStateActive on key parent states,
          // instead of having to enumerate all child states.ant
          const { LOG_INFO } = this.logger;
          LOG_INFO(`Unmapped session state ${SessionStateName.describe(sessionStateName)}`);
          return null;
        }
    }
  }

  /**
   * Gets the fine grained state name from the session FSM.
   * @returns {solace.SessionStateName} The FSM state name
   * @private
   */
  getFSMState() {
    return this._sessionFSM.getCurrentStateName();
  }

  /**
   * Creates a {@link solace.CacheSession} object that uses this Session to service its
   * cache requests.
   *
   * It should be disposed when the application no longer requires a CacheSession, by calling
   * {@link solace.CacheSession#dispose}.
   *
   * @param {solace.CacheSessionProperties} properties The properties for the cache session.
   *
   * @returns {solace.CacheSession} The newly created cache session.
   *
   * @throws {solace.OperationError} if a CacheSession is already associated with this Session.
   *   Subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}
   */
  createCacheSession(properties) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session createCacheSession called.');
    return new CacheSession(properties, this, {
      // bind instead of arrow func for varargs
      // due to bublé transpiler bug
      incStat: this._sessionFSM.incStat.bind(this._sessionFSM),
    });
  }

  /**
   * Creates a {@link solace.MessageConsumer} to receive Guaranteed Messages in this Session.
   *
   * Consumer characteristics and behavior are defined by properties. The consumer properties are
   * supplied as an object; the pertinent fields are exposed in
   * {@link solace.MessageConsumerProperties};
   * other property names are ignored. If the Message Consumer creation
   * specifies a non-durable endpoint,
   * {@link solace.QueueProperties} can be used to change the default properties on the
   * non-durable endpoint. Any values not supplied are set to default values.
   *
   * When the consumer is created, a consumer object is returned to the caller. This is the object
   * from which events are emitted, and upon which operations (for example, starting and stopping
   * the consumer) are performed.
   *
   * If this session does not support Guaranteed Messaging, this method will throw. The following
   * must be true in order to create a MessageConsumer:
   *  * The transport protocol list does not contain any HTTP transport protocols. See
   *    {@link solace.SessionProperties#transportProtocol} and
   *    {@link solace.FactoryProfile#cometEnabled}
   *  * The Solace Messaging Router must support Guaranteed Messaging
   *
   * @method solace.Session#createMessageConsumer
   * @param {solace.MessageConsumerProperties|Object} consumerProperties The properties for the
   *    consumer.
   * @returns {solace.MessageConsumer} The newly created Message Consumer.
   * @throws {solace.OperationError} when Guaranteed Message Consume is not
   *    supported on this session.
   * @target browser
   */
  /**
   * Creates a {@link solace.MessageConsumer} to receive Guaranteed Messages in this Session.
   *
   * Consumer characteristics and behavior are defined by properties. The consumer properties are
   * supplied as an object; the pertinent fields are exposed in
   * {@link solace.MessageConsumerProperties};
   * other property names are ignored. If the Message Consumer creation
   * specifies a non-durable endpoint,
   * {@link solace.QueueProperties} can be used to change the default properties on the
   * non-durable endpoint. Any values not supplied are set to default values.
   *
   * When the consumer is created, a consumer object is returned to the caller. This is the object
   * from which events are emitted, and upon which operations (for example, starting and stopping
   * the consumer) are performed.
   *
   * If this session does not support Guaranteed Messaging, this method will throw. The Solace
   * Messaging Router must support Guaranteed Messaging.
   *
   * @method solace.Session#createMessageConsumer
   * @param {solace.MessageConsumerProperties|Object} consumerProperties The properties for the
   *    consumer.
   * @returns {solace.MessageConsumer} The newly created Message Consumer.
   * @throws {solace.OperationError} if Guaranteed Message Consume is not supported on this session.
   * @target node
   */
  createMessageConsumer(consumerProperties) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session createMessageConsumer called.');
    if (this._adDisabledReason) {
      throw new OperationError('Session does not provide MessageConsumer capability',
                               ErrorSubcode.GM_UNAVAILABLE,
                               this._adDisabledReason);
    }
    if ((consumerProperties !== undefined) && (consumerProperties !== null)) {
      const { LOG_DEBUG } = this.logger;
      LOG_DEBUG('createMessageConsumer - Consumer properties:\n', consumerProperties);
    }
    return this._sessionFSM.createMessageConsumer(consumerProperties);
  }

  /**
   * Creates a {@link solace.QueueBrowser} to browse Guaranteed Messages on a specified queue in
   * this Session.
   *
   * Browser characteristics and behavior are defined by properties. The browser properties are
   * supplied as an object; the pertinent fields are exposed in
   * {@link solace.QueueBrowserProperties};
   * other property names are ignored. Any values not supplied are set to default values.
   *
   * Delivery restrictions imposed by the queue’s Access type (exclusive or non-exclusive),
   * do not apply when browsing messages with a Browser.
   *
   * When the queue browser is created, a queue browser object is returned to the caller. This is
   * the object from which events are emitted, and upon which operations (for example, starting and
   * stopping the browser) are performed.
   *
   * If this session does not support Guaranteed Messaging, this method will throw. The following
   * must be true in order to create a QueueBrowser:
   *  * The transport protocol list does not contain any HTTP transport protocols. See
   *    {@link solace.SessionProperties#transportProtocol} and
   *    {@link solace.FactoryProfile#cometEnabled}
   *  * The Solace Messaging Router must support Guaranteed Messaging
   *
   * @method solace.Session#createQueueBrowser
   * @param {solace.QueueBrowserProperties|Object} browserProperties The properties for the
   *    browser.
   * @returns {solace.QueueBrowser} The newly created Queue Browser.
   * @throws {solace.OperationError} when Guaranteed Messaging is not
   *    supported on this session.
   * @target browser
   */
  /**
   * Creates a {@link solace.QueueBrowser} to receive Guaranteed Messages in this Session.
   *
   * Browser characteristics and behavior are defined by properties. The properties are
   * supplied as an object; the pertinent fields are exposed in
   * {@link solace.QueueBrowserProperties};
   * other property names are ignored.
   *
   * Delivery restrictions imposed by the queue’s Access type (exclusive or non-exclusive),
   * do not apply when browsing messages with a Browser.
   *
   * When the browser is created, a browser object is returned to the caller. This is the object
   * from which events are emitted, and upon which operations (for example, starting and stopping
   * the browser) are performed.
   *
   * If this session does not support Guaranteed Messaging, this method will throw. The Solace
   * Messaging Router must support Guaranteed Messaging.
   *
   * @method solace.Session#createQueueBrowser
   * @param {solace.QueueBrowserProperties|Object} browserProperties The properties for the
   *    browser.
   * @returns {solace.QueueBrowser} The newly created Queue Browser.
   * @throws {solace.OperationError} if Guaranteed Messaging is not supported on this session.
   * @target node
   */
  createQueueBrowser(browserProperties) {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session createQueueBrowser called.');
    if (this._adDisabledReason) {
      throw new OperationError('Session does not provide QueueBrowser capability',
                               ErrorSubcode.GM_UNAVAILABLE,
                               this._adDisabledReason);
    }
    if ((browserProperties !== undefined) && (browserProperties !== null)) {
      const { LOG_DEBUG } = this.logger;
      LOG_DEBUG('createQueueBrowser - Browser properties:\n', browserProperties);
    }
    return this._sessionFSM.createQueueBrowser(browserProperties);
  }

  /**
   * Creates a publishing destination from a queue descriptor.
   *
   * A MessageConsumer is the only object that has any business
   * doing this, but it should not be concerned with the internals
   * which depend on the session.
   *
   * @param {AbstractQueueDescriptor|QueueDescriptor} queueDescriptor The consumer's descriptor
   * @returns {Destination} A destination that publishes to the descriptor.
   * @memberof Session
   * @private
   */
  createDestinationFromDescriptor(queueDescriptor) {
    const {
      DestinationType,
      Queue,
      Topic,
    } = DestinationLib;

    let destinationType = DestinationType.TOPIC;
    if (queueDescriptor.type === QueueType.QUEUE) {
      destinationType = queueDescriptor.durable
        ? DestinationType.QUEUE
        : DestinationType.TEMPORARY_QUEUE;
    }

    const name = queueDescriptor.name || null;

    if (queueDescriptor.durable) {
      assert(name, 'Durable endpoint with generated name is not a valid configuration');
      const factoryMethod = queueDescriptor.getType() === QueueType.QUEUE
        ? Queue.createFromLocalName
        : Topic.createFromName;
      return factoryMethod(name);
    }

    return this.createTemporaryDestination(destinationType, name);
  }

  /**
   * Creates a temporary destination.
   * @param {DestinationType} destinationType Type of destination
   * @param {String} [name] Name if any
   * @returns {Destination} Temporary destination
   * @private
   */
  createTemporaryDestination(destinationType, name) {
    const { LOG_TRACE } = this.logger;
    const { DestinationFromNetwork, DestinationUtil } = DestinationLib;
    // TRANSPORT_UP is chosen because this is when the session tells subscribers
    // that they may begin connecting
    const vrn = this.getSessionProperties().virtualRouterName;
    if (!this.isCapable(CapabilityType.TEMPORARY_ENDPOINT) ||
        vrn === null || vrn === undefined || vrn.length === 0) {
      throw new OperationError(
        'Attempt to generate temporary destination or endpoint without suitable session',
        ErrorSubcode.INVALID_OPERATION);
    }
    // Non-durable case; avoid re-prefixing
    const localName = name && name.startsWith('#P2P')
      ? name
      : DestinationUtil.createTemporaryName(destinationType, vrn, name);
    LOG_TRACE('Generated name:', localName);
    return DestinationFromNetwork.createDestinationFromName(localName);
  }

  /**
   * @param {solace.SessionEvent} sessionEvent The event to send
   * @private
   */
  sendEvent(sessionEvent) {
    if (!sessionEvent) return;
    if (this._disposed) return;

    const { LOG_TRACE } = this.logger;
    LOG_TRACE(`Sending event ${sessionEvent}`);
    this._eventCallbackInfo.sessionEventCBFunction(this,
                                                   sessionEvent,
                                                   this._eventCallbackInfo.userObject);
  }

  /**
   * Gets a transport session information string.
   * This string is informative only, and applications should not attempt to parse it.
   *
   * @returns {String} A description of the current session's transport.
   */
  getTransportInfo() {
    const { LOG_TRACE } = this.logger;
    LOG_TRACE('Session getTransportInfo called.');
    return this._sessionFSM.getTransportInfo();
  }

  /**
   * @param {Object} interceptor The transport interceptor to set
   * @private
   */
  injectTransportInterceptor(interceptor) {
    this._sessionFSM.injectTransportInterceptor(interceptor);
  }

  /**
   * @param {solace.SessionOperation} operationEnum the id of the operation
   * @param {solace.Message} message The message to send
   * @returns {?String} error message if not allowed; otherwise null
   * @private
   */
  allowOperation(operationEnum, message) {
    if (!this._sessionFSM) return false;
    let allow = true;
    const sessionStateName = this._sessionFSM.getCurrentStateName();

    if (sessionStateName === SessionStateName.DISPOSED) {
      allow = false;
    } else if (Check.anything(operationEnum)) {
      switch (operationEnum) {
        case SessionOperation.CONNECT:
          if (sessionStateName !== SessionStateName.NEW &&
              sessionStateName !== SessionStateName.DISCONNECTED) {
            allow = false;
          }
          break;
        case SessionOperation.DISCONNECT:
          if (sessionStateName === SessionStateName.NEW) {
            allow = false;
          }
          break;
        case SessionOperation.SEND:
        case SessionOperation.CTRL:
          allow =
            ((sessionStateName === SessionStateName.FULLY_CONNECTED) ||
            (message && (message.getDeliveryMode() !== MessageLib.MessageDeliveryModeType.DIRECT)));
          break;

        case SessionOperation.QUERY_OPERATION:
          allow = true;
          break;
        default:
          allow = false;
      }
    } else {
      allow = false;
    }

    if (allow) {
      return null;
    }

    return `Cannot perform operation ${operationEnum} while in state ${sessionStateName}`;
  }

  /**
   * @param {smf.ClientCtrlMessage} routerCapabilities The message containing the router caps
   * @private
   */
  updateCapabilities(routerCapabilities) {
    this._capabilities = routerCapabilities;
  }

  /**
   * @param {solace.Message} message The message to send
   * @private
   */
  validateAndSendMessage(message) {
    // Sanity checks on the message before attempting to send it
    //  * do we have a destination?
    const sendDest = message.getDestination();
    if (Check.nothing(sendDest) || Check.empty(sendDest.getName())) {
      throw new OperationError('Message must have a valid Destination', ErrorSubcode.TOPIC_MISSING);
    }

    const senderTimestamp = message.getSenderTimestamp();
    const noSenderTimestamp = senderTimestamp === null || senderTimestamp === undefined;
    if (this._sessionProperties.generateSendTimestamps &&
        (noSenderTimestamp || message.hasAutoSenderTimestamp)) {
      const now = new Date();
      message.setSenderTimestamp(now.getTime());
      message.hasAutoSenderTimestamp = true;
    }
    const sequenceNumber = message.getSequenceNumber();
    const noSequenceNumber = sequenceNumber === null || sequenceNumber === undefined;
    if (this._sessionProperties.generateSequenceNumber &&
        (noSequenceNumber || message.hasAutoSequenceNumber)) {
      message.setSequenceNumber(this._seqNum++);
      message.hasAutoSequenceNumber = true;
    }
    const senderId = message.getSenderId();
    const noSenderId = senderId === null || senderId === undefined;
    if (this._sessionProperties.includeSenderId && noSenderId) {
      message.setSenderId(this._sessionProperties.clientName);
    }

    // Allow the FSM and its delegates to prepare and send the message
    // This may mutate the delegates, so we need to validate first
    // this may throw if the message is guaranteed and the window is closed.
    // If this returns true, the message can be sent to the transport
    // (always true for direct messages whne there is a transport). If
    // this returns false, the message has been prepared and queued for
    // transport but may not be sent now.
    this._sessionFSM.prepareAndSendMessage(message);
  }

  /**
   * @param {String} correlationId The internal correlation ID for the message
   * @param {function(...[*])} reqFailedCb The callback on request failure
   * @param {Number} reqTimeout The request timeout in ms
   * @param {function(*)} replyRecvdCb The callback on reply received
   * @param {Object} userObject A user object to pass back to the callback (legacy)
   * @private
   */
  enqueueOutstandingDataReq(correlationId, reqFailedCb, reqTimeout, replyRecvdCb, userObject) {
    if (Check.none(correlationId)) {
      return;
    }
    const { LOG_TRACE, LOG_ERROR } = this.logger;

    // empty string is valid
    LOG_TRACE(`Enqueue outstanding data request correlationId=${correlationId}`);
    const timer = setTimeout(() => {
      this._sessionFSM.incStat(StatType.TX_REQUEST_TIMEOUT);
                  // remove request from queue
      try {
        const result = delete this._outstandingDataReqs[correlationId];
        if (!result) {
          LOG_ERROR(`Cannot delete data request ${correlationId}`);
        }
      } catch (e) {
        LOG_ERROR(`Cannot delete data request ${correlationId}`, e);
      }

      if (Check.anything(reqFailedCb)) {
        const requestEvent = SessionEvent.build(RequestEventCode.REQUEST_TIMEOUT,
                                                'Request timeout',
                                                correlationId);

        reqFailedCb(this, requestEvent, userObject);
      }
    }, reqTimeout || this._sessionProperties.readTimeoutInMsecs);

    const outstandingReq = new OutstandingDataRequest(correlationId,
                                                      timer,
                                                      replyRecvdCb,
                                                      reqFailedCb,
                                                      userObject);
    this._outstandingDataReqs[correlationId] = outstandingReq;
  }

  /**
   * @param {String} correlationId The internal ID of the request to cancel
   * @returns {OutstandingDataRequest} The request that was cancelled, if any
   * @private
   */
  cancelOutstandingDataReq(correlationId) {
    const { LOG_TRACE, LOG_ERROR } = this.logger;

    if (Check.none(correlationId) || !this._outstandingDataReqs) {
      return null;
    }

    const req = this._outstandingDataReqs[correlationId];
    if (req === undefined || req === null) {
      return null;
    }
    LOG_TRACE(`Cancel outstanding data request correlationId=${correlationId}`);
    if (req.timer) {
      clearTimeout(req.timer);
      req.timer = null;
    }

    try {
      const result = delete this._outstandingDataReqs[correlationId];
      if (!result) {
        LOG_ERROR(`Cannot delete data request ${correlationId}`);
      }
    } catch (e) {
      LOG_ERROR(`Cannot delete data request ${correlationId}`, e);
    }
    return req;
  }

  /**
   * @private
   */
  cleanupSession() {
    const { LOG_TRACE } = this.logger;
    if (this._outstandingDataReqs) {
      LOG_TRACE('Cancel all outstanding data requests');
      Object.keys(this._outstandingDataReqs).forEach((key) => {
        const dataReq = this.cancelOutstandingDataReq(key);
        if (dataReq && dataReq.reqFailedCBFunction) {
          const requestEvent = SessionEvent.build(RequestEventCode.REQUEST_ABORTED,
                                                  'Request aborted',
                                                  key);
          dataReq.reqFailedCBFunction(this, requestEvent, dataReq.userObject);
        }
      });
    }
  }

  /**
   * @param {Message} dataMessageIn The received direct TRmsg
   * @private
   */
  handleDataMessage(dataMessageIn) {
    const { LOG_TRACE, LOG_INFO } = this.logger;
    const dataMessage = dataMessageIn;
    if (this._sessionProperties.generateReceiveTimestamps) {
      const now = new Date();
      dataMessage._receiverTimestamp = now.getTime();
    }

    if (dataMessage.isReplyMessage()) {
      const correlationId = dataMessage.getCorrelationId();
      if (Check.anything(correlationId)) {
        const dataReq = this.cancelOutstandingDataReq(correlationId);
        if (dataReq !== null) {
          this._sessionFSM.incStat(StatType.RX_REPLY_MSG_RECVED);
          LOG_TRACE('Calling application replyReceivedCallback');
          dataReq.replyReceivedCBFunction(this, dataMessage, dataReq.userObject);
          LOG_TRACE('application replyReceivedCallback returns');
          return;
        }

        if (correlationId.startsWith(SOLCLIENT_REQUEST_PREFIX)) {
            // if a reply message doesn't have outstanding request and correlationId
            // starts with #REQ it is assumed to be a delayed reply and has to be discarded
          LOG_INFO('DROP: Discard reply message due to missing outstanding request');
          this._sessionFSM.incStat(StatType.RX_REPLY_MSG_DISCARD);
          return;
        }

        if (correlationId.startsWith(CACHE_REQUEST_PREFIX) &&
              !(CacheSession && this._messageCallbackInfo.userObject instanceof CacheSession)) {
          // If it's a cache message, only pass it along if the listener is a cache message
          // listener. The listener may drop it and increment the DISCARD stat if no
          // cache session recognizes the reply.
          LOG_INFO('DROP: Discard cache reply due to no cache session active');
          this._sessionFSM.incStat(StatType.RX_REPLY_MSG_DISCARD);
          return;
        }
      }
    }

    // notify client message callback
    LOG_TRACE('Calling application messageCallback');
    this._messageCallbackInfo.messageRxCBFunction(this,
                                                  dataMessage,
                                                  this._messageCallbackInfo.userObject);
    LOG_TRACE('application messageCallback returns');
  }

  /**
   * Callback function for subscribe/unsubscribe response
   * @param {solace.SMPMessage} smpMsg The SMP response to the subscription request
   * @param {CorrelatedRequest} request The originating request object
   * @param {Boolean} requestConfirm Whether the user asked for confirmation on the request
   * @private
   */
  handleSubscriptionUpdateResponse(smpMsg, request, requestConfirm) {
    const response = smpMsg.getResponse();
    const {
      responseCode,
      responseString,
     } = response;
    const { correlationKey } = request;
    // If we don't request confirmation, the router doesn't send one for the OK case,
    // so we don't need to guard for that.
    // The router always replies on SUBSCRIPTION_ERROR, so we track whether confirmation
    // was requested and suppress the reply in certain cases.
    if (responseCode === 200) {
      // notify client
      const sessionEvent = SessionEvent.build(SessionEventCode.SUBSCRIPTION_OK,
                                              responseString,
                                              responseCode,
                                              0,
                                              correlationKey,
                                              null);
      this.sendEvent(sessionEvent);
    } else {
      const subscriptionStr = StringUtils.stripNullTerminate(smpMsg.encodedUtf8Subscription);
      this._sessionFSM.handleSubscriptionUpdateError(responseCode,
                                                     responseString,
                                                     subscriptionStr,
                                                     correlationKey,
                                                     requestConfirm);
    }
  }


  /**
   * Callback function for queue subscribe/unsubscribe response
   * @param {solace.SMPMessage} smpMsg The SMP response to the subscription request
   * @param {CorrelatedRequest} request The originating request object
   * @param {function} callback The callback on response
   * @private
   */
  handleQueueSubscriptionUpdateResponse(smpMsg, request, callback) {
    const { LOG_TRACE } = this.logger;

    if (!smpMsg) { // reuest timed out.
      LOG_TRACE('handleQueueSubscriptionUpdateResponse called on timeout.');
      callback(false, ErrorSubcode.TIMEOUT, 0, 'Timeout');
      return;
    }

    const response = smpMsg.getResponse();
    const {
      responseCode,
      responseString,
     } = response;
    const errorSubcode = ErrorResponseSubcodeMapper.getADErrorSubcode(responseCode, responseString);
    LOG_TRACE('handleQueueSubscriptionUpdateResponse called.', smpMsg);
    if (responseCode === 200 ||
      (errorSubcode === ErrorSubcode.SUBSCRIPTION_ALREADY_PRESENT) ||
      (errorSubcode === ErrorSubcode.SUBSCRIPTION_NOT_FOUND)
    ) {
      callback(true, 0, responseCode, responseString);
    } else {
      callback(false, errorSubcode, responseCode, responseString);
    }
  }

  /**
   * Callback function for DTE unsubscribe response
   *
   * @param {solace.AdMessage} adCtrlMessage The Guaranteed Message Protocol
   *                           control response to the DTE unsub message
   * @param {CorrelatedRequest} request The originating request object
   * @private
   */
  handleDTEUnsubscribeResponse(adCtrlMessage, request) {
    const response = adCtrlMessage.getResponse();
    const {
      responseCode,
      responseString,
    } = response;
    const { correlationKey } = request;
    const eventCode = responseCode === 200
      ? SessionEventCode.UNSUBSCRIBE_TE_TOPIC_OK
      : SessionEventCode.UNSUBSCRIBE_TE_TOPIC_ERROR;
    const subcode = responseCode === 200
      ? 0
      : ErrorResponseSubcodeMapper.getADErrorSubcode(responseCode,
                                                     responseString);
    this.sendEvent(SessionEvent.build(eventCode,
                                      responseString,
                                      responseCode,
                                      subcode,
                                      correlationKey
    ));
  }

  /**
   * @param {Number} respCode The returned response code
   * @param {String} respText The returned response text
   * @param {String} subscriptionStr The requested topic name
   * @param {*} correlationKey The user-supplied correlation key
   * @param {Boolean} requestConfirm Whether confirmation was requested on subscription
   * @private
   */
  handleSubscriptionUpdateError(respCode,
                                respText,
                                subscriptionStr,
                                correlationKey,
                                requestConfirm) {
    const errorSubcode = ErrorResponseSubcodeMapper.getErrorSubcode(respCode, respText);
    if ((errorSubcode === ErrorSubcode.SUBSCRIPTION_ALREADY_PRESENT &&
         this._sessionProperties.ignoreDuplicateSubscriptionError) ||
        (errorSubcode === ErrorSubcode.SUBSCRIPTION_NOT_FOUND &&
          this._sessionProperties.ignoreSubscriptionNotFoundError)) {
      if (requestConfirm) {
        // notify client
        const sessionEvent = SessionEvent.build(
          SessionEventCode.SUBSCRIPTION_OK,
          respText,
          respCode,
          0,
          correlationKey,
          null
        );
        this.sendEvent(sessionEvent);
      }
    } else {
      // notify client
      const sessionEvent = SessionEvent.build(
        SessionEventCode.SUBSCRIPTION_ERROR,
        respText,
        respCode,
        errorSubcode,
        correlationKey,
        `Topic: ${subscriptionStr}`
      );
      this.sendEvent(sessionEvent);
    }
  }

  /**
   * @returns {SessionEventCBInfo} The session's event callback
   * @private
   */
  getEventCBInfo() {
    return this._eventCallbackInfo;
  }

  /**
   * @param {SessionEventCBInfo} eventCBInfo The new event callback to set
   * @private
   */
  setEventCBInfo(eventCBInfo) {
    this._eventCallbackInfo = eventCBInfo;
  }

  /**
   * @returns {MessageRxCBInfo} The session's message callback
   * @private
   */
  getMessageCBInfo() {
    return this._messageCallbackInfo;
  }

  /**
   * @param {MessageRxCBInfo} messageCBInfo The new message callback to set
   * @private
   */
  setMessageCBInfo(messageCBInfo) {
    this._messageCallbackInfo = messageCBInfo;
  }

  /**
   * @returns {String} The next correlation tag for this session.
   * @private
   */
  getCorrelationTag() {
    return this._sessionFSM.getCorrelationTag();
  }

  /**
   * Wraps a SessionEventCBInfo or a bare function with an event emitting function.
   * @param {SessionEventCBInfo|function|undefined} eventCallback The callback to wrap
   * @returns {SessionEventCBInfo} A SessionEventCBInfo object that handles all callbacks.
   * @private
   */
  wrapEventCallback(eventCallback) {
    const { LOG_WARN } = this.logger;
    const eventCallbackInfo = (() => {
      if (!eventCallback) return null;
      if (eventCallback.sessionEventCBFunction) return eventCallback;
      return new SessionEventCBInfo(eventCallback);
    })();
    return new SessionEventCBInfo((session, sessionEvent, obj, rfu) => {
      const { sessionEventCode } = sessionEvent;
      if (eventCallbackInfo) {
        try {
          eventCallbackInfo.sessionEventCBFunction(session, sessionEvent, obj, rfu);
        } catch (ex) {
          const error = Object.assign(new OperationError(
            `Unhandled error in SessionEventRxCBInfo callback on sessionEventCode ${
              SessionEventCode.describe(sessionEventCode)}`,
              ErrorSubcode.CALLBACK_ERROR,
              `On event: ${[sessionEventCode, sessionEvent, obj, rfu]} ${ex}`
            ), {
              stack: ex.stack,
              info:  {
                event: {
                  name:          sessionEventCode,
                  formattedName: `SessionEventCode.${SessionEventCode.describe(sessionEventCode)}`,
                  args:          [sessionEvent, obj, rfu],
                },
                error: ex,
              },
            }
          );
          LOG_WARN(error.toString(), error.info);
        }
      }
      this.emit(sessionEventCode, sessionEvent);
    });
  }

  /**
   * Wraps a CBInfo or a bare function with an event emitting function.
   * @param {MessageCBInfo|function|undefined} messageCallback The callback to wrap
   * @returns {MessageCBInfo} A MessageCBInfo object that handles all callbacks.
   * @private
   */
  wrapMessageCallback(messageCallback) {
    const { LOG_WARN } = this.logger;

    const messageCallbackInfo = (() => {
      if (!messageCallback) return null;
      if (messageCallback.messageRxCBFunction) return messageCallback;
      return new MessageRxCBInfo(messageCallback);
    })();

    const formattedName = `SessionEventCode.${SessionEventCode.describe(SessionEventCode.MESSAGE)}`;
    const buildErrorEvent = (ex, message, object) => Object.assign(
      new OperationError(`Unhandled error in MessageRxCBInfo callback/handler for ${formattedName}`,
                         ErrorSubcode.CALLBACK_ERROR),
      {
        stack: ex.stack,
        info:  {
          event: {
            name: SessionEventCode.MESSAGE,
            formattedName,
            args: [message, object],
          },
          error: ex,
        },
      });

    return new MessageRxCBInfo((session, message, object) => {
      if (messageCallbackInfo) {
        try {
          messageCallbackInfo.messageRxCBFunction(session, message, object);
        } catch (ex) {
          const error = buildErrorEvent(ex, message, object).toString();
          LOG_WARN(error, error.info, ex);
        }
      }
      try {
        this.emitDirect(message);
      } catch (ex) {
        this.emit('error', buildErrorEvent(ex, message, object));
      }
    });
  }

  /**
   * @readonly
   * @private
   */
  get adLocallyDisabled() {
    return !!this._adDisabledReason;
  }

  /**
   * @readonly
   * @private
   */
  get canConnectConsumer() {
    if (this.adLocallyDisabled) return false;
    if (this._capabilities) {
      return this.isCapable(CapabilityType.GUARANTEED_MESSAGE_CONSUME);
    }
    return undefined;
  }

  /**
   * @readonly
   * @private
   */
  get canConnectPublisher() {
    if (this.adLocallyDisabled) return false;
    if (this._capabilities) {
      return this.isCapable(CapabilityType.GUARANTEED_MESSAGE_PUBLISH);
    }
    return undefined;
  }

  /**
   * @readonly
   * @private
   */
  get disposed() {
    return this._disposed;
  }

  [util_inspect_custom]() {
    return {
      'sessionId': this._sessionFSM && this._sessionFSM.sessionIdHex || '(N/A)',
      'transport': this.getTransportInfo(),
      'state':     SessionState.describe(this.getSessionState()),
    };
  }

  toString() {
    return util_inspect(this);
  }
}

module.exports.Session = Session;
