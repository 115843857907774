const { ErrorSubcode } = require('solclient-error');
const { FsmEvent } = require('solclient-fsm');
const { Hex } = require('solclient-convert');

const { formatHexString } = Hex;
/**
 * SessionFSMEvent extends {@link FsmEvent}.  This object should be used for all events
 * passed to the SessionFSM.  This extension provides members to carry information specfic to
 * the SessionFSM transitions.
 * @extends FsmEvent
 * @private
 */
class SessionFSMEvent extends FsmEvent {
  constructor(spec, fields) {
    super(spec);
    /**
     * @type {String}
     * @description Information String for the {SessionEvent}
     */
    this.eventText = null;
    /**
     * @type {ErrorSubcode}
     * @description The Solace ErrorSubcode for any generated {SessionEvent}
     */
    this.errorSubcode = null;
    /**
     * @type {String}
     * @description The reason string to be included in any generated {SessionEvent}
     */
    this.eventReason = null;
    /**
     * @type {TransportSMFMessage}
     * @description A decoded SMF message received from the transport. May be
     * either a {ClientCtrlMessage} or a {SMPMessage}
     */
    this.smfMsg = null;
    /**
     * @type {Object}
     * @description The Transport SessionId retrieved from a {TransportSessionEvent}
     */
    this.sessionId = null;
    /**
     * @type {Object}
     * @description The Publisher or Consumer object associated with a GM event.
     */
    this.guaranteedFlowObject = null;

    Object.assign(this, fields);
  }

  [util_inspect_custom]() {
    return {
      'eventText':    this.eventText,
      'eventReason':  this.eventReason,
      'errorSubcode': ErrorSubcode.describe(this.errorSubcode),
      'sessionId':    this.sessionId && formatHexString(this.sessionId) || 'N/A',
    };
  }

  getExtraStringInfo() {
    return util_inspect(this);
  }
}

module.exports.SessionFSMEvent = SessionFSMEvent;
