/**
 * @classdesc
 * A representation of an SMF parameter
 *
 * @private
 */
class SMFParameter {
/**
 * @constructor
 * @param {Number} uh The UH value [0..2]
 * @param {SMFParameterType} type The type of SMF parameter
 * @param {*} value The value for this parameter
 * @param {Buffer} buffer instead of value, a buffer, with indexes (begin and end)
 * @param {Number} begin start index in buffer
 * @param {Number} end end index in buffer.
 */
  constructor(uh, type, value, buffer, begin, end) {
    this._type = type;
    this._value = value;
    this._uh = uh;
    this._buffer = buffer;
    this._begin = begin;
    this._end = end;
  }
  getType() {
    return this._type;
  }
  getValue() {
    if (this._buffer && !this._value) {
      return this._buffer.toString('latin1', this._begin, this._end);
    }
    return this._value;
  }
  getUh() {
    return this._uh;
  }
  getBuffer() {
    return this._buffer;
  }
  getBegin() {
    return this._begin;
  }
  getEnd() {
    return this._end;
  }

  toString() {
    return `${this._uh}:0x${this._type.toString(16)} = ${this.getValue()}`;
  }
}

module.exports.SMFParameter = SMFParameter;
