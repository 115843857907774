const { ReplayStartLocation, ReplayStartType } = require('./replayStartLocation');
/**
 * @classdesc
 *  * <b>This class is not exposed for construction by API users.
 *  Users should obtain an instances from the following:</b>
 *  * {@link solace.SolclientFactory.createReplayStartLocationDate}
 *
 * Defines the ReplayStartLocation class.
 * The ReplayStartLocation is set in the corresponding
 * MessageConsumer property {@link solace.MessageConsumerProperties#replayStartLocation}
 *
 * @extends ReplayStartLocation
 * @hideconstructor
 * @memberof solace
 */

class ReplayStartLocationDate extends ReplayStartLocation {
  /**
   * @param {Object} spec The topic spec
   * @private
   */
  constructor(spec) {
    super({
      _replayStartValue: spec.getTime(),
      _type:             ReplayStartType.DATE,
    });
  }
  [util_inspect_custom]() {
    return `[Epoch Time: ${this._replayStartValue}]`;
  }
  /**
   * @static
   * @param {Date} dateTime The Date for the replay start location
   * @returns {ReplayStartLocation} the ReplayStartLocation
   * @private
   */
  static createReplayStartLocationDate(dateTime) {
    return new ReplayStartLocationDate(dateTime);
  }
}

module.exports.ReplayStartLocationDate = ReplayStartLocationDate;
