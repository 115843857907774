const { Enum } = require('solclient-eskit');

/**
 * Enumeration of CacheReturnCode types.  The method {@link solace.CacheRequestResult#getReturnCode}
 * returns on of these basic results of a cache request.  More details are available in the
 * associated {@link solace.CacheReturnSubcode}, retrieved by
 * {@link solace.CacheRequestResult#getReturnSubcode}.
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const CacheReturnCode = {
  /**
   * @type {Number}
   * @description The cache request succeeded.  See the subcode for more information.
   */
  OK:         1,
  /**
   * @type {Number}
   * @description The cache request was not processed.  See the subcode for more information.
   */
  FAIL:       2,
  /**
   * @type {Number}
   * @description The cache request was processed but could not be completed.  See the subcode for
   * more information.
   */
  INCOMPLETE: 3,
};

module.exports.CacheReturnCode = Enum.new(CacheReturnCode);
