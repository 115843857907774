const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const CacheRequestType = {
  INVALID:                       0,
  BULK_MSG:                      1,
  REGISTER_REQUEST:              2,
  REGISTER_RESPONSE:             3,
  HEARTBEAT_REQUEST:             4,
  HEARTBEAT_RESPONSE:            5,
  EVENT_NOTIFY:                  6,
  EVENT_ACK:                     7,
  ACTION_REQUEST:                8,
  ACTION_RESPONSE:               9,
  GET_REQUEST:                   10,
  GET_RESPONSE:                  11,
  GET_NEXT_REQUEST:              12,
  GET_NEXT_RESPONSE:             13,
  SET_REQUEST:                   14,
  SET_RESPONSE:                  15,
  GET_MSG_REQUEST:               16,
  GET_MSG_RESPONSE:              17,
  GET_NEXT_MSG_REQUEST:          18,
  GET_NEXT_MSG_RESPONSE:         19,
  UNREGISTER_IND:                20,
  BULK_SET_REQUEST:              21,
  BULK_SET_RESPONSE:             22,
  PURGE_MSG_SEQUENCE_REQUEST:    23,
  PURGE_MSG_SEQUENCE_RESPONSE:   24,
  GET_MSG_SEQUENCE_REQUEST:      25,
  GET_NEXT_MSG_SEQUENCE_REQUEST: 26,
  GET_TOPIC_INFO_REQUEST:        27,
  GET_TOPIC_INFO_RESPONSE:       28,
  READY_MARKER:                  29,
  GET_TOPIC_INFO_REQUEST_RANGE:  30,
  SYNC_READY_MARKER:             31,
  VACUUM_REQUEST:                32,
  VACUUM_RESPONSE:               33,
};

module.exports.CacheRequestType = Enum.new(CacheRequestType);
