const { Enum } = require('solclient-eskit');

/**
 * Defines the possible TransportSessionEvent codes.
 * @private
 * @enum {number}
 */
const TransportSessionEventCode = {
  // Raised when TransportSession is up and ready to send/receive data
  UP_NOTICE:           1,
  // Raised if the session is destroyed
  DESTROYED_NOTICE:    2,
  // Raised when the send queue had reached its maximum, but now has space again
  CAN_ACCEPT_DATA:     4,
  // Raised when there is a decode error on received data.  The app should destroy the session
  DATA_DECODE_ERROR:   5,
  // Raised when there is a decode error on received data.  The app should destroy the session
  PARSE_FAILURE:       6,
  // Raised when the connection times out. The app should attempt downgrade if available.
  CONNECT_TIMEOUT:     7,
  // There was an error sending a message on an etablished transport
  SEND_ERROR:          8,
  // The requested downgrade was rejected.
  DOWNGRADE_FAILED:    10,
  // The requested downgrade completed.
  DOWNGRADE_SUCCEEDED: 11,
};

module.exports.TransportSessionEventCode = Enum.new(TransportSessionEventCode);
