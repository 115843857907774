/* eslint-disable no-nested-ternary */

function defaultComparator(a, b) {
  const astr = String(a);
  const bstr = String(b);
  return astr > bstr ? 1 : (astr < bstr ? -1 : 0);
}

function insertOrdered(array, element, comparator = defaultComparator) {
  const result = [...array];
  return this.inplaceInsertOrdered(result, element, comparator);
}

function inplaceInsertOrdered(array, element, comparator = defaultComparator) {
  let index = 0;
  let min = 0;
  let max = array.length;
  // Optimize for empty array
  if (max > 0) {
    // Optimize for append
    if (comparator(element, array[max - 1]) > 0) {
      index = max;
    } else {
      // Binary search
      index = (min + max) >> 1; // math.floor((min + max) / 2)
      while (max > min) {
        if (comparator(element, array[index]) < 0) {
          max = index;
        } else {
          min = index + 1;
        }
        index = (min + max) >> 1; // math.floor((min + max) / 2)
      }
    }
  }
  array.splice(index, 0, element);
}

module.exports = {
  ArrayOperations: {
    defaultComparator,
    insertOrdered,
    inplaceInsertOrdered,
  },
};
