const ParseSingleElementLib = require('./parse-single-element');
const { LOG_ERROR } = require('solclient-log');
const { ParseFieldHeader } = require('./parse-field-header');
const { SDTDataTypes } = require('../sdt-data-types');
const { SDTField } = require('../sdt-field');
const { SDTFieldType } = require('../sdt-field-types');
const { SDTMapContainer } = require('../sdt-map-container');

const ParseMap = {};
ParseMap.parseMapAt = function parseMapAt(dataBuf, offset, datalen) {
  const mapObj = new SDTMapContainer();
  let pos = offset;
  while (pos < offset + datalen) {
    // === key field ===
    const keyFieldHeader = ParseFieldHeader.parseFieldHeader(dataBuf, pos);
    pos += keyFieldHeader[3]; // consumed bytes
    // pos now points to start of string
    if (keyFieldHeader[0] !== SDTDataTypes.String) {
      // Fail!
      LOG_ERROR("Error parsing SDTMAP, expected to find a string field as map key, and didn't");
      LOG_ERROR(`Type of key: ${keyFieldHeader[0]}`);
      return SDTField.create(SDTFieldType.MAP, null);
    }
    const keyString = dataBuf.toString('latin1', pos, pos + keyFieldHeader[2] - 1);
    pos += keyFieldHeader[2];

    // === value field ===
    // pos now points to start of next value
    const valueFieldHeader = ParseFieldHeader.parseFieldHeader(dataBuf, pos);
    const valueField = ParseSingleElementLib.ParseSingleElement.parseSingleElement(dataBuf, pos);
    pos += valueFieldHeader[1]; // declared field length
    if (valueField) {
      mapObj.addField(keyString, valueField);
    }
  }
  return SDTField.create(SDTFieldType.MAP, mapObj);
};

module.exports.ParseMap = ParseMap;
