const { Enum } = require('solclient-eskit');

/**
 * Effectively a boolean governing TLS downgrade to plain text after authentication.
 * @see {@link solace.SessionProperties#sslConnectionDowngradeTo}
 *
 * @target node
 * @readonly
 * @enum {String}
 * @memberof solace
 * @namespace
 */
const SslDowngrade = {
  /**
   * @description No downgrade, TLS connection remains encrypted.
   * @type {String}
   */
  NONE:      'NONE',
  /**
   * @description TLS connection downgrades to plain text after authentication.
   * USE WITH CAUTION! Message traffic is not encrypted!
   */
  PLAINTEXT: 'PLAIN_TEXT',
};

module.exports.SslDowngrade = Enum.new(SslDowngrade);
