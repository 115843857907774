const { Bits, Convert } = require('solclient-convert');
const { Lazy } = require('solclient-eskit');
const { LOG_ERROR } = require('solclient-log');
const { SMFTransportSessionMessageType } = require('../smf-transport-session-message-types');
const { TransportSMFMessage } = require('../message-objects');

const {
  get: bits,
} = Bits;
const {
  int16ToStr,
  int32ToStr,
} = Convert;
const { lazyValue } = Lazy;

// ========== TSSMF ==========
function remains(dataBuf, offset) {
  return dataBuf.length - offset;
}

function parseTsSmfHdrAt(dataBuf, offset, smfheader) {
  let pos = offset;
  if (remains(dataBuf, pos) < 10) {
    LOG_ERROR('TsSmf parse failed: not enough data, expected at least 10B');
    return false;
  }

  const transportSMFMessage = new TransportSMFMessage();
  transportSMFMessage.smfHeader = smfheader;
  const twobyte = dataBuf.readUInt16BE(pos);
  pos += 2;
  transportSMFMessage.uh = bits(twobyte, 15, 1);
  transportSMFMessage.messageType = bits(twobyte, 8, 7);
  const tsHdrLen = bits(twobyte, 0, 8);
  transportSMFMessage.tsHeaderLength = tsHdrLen;
  transportSMFMessage.sessionId = dataBuf.toString('latin1', pos, pos + 8);
  pos += 8;

  if (transportSMFMessage.messageType ===
      SMFTransportSessionMessageType.CREATE_RESP) {
    // parse extra chunk: routerTag
    const rtrTagLen = dataBuf.readUInt8(pos);
    pos++;
    if (remains(dataBuf, pos) < rtrTagLen) {
      LOG_ERROR(`TsSmf parse failed: not enough data for RouterTag, expected ${rtrTagLen}B`);
      return false;
    }
    transportSMFMessage.routerTag = dataBuf.toString('latin1', pos, pos + rtrTagLen);
    pos += rtrTagLen;
  }

  // FFWD any remaining TsSmf padding?
  pos = offset + tsHdrLen;

  // Length of encapsulated message payload:
  // the SMF msg payload length - bytes consumed in TsSmf

  if (smfheader.payloadLength === 0xffffffff) {
    // special "streaming" unknown-length header
    transportSMFMessage.payloadLength = smfheader.payloadLength;
  } else {
    transportSMFMessage.payloadLength = smfheader.payloadLength - tsHdrLen;
  }
  return transportSMFMessage; // Header with no payload field
}

// Generates an SMF header up to, but not including the the total length
// This is fixed for all client generated transport session messages
const tsHeaderPreLength = lazyValue(() => (
  int32ToStr(0x03140001) +  // SMF version, TransportSession, TTL
  int32ToStr(12)            // Header length
));

const tsDestroyHeaderPreSid = lazyValue(() => (
  tsHeaderPreLength.value + // Header up to the message length field
  int32ToStr(22) +          // Total length
  int16ToStr(0x820a)        // msgType(destroy), length
));

const tsCreateHeader = lazyValue(() => (
  tsHeaderPreLength.value + // Header up to the message length field
  int32ToStr(22) +          // Total length
  int16ToStr(0x800a) +      // msgType(create), length
  int32ToStr(0) +           // Session ID (first half)
  int32ToStr(0)             // Session ID (second half)
));

const tsDataTokenPreSid = lazyValue(() => (
  int32ToStr(0x03940001) +
  int32ToStr(12) +
  int32ToStr(22) +
  int16ToStr(0x850a)
));

const tsDataStreamTokenPreSid = lazyValue(() => (
  int32ToStr(0x03940001) +
  int32ToStr(12) +
  int32ToStr(24) +
  int16ToStr(0x860c)
));

// Generate a full Transport Session Create header
function genTsCreateHeader() {
  return tsCreateHeader.value;
}

// Generate a full Transport Session Destroy header
function genTsDestroyHeader(sid) {
  return (
    tsDestroyHeaderPreSid.value +
    sid                       // Session ID
  );
}

// Generate a data token message
function genTsDataTokenMsg(sid) {
  return (
    tsDataTokenPreSid.value +
    sid
  );
}

// Generate a STREAMING data token message
function genTsDataStreamTokenMsg(sid, paddingBytes) {
  return (
    tsDataStreamTokenPreSid.value +
    sid +
    ((paddingBytes && paddingBytes > 0) ? int16ToStr(paddingBytes) : int16ToStr(0x0000))
  );
}

function genTsDataMsgHeaderParts(sid) {
  return [
    (int32ToStr(0x03940001) + int32ToStr(12)),
    (int16ToStr(0x840a) + sid),
  ];
}

function parseTsSmfMsgAt(dataBuf, offset, smfheader) {
  const transportSMFMessage = parseTsSmfHdrAt(dataBuf, offset, smfheader);
  if (!transportSMFMessage) {
    return null;
  }

    // need to FF to pos
  const pos = offset + transportSMFMessage.tsHeaderLength;

    // Length of encapsulated message payload:
    // the SMF msg payload length - bytes consumed in TsSmf
  if (remains(dataBuf, pos) < transportSMFMessage.payloadLength) {
    LOG_ERROR(`Couldn't read full encapsulated TsSmf payload, expected ${transportSMFMessage.payloadLength}B`);
    return null;
  }

  transportSMFMessage.payload =
                            dataBuf.slice(pos, pos + transportSMFMessage.payloadLength);
  return transportSMFMessage;
}

const Transport = {
  genTsCreateHeader,
  genTsDestroyHeader,
  genTsDataTokenMsg,
  genTsDataStreamTokenMsg,
  genTsDataMsgHeaderParts,
  parseTsSmfHdrAt,
  parseTsSmfMsgAt,
};

module.exports.Transport = Transport;
