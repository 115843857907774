const DestinationUtilLib = require('./destination-util');
const { Destination } = require('./destination');
const { DestinationType } = require('./destination-type');

/**
 * @classdesc
 * Represents a Topic, which is a type of {@link solace.Destination}.
 *
 * @extends solace.Destination
 * @memberof solace
 * @deprecated since 10.0 Applications should not directly refer to this type.
 *  {@link solace.Destination} provides the full interface available to topic, and it can be
 *  determined whether the destination represents a topic if {@link solace.Destination#getType}
 *  returns {@link solace.DestinationType.TOPIC}.
 */
class Topic extends Destination {
  /**
   * @param {Object} spec The topic spec
   * @private
   */
  constructor(spec) {
    if (typeof spec === 'object') {
      super({
        type:             DestinationType.TOPIC,
        name:             spec.name,
        bytes:            spec.bytes,
        offset:           spec.offset,
        isValidated:      spec.isValidated,
        isWildcarded:     spec.isWildcarded,
        subscriptionInfo: spec.subscriptionInfo,
      });
    } else {
      // Deprecated path -- super will do topic encode
      super(spec, DestinationType.TOPIC);
    }
  }

  [util_inspect_custom]() {
    return `[Topic ${this.getName()}]`;
  }

  /**
   * @static
   * @param {String} topicName The name for the topic
   * @returns {Topic} the Topic
   * @private
   */
  static createFromName(topicName) {
    const encoding = DestinationUtilLib.DestinationUtil.validateAndEncode(
      DestinationType.TOPIC, topicName);
    if (encoding.error) {
      throw encoding.error;
    }
    return new Topic({
      name:             topicName,
      isValidated:      true,
      bytes:            encoding.bytes,
      offset:           encoding.offset,
      isWildcarded:     encoding.isWildcarded,
      subscriptionInfo: encoding.subscriptionInfo,
    });
  }
}

module.exports.Topic = Topic;
