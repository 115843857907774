const { Enum } = require('solclient-eskit');

/**
 * The publisher currently isn't exposed, and its events are dispatched
 * via the Session. These events are therefore private. They should
 * be moved to MessageConsumerEventName when they are made public.
 * @private
 */
const MessagePublisherEventName = Enum.new({
  ACKNOWLEDGED_MESSAGE:      'MessagePublisherEventName_acknowledgedMessage',
  CONNECT_FAILED_ERROR:      'MessagePublisherEventName_connectFailedError',
  CAN_SEND:                  'MessagePublisherEventName_canSend',
  DISPOSED:                  'MessagePublisherEventName_disposed',
  DOWN:                      'MessagePublisherEventName_down',
  FLOW_NAME_CHANGED:         'MessagePublisherEventName_flowNameChanged',
  GUARANTEED_MESSAGING_DOWN: 'MessagePublisherEventName_guaranteedMessagingDown',
  REJECTED_MESSAGE:          'MessagePublisherEventName_rejectedMessage',
  DISCONNECT_FAILED_ERROR:   'MessagePublisherEventName_disconnectFailedError',
  UP:                        'MessagePublisherEventName_up',
  TRANSPORT_FULL:            'MessagePublisherEventName_transportFull',
});

module.exports.MessagePublisherEventName = MessagePublisherEventName;
