const { Enum } = require('solclient-eskit');

/**
 * Transport session states, used by WebSocketTransportSession and HTTPTransportSession
 * @enum {number}
 * @private
 */
const TransportSessionState = {
  DOWN:                0,
  WAITING_FOR_CREATE:  1,
  SESSION_UP:          2,
  WAITING_FOR_DESTROY: 4,
  CONNECTION_FAILED:   5,
};

module.exports.TransportSessionState = Enum.new(TransportSessionState);
