const { APIProperties } = require('solclient-util');
const { DestinationType } = require('solclient-destination');
const { OperationError, ErrorSubcode } = require('solclient-error');
const { QueueType } = require('./queue-types');

const DEFAULTS = {
  durable: true,
  type:    undefined,
};

const TYPE_DESCRIPTION = {
  [QueueType.QUEUE]:          'queue',
  [QueueType.TOPIC_ENDPOINT]: 'topic endpoint',
};

const DESINATION_TYPE_TO_DESCRIPTOR_TYPE = {
  [DestinationType.TOPIC]:           null,
  [DestinationType.QUEUE]:           QueueType.QUEUE,
  [DestinationType.TEMPORARY_QUEUE]: QueueType.QUEUE,
};

function maybeAdaptFromDestination(spec) {
  if (spec && spec.name && spec.type && DestinationType.values.includes(spec.type)) {
    const targetType = DESINATION_TYPE_TO_DESCRIPTOR_TYPE[spec.type];
    if (!targetType) {
      throw new OperationError(`Cannot create a descriptor from a ${
                               DestinationType.describe(spec.type)} destination`,
                               ErrorSubcode.PARAMETER_CONFLICT);
    }
    return {
      name:    spec.name,
      type:    QueueType.QUEUE,
      durable: spec.type !== DestinationType.TEMPORARY_QUEUE,
    };
  }
  return spec;
}


/**
 * @classdesc
 * <b>This class is not exposed for construction by API users.</b>
 * <p>
 * This is a base class for {@link solace.QueueDescriptor}. API users should access the
 * methods described here through a {@link solace.QueueDescriptor}.
 * @memberof solace
 * @hideconstructor
 */
class AbstractQueueDescriptor extends APIProperties {
  /*
   * @param {Object|solace.AbstractQueueDescriptor} queueSpec A specification for this descriptor.
   * @param {solace.QueueType} queueSpec.type The type of queue for this specification.
   * @param {Boolean} [queueSpec.durable=true] Whether this spec refers to a durable queue.
   * @constructor
   */
  constructor(queueSpec) { // eslint-disable-line no-useless-constructor
    super(DEFAULTS, maybeAdaptFromDestination(queueSpec));
  }

  /**
   * Gets the queue type to which this descriptor refers.
   * @returns {solace.QueueType} The queue type that this object describes
   */
  getType() {
    return this._type;
  }

  /**
   * @type {solace.QueueType}
   * @description The Queue Type.
   */
  get type() {
    return this.getType();
  }
  set type(value) {
    this._type = value;
  }

  /**
   * Gets whether this descriptor refers to a durable queue.
   *
   * @returns {Boolean} `true` if this describes a durable queue
   */
  isDurable() { // eslint-disable-line class-methods-use-this
    return this._durable;
  }
  /**
   * @type {Boolean}
   * @description True if this descriptor refers to a durable queue.
   */
  get durable() {
    return this.isDurable();
  }
  set durable(value) {
    this._durable = value;
  }

  [util_inspect_custom]() {
    return {
      'type':    this.type,
      'durable': this.durable,
    };
  }

  /**
   * An informational summary of this object, subject to change.
   * @returns {String} A summary of this object.
   */
  toString() {
    return `${!this.isDurable() ? 'non' : ''}-durable ` +
           `${TYPE_DESCRIPTION[this.getType()]}`;
  }

}

module.exports.AbstractQueueDescriptor = AbstractQueueDescriptor;
