const { ReplayStartLocation, ReplayStartType } = require('./replayStartLocation');
/**
 * @classdesc
 *  * <b>This class is not exposed for construction by API users.
 *  Users should obtain an instances from the following:</b>
 *  * {@link solace.SolclientFactory.createReplayStartLocationBeginning}
 *
 * Defines the ReplayStartLocation class.
 * The ReplayStartLocation is set in the corresponding
 * MessageConsumer property {@link solace.MessageConsumerProperties#replayStartLocation}
 *
 * @extends {ReplayStartLocation}
 * @hideconstructor
 * @memberof solace
 */

class ReplayStartLocationBeginning extends ReplayStartLocation {
  /**
   * @private
   */
  constructor() {
    super({
      _type: ReplayStartType.BEGINNING,
    });
  }
  static [util_inspect_custom]() {
    return 'BEGINNING';
  }
}

module.exports.ReplayStartLocationBeginning = ReplayStartLocationBeginning;
