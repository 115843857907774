/* eslint-disable class-methods-use-this */ // declare prototype stubs

/**
 * A logging callback. It must accept any valid number of arguments of any type. It must not throw.
 *
 * @callback
 * @function
 * @name solace.LogImpl.loggingCallback
 * @param {...*} args The arguments to log. Typically this is a mixture of strings and
 *  objects to be inspected. A simple implementation might call .toString() on each
 *  argument.
 */

/**
 * @classdesc
 * A class that provides a binding to a log implementation. Applications that need to
 * control API logging must construct a LogImpl
 * instance, a log implementation that can be set in
 * {@link solace.SolclientFactoryProperties#logger}.
 * The binding will call the supplied log methods with the
 * parameters supplied to each.
 * @memberof solace
 */
class LogImpl {
  /**
   * @param {solace.LogImpl.loggingCallback} trace Callback for {@link solace.LogLevel.TRACE} logs.
   * @param {solace.LogImpl.loggingCallback} debug Callback for {@link solace.LogLevel.DEBUG} logs.
   * @param {solace.LogImpl.loggingCallback} info Callback for {@link solace.LogLevel.INFO} logs.
   * @param {solace.LogImpl.loggingCallback} warn Callback for {@link solace.LogLevel.WARN} logs.
   * @param {solace.LogImpl.loggingCallback} error Callback for {@link solace.LogLevel.ERROR} logs.
   * @param {solace.LogImpl.loggingCallback} fatal Callback for {@link solace.LogLevel.FATAL} logs.
   *
   * @constructor
   */
  constructor(trace, debug, info, warn, error, fatal) {
    Object.assign(this, {
      trace,
      debug,
      info,
      warn,
      error,
      fatal,
    });
  }

  trace() {}
  debug() {}
  info() {}
  warn() {}
  error() {}
  fatal() {}
}

module.exports.LogImpl = LogImpl;
