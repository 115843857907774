const { LogLevel } = require('./log-levels');

const state = {
  impl:  null,
  level: LogLevel.INFO,
};

const LogBinding = {};
LogBinding.getImpl = function getImpl() {
  return state.impl;
};

LogBinding.setImpl = function setImpl(impl) {
  state.impl = impl;
};

LogBinding.getLogLevel = function getLogLevel() {
  return state.level;
};

LogBinding.setLogLevel = function setLogLevel(level) {
  state.level = level;
};

module.exports.GlobalBinding = LogBinding;
