const { ParseInteger } = require('./parse-integer');

const { autoDecodeVarLengthNumber } = ParseInteger;

const ParseFieldHeader = {};
// Parse the header part of an SDT field.
// Returns [TYPE, DECLARED_LENGTH, VALUE_DATA_LENGTH, CONSUMED_BYTES]
ParseFieldHeader.parseFieldHeader = function parseFieldHeader(dataBuf, offset) {
  let pos = offset;
  const onebyte = dataBuf.readUInt8(pos);
  const elemType = (onebyte & 0xFC) >> 2;
  const lenBytes = (onebyte & 0x03) + 1;
  pos++;
  const elemLen = autoDecodeVarLengthNumber(dataBuf, pos, lenBytes);
  pos += lenBytes;
  const elemValLen = elemLen - (1 + lenBytes);
  return [elemType, elemLen, elemValLen, pos - offset];
};

module.exports.ParseFieldHeader = ParseFieldHeader;
