const { Enum } = require('solclient-eskit');

/**
 * An attribue of a {@link solace.Message}. Applications receive messages due to subscriptions on
 * topics, or consumers connected to durable objects.  The MessageCacheStatus of such messages is:
 * {@link solace.MessageCacheStatus.LIVE}.
 *
 * Message are also delivered to an application
 * as a result of a cache request (see {@link solace.CacheSession#sendCacheRequest}) which
 * have a MessageCacheStatus that is {@link solace.MessageCacheStatus.CACHED} or
 * {@link solace.MessageCacheStatus.SUSPECT}.
 *
 * The MessageCacheStatus is retrieved with {@link solace.Message#getCacheStatus}.
 *
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const MessageCacheStatus = {
  /**
   * The message is live.
   * @type {Number}
   */
  LIVE:    0,
  /**
   * The message was retrieveed from a solCache Instance.
   * @type {Number}
   */
  CACHED:  1,
  /**
   * The message was retrieved from a suspect solCache Instance.
   * @type {Number}
   */
  SUSPECT: 2,

};

module.exports.MessageCacheStatus = Enum.new(MessageCacheStatus);
