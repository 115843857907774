const { CacheContext } = require('./cache-context');
const { LOG_DEBUG } = require('solclient-log');

const { CACHE_REQUEST_PREFIX } = CacheContext;

/**
 * @private
 */
class CacheRequest {
  /**
   * @constructor
   * @param {CacheSession} cacheSession The owning session
   * @param {CacheRequestType} cacheMessageType The type of request
   * @param {Number} requestID The ID for the request
   * @param {CacheCBInfo} cbInfo Callback target
   * @param {CacheLiveDataAction} liveDataAction Action on live data received
   * @param {Topic} topic Associated topic
   * @param {String} cacheName Associated remote cache name
   * @private
   */
  constructor(cacheSession, cacheMessageType, requestID, cbInfo, liveDataAction, topic, cacheName) {
    this.cacheSession = cacheSession;
    this.cacheMessageType = cacheMessageType;
    this.requestID = requestID;
    this.cbInfo = cbInfo;
    this.liveDataAction = liveDataAction;
    this.topic = topic;
    this.cacheName = cacheName;

    this.subscriptionWaiting = null;
    this.replyReceived = false;
    this.dataReceived = false;
    this.isSuspect = false;

    this.correlationID = `${CACHE_REQUEST_PREFIX}${CacheContext.cacheRequestCorrelationId++}`;

    this.childRequests = [];
    this.parentRequest = null;

    this.queuedLiveData = [];
    this.liveDataFulfilled = false;

    this.timeoutHandle = null;
  }

  /**
   * @returns {CacheRequest} The root cache request in the current request tree
   * @private
   */
  getRootRequest() {
    if (!this.parentRequest) {
      return this;
    }
    return this.parentRequest.getRootRequest();
  }

  /**
   * @param {CacheRequest} childIn A request to make a descendant of this node in the request tree
   * @private
   */
  addChild(childIn) {
    if (!(childIn instanceof CacheRequest)) {
      throw new Error(`Invalid child ${childIn}`);
    }
    if (childIn === this) {
      throw new Error('Constructing circular child reference');
    }
    const child = childIn;
    child.parentRequest = this;
    this.childRequests.push(child);
  }

  /**
   * @param {CacheRequest} childIn A request to remove as a descendant of this node
   * @private
   */
  removeChild(childIn) {
    if (childIn === this) {
      throw new Error('Attempting to deconstruct invalid circular child reference');
    }
    const child = childIn;
    const childIndex = this.childRequests.indexOf(child);
    if (childIndex === -1) {
      LOG_DEBUG(`Child ${child} not found in ${this}`);
    }
    this.childRequests.splice(childIndex, 1);
    child.parentRequest = null;
  }

  /**
   * @private
   */
  collapse() {
    const parentRequest = this.parentRequest;
    parentRequest.isSuspect = parentRequest.isSuspect || this.isSuspect;
    parentRequest.dataReceived = parentRequest.dataReceived || this.dataReceived;
    parentRequest.removeChild(this);
  }

  /**
   * @private
   */
  cancel() {
    if (this.parentRequest) {
      this.collapse();
    }

    while (this.childRequests.length) {
      const child = this.childRequests.shift();
      if (child.childRequests) {
        child.cancel();
      }
      this.removeChild(child);
    }

    this.clearRequestTimeout();
  }

  /**
   * @returns {Number} The ID of this request
   * @private
   */
  getRequestID() {
    return this.requestID;
  }


  /**
   * @returns {CacheCBInfo} The callback object for this request
   * @private
   */
  getCBInfo() {
    return this.cbInfo;
  }

  /**
   * @returns {Topic} The topic for this request
   * @private
   */
  getTopic() {
    return this.topic;
  }


  /**
   * @returns {CacheLiveDataAction} The live data action for this request
   * @private
   */
  getLiveDataAction() {
    return this.liveDataAction;
  }

  /**
   * @param {function({solace.CacheRequest})} cacheSessionTimeoutCB Callback to notify on timeout
   * @param {Number} timeoutMsec Timeout in milliseconds
   * @private
   */
  startRequestTimeout(cacheSessionTimeoutCB, timeoutMsec) {
    this.timeoutHandle = setTimeout(() => {
      cacheSessionTimeoutCB(this);
    }, timeoutMsec);
  }

  /**
   * @private
   */
  clearRequestTimeout() {
    if (this.timeoutHandle === null || this.timeoutHandle === undefined) {
      return;
    }

    LOG_DEBUG(`Clearing timeout for ${this}`);
    clearTimeout(this.timeoutHandle);
    this.timeoutHandle = null;
  }

  /**
   * Returns a string representing the request.
   * @returns {String} A brief description of this object
   */
  toString() {
    return `CacheRequest[correlationID=${this.correlationID
        },requestID=${this.requestID
        },cacheName=${this.cacheName
        },topic=${this.topic.getName()}]`;
  }

}

/**
 * @private
 */
CacheRequest.VERSION = 1;

/**
 * @private
 */
CacheRequest.DEFAULT_REPLY_SIZE_LIMIT = 1000000;

/**
 * @private
 */
CacheRequest.REPLY_SIZE_LIMIT = CacheRequest.DEFAULT_REPLY_SIZE_LIMIT;

module.exports.CacheRequest = CacheRequest;
