const { SolaceError } = require('./solace-error');

/**
 * @classdesc
 * <b>This class is not exposed for construction by API users.</b>
 * An error thrown when calling an API that has not been implemented.
 * @hideconstructor
 * @extends {solace.SolaceError}
 *
 * @memberof solace
 */
class NotImplementedError extends SolaceError {
  /*
   * Creates an instance of NotImplementedError.
   * @param {any} message The message to include in the error.
   * @memberof NotImplementedError
   */
  constructor(message) {
    super('NotImplementedError', message || '', NotImplementedError);
  }
  /**
   * The name of the error.
   * @name solace.NotImplementedError#name
   * @type {String}
   * @readonly
   * @description 'NotImplementedError'
   */
}

module.exports.NotImplementedError = NotImplementedError;

