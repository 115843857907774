/**
 * @module solace/fsm
 */

const { FsmEvent } = require('./lib/event');
const { State } = require('./lib/state');
const { StateMachine } = require('./lib/state-machine');

module.exports.FsmEvent = FsmEvent;
module.exports.State = State;
module.exports.StateMachine = StateMachine;
