const { ErrorSubcode, OperationError } = require('solclient-error');
const { SDTField } = require('./sdt-field');

/**
 * @classdesc
 * Defines a Structured Data Type (SDT) map container.
 * @memberof solace
 */
class SDTMapContainer {
  /**
   * @constructor
   */
  constructor() {
    this._map = []; // key-value mappings (keys are strings)
  }

  /**
   * Get the list of keys in this map, in unspecified order.
   * @returns {Array.<String>} Array of defined keys in the map.
   */
  getKeys() {
    return Object.keys(this._map);
  }

  /**
   * Return the SDTField with the given key.
   * @param {String} key The key to look up.
   * @returns {solace.SDTField} The field referenced by key.
   */
  getField(key) {
    return this._map[key];
  }

  /**
   * Delete an SDTField with the given key.
   * @param {String} key The field key to delete.
   */
  deleteField(key) {
    delete this._map[key];
  }

  /**
   * Adds a field to this map. If a key:value mapping already exists for this key, it is replaced.
   * <p>
   * @deprecated If <code>typeOrField</code> is a {@link solace.SDTField} instance,
   * it is added to the map.
   *
   * The preferred usage is to pass a {@link solace.SDTFieldType}, then the API will create a
   * SDTField of this type using <code>value</code> before adding it to the map.
   *
   * @param {String} key The key by which to store the given value.
   * @param {solace.SDTField|solace.SDTFieldType} typeOrField A SDTField instance or SDTFieldType.
   * @param {*} [value] The value to wrap as an SDTField.
   * @throws {solace.OperationError} if value does not match type
   * @throws {solace.SDTUnsupportedValueError} if value is not in range
   *  supported by the platform/runtime
   */
  addField(key, typeOrField, value = undefined) {
    if (typeOrField instanceof SDTField) {
      this._map[key] = typeOrField;
      return;
    } else if (typeof value !== 'undefined') {
      this._map[key] = SDTField.create(typeOrField, value);
      return;
    }
    throw new OperationError('Invalid parameters to addField: expected SDTField, or type and value',
      ErrorSubcode.PARAMETER_CONFLICT);
  }
}

module.exports.SDTMapContainer = SDTMapContainer;
