// eslint-disable-next-line global-require
const BufferImpl = require('buffer').Buffer;


function concatFrom(list, from, length) {
  const buffer = BufferImpl.allocUnsafe(length);
  let pos = 0;
  let i;
  const buf0 = list[0];
  pos += buf0.copy(buffer, 0, from, buf0.length);
  for (i = 1; i < list.length && pos < length; i++) {
    const buf = list[i];
    pos += buf.copy(buffer, pos, 0, buf.length);
  }
  return buffer;
}


function readUInt32BEFrag(bufList, listOffsetParam, byteIndexParam) {
  let listOffset = listOffsetParam;
  let byteIndex = byteIndexParam;
  const bytes = []; // the 4 bytes
  let i = 0;
  for (i = 0; i < 4; i++) {
    while (bufList[listOffset].length <= byteIndex) {
      byteIndex -= bufList[listOffset].length;
      listOffset++;
      if (listOffset >= bufList.length) { return null; } // should not happen.
    }
    bytes[i] = bufList[listOffset].readUInt8(byteIndex);
    byteIndex++;
  }
  // shift the bytes into a 32 bit number, BE.
  return (bytes[0] << 24) + (bytes[1] << 16) + (bytes[2] << 8) + bytes[3];
}

/**
 * Simplified FIFO of buffers.
 * Holds transport buffers without copying, allows 12 byte peeks for header and length detection.
 * For pops either a slice is returned if possible,
 * or a copy if the message spans transport buffers.
 * Pushes are refused when over capacity.
 * @internal
 */
class BufferQueue {
  constructor(maxSize) {
    let _queue = [];
    const _maxSize = maxSize;
    let _size = 0;
    let _offset = 0;

    /**
     * Creates a slice (view) of the buffer with the given size. Conceptually.
     * Since slicing is expensive, it just retruns the raw transport buffer with the offset.
     * Buffer - offset pair if possible, copy (with zero offset) if beyond first buffer boundary.
     *
     *
     * @param {Number} size Bytes to view
     * @returns {?Array} Buffer - offset pair if there was enough data to peek, or `null`
     */
    this.peekView = function peekView(size) {
      const result = [];
      if (_queue.length < 1 || size > _size) { return null; }
      if (_queue[0].length >= size + _offset) {
        result[0] = _queue[0];
        result[1] = _offset;
      } else {  //over buffer boundary, must copy.
        result[0] = concatFrom(_queue, _offset, size);
        result[1] = 0;
      }
      return result;
    };

    this.readUInt8 = function readUInt8(index) {
      if (index >= _size) { return null; }
      const oIndex = index + _offset;
      if (oIndex < _queue[0].length) {
        return _queue[0].readUInt8(oIndex);
      } // else
      let pos = _queue[0].length;
      let i = 1;
      while (pos + _queue[i].length <= oIndex) {
        pos += _queue[i].length;
        i++;
      }
      return _queue[i].readUInt8(oIndex - pos);
    };

    this.readUInt32BE = function readUInt32BE(index) {
      if (index + 3 >= _size) { return null; }
      const oIndex = index + _offset;
      if (oIndex + 3 < _queue[0].length) {
        return _queue[0].readUInt32BE(oIndex);
      } // else
      if (oIndex < _queue[0].length) {
        return readUInt32BEFrag(_queue, 0, oIndex);
      }

      let pos = _queue[0].length;
      let i = 1;
      while (pos + _queue[i].length <= oIndex) {
        pos += _queue[i].length;
        i++;
      }
      if (oIndex - pos + 3 < _queue[i].length) {
        return _queue[i].readUInt32BE(oIndex - pos);
      } // else
      return readUInt32BEFrag(_queue, i, oIndex - pos);
    };

    /**
     * Adds a buffer to the queue.
     * Fails if maxsize is exeeded.
     *
     * @param {Buffer} dataIn Source data
     * @returns {Boolean} `true` if the data fits.
     */
    this.put = function put(dataIn) {
      const buffer = BufferQueue.adaptData(dataIn);
      const size = buffer.length;
      if (size === 0) { return true; }

      if (_size + size >= _maxSize) return false;

      _queue.push(buffer);
      _size += size;

      return true;
    };

    this.advance = function advance(size) {
      if (size < 1) {
        return;
      }
      if (size >= _size) {
        this.reset();
        return;
      }
      let done = 0;
      while (done < size) {
        if (_queue[0].length - _offset <= size - done) { // drop first buffer entirely
          const chunk = _queue[0].length - _offset;
          _queue.shift();
          done += chunk;
          _size -= chunk; // invariants are good.
          _offset = 0;
        } else { // cut into first buffer, virtually, with _offset
          _offset += (size - done);
          _size -= (size - done);
          break;
        }
      }
    };

    this.reset = function reset() {
      _queue = [];
      _size = 0;
      _offset = 0;
    };

    this.remaining = function remaining() {
      return _size;
    };

    this.isEmpty = function isEmpty() {
      return _size === 0;
    };
  }

  /**
   * Adapt incoming data to the format expected by this.put().
   * @param {*} data The data to adapt.
   * @returns {Buffer} The adapted data.
   */
  static adaptData(data) {
    if (data instanceof BufferImpl) return data;
    return BufferImpl.from(data);
  }
}

module.exports.BufferQueue = BufferQueue;
module.exports.concatFrom = concatFrom;
