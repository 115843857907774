const { Parameter } = require('solclient-validate');
const { ReplayStartLocation, ReplayStartType } = require('./lib/replayStartLocation');
const { ReplayStartLocationBeginning } = require('./lib/replayStartLocationBeginning');
const { ReplayStartLocationDate } = require('./lib/replayStartLocationDate');
const { SolclientFactory } = require('solclient-factory');


module.exports.ReplayStartLocation = ReplayStartLocation;
module.exports.ReplayStartLocationBeginning = ReplayStartLocationBeginning;
module.exports.ReplayStartLocationDate = ReplayStartLocationDate;
module.exports.ReplayStartType = ReplayStartType;

/**
 * Creates a ReplayStartLocation {@link solace.ReplayStartLocation} instance that
 * when set in MessageConsumerProperties indicates that all messages available
 * in the replay log should be retrieved.
 *
 *
 * @returns {solace.ReplayStartLocation} The newly created ReplayStartLocation
 * @method
 * @name solace.SolclientFactory.createReplayStartLocationBeginning
 */
SolclientFactory.createReplayStartLocationBeginning = SolclientFactory.createFactory(() =>
  new ReplayStartLocationBeginning()
);
/**
 * Creates a ReplayStartLocation {@link solace.ReplayStartLocation} instance that
 * when set in MessageConsumerProperties indicates that only messages spooled
 * in the replay log since the given Date should be retrieved.
 *
 * @param {Date} dateTime The Date object the represents the date and time of the replay
 * start location.  dateTime is always converted to UTC time if not already a UTC time.
 * @returns {solace.ReplayStartLocation} The newly created ReplayStartLocation
 * @method
 * @name solace.SolclientFactory.createReplayStartLocationDate
 */
SolclientFactory.createReplayStartLocationDate = SolclientFactory.createFactory(dateTime =>
  ReplayStartLocationDate.createReplayStartLocationDate(Parameter.isInstanceOf('date', dateTime, Date))
);
