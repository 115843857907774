const { Enum } = require('solclient-eskit');

/**
 * Represents authentication schemes that can be used. The corresponding session
 * property is {@link solace.SessionProperties#authenticationScheme}.
 *
 * @readonly
 * @enum {String}
 * @memberof solace
 * @namespace
 */
const AuthenticationScheme = {
  /**
   * @description Username/Password based authentication scheme.
   * @type {String}
   */
  BASIC:                       'AuthenticationScheme_basic',
  /**
   * @name solace.AuthenticationScheme.CLIENT_CERTIFICATE
   * @default AuthenticationScheme_clientCertificate
   * @description Client-side certificate based authentication scheme.
   * @see {@link solace.SessionProperties#sslPfx}
   * @see {@link solace.SessionProperties#sslPfxPassword}
   * @see {@link solace.SessionProperties#sslPrivateKey}
   * @see {@link solace.SessionProperties#sslPrivateKeyPassword}
   * @see {@link solace.SessionProperties#sslCertificate}
   * @type {String}
   * @target node
   */
  /**
   * @description Client-side certificate based authentication scheme.  The certificate and
   *   private key are provided by the browser.
   * @type {String}
   * @target browser
   */
  CLIENT_CERTIFICATE:          'AuthenticationScheme_clientCertificate',
  /**
   * @deprecated Use {@link solace.AuthenticationScheme.BASIC} instead.
   * @type {String}
   */
  AUTHENTICATION_SCHEME_BASIC: 'AuthenticationScheme_basic',

  /**
   * @deprecated Use {@link solace.AuthenticationScheme.CLIENT_CERTIFICATE} instead.
   * @type {String}
   */
  AUTHENTICATION_SCHEME_CLIENT_CERTIFICATE: 'AuthenticationScheme_clientCertificate',

  /**
   * @default AuthenticationScheme_oauth2
   * @description Oauth2 authentication scheme.
   * @see {@link solace.SessionProperties#accessToken}
   * @see {@link solace.SessionProperties#idToken}
   * @see {@link solace.SessionProperties#issuerIdentifier}
   * @type {String}
   */
  OAUTH2: 'AuthenticationScheme_oauth2',
};

module.exports.AuthenticationScheme = Enum.new(AuthenticationScheme);
