const EncodeSingleElementLib = require('./encode-single-element');
const UtilLib = require('solclient-util');
const { EncodeHeader } = require('./encode-header');
const { SDTDataTypes } = require('../sdt-data-types');
const { SDTMapContainer } = require('../sdt-map-container');

const { encodeHeader } = EncodeHeader;

const EncodeMap = {
  /**
   * Encodes an SDT map.
   * @param  {Object} sdtmap The map to parse
   * @returns {String}        The map in binary string format
   */
  encodeMap(sdtmap) {
    const buf = [];
    if (!(sdtmap instanceof SDTMapContainer)) {
      return null; // skip!
    }
    const keys = sdtmap.getKeys();
    let sdtfield = null;
    let strKeyField = null;
    let strKeyName = null;
    let i;
    for (i = 0; i < keys.length; i++) {
      sdtfield = sdtmap.getField(keys[i]);
      if (sdtfield) {
        // === KEY ===
        strKeyName = UtilLib.StringUtils.nullTerminate(keys[i]);
        strKeyField = encodeHeader(SDTDataTypes.String, strKeyName.length);
        strKeyField += strKeyName;
        buf.push(strKeyField);

        // === VALUE ===
        EncodeSingleElementLib.EncodeSingleElement.encodeSingleElementToBuf(sdtfield, buf);
      }
    } // end iter over keys
    return buf.join('');
  },
};

module.exports.EncodeMap = EncodeMap;
