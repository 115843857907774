const SolclientFactoryLib = require('solclient-factory');
const { APIProperties } = require('solclient-util');
const { Check } = require('solclient-validate');
const { MessagePublisherAcknowledgeMode } = require('./message-publisher-acknowledge-modes');
const { TransportCapabilities } = require('solclient-transport');

function defaultEnablePublisher() {
  const { ProfileBinding } = SolclientFactoryLib;
  const binding = ProfileBinding.value;
  const globallyEnabled = binding.guaranteedMessagingEnabled;
  const webSocketAvailable = TransportCapabilities.web.webSocket();
  return globallyEnabled && webSocketAvailable;
}

function getDefaults() {
  return {
    enabled:                   defaultEnablePublisher(),
    windowSize:                50,
    acknowledgeTimeoutInMsecs: 2000,
    acknowledgeMode:           MessagePublisherAcknowledgeMode.PER_MESSAGE,
    connectRetryCount:         3,
    connectTimeoutInMsecs:     5000,
  };
}

/**
 * @classdesc
 * Properties that define the configuration for a guaranteed message publisher.
 *
 * @memberof solace
 */
class MessagePublisherProperties extends APIProperties {
  /**
   * @constructor
   * @param {Object} options Properties to apply to the newly constructed object.
   */
  constructor(options) {
    super(getDefaults(), options || {});
  }
  /**
   * @name solace.MessagePublisherProperties#enabled
   * @type {Boolean}
   * @description When enabled, a Guaranteed Messaging Publisher
   * is automatically created when a session is connected.
   *
   * The default value is the same as the value provided to
   * {@link solace.SolclientFactory.init},
   * in the profile, {@link solace.SolclientFactoryProperties#profile},
   * in the field {@link solace.FactoryProfile#guaranteedMessagingEnabled}.
   */
  get enabled() {
    return this._enabled;
  }
  set enabled(newValue) {
    this._enabled = newValue;
  }
  /**
   * @name solace.MessagePublisherProperties#windowSize
   * @default 50
   * @type {Number}
   * @description  Maximum number of messages that can be published
   * without acknowledgment.
   *  * The valid range is 1 <= value <= 255
   */
  get windowSize() {
    return Check.defined(this._windowSize)
      ? this._windowSize
      : getDefaults().windowSize;
  }
  set windowSize(newValue) {
    this._windowSize = newValue;
  }
  /**
   * @name solace.MessagePublisherProperties#acknowledgeTimeoutInMsecs
   * @type {Number}
   * @default 2000
   * @description  The time to wait for an acknowledgement,
   * in milliseconds, before retransmitting unacknowledged
   * messages.
   *  * The valid range is 20 <= value <= 60000.
   */
  get acknowledgeTimeoutInMsecs() {
    return Check.defined(this._acknowledgeTimeoutInMsecs)
      ? this._acknowledgeTimeoutInMsecs
      : getDefaults().acknowledgeTimeoutInMsecs;
  }
  set acknowledgeTimeoutInMsecs(newValue) {
    this._acknowledgeTimeoutInMsecs = newValue;
  }
  /**
   * @name solace.MessagePublisherProperties#acknowledgeMode
   * @type {solace.MessagePublisherAcknowledgeMode}
   * @default {@link solace.MessagePublisherAcknowledgeMode.PER_MESSAGE}
   * @description  The message-router sends windowed acknowledgements
   * which the API converts to per-message acknowledgement by default. If
   * acknowledgeMode is Windowed, then the API will simply pass through
   * the message-router acknowledgements.
   */
  get acknowledgeMode() {
    return this._acknowledgeMode || MessagePublisherAcknowledgeMode.PER_MESSAGE;
  }
  set acknowledgeMode(newValue) {
    this._acknowledgeMode = newValue;
  }
  /**
   * @name solace.MessagePublisherProperties#connectRetryCount
   * @type {Number}
   * @default 3
   * @description The number of times to retry a bind (aka open-flow) request
   * before deciding the the Guaranteed Message Publisher cannot be started.
   *  * The valid range 0 <= value.
   * @private
   */
  get connectRetryCount() {
    return Check.defined(this._connectRetryCount)
      ? this._connectRetryCount
      : getDefaults().connectRetryCount;
  }
  set connectRetryCount(newValue) {
    this._connectRetryCount = newValue;
  }
  /**
   * @name solace.MessagePublisherProperties#connectTimeoutInMsecs
   * @type {Number}
   * @default 5000
   * @description  The time to wait for an bind response,
   * in milliseconds, before retransmitting the bind request.
   *  * The valid range is 50 <= value</li>
   * @private
   */
  get connectTimeoutInMsecs() {
    return Check.defined(this._connectTimeoutInMsecs)
      ? this._connectTimeoutInMsecs
      : getDefaults().connectTimeoutInMsecs;
  }
  set connectTimeoutInMsecs(newValue) {
    this._connectTimeoutInMsecs = newValue;
  }

  [util_inspect_custom]() {
    return {
      enabled:                   this.enabled,
      windowSize:                this.windowSize,
      acknowledgeTimeoutInMsecs: this.acknowledgeTimeoutInMsecs,
      acknowledgeMode:           MessagePublisherAcknowledgeMode.describe(this.acknowledgeMode),
      connectRetryCount:         this.connectRetryCount,
      connectTimeoutInMsecs:     this.connectTimeoutInMsecs,
    };
  }
}

module.exports.MessagePublisherProperties = MessagePublisherProperties;
