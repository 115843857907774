const { Enum } = require('solclient-eskit');

/**
 * SMFProtocol
 * @private
 * @enum {number}
 */
const SMFProtocol = {
  CSPF:               0x01,
  CSMP:               0x02,
  PUBMSG:             0x03,
  XMLLINK:            0x04,
  WSE:                0x05,
  SEMP:               0x06,
  SUBCTRL:            0x07,
  PUBCTRL:            0x08,
  ADCTRL:             0x09,
  KEEPALIVE:          0x0a,
  KEEPALIVEV2:        0x0b,
  CLIENTCTRL:         0x0c,
  TRMSG:              0x0d,
  JNDI:               0x0e,
  SMP:                0x0f,
  SMRP:               0x10,
  SMF_IN_SMF:         0x11,
  SMF_IN_RV:          0x12,
  ADCTRL_PASSTHROUGH: 0x13,
  TSESSION:           0x14,

};

module.exports.SMFProtocol = Enum.new(SMFProtocol);
