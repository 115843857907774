const { Enum } = require('solclient-eskit');

/**
 * solace.CacheLiveDataAction
 * Enumeration of CacheLiveDataAction values, specifying how the CacheSession should handle
 * live data associated with a cache request in progress.
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const CacheLiveDataAction = {
  /**
   * @type {Number}
   * @description End the cache request when live data arrives that matches the topic.
   * Note that wildcard cache requests must always be {@link CacheLiveDataAction.FLOW_THRU}.
   */
  FULFILL:   1,
  /**
   * @type {Number}
   * @description Queue arriving live data that matches the topic, until the cache request
   * completes. Note that wildcard cache requests must always be {@link
   * solace.CacheLiveDataAction.FLOW_THRU}.
   */
  QUEUE:     2,
  /**
   * @type {Number}
   * @description Continue the outstanding cache request while allowing live data to flow through to
   * the application.
   * Note that wildcard cache requests must always be {@link CacheLiveDataAction.FLOW_THRU}.
   */
  FLOW_THRU: 3,
};

module.exports.CacheLiveDataAction = Enum.new(CacheLiveDataAction);
