const { Lazy } = require('./lazy');

const { lazyProperty } = Lazy;

function traversePath(commonSource, keySource, pathArr) {
  return pathArr.reduce((source, value) => {
    if (typeof value === 'string') {
      return source[value];
    }
    return value;
  }, keySource);
}

/**
 * @classdesc
 * Deferred resolution for imports. A heavyweight method of breaking circular dependencies.
 * Each specified key (see below) is resolved once by a function that lazily evaluates a
 * path.
 * <pre>
 * const resolved = new Resolver({
 *  foo: [FooLib, 'fooContainer'],        // Resolves foo from FooLib.fooContainer.foo lazily
 *  bar: ['foo'],                         // Resolves bar from previously defined foo lazily
 *  'baz,quux': [FooLib, 'bazsAndQuuxes'] // baz = FooLib.bazsAndQuuxes.baz,
 *                                        // quux = FooLib.bazsAndQuuxes.quux
 * });
 * </pre>
 * In most cases this is equivalent to using FooLib.fooContainer.foo, et cetera, directly.
 * Prefer that approach unless the dependencies are deeply hierarchical. Prefer the Resolver
 * when one-time evaluation and aliasing a path makes the code more DRY and readable.
 * @private
 */
class Resolver {
  constructor(options, source) {
    // For every key...
    Object.keys(options).forEach((compoundKey) => {
      // Split out key names if multiple were provided
      compoundKey.split(',').map(k => k.trim()).forEach((key) => {
        // Build a path array. The key name is the implicit last element.
        const rawPath = options[compoundKey];
        const path = typeof rawPath === 'string' ? rawPath.split('.') : rawPath;
        const pathArr = (Array.isArray(path) ? path : [path]).concat(key);
        // Create a defer for this key.
        lazyProperty(this, key, () => traversePath(source, this, pathArr));
      });
    });
  }

  static resolve(options, source) {
    return new Resolver(options, source);
  }
}


module.exports.Resolver = Resolver;
