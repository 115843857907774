const { ErrorSubcode } = require('./error-subcodes');
const { OperationError } = require('./operation-error');
const { RequestEventCode } = require('./request-event-codes');


const SUBCODE_FROM_EC = {
  [RequestEventCode.REQUEST_ABORTED]: ErrorSubcode.SESSION_NOT_CONNECTED,
  [RequestEventCode.REQUEST_TIMEOUT]: ErrorSubcode.TIMEOUT,
};

/**
 * @classdesc
 * <b>This class is not exposed for construction by API users.</b>
 *
 * Represents a request failure event; request failure events are passed to the application
 * event handling callback provided when sending the request {@link solace.Session#sendRequest}
 * @memberof solace
 * @extends solace.OperationError
 * @hideconstructor
 */
class RequestError extends OperationError {
  /*
   * <b>This class is not exposed for construction by API users.</b> Instances of this object will
   * be passed to the application in calls to the application's
   * {@link solace.Session.requestFailedCallback}, provided when invoking
   * {@link solace.Session.sendReqeust}.
   * @constructor
   * @param {String} message Information string
   * @param {solace.RequestEventCode} eventCode The event code
   * @param {Object} [correlationKey] Any associated correlation key
   * @param {Object} [reason] Any additional information
   * @private
   */
  constructor(message, eventCode, correlationKey, reason) {
    super(message, SUBCODE_FROM_EC[eventCode], reason);
    /**
     * The name of the error.
     * @name solace.RequestError#name
     * @type {String}
     * @readonly
     * @description 'RequestError'
     */
    this.name = 'RequestError';
    this._eventCode = eventCode;
    this._correlationKey = correlationKey;
  }

  /**
   * @type {solace.SessionEventCode}
   * @name solace.RequestError#requestEventCode
   * @description A code that provides more information about the error event.
   */
  get requestEventCode() {
    return this._requestEventCode;
  }

  /**
   * @deprecated Use {@link solace.RequestError#subcode}
   * @type {?solace.ErrorSubcode}
   * @description if applicable, an error subcode. Defined in {@link ErrorSubcode}
   */
  get errorSubcode() {
    return super.subcode;
  }

  [util_inspect_custom]() {
    const superObj = super[util_inspect_custom] ? super[util_inspect_custom]() : {};
    return Object.assign(superObj, {
      'requestEventCode': RequestEventCode.describe(this.requestEventCode),
      'infoStr':          this.infoStr,
      'correlationKey':   this.correlationKey,
    });
  }

  toString() {
    return util_inspect(this);
  }

}

module.exports.RequestError = RequestError;
