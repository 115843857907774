const PublisherLib = require('solclient-message-publisher');
const SessionPropertiesLib = require('./session-properties');
const SMFLib = require('solclient-smf');
const { APIPropertiesValidators, parseURL } = require('solclient-util');
const { AuthenticationScheme } = require('./authentication-schemes');
const { Check } = require('solclient-validate');
const { ErrorSubcode, OperationError } = require('solclient-error');
const { LOG_WARN, LOG_INFO } = require('solclient-log');
const { SslDowngrade } = require('./ssl-downgrades');
const { TransportProtocol } = require('solclient-transport');

const {
  validateInstance,
  valArrayIsMember,
  valArrayOfString,
  valBoolean,
  valLength,
  valNotEmpty,
  valNumber,
  valRange,
  valString,
  valStringOrArray,
} = APIPropertiesValidators;

const ALLOWED_PROTOCOLS = ['http:', 'https:', 'ws:', 'wss:', 'tcp:', 'tcps:'];

function valClientName(typeDesc, instance, name) {
  // valString and valLength(160) have already been called.
  const error =
    SMFLib.ClientCtrlMessage.validateClientName(
      instance[name],
      errorMessage =>
        new OperationError(`${typeDesc} validation: Property '${name}': ${errorMessage}`,
                           ErrorSubcode.PARAMETER_OUT_OF_RANGE));
  if (error) {
    throw error;
  }
}

function valIsMember(typeDesc, instance, key, enumInstance, enumName, allowNull) {
  const val = instance[key];
  if (allowNull && val === null) return;
  if (typeof (val) !== 'undefined' && !enumInstance.values.some(v => v === val)) {
    throw new OperationError(`${typeDesc} validation: Property '${key
                             }' must be a member of ${enumName}`,
                             ErrorSubcode.PARAMETER_INVALID_TYPE);
  }
}

function valSslExcludedProtocols(typeDesc, instance, name) {
  const val = instance[name];
  if (Check.array(val)) {
    if (val.length > 0) {
      const supported = SessionPropertiesLib.SessionProperties.SUPPORTED_SSL_PROTOCOLS;
      val.forEach((protocol) => {
        const prtcl = protocol.toLowerCase();
        if (supported.indexOf(prtcl) < 0) {
          throw new OperationError(`${typeDesc} validation: Property '${name
                                   }' contains unsupported protocol: ${protocol}`,
                                    ErrorSubcode.PARAMETER_OUT_OF_RANGE);
        }
      });
    }
  }
}

function valSslCipherSuites(typeDesc, instance, name) {
  const val = instance[name];
  if (val && Check.string(val) && !Check.empty(val)) {
    const ciphers = val.split(',');
    const supported = SessionPropertiesLib.SessionProperties.SUPPORTED_CIPHER_SUITES;
    ciphers.forEach((cipher) => {
      if (supported.indexOf(cipher.trim()) < 0) {
        throw new OperationError(`${typeDesc} validation: Property '${name
                        }' contains unsupported cipher suite: '${cipher}'`,
                        ErrorSubcode.PARAMETER_OUT_OF_RANGE);
      }
    });
  }
}

// maximum number of common names is 16
function valSslTrustedCommonNameList(typeDesc, instance, name) {
  const val = instance[name];
  if (Check.something(val) && Check.array(val) && val.length > 16) {
    throw new OperationError(`${typeDesc} validation: Property '${name
                }' length exceeds limit of 16`,
                ErrorSubcode.PARAMETER_OUT_OF_RANGE);
  }
}

function valUrlList(typeDesc, instance, name) {
  const val = instance[name];
  const valArray = typeof val === 'string' ? val.split(',') : val;
  if (!Check.array(valArray)) {
    throw new OperationError(`${typeDesc} validation: Property '${name
                              }' not an array or comma-delimited string`,
                              ErrorSubcode.PARAMETER_INVALID_TYPE);
  }
  valArray.forEach((el) => {
    let url = null;
    try {
      url = parseURL(el);
    } catch (ex) {
      throw new OperationError(`${typeDesc} validation: Property '${name
                                }' contained an invalid URL: ${el}`,
                                ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }
    if (!Check.included(url.protocol, ALLOWED_PROTOCOLS)) {
      throw new OperationError(`${typeDesc} validation: Property '${name
                                }' contained a URL'${url.href
                                }' with an invalid protocol: '${url.protocol}'`,
                                ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }
  });
}

function isHttpTransport(transportProtocol) {
  return (transportProtocol && (
          transportProtocol === TransportProtocol.HTTP_BINARY_STREAMING ||
          transportProtocol === TransportProtocol.HTTP_BINARY ||
          transportProtocol === TransportProtocol.HTTP_BASE64));
}

function validatePropsSupportedByTransport(transportProtocol, nonHttpPropsSet) {
  if (nonHttpPropsSet.length > 0 && isHttpTransport(transportProtocol)) {
    const propNames = nonHttpPropsSet.length <= 5 ? nonHttpPropsSet : nonHttpPropsSet.slice(0, 5);
    throw new OperationError(`SessionProperties validation: properties that are not supported by transport protocol ${
                    transportProtocol} have been set: ${propNames}`, ErrorSubcode.PARAMETER_OUT_OF_RANGE);
  }
}

function matchUrl(instance, name, regex, all) {
  const val = instance[name];
  if (val instanceof Array) {
    // host list is used, iterate to find at least one entry
    const arrayLength = val.length;
    for (let i = 0; i < arrayLength; i++) {
      const currententry = val[i];
      if (!all) {
        if (Check.string(currententry) && currententry.match(regex)) {
          return true;
        }
      } else if (all) {
        if (!Check.string(currententry) || !currententry.match(regex)) {
          return false;
        }
      }
    }
    if (!all) {
      return false;
    } else if (all) {
      return true;
    }
  }
  return (Check.string(val) && val.match(regex));
}

function useSsl(instance, name, all) {
  return matchUrl(instance, name, /^(https|wss|tcps):/i, all);
}

const SessionPropertiesValidator = {
  validate(props) {
    // Validation rules: same as JCSMP
    const v = validateInstance.bind(null, 'SessionProperties', props);
    v('url', [valNotEmpty], [valStringOrArray], [valUrlList]);
    v('userName', [valString], [valLength, 189]);
    v('password', [valString], [valLength, 128]);
    v('clientName', [valString], [valLength, 160], [valClientName]);
    v('applicationDescription', [valString], [valLength, 254]);
    v('vpnName', [valString], [valLength, 32]);
    v('connectTimeoutInMsecs', [valNumber], [valRange, 1, Number.MAX_VALUE]);
    v('connectRetriesPerHost', [valNumber], [valRange, -1, Number.MAX_VALUE]);
    v('connectRetries', [valNumber], [valRange, -1, Number.MAX_VALUE]);
    v('reconnectRetries', [valNumber], [valRange, -1, Number.MAX_VALUE]);
    v('reconnectRetryWaitInMsecs', [valNumber], [valRange, 0, 60000]);
    v('readTimeoutInMsecs', [valNumber], [valRange, 1, Number.MAX_VALUE]);
    v('sendBufferMaxSize', [valNumber], [valRange, 1, Number.MAX_VALUE]);
    v('maxWebPayload', [valNumber], [valRange, 100, Number.MAX_VALUE]);
    if (BUILD_ENV.TARGET_BROWSER) {
      v('bufferedAmountQueryIntervalInMsecs', [valNumber], [valRange, 4, Number.MAX_VALUE]);
    }
    v('generateSendTimestamps', [valBoolean]);
    v('generateReceiveTimestamps', [valBoolean]);
    v('includeSenderId', [valBoolean]);
    v('keepAliveIntervalInMsecs', [valNumber], [valRange, 0, Number.MAX_VALUE]);
    v('keepAliveIntervalsLimit', [valNumber], [valRange, 3, Number.MAX_VALUE]);
    v('generateSequenceNumber', [valBoolean]);
    v('subscriberLocalPriority', [valNumber], [valRange, 1, 4]);
    v('subscriberNetworkPriority', [valNumber], [valRange, 1, 4]);
    v('ignoreDuplicateSubscriptionError', [valBoolean]);
    v('ignoreSubscriptionNotFoundError', [valBoolean]);
    v('reapplySubscriptions', [valBoolean]);
    v('noLocal', [valBoolean]);
    v('transportDowngradeTimeoutInMsecs', [valNumber], [valRange, 1, Number.MAX_VALUE]);
    v('idToken', [valString]);
    v('accessToken', [valString]);

    if (props.transportProtocol && props.webTransportProtocolList) {
      throw new OperationError("SessionProperties validation: Property 'transportProtocol' and " +
                               "'webTransportProtocolList' cannot be set at the same time",
                               ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }
    if (props.webTransportProtocolList !== null && props.webTransportProtocolList !== undefined) {
      if (!Array.isArray(props.webTransportProtocolList)) {
        throw new OperationError("Property 'webTransportProtocolList' must be an array if set",
                                 ErrorSubcode.PARAMETER_INVALID_TYPE);
      }
      if (props.webTransportProtocolList.length === 0) {
        throw new OperationError("Property 'webTransportProtocolList' must be non-empty if set",
                                 ErrorSubcode.PARAMETER_OUT_OF_RANGE);
      }
    }

    v('authenticationScheme', [valIsMember, AuthenticationScheme, 'AuthenticationScheme', false]);
    const useClientCert = props.authenticationScheme === AuthenticationScheme.CLIENT_CERTIFICATE;
    if (!useSsl(props, 'url', true) && useClientCert) {
      throw new OperationError("SessionProperties validation: Property 'authenticationScheme' cannot be set to client certificate " +
                               'for unsecured sessions', ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }
    if (Check.equal(props.authenticationScheme, AuthenticationScheme.OAUTH2)) {
      if (!useSsl(props, 'url', true)) {
        throw new OperationError(`SessionProperties validation: Property 'authenticationScheme' ${''
                                 }cannot be set to '${AuthenticationScheme.OAUTH2}' unless the ${''
                                 }session property 'url' is written to use a secure ${''
                                 }communication protocol like tcps or https.`,
                                 ErrorSubcode.PARAMETER_CONFLICT);
      }
      if (Check.empty(props.idToken) && Check.empty(props.accessToken)) {
        throw new OperationError(`SessionProperties validation: Property 'authenticationScheme' ${''
                                 }can be set to ${''
                                 }'${AuthenticationScheme.OAUTH2}' only if there ${''
                                 }is an accompanying token set as a session property. The ${''
                                 }token types that are ${''
                                 }supported for OAuth authentication are OAuth2.0 Access ${''
                                 }Tokens and OpenID Connect ID Tokens. To set an access token ${''
                                 }you can use the accessToken session property. To set an id ${''
                                 }you can use the idToken session property.`,
                                 ErrorSubcode.PARAMETER_CONFLICT);
      }
    } else if (!Check.empty(props.idToken) || !Check.empty(props.accessToken)) {
      LOG_INFO(`SessionProperties validation: Property ${''
               }'authenticationScheme' must be set to ${''
               }'${AuthenticationScheme.OAUTH2}'in order to use either ${''
               } an OAUTH2 access token or an OpenID Connect ID token.`);
    }


    if (BUILD_ENV.TARGET_NODE) {
      // should not happen since transportProtocol and webTransportProtocolList are not public
      if (Check.something(props.transportProtocol) &&
          props.transportProtocol !== TransportProtocol.WS_BINARY) {
        throw new OperationError("SessionProperties validation: properties 'transportProtocol' " +
                                 'can only be WS_BINARY',
                                 ErrorSubcode.PARAMETER_INVALID_TYPE);
      }
      if (Check.something(props.webTransportProtocolList)) {
        if (!Check.array(props.webTransportProtocolList)) {
          throw new OperationError('SessionProperties validation: Property ' +
                                   "'webTransportProtocolList' should be type Array",
                                   ErrorSubcode.PARAMETER_INVALID_TYPE);
        }
        if (props.webTransportProtocolList.length !== 1 ||
            props.webTransportProtocolList[0] !== TransportProtocol.WS_BINARY) {
          throw new OperationError('SessionProperties validation: properties ' +
                                   "'webTransportProtocolList' can only contain element WS_BINARY",
                                   ErrorSubcode.PARAMETER_INVALID_TYPE);
        }
      }

      v('sslExcludedProtocols', [valArrayOfString], [valSslExcludedProtocols]);
      v('sslCipherSuites', [valString], [valSslCipherSuites]);
      v('sslValidateCertificate', [valBoolean]);

      if (props.sslValidateCertificate || useClientCert) {
        v('sslTrustStores', [valArrayOfString]);
        v('sslTrustedCommonNameList', [valArrayOfString], [valSslTrustedCommonNameList]);
      }

      if (useClientCert) {
        v('sslPfx', [valString]);
        v('sslPfxPassword', [valString]);
        v('sslPrivateKey', [valString]);
        v('sslPrivateKeyPassword', [valString]);
        v('sslCertificate', [valString]);
        // either sslPfx or sslPrivateKey and sslCertificate must be specified,
        // but not at the same time
        const sslPfxSet = Check.something(props.sslPfx) && props.sslPfx.length;
        const sslPrivateKeySet = (
          Check.something(props.sslPrivateKey) &&
          props.sslPrivateKey.length
        );
        const sslCertSet = (
          Check.something(props.sslCertificate) &&
          props.sslCertificate.length
        );
        if (!sslPfxSet && !sslPrivateKeySet && !sslCertSet) {
          throw new OperationError('SessionProperties validation: ' +
                                   "Either property 'sslPfx', or 'sslPrivateKey' and 'sslCertificate' " +
                                   'must be set when authenticationScheme is client certificate',
                                   ErrorSubcode.PARAMETER_OUT_OF_RANGE);
        }
        if (sslPfxSet && (sslPrivateKeySet || sslCertSet)) {
          throw new OperationError('SessionProperties validation: ' +
                                   "Property 'sslPfx' can only be set when 'sslPrivateKey' and 'sslCertificate' " +
                                   'are not set',
                                   ErrorSubcode.PARAMETER_OUT_OF_RANGE);
        }
        if ((sslPrivateKeySet && !sslCertSet) || (!sslPrivateKeySet && sslCertSet)) {
          throw new OperationError('SessionProperties validation: ' +
                                   "Property 'sslPrivateKey' and 'sslCertificate' " +
                                   'must be set at the same time',
                                   ErrorSubcode.PARAMETER_OUT_OF_RANGE);
        }
      }

      v('compressionLevel', [valNumber], [valRange, 0, 9]);
      // Compression and web protocols do not mix.
      if (props.compressionLevel > 0) {
        if (props.url instanceof Array) {
          const allTcp = props.url.every(url => Check.string(url) && url.match(/tcps?:/i));
          if (!allTcp) {
            throw new OperationError('SessionProperties validation: Property ' +
                                     "'compressionLevel' is non-zero, " +
                                     'but not all URLs in the host list ' +
                                     'support compression. (tcp:// or tcps:// expected)',
                                     ErrorSubcode.PARAMETER_OUT_OF_RANGE);
          }
        } else if (Check.string(props.url)) {
          if (!props.url.match(/tcps?:/i)) {
            throw new OperationError('SessionProperties validation: Property ' +
                                     "'compressionLevel' is non-zero, " +
                                     'but the url does not ' +
                                     'support compression. (tcp:// or tcps:// expected)',
                                     ErrorSubcode.PARAMETER_OUT_OF_RANGE);
          }
        } else {
          throw new OperationError('SessionProperties validation: Property' +
                                   "'url' must be string or array of strings." +
                                   ` instead got ${props.url} ` +
                                   `of type ${typeof props.url}`,
                                    ErrorSubcode.PARAMETER_OUT_OF_RANGE);
        }
      }

      v('sslConnectionDowngradeTo', [valIsMember, SslDowngrade, 'SslDowngrade', false]);
    }
    v('transportProtocol', [valIsMember, TransportProtocol, 'TransportProtocol', true]);
    v('webTransportProtocolList',
      [valArrayIsMember, TransportProtocol, 'TransportProtocol',
        true, false, false]);

    validatePropsSupportedByTransport(props.transportProtocol,
                                      props.nonHTTPTransportPropsSet);

    if (props.publisherProperties) {
      PublisherLib.MessagePublisherPropertiesValidator.validate(props.publisherProperties);
    }

    // Non-errors

    const recommendedMin = props.defaultConnectTimeoutInMsecs;
    const connectTimeout = props.connectTimeoutInMsecs;
    const transportCount = props.webTransportProtocolList
      ? props.webTransportProtocolList.length
      : 1;
    if (transportCount > 1 && connectTimeout < recommendedMin) {
      LOG_WARN(
        `Connect timeout of ${connectTimeout} msecs is less than default and recommended ` +
        `minimum of ${recommendedMin} msecs for current transport selection. Transport ` +
        'downgrades may not complete.');
    }
  },
};

module.exports.SessionPropertiesValidator = SessionPropertiesValidator;

