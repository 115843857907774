const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SessionOperation = {
  CONNECT:         'CONNECT',
  DISCONNECT:      'DISCONNECT',
  CTRL:            'CTRL',
  SEND:            'SEND',
  QUERY_OPERATION: 'QUERY_OPERATION',
};

module.exports.SessionOperation = Enum.new(SessionOperation);
