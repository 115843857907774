class TimingBucket {
  constructor(name, interval) {
    this.name = name;
    this.interval = interval;
    this.buckets = [];
  }

  get bucketCount() {
    let c = 0;
    for (let i = 0, n = this.buckets.length; i < n; ++i) {
      c += this.buckets[i] || 0;
    }
    return c;
  }

  log(v) {
    if (v === undefined || isNaN(v)) {
      return;
    }
    const normalized = Math.floor(v / this.interval) * this.interval;
    this.buckets[normalized] = this.buckets[normalized] || 0;
    this.buckets[normalized]++;
  }

  toString() {
    const cont = [];
    this.buckets.forEach((i) => {
      cont.push(`${i}: ${this.buckets[i]}`);
    });
    return `{${cont.join(', ')}}`;
  }
}

module.exports.TimingBucket = TimingBucket;
