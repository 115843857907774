const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SMFParameterType = {
  // SMF parameter types 5 bit types
  PADDING:                  0x00,
  PUBLISHER_ID:             0x01,
  PUBLISHER_MSGID:          0x02,
  MESSAGEPRIORITY:          0x03,
  USERDATA:                 0x04,
  USERNAME:                 0x06,
  PASSWORD:                 0x07,
  RESPONSE:                 0x08,
  SUB_ID_LIST:              0x0A,
  GENERIC_ATTACHMENT:       0x0B,
  BINARY_ATTACHMENT:        0x0C,
  DELIVERY_MODE:            0x10,
  ASSURED_MESSAGE_ID:       0x11,
  ASSURED_PREVMESSAGE_ID:   0x12,
  ASSURED_REDELIVERED_FLAG: 0x13,
  MESSAGE_CONTENT_SUMMARY:  0x16,
  ASSURED_FLOWID:           0x17,
  TR_TOPICNAME:             0x18,
  AD_FLOWREDELIVERED_FLAG:  0x19,
  AD_TIMETOLIVE:            0x1C,
  AD_TOPICSEQUENCE_NUMBER:  0x1e,
  EXTENDED_TYPE_STREAM:     0x1f,
  /* extended parameters 12 bit types */
  AD_ACK_MESSAGE_ID:        0x29,
  AD_SPOOLER_UNIQUE_ID:     0x2c,
  AD_REPL_MATE_ACK_MSGID:   0x2d,
  AD_REDELIVERY_COUNT:      0x2e,

  /* light wieght parameters */
  LIGHT_CORRELATION:       0x00,
  LIGHT_TOPIC_NAME_OFFSET: 0x01,
  LIGHT_QUEUE_NAME_OFFSET: 0x02,
  LIGHT_ACK_IMMEDIATELY:   0x03,
};

const SMFExtendedParameterType = {
  // SMFv3 Extended parameters inside the Extended Type Stream
//Not supported yet
//  GSS_API_TOKEN:        0x28,
//  AD_ACK_MESSAGE_ID:    0x29,
//  AD_TRANSACTION_ID:    0x2a,
//  AD_TRANSACTION_FLAGS: 0x2b,
  OAUTH2_ISSUER_IDENTIFIER: 0x2f,
  OIDC_ID_TOKEN:            0x30,
  OAUTH2_ACCESS_TOKEN:      0x31,
};

module.exports.SMFParameterType = Enum.new(SMFParameterType);
module.exports.SMFExtendedParameterType = Enum.new(SMFExtendedParameterType);
