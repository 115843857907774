/* eslint-disable global-require */
const { BaseSMFClient } = require('./base-smf-client');

// Got rid of anonymous function that checked browser and set smf-client
// Now ignoring string-smf-client and setting it straight to buffer-smf-client
// const SMFClient = require('./buffer-smf-client').BufferSMFClient;

const SMFClient = (() => {
  if (typeof navigator !== 'undefined') {
      /* eslint-env browser */
    if (navigator.appVersion.indexOf('MSIE 9.') !== -1 ||
        navigator.appVersion.indexOf('Trident/') !== -1) {
        // Avoid the buffer-based version for IE9, 10, and 11, but not Edge.
      return require('./buffer-smf-client').BufferSMFClient;
    }
  }
  /* eslint-env shared-browser-node */
  return require('./buffer-smf-client').BufferSMFClient;
})();

SMFClient.SMF_CLIENTCTRL_LOGIN_FAKE_CORRELATIONTAG = BaseSMFClient.SMF_MAX_CORRELATION;

module.exports.SMFClient = SMFClient;
