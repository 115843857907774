/**
 * @classdesc
 *
 * Encapsulates the properties of a cache session.
 *
 * @memberof solace
 *
 */
class CacheSessionProperties {

   /**
    * @constructor
    *
    * @param {String} cacheName A property that specifies the cache name to which CacheSession
    *   operations should be sent.
    * @param {Number} [maxAgeSec=0] The maximum allowable message age in seconds to deliver in
    *   response to a cache request. 0 means no restriction on age.
    * @param {Number} [maxMessages=1] The maximum number of messages per Topic to deliver in
    *   response to cache requests. 0 means no restriction on the number of messages.
    * @param {Number} [timeoutMsec=10000] The timeout period (in milliseconds) to wait for a
    *   response from the cache. This is a protocol timer used internally by the API on each
    *   message exchange with SolCache. A single call to
    *   {@link solace.CacheSession#sendCacheRequest} may lead to many request-reply exchanges
    *   with SolCache and so is not bounded by this timer as long as each internal request is
    *   satisfied in time.
    *   * The valid range for this property is >= 3000.
    */
  constructor(cacheName, maxAgeSec, maxMessages, timeoutMsec) {
    /**
     * @type {String}
     *
     * @description A property that specifies the cache name to which CacheSession operations should
     * be sent.
     */
    this.cacheName = cacheName;

    /**
     * @type {Number}
     *
     * @description The maximum allowable message age in seconds to deliver in response to cache
     * requests.  0 means no restriction on age.
     *
     * @default 0
     */
    this.maxAgeSec = maxAgeSec || 0;

    /**
     * @type {Number}
     *
     * @description The maximum number of messages per Topic to deliver in response to cache
     * requests.  0 means no restriction on the number of messages.
     *
     * @default 1
     */
    this.maxMessages = (maxMessages === null || maxMessages === undefined) ? 1 : maxMessages;

    /**
     * @type {Number}
     *
     * @description The timeout for a cache request, in milliseconds.  The valid range for this
     * property is >= 3000.
     *
     * @default 10000
     */
    this.timeoutMsec = timeoutMsec || 10000;

    /**
     * @private
     * @type {Boolean}
     * @description Whether to include other clusters in the request.
     * @default true
     */
    this.includeOtherClusters = true;

    /**
     * @private
     */
    this.cachePrefix = '#P2P/CACHEINST/';
  }

  /**
   * Gets the cache name to which {@link solace.CacheSession} requests should be sent, for
   * sessions constructed using these properties.
   *
   * @returns {String} The cache name.
   */
  getCacheName() {
    return this.cacheName;
  }

  /**
   * Sets the cache name to which requests should be sent. Cannot be null or blank.
   * @param {String} value The cache name to which requests should be sent.
   */
  setCacheName(value) {
    this.cacheName = value;
  }

  /**
   * Gets the maximum allowable message age for messages to be delivered in response to a request
   * made on a {@link solace.CacheSession} that was constructed using these properties.  0 means no
   * restriction on age.
   *
   * @returns {Number} The maximum allowable message age to be returned by an associated
   * {@link solace.CacheSession}, or 0 for no restriction.
   */
  getMaxMessageAgeSec() {
    return this.maxAgeSec;
  }

  /**
   * Sets the maximum allowable message age. 0 means no restriction on age.
   *
   * @param {Number} value The maximum allowable message age, or 0 for no restriction.
   */
  setMaxMessageAgeSec(value) {
    this.maxAgeSec = value;
  }

  /**
   * Gets the maximum count of messages to be delivered, per {@link solace.Destination}, in
   * response to a request issued on a {@link solace.CacheSession} constructed using these
   * properties.  0 means no restriction on the number of messages.
   *
   * @returns {Number} The maximum number of messages per Topic to deliver, or 0 for no restriction.
   */
  getMaxMessages() {
    return this.maxMessages;
  }

  /**
   * Sets the maximum count of messages to be delivered per {@link solace.Destination} in response
   * to a cache request. 0 means no restriction.
   *
   * @param {Number} value The maximum count of messages to deliver, or 0 for no restriction.
   */
  setMaxMessages(value) {
    this.maxMessages = value;
  }

  /**
   * Gets the timeout for requests issued on a {@link solace.CacheSession} constructed
   * using these properties.
   *  * The valid range is >= 3000.
   *
   * @returns {Number} The timeout, in milliseconds, for cache session requests.
   */
  getTimeoutMsec() {
    return this.timeoutMsec;
  }

  /**
   * Sets the timeout for requests.
   *  * The valid range is >= 3000.
   * @param {Number} value The timeout for requests.
   */
  setTimeoutMsec(value) {
    this.timeoutMsec = value;
  }

}

module.exports.CacheSessionProperties = CacheSessionProperties;
