const { EventEmitter } = require('solclient-events');
const { StatType } = require('./stat-types');

/**
 * @private
 */
class Stats extends EventEmitter {
  constructor(parent) {
    super();

    this._parent = parent;
    this._statsMap = [];
    StatType.values.forEach((value) => {
      this._statsMap[value] = 0;
    });
  }

  resetStats() {
    this.emit('reset');
    // The stat types are sequential, so .fill(0) would work, but the API design
    // doesn't really guarantee this so overwriting every existing index is safer.
    this._statsMap = this._statsMap.map(() => 0);
  }

  incStat(statType, value = 1) {
    // should we validate statType?
    this._statsMap[statType] += value;
    if (this._parent) {
      this._parent.incStat(statType, value);
    }
  }

  getStat(statType) {
    return this._statsMap[statType];
  }

}

module.exports.Stats = Stats;
