/**
 * This callback is called by a cache session when a cache request
 * completes.
 *
 * @callback solace.CacheCBInfo.cacheRequestCallback
 * @function
 * @param {Number} requestID The ID of the request on which the event is notified.
 * @param {solace.CacheRequestResult} result The result of the cache request.
 * @param {Object} userObject The user object provided.
 */


/**
 * @classdesc
 * Encapsulates a {@link solace.CacheSession}'s request listener callback function and
 * optional application-specified context object.
 *
 * Instances of this class are required as a parameter to
 * {@link solace.CacheSession#sendCacheRequest} when creating a CacheSession request.
 *
 * @memberof solace
 */
class CacheCBInfo {

  /**
   * Creates an instance of CacheCBInfo using the provided callback and user context object.
   * @param {solace.CacheCBInfo.cacheRequestCallback} cacheCBFunction The callback to be invoked.
   * @param {Object} userObject A context object to be returned with the callback.
   */
  constructor(cacheCBFunction, userObject) {
    /**
     * The function that will be called by the cache session when a request
     * completes.
     * @type {solace.CacheCBInfo.cacheRequestCallback}
     */
    this.cacheCBFunction = cacheCBFunction;

    /**
     * The user context object that will be supplied to the callback function
     * when the cache request completes.
     * @type {object}
     */
    this.userObject = userObject;
  }

  /**
   * @returns {solace.CacheCBInfo.cacheRequestCallback} The callback function
   * @private
   */
  getCallback() {
    return this.cacheCBFunction;
  }

  /**
   * @returns {*} The user context object, if any
   * @private
   */
  getUserObject() {
    return this.userObject;
  }

}

module.exports.CacheCBInfo = CacheCBInfo;
