const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SessionState = {
  /**
   * The session is connecting.
   */
  CONNECTING:    1,
  /**
   * The session is connected.
   */
  CONNECTED:     2,
  /**
   * The session experienced an error.
   */
  SESSION_ERROR: 3,
  /**
   * The session is disconnecting.
   */
  DISCONNECTING: 4,
  /**
   * The session is disconnected.
   */
  DISCONNECTED:  5,

};

module.exports.SessionState = Enum.new(SessionState);
