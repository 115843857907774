const DebugLib = require('solclient-debug');
const { SolaceError } = require('solclient-error');

/**
 * @classdesc
 * <b>This class is not exposed for construction by API users.</b>
 *
 * Represents a SDT unsupported value error.  An SDT field was assigned a value that is within
 * the type range for the given SDT type, but is not supported on this platform/runtime.
 * This occurs when a received {@link solace.SDTContainerMap} or {@link solace.SDTContainerStream}
 * contains a field with a value that can not represented in the local architecture.
 * Possible causes include:
 * * receive 64 bit integer that cannot be represented accurately in a javaScript number. JavaScript
 *   numbers are floats and can only hold a 48 bit integer without loss of precission. Any integer
 *   greater than 281474976710655 or less than -281474976710655 will cause this exception.
 * @hideconstructor
 * @extends solace.SolaceError
 * @memberof solace
 */
class SDTUnsupportedValueError extends SolaceError {
  /*
   * @constructor
   * @param {String} message The message associated with this error
   * @param {SDTValueErrorSubcode} subcode The subcode associated with this error
   * @param {Object} sourceData The original representation of the value
   */
  constructor(message, subcode, sourceData) {
    /**
     * The name of the error.
     * @name solace.SDTUnsupportedValueError#name
     * @type {String}
     * @readonly
     * @description 'SDTUnsupportedValue'
     */
    super('SDTUnsupportedValue', message);
    /**
     * The subcode for the error. see {@link solace.SDTValueErrorSubcode}
     * @name solace.SDTUnsupportedValueError#subcode
     * @type {solace.SDTValueErrorSubcode}
     */
    this.subcode = subcode;
    this.sourceData = sourceData || '';
  }

  inspect() {
    return super.inspect({
      subcode:    null,
      sourceData: v => DebugLib.Debug.formatDumpBytes(v, false, 0),
    });
  }

  getSubcode() {
    return this.subcode;
  }

  getSourceData() {
    return this.sourceData;
  }
}


module.exports.SDTUnsupportedValueError = SDTUnsupportedValueError;
