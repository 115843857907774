/**
 * @classdesc
 * Encapsulates the session's event callback function and an optional user-specified object.
 *
 * This class is passed to {@link solace.SolclientFactory.createSession} when creating a session.
 * @deprecated The {@link solace.Session} is an <b>EventEmitter</b>.  Register event handlers
 * against {@link solace.SessionEventCode} events instead.
 *
 * @memberof solace
 */

class SessionEventCBInfo {

  /**
   * Creates an instance of SessionEventCBInfo.
   *
   * @param {function(Session, SessionEvent, Object, Object)} sessionEventCBFunction
   *  invoked by the Messaging API when a session event occurs. The prototype
   *  of this function is the following: ({@link solace.Session},
   *                                      {@link solace.SessionEvent},
   *                                      {Object}})
   * @param {Object} userObject An optional user-specified object passed on
   * every session event callback.
   */
  constructor(sessionEventCBFunction, userObject) {
    /**
     * @type {Object}
     * @description user-specified object
     */
    this.userObject = userObject;

    /**
     * @type {function}
     * @description The prototype of this function is the
     * following:
     * ({@link solace.Session}, {@link solace.SessionEvent},
     * userObject {Object}, RFUObject {Object})
     */
    this.sessionEventCBFunction = sessionEventCBFunction;
  }
}

module.exports.SessionEventCBInfo = SessionEventCBInfo;
