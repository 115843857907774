const DebugLib = require('solclient-debug');
const SolclientDestinationLib = require('solclient-destination');
const SolclientMessageLib = require('solclient-message');
const SolclientSDTLib = require('solclient-sdt');
const { BinaryMetaBlock, KeepAliveMessage } = require('../message-objects');
const { ContentSummaryType } = require('./content-summary-types');
const { Hex, Long } = require('solclient-convert');
const { Lazy } = require('solclient-eskit');
const { LogFormatter } = require('solclient-log');
const { parseAdpAt } = require('./adprotocol');
const { parseCCAt } = require('./client-ctrl');
const { ParseSMF } = require('./parse-smf');
const { PriorityUserCosMap } = require('./priority-user-cos-map');
const { SMFProtocol } = require('../smf-protocols');
const { SMP } = require('./smp');
const { Transport } = require('./transport');

const { formatHexString } = Hex;
const { lazyValue } = Lazy;
const { parseSMFAt } = ParseSMF;
const { parseSMPAt } = SMP;
const { parseTsSmfMsgAt } = Transport;

const logger = new LogFormatter('[smf-decode]');
const { LOG_DEBUG,
        LOG_ERROR } = logger;

const userCosForPriority = lazyValue(() => new PriorityUserCosMap().reverse);

const BIN_STRUCTYPES = {
  0x0A: SolclientMessageLib.MessageType.MAP,
  0x0B: SolclientMessageLib.MessageType.STREAM,
  0x07: SolclientMessageLib.MessageType.TEXT,
};

function adaptBinaryMetaToMessage(binaryMeta, messageIn) {
  const message = messageIn;
  const messageSdt = SolclientSDTLib.Codec.parseSingleElement(binaryMeta.payload, 0);

  if (!(messageSdt && messageSdt.getType() === SolclientSDTLib.SDTFieldType.STREAM)) {
    return;
  }

  const sdtstream = messageSdt.getValue();
  let sdtfield = sdtstream.getNext();
  if (sdtfield && sdtfield.getType() === SolclientSDTLib.SDTFieldType.BYTEARRAY
    && sdtfield._value && sdtfield._value.length > 0) {
    // Preamble byte array is present
    const preambleByte0 = sdtfield._value.readUInt8(0);
    if ((preambleByte0 & 0x80) === 0) {
      // structured message: override default "BIN" message type
      message._messageType = (BIN_STRUCTYPES[preambleByte0 & 0x0F] ||
                              SolclientMessageLib.MessageType.BINARY);
    }
    if (sdtfield._value.length > 1) {
      const preambleByte1 = sdtfield._value.readUInt8(1);
      message.setAsReplyMessage((preambleByte1 & 0x80) !== 0);
    }
  }

  sdtfield = sdtstream.getNext();
  if (sdtfield && sdtfield.getType() === SolclientSDTLib.SDTFieldType.MAP) {
    const sdtMap = sdtfield.getValue();
    const p = sdtMap.getField('p');
    const h = sdtMap.getField('h');
    if (p) {
      message.setUserPropertyMap(p.getValue());
    }
    if (h) {
      const headerMap = h.getValue();
      const ci = headerMap.getField('ci');
      const mi = headerMap.getField('mi');
      const mt = headerMap.getField('mt');
      const rt = headerMap.getField('rt');
      const si = headerMap.getField('si');
      const sn = headerMap.getField('sn');
      const ts = headerMap.getField('ts');
      const ex = headerMap.getField('ex');
      if (ci) {
        message.setCorrelationId(ci.getValue());
      }
      if (mi) {
        message.setApplicationMessageId(mi.getValue());
      }
      if (mt) {
        message.setApplicationMessageType(mt.getValue());
      }
      if (rt) {
        message.setReplyTo(rt.getValue());
      }
      if (si) {
        message.setSenderId(si.getValue());
      }
      if (sn) {
        message.setSequenceNumber(sn.getValueNoThrow());
      }
      if (ts) {
        message.setSenderTimestamp(ts.getValue());
      }
      if (ex) {
        message.setGMExpiration(ex.getValue());
      }
    }
  }
}

function adaptSmfToMessage(smfHeader, messageIn, stream, offset) {
  const message = messageIn;
  message._setDeliverToOne(!!smfHeader.smf_dto);
  message._setDeliveryMode(smfHeader.pm_deliverymode ||
                          SolclientMessageLib.MessageDeliveryModeType.DIRECT);
  if (smfHeader.pm_tr_topicname_bytes !== null) {
    message._setDestination(
      SolclientDestinationLib.DestinationFromNetwork.createDestinationFromBytes(
        smfHeader.pm_tr_topicname_bytes));
  }
  message._setDiscardIndication(!!smfHeader.smf_di);
  message._setElidingEligible(!!smfHeader.smf_elidingEligible);
  message._setDMQEligible(!!smfHeader.smf_deadMessageQueueEligible);
  message._setUserCos(userCosForPriority.value.get(smfHeader.smf_priority));
  message._setPriority(smfHeader.pm_msg_priority);
  if (smfHeader.pm_userdata) message._setUserData(smfHeader.pm_userdata);

  message.setRedelivered(!!smfHeader.pm_ad_redelflag || !!smfHeader.pm_ad_flowredelflag);

  message.setFlowId(smfHeader.pm_ad_flowid);
  message.setGuaranteedMessageId(smfHeader.pm_ad_msgid);
  message.setGuaranteedPreviousMessageId(smfHeader.pm_ad_prevmsgid);
  message.setPublisherId(smfHeader.pm_ad_publisherid);
  message.setPublisherMessageId(smfHeader.pm_ad_publishermsgid);
  message.setTopicSequenceNumber(smfHeader.pm_ad_topicSequenceNumber);
  if (message.getDeliveryMode() === SolclientMessageLib.MessageDeliveryModeType.DIRECT) {
    message.setDeliveryCount(-1);
  } else if (smfHeader.pm_ad_redeliveryCount) {
    message.setDeliveryCount(smfHeader.pm_ad_redeliveryCount + 1);
  } else { // AD, but delivery count header not present
    // Only the flow knows whether the qEndpointBehaviour DC flag was set,
    // so it overrides the value before passing it to the user with -1 if it wasn't.
    message.setDeliveryCount(1);
  }

  // set the suid for the message if present
  if (smfHeader.pm_ad_spooler_unique_id) {
    message._setSpoolerUniqueId(smfHeader.pm_ad_spooler_unique_id);
  }
  // set the message id of the replication group message id using
  // ASSURED_DELIVERY_REPLICATION_MATE_ACK_MESSAGE_ID if present
  // otherwise use ASSURED_DELIVERY_ACK_MESSAGE_ID if present
  // otherwise let the Mesage.getReplicationGroupMessageId use
  // the value set by message.setGuaranteedMessageId
  if (smfHeader.pm_ad_replication_mate_ack_message_id) {
    message._setSpoolerMessageId(smfHeader.pm_ad_replication_mate_ack_message_id);
  } else if (smfHeader.pm_ad_local_spooler_message_id) {
    message._setSpoolerMessageId(smfHeader.pm_ad_local_spooler_message_id);
  }

  if (Long.isLong(smfHeader.pm_ad_ttl)) {
    message.setTimeToLive(smfHeader.pm_ad_ttl.toNumber());
  } else {
    message.setTimeToLive(smfHeader.pm_ad_ttl);
  }

  // Copy content into fields (from input bytes)
  const payloadOffset = offset + smfHeader.headerLength;
  const cs = smfHeader.pm_content_summary;

  if (!(cs && cs.length)) {
    // No content-summary, assume binary attachment
    message._setBinaryAttachment(smfHeader.payloadLength > 0
                                ? stream.slice(payloadOffset,
                                               payloadOffset + smfHeader.payloadLength)
                                : undefined);
    return;
  }

  for (let i = 0, n = cs.length; i < n; ++i) {
    const currentChunk = cs[i];
    const chunkBegin = payloadOffset + currentChunk.position;
    const chunkEnd = payloadOffset + currentChunk.position + currentChunk.length;
    switch (currentChunk.type) {
      case ContentSummaryType.BINARY_ATTACHMENT:
        message._setBinaryAttachment(stream.slice(chunkBegin, chunkEnd));
        break;
      case ContentSummaryType.BINARY_METADATA:
        {
          const binaryMeta = BinaryMetaBlock.fromEncodedSmf(stream, chunkBegin);
          message.binaryMetadataChunk = binaryMeta;
          if (binaryMeta.type === 0) {
            // we have SDT JMS metadata
            adaptBinaryMetaToMessage(binaryMeta, message);
          }
          break;
        }
      case ContentSummaryType.XML_META:
        message._setXmlMetadata(stream.toString('latin1', chunkBegin, chunkEnd));
        break;
      case ContentSummaryType.XML_PAYLOAD:
        message._setXmlContentInternal(stream.toString('latin1', chunkBegin, chunkEnd));
        break;
      default:
        LOG_ERROR(`Unhandled ContentSummaryType: ${ContentSummaryType.describe(currentChunk.type)}`);
    }
  }
}

function decodeCompoundMessage(dataBuf, pos) {
  const header = parseSMFAt(dataBuf, pos);
  if (!header) {
    LOG_DEBUG('decodeCompoundMessage: SMF parsing failed');
    return null;
  }
  // the parser determined there was a full SMF message
  const payloadPosition = pos + header.headerLength;
  const payloadLen = header.payloadLength;
  let message;
  switch (header.smf_protocol) {
    case SMFProtocol.TSESSION:
      message = parseTsSmfMsgAt(dataBuf, payloadPosition, header);
      if (!message) break;

      message.smfHeader = header;
      return message;

    case SMFProtocol.TRMSG:
      message = new SolclientMessageLib.Message();
      message._smfHeader = header;
      adaptSmfToMessage(header, message, dataBuf, pos);
      return message;

    case SMFProtocol.ADCTRL:
      message = parseAdpAt(dataBuf, payloadPosition, payloadLen);
      message.smfHeader = header;
      return message;

    case SMFProtocol.CLIENTCTRL:
      message = parseCCAt(dataBuf, payloadPosition, payloadLen);
      if (!message) break;

      message.smfHeader = header;
      return message;

    case SMFProtocol.SMP:
      message = parseSMPAt(dataBuf, payloadPosition);
      if (!message) break;

      message.smfHeader = header;
      return message;

    case SMFProtocol.KEEPALIVE:
    case SMFProtocol.KEEPALIVEV2:
      message = new KeepAliveMessage();
      message.smfHeader = header;
      return message;

    default:
      LOG_ERROR(`Unknown protocol: 0x${formatHexString(header.smf_protocol)}, ` +
                `dump message content: \n${
                DebugLib.Debug.formatDumpBytes(dataBuf.slice(pos,
                                                             pos + header.messageLength).toString('latin1'),
                                               true, 0)}`);
      break;
  }
  return null;
}

const Decode = {
  decodeCompoundMessage,
};

module.exports.Decode = Decode;
