
const WINDOWS_VERSION_MAP = {
  '6.4':  '10.0',
  '6.3':  '8.1',
  '6.2':  '8',
  '6.1':  '7',
  '6.0':  'Vista',
  '5.2':  'Server 2003',
  '5.1':  'XP',
  '5.01': '2000 SP1',
  '5.0':  '2000',
  '4.0':  '4.0',
};

const NODE_OS_TYPES = {
  Darwin:     'OSX',
  Windows_NT: 'Windows',
};

const context = typeof window !== 'undefined' ? window : global;

const DEFAULT = {
};

const NODE_PROCESS = {
  product:  'solclientjs',
  platform: 'node',
  agent:    'node',
  process:  context.process,
};

// Worst case fallbacks
const BROWSER_PROCESS = {
  product:  'solclientjs',
  platform: 'unknown',
  agent:    'Gecko',
};

function getBrowserAgentInfo() {
  /* eslint-env browser */
  const navObj = typeof navigator !== 'undefined' ? navigator : null;
  if (!navObj) return null;
  const agentStr = navigator.userAgent;

  // Execute these regex checks against the user agent string in this order.
  // The earlier browsers have a lot of impersonation substrings in their user
  // agent list so we positively test for them before considering the browsers
  // that are frequently impersonated.
  const browsers = [
    ['edge', /Edge\/([0-9._]+)/],
    ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/],
    ['firefox', /Firefox\/([0-9.]+)(?:\s|$)/],
    ['opera', /Opera\/([0-9.]+)(?:\s|$)/],
    ['opera', /OPR\/([0-9.]+)(:?\s|$)$/],
    ['ie', /Trident\/7\.0.*rv:([0-9.]+).*\).*Gecko$/],
    ['ie', /MSIE\s([0-9.]+);.*Trident\/[4-8].0/], // Edit: IE11 in IE9 emu mode uses Trident 8
    ['ie', /MSIE\s(7\.0)/],
    ['bb10', /BB10;\sTouch.*Version\/([0-9.]+)/],
    ['android', /Android\s([0-9.]+)/],
    ['ios', /Version\/([0-9._]+).*Mobile.*Safari.*/],
    ['safari', /Version\/([0-9._]+).*Safari/],
  ];

  const agentInfo = {
    browser:  { name: 'unknown', version: '0.0.0' },
    platform: { os: 'unknown', arch: 'unknown', version: 'unknown' },
  };

  const agentHas = (...strs) => strs.some(str => agentStr.indexOf(str) >= 0);
  const firstValue = (...vals) => vals.filter(Boolean).shift();

  Object.assign(
    agentInfo, // start with fallbacks above
    // 1. Run the callback below for each browser name/regex-rule pair.
    // 2. Decompose the pair into variables called `key` and `rule`.
    browsers.map(([key, rule]) => {
      // 3. Skip if the rule doesn't match the user agent string.
      if (!rule.test(agentStr)) return false;
      // 4. Get the matches for the rule.
      const match = rule.exec(agentStr);
      // 5. Extract the version from match group 1.
      const versionStrs = match && match[1].split(/[._]/).slice(0, 3);
      // 6. Convert the version strings to numbers.
      const version = versionStrs.map(str => parseInt(str, 10));
      // 7. Ensure our version tuple is length 3 by appending 0 elements.
      while (version.length < 3) version.push(0);
      // 8. Return an agentInfo object in the same form as above, consuming the version array.
      return { browser: { name: key, version: version.join('.') } };
    }).filter(Boolean).shift()); // 9. Filter out anything that returned `false` (#3)...
    // 10. ...and return the first element.

  // Now try to extract platform info
  const os = firstValue(
    agentHas('Windows Phone') && 'WindowsPhone',
    agentHas('Windows') && 'Windows',
    agentHas('Linux') && 'Linux',
    agentHas('like Mac OS X') && 'iOS',
    agentHas('OS X') && 'OSX',
    agentHas('Android', 'Adr') && 'Android',
    agentHas('BB10', 'RIM Tablet OS', 'BlackBerry') && 'BlackBerry'
  );
  const versionTests = {
    'Windows': () => firstValue(
      agentHas('Win16') && '3.1.1',
      agentHas('Windows CE') && 'CE',
      agentHas('Windows 95') && '4.00.950',
      agentHas('Windows 98; Win 9x 4.90') && '4.90',
      agentHas('Windows 98') && '4.10',
      (() => {
        // Look in the first set of parentheses for a version string key.
        // This will fail when Windows 16 comes out
        const parenParts = agentStr.match(/\(.+?\)/);
        const versionPart = parenParts[0];
        if (!versionPart) return false;
        const versionMatch = str => versionPart.indexOf(str) >= 0;
        return firstValue(...Object.keys(WINDOWS_VERSION_MAP).map(k =>
            versionMatch(k) && WINDOWS_VERSION_MAP[k]));
      })()
    ),
    'OSX':        () => agentStr.match(/OS X ((\d+[._])+\d+)\b/)[1],
    'Linux':      () => '',
    'iOS':        () => agentStr.match(/OS ((\d+[._])+\d+) like Mac OS X/)[1],
    'Android':    () => agentStr.match(/(?:Android|Adr) ((\d+[._])+\d_)/)[1],
    'BlackBerry': () => agentStr.match(/(?:Version\/|RIM Tablet OS )((\d+\.)+\d+)/)[1],
  };
  const versionTest = versionTests[os];
  agentInfo.platform.os = os || 'Unknown';
  agentInfo.platform.version = (versionTest && versionTest() || '0.0.0').replace(/_/g, '.');
  return agentInfo;
}
/* eslint-env shared-browser-node */

try {
  if (BUILD_ENV.TARGET_BROWSER && context.navigator) {
    // These are historical defaults
    const safeEncode = str => str.replace(/[^a-zA-Z0-9_/.]/g, '-');
    Object.assign(BROWSER_PROCESS, {
      platform:    context.navigator.platform,
      agent:       context.navigator.product,
      description: safeEncode(context.navigator.userAgent),
      navigator:   context.navigator,
    });
    const browserAgentInfo = getBrowserAgentInfo();
    if (browserAgentInfo) {
      BROWSER_PROCESS.agent = safeEncode(`${browserAgentInfo.browser.name
                                           }-${browserAgentInfo.browser.version}`);
      BROWSER_PROCESS.platform = `${BROWSER_PROCESS.agent}-${safeEncode(
        `${browserAgentInfo.platform.os}-${browserAgentInfo.platform.version}`)}`;
    }
  }
  if (BUILD_ENV.TARGET_NODE && context.process) {
  //if (false) {
    const os = require('os'); // eslint-disable-line global-require
    const ostype = os.type();
    const osname = NODE_OS_TYPES[ostype] || ostype || 'unknown';
    const nodeVersionStr = `node-${process.version.substr(1)}`;
    Object.assign(NODE_PROCESS, {
      platform:    `${nodeVersionStr}-${osname}-${os.release()}-${os.arch()}`,
      agent:       nodeVersionStr,
      description: nodeVersionStr,
    });
  }
} catch (ex) {
  // Fall back to defaults
}

const Process = Object.assign({}, DEFAULT,
                              BUILD_ENV.TARGET_BROWSER ? BROWSER_PROCESS : {},
                              BUILD_ENV.TARGET_NODE ? NODE_PROCESS : {});

module.exports.Process = Process;
