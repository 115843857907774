const { Enum } = require('solclient-eskit');

/**
 * An enumeration of consumer acknowledgement modes. The corresponding
 * MessageConsumer property {@link solace.MessageConsumerProperties#acknowledgeMode}
 * configures how acknowledgments are generated for received Guaranteed messages.
 *
 * When received messages are acknowledged they are removed from the Guaranteed
 * Message storage on the Solace Message Router. Message Consumer acknowledgements,
 * <b>only</b> remove messages from the Solace Message Router.
 *
 * In particular, withholding Message Consumer Acknowledgemnts does not stop
 * message delivery. For Message Consumer flow control see
 * {@link solace.MessageConsumer.stop}/{@link solace.MessageConsumer.start}. Message Consumer
 * flow control may also be imlpemented by removing the
 * {@link solace.MessageConsumerEventName#event:MESSAGE} listener.
 *
 * @memberof solace
 * @enum {string}
 * @namespace
 */
const MessageConsumerAcknowledgeMode = {
  /**
   * The API automatically acknowledges any message that was delivered to all
   * {@link solace.MessageConsumerEventName#event:MESSAGE} listeners with no exception thrown
   * on any of them.
   */
  AUTO:   'AUTO',
  /**
   * The API acknowledges a message only when the application calls
   * {@link solace.Message#acknowledge}.
   */
  CLIENT: 'CLIENT',
};

module.exports.MessageConsumerAcknowledgeMode = Enum.new(MessageConsumerAcknowledgeMode);
