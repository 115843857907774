module.exports.WebSocketCloseCodes = {
  0: {
    name:        'Unknown code',
    description: 'No status code was returned by the operation',
  },
  1000: {
    name:        'Normal Closure',
    description: 'The connection closed normally',
  },
  1001: {
    name:        'Going Away',
    description: 'The endpoint is going away due to a server failure or client navigation',
  },
  1002: {
    name:        'Protocol Error',
    description: 'A WebSocket protocol error occurred',
  },
  1003: {
    name:        'Unsupported Data',
    description: 'The endpoint cannot handle the specified data type',
  },
  1004: {
    name:        'Reserved',
    description: '',
  },
  1005: {
    name:        'No Status Recvd',
    description: 'Expected a status code but none was provided',
  },
  1006: {
    name:        'Abnormal Closure',
    description: 'No close frame was received before remote hangup',
  },
  1007: {
    name:        'Invalid Frame Payload Data',
    description: 'A message contained data inconsistent with its encoding',
  },
  1008: {
    name:        'Policy Violation',
    description: 'A message violated endpoint policy',
  },
  1009: {
    name:        'Message Too Big',
    description: 'A data frame was too large',
  },
  1010: {
    name:        'Missing Extension',
    description: 'The endpoint did not negotiate an expected extension',
  },
  1011: {
    name:        'Internal Error',
    description: 'The server encountered an unexpected condition that prevented it from fulfilling the request',
  },
  1012: {
    name:        'Service Restart',
    description: 'The server is restarting',
  },
  1013: {
    name:        'Try Again Later',
    description: 'The server is terminating the connection due to a temporary condition',
  },
  1014: {
    name:        'Bad Gateway',
    description: 'A gateway or proxy received an invalid response from the upstream server',
  },
  1015: {
    name:        'TLS Handshake',
    description: 'The connection was closed due to a failure to perform a TLS handshake',
  },
};
