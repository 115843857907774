/**
 * @private
 */
class BidiMap {
  constructor(...pairs) {
    this.forward = new Map();
    this.reverse = new Map();
    pairs.forEach((p) => {
      this.setValues(p[0], p[1]);
    });
  }

  /**
   * Sets the key => value and value => key relationships for the supplied parameters.
   *
   * Method name is #set, not #put, for alignment with ES map method names.
   * @param {*} key The key for the forward mapping (value in the reverse mapping)
   * @param {*} value The value for the forward mapping (key in the reverse mapping)
   */
  setValues(key, value) {
    this.forward.set(key, value);
    this.reverse.set(value, key);
  }

}

module.exports.BidiMap = BidiMap;
