const { StatType } = require('./stat-types');

module.exports = {
  StatsByMode: {
    STAT_TX_BYMODE_MSGS: [
      StatType.TX_DIRECT_MSGS,
      StatType.TX_PERSISTENT_MSGS,
      StatType.TX_NONPERSISTENT_MSGS,
    ],
    STAT_TX_BYMODE_BYTES: [
      StatType.TX_DIRECT_BYTES,
      StatType.TX_PERSISTENT_BYTES,
      StatType.TX_NONPERSISTENT_BYTES,
    ],
    STAT_TX_BYMODE_REDELIVERED: [
      StatType.TX_DIRECT_MSGS,     // unused placeholder, direct messsages are not redelivered
      StatType.TX_PERSISTENT_REDELIVERED,
      StatType.TX_NONPERSISTENT_REDELIVERED,
    ],
    STAT_TX_BYMODE_BYTES_REDELIVERED: [
      StatType.TX_DIRECT_BYTES,     // unused placeholder, direct messsages are not redelivered
      StatType.TX_PERSISTENT_BYTES_REDELIVERED,
      StatType.TX_NONPERSISTENT_BYTES_REDELIVERED,
    ],
    STAT_RX_BYMODE_MSGS: [
      StatType.RX_DIRECT_MSGS,
      StatType.RX_PERSISTENT_MSGS,
      StatType.RX_NONPERSISTENT_MSGS,
    ],
    STAT_RX_BYMODE_BYTES: [
      StatType.RX_DIRECT_BYTES,
      StatType.RX_PERSISTENT_BYTES,
      StatType.RX_NONPERSISTENT_BYTES,
    ],
  },
};
