const Bits = {
  get(val, shift, numBits) {
    return (val >>> shift) & ((0x01 << numBits) - 1);
  },

  set(dataIn, val, shift, numBits) {
    const curMask = (1 << numBits) - 1;
    const shiftedVal = (val & curMask) << shift;
    const data = dataIn & ~(curMask << shift);
    return (data | shiftedVal);
  },
};

module.exports.Bits = Bits;
