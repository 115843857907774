const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const TransportReturnCode = {
  OK:                          0,
  FAIL:                        1,
  NO_SPACE:                    2,
  DATA_DECODE_ERROR:           3,
  INVALID_STATE_FOR_OPERATION: 4,
  CONNECTION_ERROR:            5,
};

module.exports.TransportReturnCode = Enum.new(TransportReturnCode);

/* eslint-disable */
// Manually demangle these names; bug 70031
module.exports.TransportReturnCode._setCanonical({
  'OK':                          TransportReturnCode.OK,
  'FAIL':                        TransportReturnCode.FAIL,
  'NO_SPACE':                    TransportReturnCode.NO_SPACE,
  'DATA_DECODE_ERROR':           TransportReturnCode.DATA_DECODE_ERROR,
  'INVALID_STATE_FOR_OPERATION': TransportReturnCode.INVALID_STATE_FOR_OPERATION,
  'CONNECTION_ERROR':           TransportReturnCode.CONNECTION_ERROR
});
/* eslint-enable */
