const { DestinationType } = require('./destination-type');
const { DestinationUtil } = require('./destination-util');
const { Queue } = require('./queue');
const { Topic } = require('./topic');

const QUEUE_PREFIX = '#P2P/QUE/';
const QUEUE_PREFIX_LEN = QUEUE_PREFIX.length;
const TEMPORARY_QUEUE_PREFIX = '#P2P/QTMP/';

function createDestinationFromName(networkTopicName, networkTopicBytes = undefined) {
  if (networkTopicName === null || networkTopicName.length === 0) {
    return null;
  }

  const spec = {
    name:  networkTopicName,
    bytes: networkTopicBytes || DestinationUtil.encodeBytes(networkTopicName),
  };
  if (networkTopicName[0] === '#') {
    if (networkTopicName.startsWith(QUEUE_PREFIX)) {
      const offset = QUEUE_PREFIX_LEN;
      spec.name = networkTopicName.substr(offset);
      spec.type = DestinationType.QUEUE;
      spec.offset = offset;
      return new Queue(spec);
    } else if (networkTopicName.startsWith(TEMPORARY_QUEUE_PREFIX)) {
      spec.name = networkTopicName;
      spec.type = DestinationType.TEMPORARY_QUEUE;
      spec.offset = 0;
      return new Queue(spec);
    }
  }
  return new Topic(spec);
}

function createDestinationFromBytes(networkTopicBytes) {
  if (networkTopicBytes === null || networkTopicBytes.length === 0) {
    return null;
  }

  const networkTopicName = DestinationUtil.decodeBytes(networkTopicBytes);
  return createDestinationFromName(networkTopicName, networkTopicBytes);
}

const DestinationFromNetwork = {
  createDestinationFromBytes,
  createDestinationFromName,
};

module.exports.DestinationFromNetwork = DestinationFromNetwork;
