const { ErrorSubcode } = require('solclient-error');
const { Hex } = require('solclient-convert');
const { TransportSessionEventCode } = require('./transport-session-event-codes');

const { formatHexString } = Hex;

/**
 * @classdesc
 * Defines a Transport Session Event
 *
 * @private
 */
class TransportSessionEvent {

  /**
   * @constructor
   * @param {Object} tsEventCode The {@link TransportSessionEventCode} associated with this event
   * @param {String} infoStr An informational string
   * @param {?Number} responseCode The response code from the router, if any
   * @param {ErrorSubcode} subcode The {@link ErrorSubcode} associated with this event
   * @param {Number} sessionId The session ID associated with this event
   *
   */
  constructor(tsEventCode, infoStr, responseCode, subcode, sessionId) {
    this._transportEventCode = tsEventCode;
    this._infoStr = infoStr;
    this._responseCode = responseCode;
    this._errorSubcode = subcode;
    this._sid = sessionId;
  }

  // TransportSessionEvent functions

  getTransportEventCode() {
    return this._transportEventCode;
  }

  get transportEventCode() {
    return this._transportEventCode;
  }

  getInfoStr() {
    return this.infoStr;
  }

  get infoStr() {
    return this._infoStr;
  }

  getResponseCode() {
    return this.responseCode;
  }

  get responseCode() {
    return this._responseCode;
  }

  getSubcode() {
    return this.errorSubcode;
  }

  get errorSubcode() {
    return this._errorSubcode;
  }

  getSessionId() {
    return this.sessionId;
  }

  get sessionId() {
    return this._sid;
  }

  [util_inspect_custom]() {
    return {
      'transportEventCode': TransportSessionEventCode.describe(this.transportEventCode),
      'infoStr':            this.infoStr,
      'responseCode':       this.responseCode,
      'errorSubcode':       ErrorSubcode.describe(this.errorSubcode),
      'sid':                this.sid && formatHexString(this.sid) || 'N/A',
    };
  }

  toString() {
    return util_inspect(this);
  }

}

module.exports.TransportSessionEvent = TransportSessionEvent;
