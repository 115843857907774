function flatten(array) {
  return array.reduce((acc, el) => acc.concat(Array.isArray(el) ? flatten(el) : el), []);
}

function includes(array, el) {
  return array.some(v => v === el);
}

module.exports.ArrayUtils = {
  flatten,
  includes,
};
