/**
 * @private
 * @constructor
 */
class TransportClientStats {
  constructor() {
    this.bytesWritten = 0;
    this.msgWritten = 0;
  }
}

module.exports.TransportClientStats = TransportClientStats;
