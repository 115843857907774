/**
 * A tuple associating subscriptions, correlation IDs and cache sessions.
 * @param {String} correlationID The correlation ID for this tuple
 * @param {Topic} topic The topic for this tuple
 * @param {CacheSession} cacheSession The cache session for this tuple
 * @private
 */
class CacheSessionSubscribeInfo {
  constructor(correlationID, topic, cacheSession) {
    Object.assign(this, {
      correlationID,
      topic,
      cacheSession,
    });
  }
}

module.exports.CacheSessionSubscribeInfo = CacheSessionSubscribeInfo;
