const { Check } = require('solclient-validate');
const { ErrorSubcode, OperationError } = require('solclient-error');

const APIPropertiesValidators = {
  validateInstance(typeDesc, instance, name, ...args) {
    args.forEach((check) => {
      const validator = check.shift();
      const validatorArgs = [typeDesc, instance, name, ...check];
      validator(...validatorArgs);
    });
  },

  valInstance(typeDesc, instance, name, typeInstance, typeInstanceDesc) {
    if (!Check.instanceOf(instance[name], typeInstance)) {
      throw new OperationError(`${typeDesc} validation: Property '${name
                               }' must be instance of ${typeInstanceDesc}`);
    }
  },

  valNotEmpty(typeDesc, instance, name) {
    if (Check.none(instance[name]) || instance[name] === '') {
      throw new OperationError(`${typeDesc} validation: Property '${name
                             }' cannot be empty.`,
                             ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }
  },

  valLength(typeDesc, instance, name, max) {
    if (Check.string(instance[name]) && instance[name].length > max) {
      throw new OperationError(`${typeDesc} validation: Property '${name
                             }' exceeded max length ${max}`,
                             ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }
  },

  valRange(typeDesc, instance, name, min, max) {
    if (Check.number(instance[name]) && (instance[name] < min || instance[name] > max)) {
      throw new OperationError(`${typeDesc} validation: Property '${name
                              }' out of range [${min}; ${max}].`,
                              ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }
  },

  valString(typeDesc, instance, name) {
    if (!Check.string(instance[name])) {
      throw new OperationError(`${typeDesc} validation: Property '${name
                             }' must be type string; was ${typeof instance[name]}`,
                             ErrorSubcode.PARAMETER_INVALID_TYPE);
    }
  },

  valNumber(typeDesc, instance, name) {
    if (!Check.number(instance[name])) {
      throw new OperationError(`${typeDesc} validation: Property '${name
                             }' must be type number; was ${typeof instance[name]}`,
                             ErrorSubcode.PARAMETER_INVALID_TYPE);
    }
  },

  valBoolean(typeDesc, instance, name) {
    const val = instance[name];
    if (!Check.boolean(val)) {
      throw new OperationError(`${typeDesc} validation: Property '${name
                             }' must be type boolean; was ${typeof val}`,
                             ErrorSubcode.PARAMETER_INVALID_TYPE);
    }
  },

  valIsMember(typeDesc, instance, key, enumInstance, enumName, allowNull = false) {
    const val = instance[key];
    if (allowNull && (val === null || val === undefined)) return;
    if (enumInstance.values.indexOf(val) >= 0) return;
    throw new OperationError(`${typeDesc} validation: Property '${key
                             }'=${val} must be a member of ${enumName}`,
                             ErrorSubcode.PARAMETER_INVALID_TYPE);
  },

  valStringOrArray(typeDesc, instance, name) {
    const val = instance[name];
    if (typeof val !== 'string' && !Array.isArray(val)) {
      throw new OperationError(`${typeDesc} validation: Property '${name
                                }' must be a string or array`,
                                ErrorSubcode.PARAMETER_INVALID_TYPE);
    }
  },

  valArrayIsMember(typeDesc, instance, name, enumInstance, enumName,
                   allowUndefined, allowEmpty, allowDuplicate) {
    const val = instance[name];
    if ((val === undefined || val === null)) {
      if (allowUndefined) {
        return;
      }
      throw new OperationError(`${typeDesc} validation: Property '${name
                              }' must be type Array`,
                              ErrorSubcode.PARAMETER_INVALID_TYPE);
    }

    if (!Array.isArray(instance[name])) {
      throw new OperationError(`${typeDesc} validation: Property '${name
                              }' must be type Array`,
                              ErrorSubcode.PARAMETER_INVALID_TYPE);
    }
    if (!allowEmpty && instance[name].length === 0) {
      throw new OperationError(`${typeDesc} validation: Property '${name
                              }' cannot be empty`,
                              ErrorSubcode.PARAMETER_INVALID_TYPE);
    }

    instance[name].forEach((ele, index) => {
      if (!enumInstance.values.includes(ele)) {
        throw new OperationError(`${typeDesc} validation: Property '${name
                                }' must be an array of ${enumName}`,
                                ErrorSubcode.PARAMETER_INVALID_TYPE);
      }
      if (!allowDuplicate) {
        if (instance[name].indexOf(ele, index + 1) >= 0) {
          throw new OperationError(`${typeDesc} validation: Property '${name
                          }' cannot have duplicate element value`,
                          ErrorSubcode.PARAMETER_OUT_OF_RANGE);
        }
      }
    });
  },

  valArrayOfString(typeDesc, instance, name) {
    const val = instance[name];
    if (Check.something(val)) {
      if (!Array.isArray(val)) {
        throw new OperationError(`${typeDesc} validation: Property '${name
                               }' must be type Array`,
                               ErrorSubcode.PARAMETER_INVALID_TYPE);
      }
      val.forEach((ele) => {
        if (typeof ele !== 'string') {
          throw new OperationError(`${typeDesc} validation: Property '${name
                                 }' must be an array of string`,
                                 ErrorSubcode.PARAMETER_INVALID_TYPE);
        }
      });
    }
  },

  valTopicString(typedesc, instance, name) {
    // Pardon this late import. I need to break a circular dependency.
    // eslint-disable-next-line global-require
    const { DestinationUtil, DestinationType } = require('solclient-destination');
    module.exports.APIPropertiesValidators.valString(typedesc, instance, name);
    const val = instance[name];
    const result = DestinationUtil.validateAndEncode(DestinationType.TOPIC, val);
    if (result.error) {
      throw new OperationError(`${typedesc} validation: Property '${name}' must be ` +
                               `a valid topic string: ${result.error}`,
                              ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }
  },

  valTopicStringOrEmpty(typedesc, instance, name) {
    const val = instance[name];
    if (val && val.length) {
      module.exports.APIPropertiesValidators.valTopicString(typedesc, instance, name);
    }
  },
};

module.exports.APIPropertiesValidators = APIPropertiesValidators;
