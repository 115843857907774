const { Enum } = require('solclient-eskit');

/********************************************************************************
 * @private
 * Events on the Publisher FSM
 * @enum {string}
 ********************************************************************************/
const PublisherFSMEventNames = {
  SESSION_UP:       'PublisherSessionUp',
  SESSION_UP_NO_AD: 'PublisherSessionUpNoAD',
  SESSION_DOWN:     'PublisherSessionDown',
  FLOW_FAILED:      'MessagePublisherFailed',
  FLOW_UP:          'MessagePublisherUp',
  FLOW_CLOSE:       'MessagePublisherClose',
  FLOW_UNBOUND:     'MessagePublisherUnbound',
  TRANSPORT_FULL:   'PublisherTransportFull',
  ACK:              'PublisherAck',
  ACK_TIMEOUT:      'PublisherAckTimeout',
  BIND_TIMEOUT:     'PublisherBindTimeout',
  UNBIND_TIMEOUT:   'PublisherUnbindTimeout',
  CAN_SEND:         'PublisherCanSend',
  TRANSPORT_ERROR:  'PublisherTransportError',
  RESEND_COMPLETE:  'PublisherResendComplete',
  DISPOSE:          'PublisherDispose',
};

module.exports.PublisherFSMEventNames = Enum.new(PublisherFSMEventNames);
