/**
 * @classdesc
 *
 * Encapsulates the session's message receive callback function and
 * an optional user-specified object.
 *
 * This class is passed to {@link solace.SolclientFactory.createSession} when creating a session.
 * @deprecated The {@link solace.Session} is an <b>EventEmitter</b>.
 * Use <tt>sessionObject.on({@link solace.SessionEventCode.MESSAGE})</tt> instead.
 *
 * @memberof solace
 */
class MessageRxCBInfo {

  /**
   * Creates an instance of MessageRxCBInfo.
   *
   * @param {function(Session, Message, Object)} messageRxCBFunction
   *          Invoked by the API when a message is received
   *          over the session. The prototype of this function is the
   *          following: ({@link solace.Session},
   *                      {@link solace.Message},
   *                      {Object})
   * @param {Object} userObject
   *          An optional user-specified object passed on every message receive callback.
   *
   * @memberof solace
   */
  constructor(messageRxCBFunction, userObject) {
    /**
     * @type {function(Session, Message, Object)}
     * @description The prototype of this function is the
     * following: ({@link solace.Session}, {@link solace.Message}, userObject {Object})
     */
    this.messageRxCBFunction = messageRxCBFunction;

    /**
     * @type {Object}
     * @description user-specified object
     */
    this.userObject = userObject;
  }
}

module.exports.MessageRxCBInfo = MessageRxCBInfo;
