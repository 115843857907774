const { Enum } = require('solclient-eskit');

/**
 * Represents an enumeration of message dump formats. It controls
 * the output of {@link solace.Message#dump}.
 *
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const MessageDumpFlag = {
  /**
   * Display only the length of the binary attachment, XML content and user property maps.
   */
  MSGDUMP_BRIEF: 0,
  /**
   * Display the entire message contents.
   */
  MSGDUMP_FULL:  1,
};

module.exports.MessageDumpFlag = Enum.new(MessageDumpFlag);
