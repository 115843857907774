const { DestinationFromNetwork, DestinationType, Queue } = require('solclient-destination');
const { LOG_DEBUG, LOG_INFO } = require('solclient-log');
const { SDTDestType } = require('../sdt-destination-types');
const { SDTField } = require('../sdt-field');
const { SDTFieldType } = require('../sdt-field-types');
const { Topic } = require('solclient-destination');

const ParseDestination = {};
ParseDestination.parseDestination = function parseDestination(dataBuf, pos, len) {
  const destType = dataBuf.readUInt8(pos);
  const destBytes = dataBuf.toString('latin1', pos + 1, pos + len);
  // first try to create a destination from the string, this handles strings with
  // the preamble ('#P2P/QUE' or '#P2P/TQUE').
  let destination = DestinationFromNetwork.createDestinationFromBytes(destBytes);
  if (SDTDestType[destination.type] !== destType) {
    // SDT destination type does not match the string format. This means either:
    //    * that the string contains a queue preamble and the type is topic,
    //      so just make it a topic
    //    * that the type is queue and the string does not have the preamble, which means we
    //      should construct a queue.
    if (SDTDestType[DestinationType.QUEUE] === destType) {
      destination = Queue.createFromLocalName(destBytes); //createFromLocal has issue with buffer
    } else if (SDTDestType[DestinationType.TOPIC] === destType) {
      LOG_DEBUG(`Found and allowed SDT field with  destination type ${destType} when decoding ${destBytes} to ${destination.type}`);
      //
      // override the format and set destType as specified in the SMF (i.e. TOPIC)
      destination = new Topic(destBytes);
    } else {
      LOG_INFO(`Drop SDT field with invalid destination type ${destType} when decoding ${destBytes} to ${destination.type}`);
      return null;
    }
  }
  return SDTField.create(SDTFieldType.DESTINATION, destination);
};

module.exports.ParseDestination = ParseDestination;
