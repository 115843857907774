/**
 * Created by rpaulson on 06/02/2017.
 */
const { Enum } = require('solclient-eskit');

/********************************************************************************
 * @private
 * Events on the Consumer FSM
 * @enum {string}
 ********************************************************************************/
const ConsumerFSMEventNames = {
  SESSION_UP:                 'SESSION_UP',
  SESSION_UP_NO_AD:           'SESSION_UP_NO_AD',
  SESSION_DOWN:               'SESSION_DOWN',
  SESSION_DISCONNECT:         'SESSION_DISCONNECT',
  FLOW_FAILED:                'FLOW_FAILED',
  FLOW_UP:                    'FLOW_UP',
  FLOW_ACTIVE_IND:            'FLOW_ACTIVE_IND',
  FLOW_CLOSE:                 'FLOW_CLOSE',
  FLOW_OPEN:                  'FLOW_OPEN',
  FLOW_UNBOUND:               'FLOW_UNBOUND',
  ACK:                        'ACK',
  ACK_TIMEOUT:                'ACK_TIMEOUT',
  BIND_TIMEOUT:               'BIND_TIMEOUT',
  CREATE_TIMEOUT:             'CREATE_TIMEOUT',
  UNBIND_TIMEOUT:             'UNBIND_TIMEOUT',
  CAN_SEND:                   'CAN_SEND',
  TRANSPORT_ERROR:            'TRANSPORT_ERROR',
  DISPOSE:                    'DISPOSE',
  VIRTUALROUTER_NAME_CHANGED: 'VIRTUALROUTER_NAME_CHANGED',
  RECONNECT_INTERVAL_TIMEOUT: 'RECONNECT_INTERVAL_TIMEOUT',
  BIND_RESPONSE:              'BIND_RESPONSE',
  CREATE_FAILED:              'CREATE_FAILED',
  CREATE_SUCCESS:             'CREATE_SUCCESS',
};

module.exports.ConsumerFSMEventNames = Enum.new(ConsumerFSMEventNames);
