/**
 * @private
 * @static
 */
const CacheContext = {
  /**
   * A prefix appearing on the request IDs of all requests associated with a Solcache session.
   * @type {String}
   * @private
   */
  CACHE_REQUEST_PREFIX: '#CRQ',

  /**
   * A global counter generating unique correlation IDs for cache requests.
   * @private
   */
  cacheRequestCorrelationId: 0,
};

module.exports.CacheContext = CacheContext;
