const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const CacheGetResultCode = {
  INVALID: 0,
  OK:      1,
};

module.exports.CacheGetResultCode = Enum.new(CacheGetResultCode);
