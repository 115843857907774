const { Base64, Convert } = require('solclient-convert');
const { XHRFactory } = require('./xhr-factory');

const { encode: base64Encode } = Base64;
const { stringToUint8Array } = Convert;

function sendXhrBinaryMSIE10(xhr, data, contentType) {
  xhr.responseType = 'arraybuffer';
  xhr.overrideMimeType(`${contentType}; charset=x-user-defined`);
  xhr.setRequestHeader('Content-Type', `${contentType}; charset=x-user-defined`);
  xhr.send(stringToUint8Array(data));
}

function sendXhrBinaryXHR2(xhr, data, contentType) {
  xhr.overrideMimeType(`${contentType}; charset=x-user-defined`);
  xhr.setRequestHeader('Content-Type', `${contentType}; charset=x-user-defined`);
  xhr.send(stringToUint8Array(data).buffer);
}

function sendXhrText(xhr, data, contentType, connClose) {
  xhr.setRequestHeader('Content-Type', `${contentType}; charset=x-user-defined`);
  xhr.send(data === null || data === undefined ? data : base64Encode(data), connClose);
}

const sendXhrBinary = (() => {
  // Uint8Array is probably shimmed by core-js. We don't use window.Blob, but
  // it is a Working Draft, not  part of ES6, and not shimmed by core-js.
  // Therefore it makes a workable canary for IE10 detection.
  if (typeof window !== 'undefined' && window.Uint8Array && window.Blob /* !ie9 */) {
    const xhr = XHRFactory.create(true);
    if (xhr.responseType /* ie10 */) {
      return sendXhrBinaryMSIE10;
    }
    return sendXhrBinaryXHR2;
  }
  return sendXhrText;
})();

module.exports.sendXhrBinary = sendXhrBinary;
module.exports.sendXhrText = sendXhrText;
