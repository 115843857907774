
const { ErrorSubcode } = require('solclient-error');
const { FsmEvent, State, StateMachine } = require('solclient-fsm');
const { LogFormatter } = require('solclient-log');
const { TransportReturnCode } = require('../transport-return-codes');
const { TransportSessionEvent } = require('../transport-session-event');
const { TransportSessionEventCode } = require('../transport-session-event-codes');
const { WebTransportEvent } = require('./web-transport-events');
const { WebTransportState } = require('./web-transport-states');

const {
  LOG_TRACE,
  LOG_INFO,
} = new LogFormatter();

class WebTransportFSM extends StateMachine {
  constructor(transportIn, getId) {
    super({ name: 'WebTransportFSM' });
    const transport = transportIn;
    const fsm = this;
    const logger = new LogFormatter();
    logger.formatter = function logFormatter(...args) {
      return [`[web-transport-fsm=${getId()}]`, ...args];
    };
    this.log = logger.wrap(this.log, this);
    this.transport = transport;

    this.initial(function onInitial() {
      return this.transitionTo(
        this.WebTransportDown,
        (context) => {
          LOG_TRACE(`Starting ${context.getStateMachine().getName()}`);
        });
    });

    this.unhandledEventReaction(function onUnhandledEvent(wEvent) {
      LOG_TRACE(`Ignoring event ${wEvent.getName()} in state ${this.getCurrentState().getName()}`);
      return this;
    });

    this.WebTransportDown = new State({
      name:          WebTransportState.DOWN,
      parentContext: this,
    })
      .reaction(WebTransportEvent.CONNECT, function onConnect(/* wEevent */) {
        return this.transitionTo(fsm.WebTransportConnecting);
      })
      .reaction(WebTransportEvent.DESTROY, function onDestroy(wEvent) {
        transport.destroyInternal(wEvent._destroyMsg, wEvent._subcode);
        return this.transitionTo(fsm.WebTransportDestroying);
      });

    this.WebTransportConnecting = new State({
      name:          WebTransportState.CONNECTING,
      parentContext: this,
    })
      .entry(() => {
        try {
          const rc = transport.connectInternal();
          if (rc !== TransportReturnCode.OK) {
            const connError = transport.getConnError();
            const wEvent = new FsmEvent({ name: WebTransportEvent.DESTROY });
            wEvent._destroyMsg = connError ? connError.message : 'Error occurred while establishing transport';
            wEvent._subcode = connError ? connError.subcode : null;
            wEvent._eventReason = connError;
            return this.processEvent(wEvent);
          }
        } catch (e) {
          LOG_INFO(`transport.connectInternal threw: ${e.message}`);
          const wEvent = new FsmEvent({ name: WebTransportEvent.DESTROY });
          wEvent._destroyMsg = e.message;
          wEvent._subcode = e.subcode ? e.subcode : ErrorSubcode.CONNECTION_ERROR;
          wEvent._eventReason = e;
          return this.processEvent(wEvent);
        }
        return undefined;
      })
      // Transport destroy is async, so in downgrade cases we need an event to start the downgrade
      // (and associated transport destroy) and one to complete it (DESTROYED_NOTICE).
      // These two events trigger downgrade: SEND_ERROR on connection (protocol issue) and
      // CONNECT_TIMEOUT (possible black hole proxy).
      .reaction(WebTransportEvent.SEND_ERROR, (wEvent) => {
        transport.notifyEvent(wEvent._transportEvent); // Give the session a chance to intervene
        return fsm.attemptDowngrade(wEvent._transportEvent);
      })
      .reaction(WebTransportEvent.CONNECT_TIMEOUT, e => fsm.attemptDowngrade(e._transportEvent))
      .reaction(WebTransportEvent.DESTROYED_NOTICE, (wEvent) => {
        transport.notifyEvent(wEvent._transportEvent);
        return this.transitionTo(fsm.WebTransportDown);
      })
      .reaction(WebTransportEvent.UP_NOTICE, function onUpNotice(wEvent) {
        transport.notifyEvent(wEvent._transportEvent);
        return this.transitionTo(fsm.WebTransportUp);
      })
      .reaction(WebTransportEvent.DESTROY, function onDestroy(wEvent) {
        transport.destroyInternal(wEvent._destroyMsg, wEvent._subcode);
        return this.transitionTo(fsm.WebTransportDestroying);
      });
    this.WebTransportDowngrading = new State({
      name:          WebTransportState.DOWNGRADING,
      parentContext: this,
    })
      .reaction(WebTransportEvent.DESTROYED_NOTICE, function onDestroyed(wEvent) {
        LOG_INFO('Web transport: request downgrade');
        if (!transport.completeDowngrade()) {
          LOG_INFO('Web transport: connection error, no downgrade');
          transport.notifyEvent(wEvent._transportEvent);
          fsm.notifyDowngradeFailed();
          return this.transitionTo(fsm.WebTransportDown);
        }
        return this.transitionTo(fsm.WebTransportConnecting);
      })
      .reaction(WebTransportEvent.DESTROY, function onDestroy(wEvent) {
        transport.destroyInternal(wEvent._destroyMsg, wEvent._subcode);
        return this.transitionTo(fsm.WebTransportDestroying);
      });
    this.WebTransportUp = new State({
      name:          WebTransportState.UP,
      parentContext: this,
    })
      .reaction(WebTransportEvent.DOWNGRADE, wEvent =>
        fsm.attemptDowngrade(new TransportSessionEvent(wEvent._downgradeMsg, wEvent._subcode)))
      .reaction(WebTransportEvent.DESTROYED_NOTICE, function onDestroyed(wEvent) {
        transport.notifyEvent(wEvent._transportEvent);
        return this.transitionTo(fsm.WebTransportDown);
      })
      .reaction(WebTransportEvent.DESTROY, function onDestroy(wEvent) {
        transport.destroyInternal(wEvent._destroyMsg, wEvent._subcode);
        return this.transitionTo(fsm.WebTransportDestroying);
      })
      .reaction(WebTransportEvent.SEND_ERROR, function onUpNotice(wEvent) {
        transport.notifyEvent(wEvent._transportEvent);
        transport.destroyInternal(wEvent._destroyMsg, wEvent._subcode);
        return this.transitionTo(fsm.WebTransportDestroying);
      });
    this.WebTransportDestroying = new State({
      name:          WebTransportState.DESTROYING,
      parentContext: this,
    })
      .reaction(WebTransportEvent.DESTROYED_NOTICE, function onDestroyed(wEvent) {
        transport.notifyEvent(wEvent._transportEvent);
        return this.transitionTo(fsm.WebTransportDown);
      });
  }


    /**
     * Attempt a downgrade. This is the procedure.
     * 1. Call this. If it returns true, wait for DESTROYED_NOTICE, then
     * 2. Call transport.completeDowngrade().
     * @param {TransportSessionEvent} tsEvent The event triggering the downgrade
     * @returns {Boolean} `true` if downgrade is starting (by destroying the transport)
     */
  attemptDowngrade(tsEvent) {
    const { infoStr, errorSubcode } = tsEvent;
    if (!this.transport.beginDowngrade(infoStr, errorSubcode)) {
      LOG_TRACE('Downgrade unavailable');
      this.transport.destroyInternal(infoStr, errorSubcode);
      this.transport.notifyEvent(tsEvent);
      return this.transitionTo(this.WebTransportDestroying);
    }
    LOG_TRACE('Downgrade available');
    return this.transitionTo(this.WebTransportDowngrading);
  }

  notifyDowngradeFailed() {
    LOG_TRACE('Notifying of downgrade failure');
    this.transport.notifyEvent(new TransportSessionEvent(TransportSessionEventCode.DOWNGRADE_FAILED,
                                  'Downgrade failed'));
  }
}

module.exports.WebTransportFSM = WebTransportFSM;
