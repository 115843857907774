const { AbstractQueueDescriptor } = require('./lib/abstract-queue-descriptor');
const { QueueAccessType } = require('./lib/queue-access-types');
const { QueueDescriptor } = require('./lib/queue-descriptor');
const { QueueDescriptorValidator } = require('./lib/queue-descriptor-validator');
const { QueueDiscardBehavior } = require('./lib/queue-discard-behaviors');
const { QueuePermissions } = require('./lib/queue-permissions');
const { QueueProperties } = require('./lib/queue-properties');
const { QueuePropertiesValidator } = require('./lib/queue-properties-validator');
const { QueueType } = require('./lib/queue-types');

module.exports.AbstractQueueDescriptor = AbstractQueueDescriptor;
module.exports.QueueAccessType = QueueAccessType;
module.exports.QueueDescriptor = QueueDescriptor;
module.exports.QueueDescriptorValidator = QueueDescriptorValidator;
module.exports.QueueDiscardBehavior = QueueDiscardBehavior;
module.exports.QueuePermissions = QueuePermissions;
module.exports.QueueProperties = QueueProperties;
module.exports.QueuePropertiesValidator = QueuePropertiesValidator;
module.exports.QueueType = QueueType;
