const { Enum } = require('solclient-eskit');

/**
 * Statistics for sent/received messages and control operations.
 *
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const StatType = {

  // ------- SMF and Direct Messaging

  /**
   * Count of bytes sent as part of data messages.
   */
  TX_TOTAL_DATA_BYTES:            0,
  /**
   * Count of data messages sent.
   */
  TX_TOTAL_DATA_MSGS:             1,
  /**
   * Count of bytes sent as part of direct data messages.
   */
  TX_DIRECT_BYTES:                2,
  /**
   * Count of direct data messages sent.
   */
  TX_DIRECT_MSGS:                 3,
  /**
   * Count of bytes sent as part of control messages.
   */
  TX_CONTROL_BYTES:               4,
  /**
   * Count of control messages sent.
   */
  TX_CONTROL_MSGS:                5,
  /**
   * Count of request messages sent.
   */
  TX_REQUEST_SENT:                6,
  /**
   * Count of request timeouts that occurred.
   */
  TX_REQUEST_TIMEOUT:             7,
  /**
   * Count of bytes received as part of data messages.
   */
  RX_TOTAL_DATA_BYTES:            8,
  /**
   * Count of data messages received.
   */
  RX_TOTAL_DATA_MSGS:             9,
  /**
   * Count of bytes received as part of direct data messages.
   */
  RX_DIRECT_BYTES:                10,
  /**
   * Count of direct data messages received.
   */
  RX_DIRECT_MSGS:                 11,
  /**
   * Count of bytes received as part of control messages.
   */
  RX_CONTROL_BYTES:               12,
  /**
   * Count of control messages received.
   */
  RX_CONTROL_MSGS:                13,
  /**
   * Count discard message indications received on incoming messages.
   */
  RX_DISCARD_MSG_INDICATION:      14,
  /**
   * Count of reply messaged received.
   */
  RX_REPLY_MSG_RECVED:            15,
  /**
   * Count of received reply messages that were discarded.
   */
  RX_REPLY_MSG_DISCARD:           16,
  /**
   * @description
   * Count of messages discarded due to the presence of an unknown element or
   * unknown protocol in the SMF header.
   */
  RX_DISCARD_SMF_UNKNOWN_ELEMENT: 17,

  // ------- SolCache Session

  /**
   * @description
   * Count of cache requests sent. One conceptual request (i.e. one API call)
   * may involve many requests and replies.
   */
  CACHE_REQUEST_SENT:                     18,
  /**
   * @description
   * Count of OK responses to cache requests.
   */
  CACHE_REQUEST_OK_RESPONSE:              19,
  /**
   * @description
   * Count of cache requests that returned a failure response.
   */
  CACHE_REQUEST_FAIL_RESPONSE:            20,
  /**
   * @description
   * Count of cache replies discarded because a request has been fulfilled.
   */
  CACHE_REQUEST_FULFILL_DISCARD_RESPONSE: 21,
  /**
   * @description
   * Count of cached messages delivered to the application.
   */
  RX_CACHE_MSG:                           22,
  /**
   * @description
   * Count of cache requests that were incomplete.
   */
  CACHE_REQUEST_INCOMPLETE_RESPONSE:      23,
  /**
   * @description
   * The cache session operation completed when live data arrived on the requested topic.
   */
  CACHE_REQUEST_LIVE_DATA_FULFILL:        24,

  // ------ Guaranteed Messaging

  /**
   * Count of bytes sent as part of persistent data messages.
   */
  TX_PERSISTENT_BYTES:                25,
  /**
   * Count of persistent data messages sent.
   */
  TX_PERSISTENT_MSGS:                 26,
  /**
   * Count of non-persistent data bytes sent.
   */
  TX_NONPERSISTENT_BYTES:             27,
  /**
   * Count of non-persistent data messages sent.
   */
  TX_NONPERSISTENT_MSGS:              28,
  /**
   * The number of bytes redelivered in Persistent messages.
   */
  TX_PERSISTENT_BYTES_REDELIVERED:    29,
  /**
   * The number of Persistent messages redelivered.
   */
  TX_PERSISTENT_REDELIVERED:          30,
  /**
   * The number of bytes redelivered in Non-Persistent messages.
   */
  TX_NONPERSISTENT_BYTES_REDELIVERED: 31,
  /**
   * The number of Non-Persistent messages redelivered.
   */
  TX_NONPERSISTENT_REDELIVERED:       32,
  /**
   * The number of acknowledgments received.
   */
  TX_ACKS_RXED:                       33,
  /**
   * The number of times the transmit window closed.
   */
  TX_WINDOW_CLOSE:                    34,
  /**
   * The number of times the acknowledgment timer expired.
   */
  TX_ACK_TIMEOUT:                     35,
  /**
   * Count of bytes received as part of persistent data messages.
   */
  RX_PERSISTENT_BYTES:                36,
  /**
   * Count of persistent data messages received.
   */
  RX_PERSISTENT_MSGS:                 37,
  /**
   * Count of bytes received as part of non-persistent data messages.
   */
  RX_NONPERSISTENT_BYTES:             38,
  /**
   * Count of non-persistent data messages received.
   */
  RX_NONPERSISTENT_MSGS:              39,
  /**
   * Count of acknowledgements sent to the Solace Message Router
   * for guaranteed messages received by the API.
   */
  RX_ACKED:                           40,
  /**
   * Count of guaranteed messages discarded for being duplicates.
   */
  RX_DISCARD_DUPLICATE:               41,
  /**
   * Count of guaranteed messages discarded due to no match message consumer for the message.
   */
  RX_DISCARD_NO_MATCHING_CONSUMER:    42,
  /**
   * Count of guaranteed messages discarded for being received out of order.
   */
  RX_DISCARD_OUT_OF_ORDER:            43,
};

module.exports.StatType = Enum.new(StatType);
