const { Convert } = require('solclient-convert');
const { SDTDataTypes } = require('../sdt-data-types');

const EncodeHeader = {};
EncodeHeader.encodeHeader = function encodeHeader(tag, valueLen) {
  // Tag in first 6 bits, then (lenbytes-1) in 2 bits
  let byte0 = (tag << 2) & 0xFF;
  let strSdtLen = null;

  if (tag === SDTDataTypes.Map || tag === SDTDataTypes.Stream) {
    // force 4 bytes
    strSdtLen = Convert.int32ToStr(valueLen + 5);
    byte0 |= 3; // 4 length bytes
  } else if (valueLen + 2 <= 255) {
    strSdtLen = Convert.int8ToStr(valueLen + 2);
    byte0 |= 0; // 1 length byte
  } else if (valueLen + 3 <= 65535) {
    strSdtLen = Convert.int16ToStr(valueLen + 3);
    byte0 |= 1; // 2 length bytes
  } else {
    strSdtLen = Convert.int32ToStr(valueLen + 5);
    byte0 |= 3; // 4 length bytes
  }
  const ret = Convert.int8ToStr(byte0) + strSdtLen;
  return ret;
};

module.exports.EncodeHeader = EncodeHeader;
