const { DestinationType } = require('./destination-type');
const { DestinationUtil } = require('./destination-util');

/**
 * @classdesc
 * <b>This class is not exposed for construction by API users. Users should obtain an instances from
 * one of the following:</b>
 * * {@link solace.SolclientFactory.createTopicDestination}
 * * {@link solace.SolclientFactory.createDurableQueueDestination}
 * * {@link solace.MessageConsumer#getDestination}
 * * {@link solace.SDTField#getValue} when {@link solace.SDTField#getType} returns
 *   {@link solace.SDTFieldType.DESTINATION}.
 *
 * Represents a message destination.
 *
 * Publishers can send messages to topics or queues, to which subscribers can subscribe or
 * bind. A Destination specifies the target of such an operation.
 * @hideconstructor
 * @memberof solace
 */
class Destination {

  /*
   * @constructor
   * @param {String|solace.Destination|Object} spec The name of the destination, or, the
   *  destination to copy. If this is a {@link solace.Destination} then all other parameters are
   *  ignored.
   * @param {solace.DestinationType} [type=solace.DestinationType.Topic] The type of destination
   *  to create
   * @deprecated since 10.0 Applications should not call this constructor.
   *  Use the factory methods on {@link solace.SolclientFactory}.
   **/
  constructor(spec, type = DestinationType.TOPIC) {
    if (typeof spec === 'object') {
      this._name = spec.name;
      this._type = spec.type;
      this._bytes = spec.bytes;
      this._offset = spec.offset;
      if (spec.isValidated) {
        this._isValidated = true;
        this._isWildcarded = spec.isWildcarded;
        this._subscriptionInfo = spec.subscriptionInfo || {};
      } else {
        this._isValidated = false;
        this._subscriptionInfo = {};
      }
    } else {
      this._name = spec;
      this._type = type;

      // Don't validate encoding for deprecated construction path.
      // This path would have more restrictions now, and it allows
      // sdkperf a path for arbitrary topic construction.
      const result = DestinationUtil.encode(type, spec);
      this._bytes = result.bytes;
      this._offset = result.offset;
      this._isValidated = false;
      this._subscriptionInfo = {};
    }
  }

  /**
   * @returns {String} The destination name specified at creation time.
   */
  getName() {
    return this._name;
  }

  /**
   * @type {String}
   * @readonly
   */
  get name() {
    return this.getName();
  }

  /**
   * @returns {solace.DestinationType} The destination type
   */
  getType() {
    return this._type;
  }

  /**
   * @type {solace.DestinationType}
   * @readonly
   */
  get type() {
    return this.getType();
  }

  /**
   * @returns {String} Returns the null-terminated UTF-8 encoded destination bytes.
   *
   * @private
   */
  getBytes() {
    return this._bytes;
  }
  get bytes() {
    return this.getBytes();
  }

  getOffset() {
    return this._offset;
  }
  get offset() {
    return this.getOffset();
  }

  /**
   * This provides the legacy validation that was done post-creation and is used in some code paths
   * on use.  It is preferred to use non-deprecated ways of creating destinations, which do
   * validation on create instead.
   * @throws OperationError if the destination is not valid.
   * @private
   */
  validate() {
    if (this._isValidated) {
      if (this._error) throw this._error;
      return;
    }

    const { error, isWildcarded } =
      DestinationUtil.legacyValidate(this.type, this.bytes, this.name);
    this._isValidated = true;
    if (error) {
      this._error = error;
      throw error;
    }
    this._isWildcarded = isWildcarded;
  }

  /**
   * This is really only useful for topics.  If a queue name contains a wildcarded construct, it is
   * up to the router what to do -- it would either reject it or treat the wildcard as literal.  But
   * it is included here for all destination types since we have the info.
   * @returns {Boolean} True if the underlying name represents a wildcarded topic; false otherwise.
   * @throws {OperationError} If the destination is not valid.
   * @private
   */
  isWildcarded() {
    this.validate();
    return this._isWildcarded;
  }

  /**
   * @returns {Object} subscriptionInfo object or empty object
   * @private
   */
  getSubscriptionInfo() {
    return this._subscriptionInfo || {};
  }

  /**
   * @returns {String} A generic description of the Destination.
   */
  toString() {
    return util_inspect(this);
  }

  /**
   * @param {*} other The other object for comparison
   * @returns {Boolean} True if the passed destination is the same
   * @private
   */
  equals(other) {
    if (!(other instanceof Destination)) {
      return false;
    }
    return (this.toString().valueOf() === other.toString().valueOf());
  }
}

module.exports.Destination = Destination;
