const { Enum } = require('solclient-eskit');

/**
 * Enumerates destination types for destination objects.
 *
 * @enum {string}
 * @namespace
 * @memberof solace
 */
const DestinationType = {
  /**
   * A Topic destination.
   */
  TOPIC:           'topic',
  /**
   * A queue destination.
   */
  QUEUE:           'queue',
  /**
   * A temporary queue destination.
   */
  TEMPORARY_QUEUE: 'temporary_queue',
};

module.exports.DestinationType = Enum.new(DestinationType);

/* eslint-disable */
// Manually demangle these names; bug 70131
module.exports.DestinationType._setCanonical({
  'TOPIC':           DestinationType.TOPIC,
  'QUEUE':           DestinationType.QUEUE,
  'TEMPORARY_QUEUE': DestinationType.TEMPORARY_QUEUE
});
/* eslint-enable */
