const { ErrorSubcode, OperationError } = require('solclient-error');
const { TransportReturnCode } = require('./transport-return-codes');

// Stubs don't use this
/* eslint-disable class-methods-use-this */
// Stubs declare all params
/* eslint-disable no-unused-vars */

/**
 *
 * Base class for various transports
 * @private
 */
class TransportBase {
  /**
   * @constructor
   * @param {URL} url The URL to connect to
   * @param {function} eventCB The callback to notify on events
   * @param {SMFClient} client The SMF client to notify on data
   * @param {Object} transportProps Additional properties to assign to the transport
   * @param {?Object} [interceptor] The transport interceptor to install
   */
  constructor(url, eventCB, client, transportProps, interceptor = null) {
    this._url = url;
    this._ssl = TransportBase.useSsl(url);
    this._client = client;
    this._eventCB = eventCB;
    this._props = transportProps;
    this.setInterceptor(interceptor);
  }

  /**
   * Establish underlying transport.
   * Returns transport return code, to be overridden in subclass
   * @returns {TransportReturnCode} The result of this operation
   */
  connect() { return TransportReturnCode.OK; }

  /**
   * Destroy the underlying transport.
   * Returns transport return code, to be overridden in subclass
   * @param {Boolean} immediate Destroy gracefully if false.
   * @param {?String} msg The message associated with this operation, if any.
   * @param {?ErrorSubcode} subcode The subcode associated with this operation, if any.
   * @returns {TransportReturnCode} The result of this operation
   */
  destroy(immediate, msg, subcode) { return TransportReturnCode.OK; }

  flush(callback) {
    callback();
    return TransportReturnCode.OK;
  }

  /**
   * Send encoded SMF message
   * Returns transport return code, to be overridden in subclass
   * @param {String} message The encoded SMF message to send
   * @param {?Boolean} [forceAllowEnqueue=false] If true, do not fail due to transport buffer full
   * @returns {TransportReturnCode} The result of this operation
   */
  send(message, forceAllowEnqueue = false) { return TransportReturnCode.OK; }

  /**
   * @returns {TransportProtocol} Return transport protocol in use
   */
  getTransportProtocol() {
    return this._props.transportProtocol;
  }

  /**
   * @returns {?String} Return transport session information such as sessionId
   */
  getInfoStr() { return null; }

  /**
   * @returns {TransportClientStats} transport statistics
   */
  getClientStats() { return null; }

  /**
   * Check whether or not the transport can be downgraded to the next protocol.
   * It is possible to downgrade the transport, the existing transport is destroyed and
   * the new transport connection initiated.
   * @param {String} msg The message associated with this operation
   * @param {ErrorSubcode} subcode The subcode associated with this operation
   * @returns {Boolean} Return true if downgrade is allowed and has been initiated; false otherwise
   */
  beginDowngrade(msg, subcode) { return false; }

  /**
   * Sets the interceptor for this transport. This object has the chance
   * to intervene before bytes are sent or received.
   * @param {Object} [interceptor] If set, the interceptor is inserted into the
   *
   * @private
   */
  setInterceptor(interceptor) {
    if (this._interceptor) {
      // Reset original callbacks
      if (this._interceptor.removed) this._interceptor.removed(this);
    }
    this._interceptor = interceptor;
    if (interceptor) {
      if (interceptor.installed) interceptor.installed(this);
    }
  }

  toString() {
    return `${this.getTransportProtocol()}${this._ssl ? ' (SSL)' : ''}`;
  }

  /**
   * @param {String} url The URL to check
   * @returns {Boolean} `true` if the URL requires SSL; `false` otherwise
   * @static
   */
  static useSsl(url) {
    const urlParts = (url || '').split('://');
    if (urlParts.length === 0 || TransportBase.validSchemes.indexOf(urlParts[0]) < 0) {
      throw new OperationError(`Invalid url "${url}": Only [${
                               TransportBase.validSchemes.join(', ')}] URL schemes are supported`,
                               ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }

    const useSSL = (urlParts[0] === 'https' || urlParts[0] === 'wss' || urlParts[0] === 'tcps');
    return useSSL;
  }

}

/**
 * @type {Array.<String>}
 */
TransportBase.validSchemes = ['http', 'https', 'ws', 'wss', 'tcp', 'tcps'];

module.exports.TransportBase = TransportBase;

