const { Bits, Convert } = require('solclient-convert');
const { LOG_DEBUG, LOG_TRACE } = require('solclient-log');
const { SMFSMPMessageType } = require('../smf-smp-message-types');
const { SMPMessage } = require('../message-objects');

const {
  get: bits,
  set: setBits,
} = Bits;

const {
  int8ToStr,
  int32ToStr,
} = Convert;

const SMP = {};
SMP.parseSMPAt = function parseSMPAt(dataBuf, offset) {
  if ((offset + 6) > dataBuf.length) {
    // not enough data
    LOG_DEBUG('Not enough data to read an SMP message.');
    return false;
  }
  let pos = offset;
  const onebyte = dataBuf.readUInt8(pos);
  pos++;

  // var msgUh = bits(onebyte, 7, 1);
  const msgType = bits(onebyte, 0, 7);
  const smpMsg = new SMPMessage();

  if (!(msgType === SMFSMPMessageType.ADDSUBSCRIPTION ||
        msgType === SMFSMPMessageType.REMSUBSCRIPTION ||
        msgType === SMFSMPMessageType.ADDQUEUESUBSCRIPTION ||
        msgType === SMFSMPMessageType.REMQUEUESUBSCRIPTION
  )) {
    LOG_DEBUG(`Found unsupported SMP messageType ${msgType}`);
    return false; // unsupported type
  }

  LOG_TRACE('SMP.parseSMPAt called, ', dataBuf.toString('latin1'));

  const msgLength = dataBuf.readUInt32BE(pos);
  pos += 4;
  if ((offset + msgLength) > dataBuf.length) {
    // not enough data
    LOG_DEBUG(`Invalid declared length of ${msgLength}, unable to read SMP message.`);
    return false;
  }
  const msgFlags = dataBuf.readUInt8(pos);
  pos++;

  smpMsg.msgType = msgType;
  smpMsg.smpFlags = msgFlags;
  if (msgType === SMFSMPMessageType.ADDSUBSCRIPTION ||
    msgType === SMFSMPMessageType.REMSUBSCRIPTION) {
    // 6 is the base len (msgLength - 6)
    smpMsg.encodedUtf8Subscription = dataBuf.toString('latin1', pos, pos + msgLength - 6);
  } else {
    const queueLength = dataBuf.readUInt8(pos);
    pos++;
    smpMsg.encodedUtf8QueueName = dataBuf.toString('latin1', pos, pos + queueLength);
    pos += queueLength;
    const subsLength = dataBuf.readUInt8(pos);
    pos++;
    smpMsg.encodedUtf8Subscription = dataBuf.toString('latin1', pos, pos + subsLength);
    pos += subsLength;
  }
  return smpMsg;
};

SMP.encSmp = function encSmp(smpMsg) {
  if (!(smpMsg.msgType === SMFSMPMessageType.ADDSUBSCRIPTION ||
        smpMsg.msgType === SMFSMPMessageType.REMSUBSCRIPTION ||
        smpMsg.msgType === SMFSMPMessageType.ADDQUEUESUBSCRIPTION ||
        smpMsg.msgType === SMFSMPMessageType.REMQUEUESUBSCRIPTION
  )) {
    LOG_DEBUG(`Unsupported SMP message for encoding: ${smpMsg}`);
    return false;
  }

  LOG_TRACE('encSmp called.', smpMsg);
  const data = [];
  let onebyte = 0;
  onebyte = setBits(onebyte, 1, 7, 1);
  onebyte = setBits(onebyte, smpMsg.msgType, 0, 7);
  data.push(int8ToStr(onebyte));
  let msgLength = 6 + smpMsg.encodedUtf8Subscription.length;
  if (smpMsg.msgType === SMFSMPMessageType.ADDQUEUESUBSCRIPTION ||
    smpMsg.msgType === SMFSMPMessageType.REMQUEUESUBSCRIPTION) {
    msgLength += 2 + smpMsg.encodedUtf8QueueName.length; //both strings have a 1-byte length.
  }
  data.push(int32ToStr(msgLength)); // length
  data.push(int8ToStr(smpMsg.smpFlags));
  if (smpMsg.msgType === SMFSMPMessageType.ADDQUEUESUBSCRIPTION ||
    smpMsg.msgType === SMFSMPMessageType.REMQUEUESUBSCRIPTION) {
    //TODO: validate the lengths are < 251 including null termination!
    // Somewhere else though.
    data.push(int8ToStr(smpMsg.encodedUtf8QueueName.length));
    data.push(smpMsg.encodedUtf8QueueName);
    data.push(int8ToStr(smpMsg.encodedUtf8Subscription.length));
    data.push(smpMsg.encodedUtf8Subscription);
  } else {
    data.push(smpMsg.encodedUtf8Subscription);
  }
  return data.join('');
};

module.exports.SMP = SMP;
