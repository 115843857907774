const Long = require('long');
const { SDTField } = require('../sdt-field');
const { SDTFieldType } = require('../sdt-field-types');
const { SDTUnsupportedValueError } = require('../sdt-unsupported-value-error');
const { SDTValueErrorSubcode } = require('../sdt-value-error-subcodes');

const { create: createField } = SDTField;

const ParseInteger = {};

// Util: decode 1, 2, 3, 4 byte UINT.
ParseInteger.autoDecodeVarLengthNumber = function autoDecodeVarLengthNumber(dataBuf, pos, len) {
  if ([1, 2, 3, 4].includes(len)) {
    return dataBuf.readUIntBE(pos, len);
  }
  return false;
};

// Parse an integer SDT Field: [U]INT 8, 16, 32, 64.
ParseInteger.parseIntegerField = function parseIntegerField(isSigned, dataBuf, pos, len) {
  let val = 0;

  switch (len) {
    case 1:
      if (isSigned) {
        val = dataBuf.readInt8(pos);
        return createField(SDTFieldType.INT8, val);
      } // else unsigned:
      val = dataBuf.readUInt8(pos);
      return createField(SDTFieldType.UINT8, val);

    case 2:
      if (isSigned) {
        val = dataBuf.readInt16BE(pos);
        return createField(SDTFieldType.INT16, val);
      } // else signed:
      val = dataBuf.readUInt16BE(pos);
      return createField(SDTFieldType.UINT16, val);

    case 4:
      if (isSigned) {
        val = dataBuf.readInt32BE(pos);
        return createField(SDTFieldType.INT32, val);
      } // else signed:
      val = dataBuf.readUInt32BE(pos);
      return createField(SDTFieldType.UINT32, val);

    case 8:
      {
        let error = null;
        const longValue =
          Long.fromBits(dataBuf.readUInt32BE(pos + 4), dataBuf.readUInt32BE(pos), !isSigned);
        if (longValue.getNumBitsAbs() > 48) {
          error = new SDTUnsupportedValueError('Value is not supported',
            SDTValueErrorSubcode.VALUE_OUTSIDE_SUPPORTED_RANGE,
            dataBuf.toString('latin1', pos, len));
        }
        val = longValue.toNumber();
        const field = createField(isSigned ? SDTFieldType.INT64 : SDTFieldType.UINT64, val);
        if (error) {
          field.setError(error);
        }

        return field;
      }

    default:
      return null;
  }
};

module.exports.ParseInteger = ParseInteger;
