const WebTransportsLib = require('./web');
const { Lazy } = require('solclient-eskit');
const { LOG_TRACE } = require('solclient-log');
const { TransportBase } = require('./transport-base');
const { TransportProtocol } = require('./transport-protocols');

const { lazyValue } = Lazy;

// Define transportProtocol to transport mapping
const lutTransportProtocols = lazyValue(() => {
  const protocols = {
    [TransportProtocol.HTTP_BASE64]:           WebTransportsLib.StateBase64,
    [TransportProtocol.HTTP_BINARY]:           WebTransportsLib.StateBinary,
    [TransportProtocol.HTTP_BINARY_STREAMING]: WebTransportsLib.StateStreamingAndBinary,
    [TransportProtocol.WS_BINARY]:             WebTransportsLib.StateWebSocketBinary,
  };
  return protocols;
});

/**
 * @classdesc
 * Handles web transport selection and downgrade without creating the actual transport
 * session object
 * @memberof solace
 * @private
 */
class TransportProtocolHandler {
  constructor(url, webTransportProtocolList) {
    const useSSL = TransportBase.useSsl(url);
    let transport = null;
    let downgradeTransport = null;
    webTransportProtocolList.slice().reverse().forEach((el) => {
      const TransportConstructor = lutTransportProtocols.value[el];
      transport = new TransportConstructor(useSSL, this.switchState.bind(this), downgradeTransport);
      downgradeTransport = transport;
    });
    this._transport = transport;
    this._transport.onEnter();
  }

  getTransportProtocol() {
    return this._transport.getTransportProtocol();
  }

  completeDowngrade(err) {
    return this._transport.completeDowngrade(err);
  }

  canCompleteDowngrade() {
    return (this._transport.getNextState() !== null);
  }

  toString() {
    return this._transport.toString();
  }

  switchState(newState, reason) {
    LOG_TRACE(`Switching ${this._transport} => ${newState} (${reason})`);
    this._transport = newState;
    newState.onEnter();
  }
}

module.exports.TransportProtocolHandler = TransportProtocolHandler;
