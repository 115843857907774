const { BaseMessage } = require('./base-message');
const { SMFHeader } = require('./smf-header');
const { SMFProtocol } = require('../smf-protocols');

class KeepAliveMessage extends BaseMessage {
  constructor() {
    super(new SMFHeader(SMFProtocol.KEEPALIVEV2, 2));
    this._smfHeader.smf_uh = 2;
  }
}

module.exports.KeepAliveMessage = KeepAliveMessage;
