const { EntryPoint } = require('./entry-point');
const { ExitPoint } = require('./exit-point');
const { StateContext } = require('./state-context');

/**
 * @classdesc
 * This class represents a state in a state machine.
 * @private
 */
class State extends StateContext {
  /**
   * @constructor
   * @param {Object} spec Object specifier used to implement the named parameter
   *  idiom.  In addition to the properties documented here, it is also expected
   *  to have properties required by the [StateContext base class]{@link StateContext}.
   * @param {String} spec.name The name of the state, used in debug logs.
   * @param {State|StateMachine} spec.parentContext One of:
   *  - The parent state object or;
   *  - The stateMachine object for top-level states.
   * @param {Object} [extensions] Additional methods to add to the state.
   */
  constructor(spec, extensions = null) {
    super(spec);

    const parentContext = spec.parentContext;
    Object.assign(this.impl, {
      parentContext,
      reactions:            {},
      entryPoints:          {},
      exitPoints:           {},
      ancestorList:         [...parentContext.getAncestorList(), this],
      handleUnhandledEvent: e => (
        parentContext.handleEvent
          ? parentContext.handleEvent(e)
          : parentContext.impl.handleUnhandledEvent(e)
      ),
    });
    if (parentContext) this.log = parentContext.log.bind(this);

    // Extend and bind functions
    Object.keys(extensions || {}).forEach((k) => {
      const extension = extensions[k];
      this[k] = typeof extension === 'function' ? extension.bind(this) : extension;
    });

    this.setLogPadding(' '.repeat(this.impl.ancestorList.length));
  }

  /**
   * Register a reaction function for a particular event.
   * @param {String} eventName The name of the event func is to react to.
   * @param {StateContext.reactionCallback} func The function to call when
   *  the state processes an event with the name eventName.  The function is
   *  bound to this object so that this refers to this state object when it
   *  is invoked.
   * @returns {State} This state object.
   * @public
   */
  reaction(eventName, func) {
    if (!eventName) throw new Error('No event name for reaction');
    if (!func) throw new Error(`No reaction function for reaction ${eventName}`);

    this.log(`Adding reaction to ${this} for event ${eventName}`);

    if (this.impl.reactions[eventName]) {
      this.log(`Replacing reaction ${this.impl.reactions[eventName]} with ${func}`);
    }
    this.impl.reactions[eventName] = func.bind(this);
    return this;
  }

  /**
   * Create an entryPoint for this state.
   * @param {String} entryPointName The name of the entryPoint being created.
   * @param {StateContext.reactionCallback} func The reaction to invoke after
   *  the state is entered via this entryPoint
   * @returns {State} This state object.
   * @public
   */
  entryPoint(entryPointName, func) {
    if (!entryPointName) throw new Error('No entry point name for entry point');
    if (!func) throw new Error(`No reaction function for entry point ${entryPointName}`);

    this.log(`Adding entryPoint ${entryPointName} to ${this}`);

    if (this.impl.entryPoints[entryPointName]) {
      this.log(`EntryPoint ${entryPointName} already exists in ${this}`);
      return this;
    }

    this.impl.entryPoints[entryPointName] = new EntryPoint({
      state: this,
      entryPointName,
      func,
    });

    return this;
  }

  /**
   * Create an exitPoint for this state.
   * @param {String} exitPointName The name of the exitPoint being created.
   * @param {StateContext.reactionCallback} func The reaction to invoke after
   *  the state is exited via this exitPoint.
   * @returns {State} This state object.
   * @public
   */
  exitPoint(exitPointName, func) {
    if (!exitPointName) throw new Error('No exit point name for entry point');
    if (!func) throw new Error(`No reaction function for exit point ${exitPointName}`);

    this.log(`Adding exitPoint ${exitPointName} to ${this}`);

    if (this.impl.exitPoints[exitPointName]) {
      this.log(`ExitPoint ${exitPointName} already exists in  ${this}`);
      return this;
    }

    this.impl.exitPoints[exitPointName] = new ExitPoint({
      state: this,
      exitPointName,
      func,
    });

    return this;
  }

  /**
   * @param {String} entryPointName The name of the entry point to be
   *      transitioned to.
   * @returns { StateContext } The state context to transition to when
   *      entering via the state's entryPoint named entryPointName.
   * @protected
   */
  getEntryPointDestState(entryPointName) {
    if (this.impl.entryPoints[entryPointName] === undefined) {
      this.log(`${this}: EntryPoint ${entryPointName} does not exist.`);
      return this;
    }

    return this.impl.entryPoints[entryPointName].getDestState();
  }

  /**
   * @param {String} exitPointName The name of the exit point to be
   *      transitioned to.
   * @returns {StateContext} The state context to transition to when
   *      exiting via the state's exitPoint named exitPointName.
   * @protected
   */
  getExitPointDestState(exitPointName) {
    if (this.impl.exitPoints[exitPointName] === undefined) {
      this.log(`${this}: ExitPoint ${exitPointName} does not exist.`);
      return this;
    }

    return this.impl.exitPoints[exitPointName].getDestState();
  }

  /**
   * Register a function to be called when the state is entered.
   * @param {function} func The function to call when the state is entered.
   *  The function is bound to this object so that this refers to this
   *  state object when it is invoked.
   * @returns {State} This state object.
   * @public
   */
  entry(func) {
    if (this.impl.appEntryFunc) {
      this.log(`Replacing entry function ${this.impl.appEntryFunc} with ${func}`);
    }
    this.impl.appEntryFunc = func.bind(this);
    return this;
  }

  /**
   * Register a function to be called when the state is exited.
   * @param {function} func The function to call when the state is exited.
   *  The function is bound to this object so that this refers to this
   *  state object when it is invoked.
   * @returns {State} This state object.
   * @public
   */
  exit(func) {
    if (this.impl.appExitFunc) {
      this.log(`Replacing exit function ${this.impl.appExitFunc} with ${func}`);
    }
    this.impl.appExitFunc = func.bind(this);
    return this;
  }

  /**
   * This is the same as [transitionTo]{@link StateContext#transitionTo},
   * except the outermost state is exited if the source state is within the
   * dest state or vice versa.  If one state is not within the other, this
   * is equivalent to [transitionTo]{@link StateContext#transitionTo}.
   * @param {State} state The state to transition to; if undefined, it is
   *      a self-transition.
   * @param {StateContext.actionCallback} [action] Optional The action to perform as part
   *      of the transition, if desired.
   * @returns {StateContext.ReactionResult} The result of the transition.
   */
  externalTransitionTo(state, action) {
    return new StateContext.ReactionResult({
      caller:    this,
      destState: state,
      action,
      external:  true,
    });
  }

  /**
   * This is similar to [transitionTo]{@link StateContext#transitionTo}, except
   * that this is a transition to a named entryPoint created on state.  If the
   * named entryPoint does not exist, this is a malformed FSM and the behaviour
   * is undefined.
   * @param {State} state The state that has the named entryPoint.
   * @param {String} entryPointName The name of the entryPoint of state.
   * @param {StateContext.actionCallback} action The action to perform as part of
   *      the transaction, if desired.
   * @returns {StateContext.ReactionResult} The result of the transition
   */
  transitionToEntryPoint(state, entryPointName, action) {
    return new StateContext.ReactionResult({
      caller:    this,
      destState: state.getEntryPointDestState(entryPointName),
      action,
    });
  }

  /**
   * This is similar to [transitionTo]{@link StateContext#transitionTo}, except
   * that this is a transition to a named exitPoint created on state.  If the
   * named exitPoint does not exist, this is a malformed FSM and the behaviour
   * is undefined.
   * @param {State} state The state that has the named exitPoint.
   * @param {String} exitPointName The name of the exitPoint of state.
   * @param {StateContext.actionCallback} [action] Optional The
   * action to perform as part of the transaction, if desired.
   * @returns {StateContext.ReactionResult} The result of the transition
   */
  transitionToExitPoint(state, exitPointName, action) {
    return new StateContext.ReactionResult({
      caller:    this,
      destState: state.getExitPointDestState(exitPointName),
      action,
    });
  }

  /**
   * If a reaction evaluates the guard conditions for the reaction and none
   * succeed, the eventUnhandled
   * [ReactionResult]{@link StateContext.ReactionResult} should be
   * returned.  This causes the FSM to continue looking for a reaction to
   * process the event.
   * @returns {StateContext.ReactionResult} The result of the transition
   */
  eventUnhandled() {
    return new StateContext.ReactionResult({
      caller: this,
    });
  }

  /**
   * This [ReactionResult]{@link StateContext.ReactionResult} is used
   * when an event has been handled in a state and the transition is a local
   * transition back to itself.  Note that internal transitions never cause
   * state exits or entries -- if the internal transition is defined in an
   * outer state that contains the active state, then the behaviour is as if the
   * active state has inherited the internal transition from the outer state.
   * @param {?Function} action The action to perform in the transition.
   * @returns {StateContext.ReactionResult} The result of the transition
   */
  internalTransition(action) {
    return new StateContext.ReactionResult({
      caller:    this,
      destState: this.getStateMachine().getCurrentState(),
      action,
    });
  }

  /**
   * Used to terminate the FSM.
   * @param {StateContext.actionCallback} action An optional action to
   *      take within the FSM context after all states have been exited.
   * @returns {StateContext.ReactionResult} The result of the transition
   */
  terminate(action) {
    return new StateContext.ReactionResult({
      caller:    this,
      destState: this.getStateMachine().getFinalState(),
      action,
    });
  }


  /**
   * @returns {StateContext} The parent state context for this state, which
   *      could either be an outer state, or the state machine for top-level
   *      states.
   * @protected
   */
  getParent() {
    return this.impl.parentContext;
  }

  /**
   * Called when a state is being entered.  Calls the app's registered entry
   * function, if any.
   * @protected
   */
  onEntry() {
    this.log(`Entering: ${this}`);
    if (this.impl.appEntryFunc) {
      this.impl.appEntryFunc();
    }
  }

  /**
   * Called when a state is being exited.  Calls the app's registered exit
   * function, if any.
   * @protected
   */
  onExit() {
    this.log(`Exiting: ${this}`);
    if (this.impl.appExitFunc) {
      this.impl.appExitFunc();
    }
  }

  /**
   * Called when a state is to handle an event.
   * @param {FsmEvent} e The event to handle.
   * @returns {ReactionResult} The result of handling the event.
   * @protected
   */
  handleEvent(e) {
    this.log(`Process: ${e}`);
    const reaction = this.impl.reactions[e.getName()];
    if (reaction) {
      const result = reaction(e);
      // All ReactionResults in which an event is considered handled
      // have a destState defined.
      if (!result) {
        this.log(`Reaction returned undefined: ${e} in ${this}`);
      }
      if (result.destState) {
        this.log(`Handled: ${e}`);
        return result;
      }
      this.log(`Unhandled: ${e} in ${this}`);
    } else {
      this.log(`No reaction: ${e} in ${this}`);
    }

    return this.impl.handleUnhandledEvent(e);
  }
}

module.exports.State = State;
