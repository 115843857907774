/**
 * Created by rpaulson on 06/02/2017.
 */
const { FsmEvent } = require('solclient-fsm');

/**
 *
 * ConsumerFSMEvent extends {@link FsmEvent}.  This object should be used for all events
 * passed to the Subscriber FSM.  This extension provides members to carry information specfic to
 * the Subscriber FSM transitions.
 *
 * @extends FsmEvent
 * @private
 */
class ConsumerFSMEvent extends FsmEvent {
  constructor(spec, details) {
    super(spec);
    this.details = details;
  }
}

module.exports.ConsumerFSMEvent = ConsumerFSMEvent;
