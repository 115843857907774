const { Enum } = require('solclient-eskit');

/**
 * Connection scheme types referenced by {@link solace.SessionProperties#transportProtocol},
 * {@link solace.SessionProperties#webTransportProtocolList} (browser only), and
 * {@link solace.SessionProperties#transportProtocolInUse}.
 *
 * @target browser
 * @enum {string}
 * @namespace
 * @memberof solace
 */
const TransportProtocol = {

  /**
   * A COMET model that uses base64 payload encoding. HTTP responses have a defined Content-Length.
   *
   * Guaranteed Messaging is incompatbile with HTTP_ transports.
   *  * {@link solace.MessagePublisherProperties#enabled} must be `false` for a session that may
   *    use this transport protocol.
   *  * {@link solace.Session#createMessageConsumer} will throw on a session that may use this
   *    transport protocol if {@link solace.MessagePublisherProperties#enabled} is `true`.
   *
   * @target browser
   */
  HTTP_BASE64: 'HTTP_BASE64',

  /**
   * A COMET model that uses binary payload encoding. HTTP responses have a defined Content-Length.
   *
   * Guaranteed Messaging is incompatbile with HTTP_ transports.
   *  * {@link solace.MessagePublisherProperties#enabled} must be `false` for a session that may
   *    use this transport protocol.
   *  * {@link solace.Session#createMessageConsumer} will throw on a session that may use this
   *    transport protocol if {@link solace.MessagePublisherProperties#enabled} is `true`.
   *
   * @target browser
   */
  HTTP_BINARY: 'HTTP_BINARY',

  /**
   * A COMET model that uses binary payload encoding. HTTP responses use Chunked Transfer-Encoding
   * to stream data from the Solace Message Router to the client without needing to terminate the
   * HTTP response.
   *
   * Guaranteed Messaging is incompatbile with HTTP_ transports.
   *  * {@link solace.MessagePublisherProperties#enabled} must be `false` for a session that may
   *    use this transport protocol.
   *  * {@link solace.Session#createMessageConsumer} will throw on a session that may use this
   *    transport protocol if {@link solace.MessagePublisherProperties#enabled} is `true`.
   *
   * @target browser
   */
  HTTP_BINARY_STREAMING: 'HTTP_BINARY_STREAMING',

  /**
   * A WebSocket communication channel uses binary payload encoding and provides full-duplex
   * communication between the client and the Solace Message Router over a single TCP connection.
   *
   * This is the preferred protocol to use if all networking infrastructure betweed the application
   * and the Solace Message router support WebSockets. If this is not the case, it may be necessary
   * to use one of the HTTP protocols that use the COMET model of messaging.
   *
   * This transport protocol supports Guaranteed Messaging.
   */
  WS_BINARY: 'WS_BINARY',

};

module.exports.TransportProtocol = Enum.new(TransportProtocol);
