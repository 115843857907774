const DestinationUtilLib = require('./destination-util');
const { assert } = require('solclient-eskit');
const { Destination } = require('./destination');
const { DestinationType } = require('./destination-type');

/**
 * @classdesc
 * Represents a Queue, which is a type of {@link solace.Destination}.
 *
 * Instances should be acquired through
 * {@link solace.SolclientFactory.createDurableQueueDestination} or by calling
 * {@link solace.MessageConsumer#getDestination} on a consumer bound to a temporary queue.
 *
 * @extends solace.Destination
 * @memberof solace
 * @private
 */
class Queue extends Destination {

  /**
   * @param {Object} spec The spec for this instance
   * @private
   */
  constructor(spec) {
    assert(spec.name, 'Queue name not supplied');
    assert(spec.type === DestinationType.QUEUE
           || spec.type === DestinationType.TEMPORARY_QUEUE, 'Queue spec.type is invalid');
    assert(spec.bytes, 'Queue spec missing bytes');
    assert(spec.offset !== undefined, 'Queue spec missing offset');
    super(spec);
  }

  getOffset() {
    return this._offset;
  }
  get offset() {
    return this.getOffset();
  }

  [util_inspect_custom]() {
    return `[Queue ${this.getName()}]`;
  }

  /**
   * @static
   * @param {String} queueName The name of the queue (no prefix)
   * @returns {Queue} a Queue
   * @private
   */
  static createFromLocalName(queueName) {
    const encoding = DestinationUtilLib.DestinationUtil.validateAndEncode(
      DestinationType.QUEUE, queueName);
    if (encoding.error) {
      throw encoding.error;
    }
    return new Queue({
      name:             queueName,
      type:             DestinationType.QUEUE,
      isValidated:      true,
      bytes:            encoding.bytes,
      offset:           encoding.offset,
      isWildcarded:     encoding.isWildcarded,
      /* probably don't need this until queues get subscription attributes */
      subscriptionInfo: encoding.subscriptionInfo,
    });
  }

}

module.exports.Queue = Queue;
