const { ErrorSubcode } = require('solclient-error');
const { TransportError } = require('../../transport-error');

/* eslint-env browser */
const XHRFactory = {
  /**
   * Creates an XHR object.
   *
   * @param {Boolean} [allowFail=false] If `true`, return null on failure; otherwise throw.
   * @throws {TransportError}
   * @returns {XMLHttpRequest} The new XHR object
   */
  create(allowFail = false) {
    const xhr = typeof XMLHttpRequest !== 'undefined' ? new XMLHttpRequest() : null;
    if (!allowFail && !xhr) {
      throw new TransportError('Failed to create an XMLHTTPRequest',
                                 ErrorSubcode.CREATE_XHR_FAILED);
    }
    return xhr;
  },
};

module.exports.XHRFactory = XHRFactory;
