/**
 * @classdesc
 * <b>This class is not exposed for construction by API users. A CacheRequestResult object is
 * provided on the callback (see {@link solace.CacheCBInfo.cacheRequestCallback} when a cache
 * request completes.</b>
 *
 * An object that indicates the termination of a cache request, and provides details how it
 * concluded.
 * @memberof solace
 * @hideconstructor
 */
class CacheRequestResult {
  /*
   * @param {solace.CacheReturnCode} rc The result of the request.
   *   * When returnCode === CacheReturnCode.OK, the applicable subcodes are:
   *     * {@link CacheReturnSubcode.REQUEST_COMPLETE}
   *     * {@link CacheReturnSubcode.LIVE_DATA_FULFILL}
   *   * When returnCode === CacheReturnCode.FAIL, applicable subcodes are:
   *     * {@link CacheReturnSubcode.ERROR_RESPONSE}
   *     * {@link CacheReturnSubcode.INVALID_SESSION}
   *     * {@link CacheReturnSubcode.REQUEST_ALREADY_IN_PROGRESS}
   *   * When returnCode === CacheReturnCode.INCOMPLETE, applicable subcodes are:
   *     * {@link CacheReturnSubcode.NO_DATA}
   *     * {@link CacheReturnSubcode.REQUEST_TIMEOUT}
   *     * {@link CacheReturnSubcode.SUSPECT_DATA}
   *
   * @param {solace.CacheReturnSubcode} subcode The subcode result of the request. Provides
   *    details on the condition that caused the result.
   * @param {solace.Destination} topic The topic destination on which the request was made.
   * @param {String} error The error, if any, that caused the current result.
   */
  constructor(rc, subcode, topic, error) {
    this._returnCode = rc;
    this._subcode = subcode;
    this._topic = topic;
    this._error = error;
  }

  /**
   * Gets the return code from the cache request result.
   *
   * @returns {solace.CacheReturnCode} The return code associated with the result of
   * the request.
   */
  getReturnCode() {
    return this._returnCode;
  }

  /**
   * Gets the return subcode from the cache request result.
   *
   * @returns {solace.CacheReturnSubcode} A subcode that gives more detail than
   * {@link CacheRequestResult#getReturnCode} about the result of the request.
   */
  getReturnSubcode() {
    return this._subcode;
  }

  /**
   * Gets the topic object associated with the cache request.
   *
   * @returns {solace.Destination} The topic destination supplied for the cache request.
   */
  getTopic() {
    return this._topic;
  }


  /**
   * Gets the error, if any, associated with the returned result.
   *
   * @returns {String} The error associated with the returned result.
   */
  getError() {
    return this._error;
  }
}

module.exports.CacheRequestResult = CacheRequestResult;
