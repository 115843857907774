const { SolaceError } = require('./solace-error');

/**
 * @classdesc
 * <b>This class is not exposed for construction by API users.</b>
 * An error thrown by the API when an operational error is encountered.
 * @memberof solace
 * @extends {solace.SolaceError}
 * @hideconstructor
 */
class OperationError extends SolaceError {

  /*
   * @constructor
   * @param {String} message The message to include in the error.
   * @param {Number} [subcode] The subcode. See {@link ErrorSubcode} for valid values
   * @param {Object} [reason] Embedded error or exception (optional)
   */
  constructor(message, subcode, reason) {
    /**
     * The name of the error.
     * @name solace.OperationError#name
     * @type {String}
     * @readonly
     * @description 'OperationError'
     */
    super('OperationError', message, OperationError);
    /**
     * The subcode for the error. @see {@link solace.ErrorSubcode}
     * @name solace.OperationError#subcode
     * @type {solace.ErrorSubcode}
     */
    this.subcode = subcode;
    /**
     * The reason for the error: an embedded error object or exception.
     * @name solace.OperationError#reason
     * @type {Object}
     */
    this.reason = reason;
  }

}

module.exports.OperationError = OperationError;
