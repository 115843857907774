const { Enum } = require('solclient-eskit');
/**
 * An enumeration of message consumer event names. A {@link solace.MessageConsumer} will emit
 * these events as part of its lifecycle.  Applications, having created a MessageConsumer can
 * choose to listen to all of the events described here, or any subset of these events. For Example:
 * <pre>
 *   <code>
 *     mc = solace.Session.createMessageConsumer(...);
 *     mc.on(solace.MessageConsumerEventName.CONNECT_FAILED_ERROR,
 *           function connectFailedErrorEventCb(error) {
 *             // error can be used as an OperationError object
 *           });
 *   </code>
 * </pre>
 *
 * @memberof solace
 * @enum {string}
 * @namespace
 */
const MessageConsumerEventName = {
  /**
   * The message consumer is established.
   * @event solace.MessageConsumerEventName#UP
   */
  UP:                   'MessageConsumerEventName_up',
  /**
   * The message consumer is successfully disconnected.
   * The message consumer is disabled.
   *
   * @event solace.MessageConsumerEventName#DOWN
   */
  DOWN:                 'MessageConsumerEventName_down',
 /**
   * The message consumer has become active.
   *
   * @event solace.MessageConsumerEventName#ACTIVE
   */
  ACTIVE:               'MessageConsumerEventName_active',
  /**
   * The message consumer has become inactive.
   *
   * @event solace.MessageConsumerEventName#INACTIVE
   */
  INACTIVE:             'MessageConsumerEventName_inactive',
  /**
   * The message consumer was established and then disconnected by the router,
   * likely due to operator intervention. The message consumer is disabled.
   *
   * @event solace.MessageConsumerEventName#DOWN_ERROR
   * @param {solace.OperationError} error Details of the error.
   */
  DOWN_ERROR:           'MessageConsumerEventName_downError',
  /**
   * The message consumer was established and then disconnected by the router,
   * likely due to operator intervention, but flow auto reconnect is active.
   * The message consumer is disabled, but actively reconnecting.
   * Expect a RECONNECTED or DOWN_ERROR on success of failure. respectively.
   * See also MessageConsumerProperties.reconnectAttempts and reconnectIntervalInMsecs.
   *
   * @event solace.MessageConsumerEventName#RECONNECTING
   * @param {solace.OperationError} error Details of the error that triggered the reconnect.
   */
  RECONNECTING:         'MessageConsumerEventName_reconnecting',
  /**
   * The message consumer successfully auto-reconnected.
   *
   * @event solace.MessageConsumerEventName#RECONNECTED
   */
  RECONNECTED:          'MessageConsumerEventName_reconnected',
  /**
   * The message consumer attempted to connect but was unsuccessful.
   * The message consumer is disabled.
   *
   * @event solace.MessageConsumerEventName#CONNECT_FAILED_ERROR
   * @param {solace.OperationError} error Details of the error.
   */
  CONNECT_FAILED_ERROR: 'MessageConsumerEventName_connectFailedError',
  /**
   * The message consumer will not connect because the current session is incompatible
   * with Guaranteed Messaging. The message consumer is disabled until a compatible session
   * is available.
   *
   * @event solace.MessageConsumerEventName#GM_DISABLED
   */
  GM_DISABLED:          'MessageConsumerEventName_GMDisabled',
  /**
   * The message consumer is being disposed. No further events will be emitted.
   *
   * @event solace.MessageConsumerEventName#DISPOSED
   */
  DISPOSED:             'MessageConsumerEventName_disposed',
  /**
   * A message was received on the message consumer.
   *
   * If the application throws an exception in this listener, and the consumer was configured
   * to automatically acknowledge messages
   * (see {@link solace.MessageConsumerProperties#acknowledgeMode}),
   * the API will not acknowledge the message, since it
   * may not have been successfully processed by the application. Such a message must be
   * acknowledged manually. If the application did not retain a reference to the message, it
   * may be redelivered by calling {@link solace.MessageConsumer#disconnect} followed by
   * {@link solace.MessageConsumer#connect} depending on the configuration of the queue.
   *
   * When there is no listener for <i>MESSAGE</i> on a MessageConsumer, messages are queued
   * internally until a listener is added.
   *
   * @event solace.MessageConsumerEventName#MESSAGE
   * @param {solace.Message} message The received message being delivered in this event.
   */
  MESSAGE:              'MessageConsumerEventName_message',
  /**
   * The subscribe or unsubscribe operation succeeded on the queue.
   * @event solace.MessageConsumerEventName#SUBSCRIPTION_OK
   * @param {solace.MessageConsumerEvent} event The details related
   * to the successful subscription update.
   */
  SUBSCRIPTION_OK:      'MessageConsumerEventName_ok',
  /**
   * The Solace Message Router rejected a queue subscription (add or remove).
   * @event solace.MessageConsumerEventName#SUBSCRIPTION_ERROR
   * @param {solace.MessageConsumerEvent} error The details related
   * to the failed subscription update.
   */
  SUBSCRIPTION_ERROR:   'MessageConsumerEventName_error',

};

module.exports.MessageConsumerEventName = Enum.new(MessageConsumerEventName);
