function numToHex(n) {
  if (typeof n !== 'number') {
    return '';
  }
  const s = n.toString(16);
  return (s.length < 2) ? `0${s}` : s;
}


function formatHexString(obj) {
  if (typeof obj === 'number') {
    return `0x${numToHex(obj)}`;
  }

  if (typeof obj === 'object' && Array.isArray(obj)) {
    return obj.map(numToHex).join();
  }

  if (typeof obj === 'string') {
    return Array.prototype.map.call(obj, (_, i) => numToHex(obj.charCodeAt(i))).join('');
  }

  return null;
}

const Hex = {
  formatHexString,
};

module.exports.Hex = Hex;
