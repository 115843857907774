const DebugLib = require('solclient-debug');
const SMFLib = require('solclient-smf');
const { Check } = require('solclient-validate');
const { Convert, Hex } = require('solclient-convert');
const { ErrorSubcode, OperationError } = require('solclient-error');
const { HTTPConnection } = require('./http-connection');
const { LogFormatter } = require('solclient-log');
const { SMFClient } = require('../../smf-client');
const { TransportError } = require('../../transport-error');
const { TransportProtocol } = require('../../transport-protocols');
const { TransportReturnCode } = require('../../transport-return-codes');
const { TransportSessionEvent } = require('../../transport-session-event');
const { TransportSessionEventCode } = require('../../transport-session-event-codes');
const { TransportSessionState } = require('../../transport-session-states');
const { WebTransportSessionBase } = require('../web-transport-session-base');

const { int32ToStr, strToByteArray, strToHexArray } = Convert;
const { formatHexString } = Hex;

const {
  LOG_TRACE,
  LOG_DEBUG,
  LOG_ERROR,
  LOG_INFO,
} = new LogFormatter('[http-transport-session]');

/**
 * @private
 * @namespace Values for tracking current state of incoming streaming data
 */
const PacketReadState = {
  READING_HEADER: 0,
  STREAMING:      1,
};

// eslint-disable-next-line global-require
const BufferImpl = require('buffer').Buffer;

/**
 * @private
 */
const MSIE_TRANSPORT_PADDING = 257;

function adaptURL(url) {
  const v = url.match(/(ws|http)(s?:\/\/.+)/);
  return `http${v[2]}`;
}

/** ===========================================================================
 * HTTPTransportSession :
 *
 * This contains all data and code required to maintain HTTP transport sessions
 * with Solace routers
 * ============================================================================
 * @extends WebTransportSessionBase
 * @private
 */
class HTTPTransportSession extends WebTransportSessionBase {
  constructor(baseUrl, eventCB, client, props) {
    // Our internal data format (for now) is binary string, so we wrap the callback
    // in a function that does the required conversion to ArrayBuffer.
    super(baseUrl,
          eventCB,
          client,
          props);

    // const self = this;
    // logger.formatter = function formatter(...args) {
    //   return [self.sessionIdHex, ...args];
    // };

    // Set to true if we have the data token that we need for sending data to the router
    this._haveToken = true;

    // Maximum payload chunk size in web transport
    this._confMaxWebPayload = props.maxWebPayload;
    this._maxPayloadBytes = 0;

    // Timer that will keep track of the destroy time
    this._destroyTimer = null;
    this._destroyTimeout = props.connectTimeoutInMsecs;

    // The URL used for create messages
    this._createUrl = adaptURL(baseUrl);

    // The URL used for all other messages - it will have the router tag appended
    // after the session has been created
    this._routerUrl = this._createUrl;

    // SMF client (instantiated after session is created)
    this._rxChannelClient = null;
    // Send data connection (instantiated after session is created)
    this._httpSendConn = null;

    // Receive data connection (instantiated after session is created)
    this._httpReceiveConn = null;

    // Data Token SMF header - this is preformatted for performance
    // It will be set after session is created
    this._smfDataTokenTSHeader = null;

    // Router Tag - a string that will be added to HTTP request URLs
    this._routerTag = '';

    // Session ID - 8-byte identifier that will associate this client
    // with client resources on the router
    this._sid = null;

    if (props.transportProtocol === null || props.transportProtocol === undefined) {
      throw new OperationError('transportProtocol is not set', ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }

    this._transportProtocol = props.transportProtocol;
    this._useBinaryTransport = false;
    this._useStreamingTransport = false;
    this._streamingTransportPadding = 0;

    this._useBinaryTransport = (props.transportProtocol !== TransportProtocol.HTTP_BASE64);
    this._useStreamingTransport = (props.transportProtocol ===
                                   TransportProtocol.HTTP_BINARY_STREAMING);

    // extra state for STREAMING transport
    this._incomingBuffer = '';
    this._packetReadState = PacketReadState.READING_HEADER;

    const agent = navigator.userAgent || '';
    if (agent.match(/trident/i) || agent.match(/msie/i)) {
      this._streamingTransportPadding = MSIE_TRANSPORT_PADDING;
    }

    if (props.transportContentType === null || props.transportContentType === undefined) {
      throw new OperationError('transportContentType is not set', ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }
    this._contentType = props.transportContentType;
  }

  /**
   * @override
   */
  connectTimerExpiry() {
    LOG_INFO('HTTP transport connect timeout');
    this.destroyCleanup('HTTP transport connect timeout', ErrorSubcode.TIMEOUT);
  }

  get sessionIdHex() {
    return (this._sid) ? formatHexString(this._sid) : '';
  }

  updateMaxWebPayload() {
    // 22 Bytes of TransportSMF wrapping overhead
    const trLessEncapSMF = this._confMaxWebPayload - 22;
    // Base64 has a 4:3 expansion
    this._maxPayloadBytes = this._useBinaryTransport
      ? trLessEncapSMF
      : Math.floor(trLessEncapSMF * 0.75);
  }

  /**
   * Connect transport session to router
   * @returns {TransportReturnCode} The result of the operation
   */
  connect() {
    // Check that we we are in an acceptable state for connection
    if (this._state !== TransportSessionState.DOWN) {
      return TransportReturnCode.INVALID_STATE_FOR_OPERATION;
    }

    return this.connectInternal();
  }

  connectInternal() {
    // Create the XHR to talk to the router
    this._connError = null;
    try {
      this._createConn = new HTTPConnection(this._createUrl,
                                            !(this._useBinaryTransport),
                                            false,
                                            (rc, data) => this.handleCreateResponse(rc, data),
                                            (rc, data) => this.handleCreateConnFailure(rc, data),
                                            this._contentType);
    } catch (e) {
      LOG_INFO(`Failed to create connection to router: ${e.message}`);
      this._connError = e;
      return TransportReturnCode.CONNECTION_ERROR;
    }
    if (Check.nothing(this._createConn)) {
      LOG_INFO('Failed to create connection to router');
      return TransportReturnCode.CONNECTION_ERROR;
    }

    // Get an SMF transport session create message
    const createMsg = SMFLib.Codec.Transport.genTsCreateHeader();

    if (this._state === TransportSessionState.WAITING_FOR_CREATE) {
      // already connecting (this is likely a retry with Base64 encoding)
      LOG_DEBUG('Connect attempt while in WAITING_FOR_CREATE (retry)');
    } else {
      this.createConnectTimeout();
      // Set the current state
      this._state = TransportSessionState.WAITING_FOR_CREATE;
    }

    // Send the create message to the router.  When the response is received, the
    // handleCreateResponse method will be called
    try {
      this._createConn.send(createMsg);
    } catch (connError) {
      LOG_INFO(`Error connecting: ${connError.message}`);
      LOG_TRACE('Error details:', connError.stack || connError);
      this._state = TransportSessionState.CONNECTION_FAILED;
      this.cancelConnectTimeout();
      if (connError instanceof TransportError) {
        this._connError = connError;
      } else {
        this._connError = new TransportError(
          `Could not create HTTP transport session: ${connError.message}`,
          connError.subcode || ErrorSubcode.CONNECTION_ERROR);
      }
      return TransportReturnCode.CONNECTION_ERROR;
    }

    return TransportReturnCode.OK;
  }

  /**
   * Destroy transport session to router
   * @param {String} msg The message associated with the operation
   * @param {ErrorSubcode} subcode The subcode associated with the operation
   * @returns {TransportReturnCode} The result of the operation
   */
  destroy(msg, subcode) {
    LOG_TRACE(`Destroy transport session when in state ${this._state}`);
    if (this._state === TransportSessionState.WAITING_FOR_DESTROY ||
        this._state === TransportSessionState.DOWN) {
      // Nothing to do
      return TransportReturnCode.OK;
    }

    if (this._state === TransportSessionState.CONNECTION_FAILED ||
        this._state === TransportSessionState.WAITING_FOR_CREATE) {
      // The connections are in an unreliable state - we will just
      // kill our local object and let the router clean itself up with its inactivity timer
      LOG_INFO('The connection is in unreliable state, close transport');
      this.destroyCleanup(msg, subcode, true);
      return TransportReturnCode.OK;
    }

    LOG_INFO('Destroy transport session immediately');
    // Set the current state
    this._state = TransportSessionState.WAITING_FOR_DESTROY;

    // Abort any current requests for this session
    if (this._httpSendConn !== null) {
      LOG_INFO('Destroy transport session: abort sendConn');
      this._httpSendConn.abort();
    }
    if (this._httpReceiveConn !== null) {
      LOG_INFO('Destroy transport session: abort receiveConn');
      this._httpReceiveConn.abort();
    }

    // Start a timer
    this._destroyTimer = setTimeout(() => {
      this.destroyTimerExpiry();
    }, this._destroyTimeout);

    // Send the destroy message over new HTTPConnection to the router so that the async abort
    // can properly finish in the old _httpSendConn.
    // When the response is received, the handleDestroyResponse method will be called.
    this._httpSendConn = new HTTPConnection(
        this._routerUrl,
        !(this._useBinaryTransport), false,
        (rc, data) => this.handleRxDataToken(rc, data), // RxData callback
        (rc, data) => this.handleSendFailure(rc, data), // connection close or error callback
        this._contentType,
        true);

    // Get an SMF transport session destroy message
    const destroyMsg = SMFLib.Codec.Transport.genTsDestroyHeader(this._sid);

    LOG_TRACE(`destroy message: ${strToHexArray(destroyMsg)}`);
    this._httpSendConn.send(destroyMsg);

    return TransportReturnCode.OK;
  }

  /**
   * Send data over the connection - this requires a send token
   * @param {String} dataIn The data to send
   * @param {Boolean} [forceAllowEnqueue=false] If `true`, do not fail due to out of space
   * @returns {TransportReturnCode} The result of the operation
   */
  send(dataIn, forceAllowEnqueue = false) {
    let data = dataIn;
    // LOG_TRACE(`HTTPTransportSession:send ${data.length}, tx_queued:${this._queuedDataSize}`);
    if (this._state !== TransportSessionState.SESSION_UP) {
      return TransportReturnCode.INVALID_STATE_FOR_OPERATION;
    }

    // Check to see if we already have queued data
    if ((this._queuedData.length > 0) || (!this._haveToken)) {
      return this.enqueueData(data, forceAllowEnqueue);
    }

    // Check if we need to chop up the payload
    let remainder = null;
    if (data.length > this._maxPayloadBytes) {
      remainder = data.substr(this._maxPayloadBytes);
      data = data.substr(0, this._maxPayloadBytes);

      // If no space for remainder, return FAIL without sending anything.
      if (!this.allowEnqueue(remainder.length)) {
        return this.enqueueFailNoSpace();
      }

    // LOG_DEBUG("$$ send dataChunk:" + data.length + ", remainderChunk:" + remainder.length);
    }

    // We have the token, so send the data
    this._haveToken = false;

    const transportPacketLen = (this._smfDataTSHeaderParts[0].length + 4 +
                                this._smfDataTSHeaderParts[1].length + data.length);

    this._httpSendConn.send(this._smfDataTSHeaderParts[0] +
                            int32ToStr(transportPacketLen) +
                            this._smfDataTSHeaderParts[1] +
                            data);
    this._clientstats.bytesWritten += data.length;

    if (remainder) {
      // The message was partially sent. The message written count will be incremented
      // when its last bytes go out.
      return this.enqueueData(remainder, null);
    }

    // The whole message was sent.
    this._clientstats.msgWritten++;
    return TransportReturnCode.OK;
  }

  /**
   * Push data onto the pending send queue as long as it doesn't violate
   * the max stored message size
   * @param {String} data The data to enqueue
   * @param {Boolean} [forceAllowEnqueue=false] If `true`, don't fail due to no space.
   * @returns {TransportReturnCode} The result of the operation.
   */
  enqueueData(data, forceAllowEnqueue = false) {
    const dataLen = data.length;

    // LOG_DEBUG("enqueueing data: " + data.length + ", queue depth: " + this._queuedDataSize);
    if (forceAllowEnqueue || this.allowEnqueue(dataLen)) {
      this._queuedDataSize += dataLen;
      this._queuedData.push(data);
    } else {
      return this.enqueueFailNoSpace();
    }

    return TransportReturnCode.OK;
  }

  /**
   * Set the data in the preformatted headers.  The headers are set up this way
   * for performance reasons
   * @param {String} sid The session ID to incorporate into the headers
   */
  initPreformattedHeaders(sid) {
    // _smfDataTSHeaderParts is a two entry array - one part before the total length
    // and the other after.  The total length is not known until actual data is sent
    this._smfDataTSHeaderParts = SMFLib.Codec.Transport.genTsDataMsgHeaderParts(sid);

    // _smfDataTokenTSHeader is a single header that all data-token messages require
    if (this._useStreamingTransport) {
      this._smfDataTokenTSHeader = SMFLib.Codec.Transport.genTsDataStreamTokenMsg(
        sid,
        this._streamingTransportPadding);
    } else {
      this._smfDataTokenTSHeader = SMFLib.Codec.Transport.genTsDataTokenMsg(sid);
    }
  }

  /**
   * @override
   */
  flush(callback) {
    if (this._queuedDataSize) {
      this._flushCallback = callback;
    } else {
      callback();
    }
  }

  /**
   * Check if there is any data waiting to be sent to the router.
   * If there is, send it.
   */
  sendQueuedData() {
    if (this._queuedDataSize === 0) {
      return;
    }
    this._haveToken = false;
    const data = this.getQueuedDataToSend();
    const transportPacketLen = this._smfDataTSHeaderParts[0].length + 4 +
                               this._smfDataTSHeaderParts[1].length + data.length;

    this._httpSendConn.send(this._smfDataTSHeaderParts[0] +
                            int32ToStr(transportPacketLen) +
                            this._smfDataTSHeaderParts[1] +
                            data);
    this._clientstats.bytesWritten += data.length;


    if (this._canSendNeeded) {
      this._canSendNeeded = false;
      this._eventCB(
        new TransportSessionEvent(TransportSessionEventCode.CAN_ACCEPT_DATA,
                                  '',
                                  null,
                                  0,
                                  this._sid));
    }

    if (this._flushCallback) {
      const cb = this._flushCallback;
      this._flushCallback = null;
      cb();
    }
  }

  // Internal Callbacks

  // Called when a create response message has been received
  handleCreateResponse(tsRc, response) {
    if (this._state === TransportSessionState.WAITING_FOR_DESTROY ||
        this._state === TransportSessionState.DOWN) {
      LOG_DEBUG('Received create response on a destroyed transport session, ignore');
      return;
    }

    // Was: stop the connect timer. We don't do that in this transport now.
    // We wait for the login response.

    // We know whether we're using Base64 or not, so update our max payload size.
    this.updateMaxWebPayload();

    if (tsRc !== TransportReturnCode.OK) {
      LOG_INFO(`Received create response with return code ${TransportReturnCode.describe(tsRc)}`);
      if (tsRc === TransportReturnCode.DATA_DECODE_ERROR) {
        this.destroyCleanup('Received data decode error on create session response', ErrorSubcode.DATA_DECODE_ERROR);
      } else {
        this.destroyCleanup('Failed to handle create session response', ErrorSubcode.CONNECTION_ERROR);
      }
      return;
    }

    if (response.length === 0) {
      return; // null read indicating end of stream
    }

    // Parse the Transport Session SMF
    const parsedResponse = SMFLib.Codec.Decode.decodeCompoundMessage(BufferImpl.from(response, 'latin1'), 0);
    if (!parsedResponse) {
      LOG_ERROR('Could not parse create response as SMF. Destroying transport');
      this.destroyCleanup('Failed to parse create response message', ErrorSubcode.CONNECTION_ERROR);
      return;
    }

    const smfresponse = parsedResponse.getResponse();
    if (smfresponse.responseCode !== 200) {
      this.destroyCleanup(`Transport create request failed (${smfresponse.responseCode}, ${smfresponse.responseString})`,
                          ErrorSubcode.CONNECTION_ERROR);
      return;
    }

    this.cancelConnectTimeout();
    this._createConn.abort();
    this._createConn = null;
    this._state = TransportSessionState.SESSION_UP;
    this._sid = parsedResponse.sessionId;
    this._routerTag = parsedResponse.routerTag;

    // Trim any parameters off the create url before using it for the routerUrl
    this._routerUrl = this._createUrl.replace(/\?.*/, '');
    if (this._routerTag !== '') {
      this._routerUrl = this._routerUrl + this._routerTag;
    }

    this.initPreformattedHeaders(this._sid);
    const useBase64 = !this._useBinaryTransport;
    const useStreaming = this._useStreamingTransport;

    // Create the two connections to the router
    // By now, getXhrObj() should not throw any exception inside HTTPConnection constructor
    this._httpSendConn = new HTTPConnection(this._routerUrl, useBase64, false,
        (rc, data) => this.handleRxDataToken(rc, data), // RxData callback
        (rc, data) => this.handleSendFailure(rc, data), // connection close or error callback
        this._contentType);
    if (this._useStreamingTransport) {
      // When the transport is HTTP_BINARY_STREAMING the SMF encapsulation
      // is complete, the SMF header indicates a message length of 0xFFFFFFFF
      // and after decoding just the header alone the data is passed through
      // to the session layer, so we must use a simplified SMF parser and a
      // stateful data callback in that case.
      this._httpReceiveConn = new HTTPConnection(this._routerUrl, useBase64, useStreaming,
            (rc, data) => this.handleRxStreaming(rc, data), // RxData Callback
            (rc, data) => this.handleSendFailure(rc, data), // connection close or error callback
            this._contentType,
            true);
    } else {
      // Create a SMF client for the Receive Data channel, when http is used.
      // SMF messages are encapsualated in a solace http-transport which is itself
      // encapsulated in SMF.  So create an SMF client that will callback with
      // an smfMessage construct to the HTTPTransportSession.
      this._rxChannelClient = new SMFClient(
            rxData => this.handleSmfMessage(rxData),
            rxError => this.handleSmfParseError(rxError),
            null);    // we don't have a 'session' for this client. It's just a parser.
      this._httpReceiveConn = new HTTPConnection(this._routerUrl, useBase64, useStreaming,
            (rc, data) => this.handleRxData(rc, data), // RxData Callback
            (rc, data) => this.handleSendFailure(rc, data), // connection close or error callback
            this._contentType);
    }

    // Give the router the data token so that it will be able to send data
    this._httpReceiveConn.send(this._smfDataTokenTSHeader);

    // Send the event to the application letting it know that the session is up
    this._eventCB(
      new TransportSessionEvent(TransportSessionEventCode.UP_NOTICE,
                                smfresponse.responseString,
                                smfresponse.responseCode,
                                0,
                                parsedResponse.sessionId));
  }

  // Called when receiving a destroy response
  handleDestroyResponse(response) {
    LOG_DEBUG('Handle destroy response');
    // Stop the timer
    this.cancelDestroyTimeout();
    const innerResponse = response.getResponse();
    const responseString = innerResponse ? innerResponse.responseString : '';
    this.destroyCleanup(
      `${responseString} handled Destroy Response addressed to session ${
      formatHexString(response.sessionId)}, on session ${formatHexString(this._sid)}`,
      0);
  }

  handleSmfMessage(tsmsg) {
    const smfHeader = tsmsg.smfHeader;
    if (smfHeader.smf_protocol !== SMFLib.SMFProtocol.TSESSION) {
      this.handleSmfParseError(`Unexpected Message Prototcol (${smfHeader.smf_protocol}) on ReceiveData connection`);
      return;
    }

      // we have found a transport SMF, can we now read the Transport SMF chunk
    const data = tsmsg.payload;
    const TotalPayloadToRead = tsmsg.payloadLength;

    switch (tsmsg.messageType) {
      case SMFLib.SMFTransportSessionMessageType.DESTROY_RESP:
        this.handleDestroyResponse(tsmsg);
        return;

      case SMFLib.SMFTransportSessionMessageType.DATA:
        if (tsmsg.sessionId !== this._sid) {
          // The router may have given us an error code; if so, include in the error message.
          const smfErrResponse = tsmsg.getResponse();
          const responseErrStr = smfErrResponse
              ? (` (${smfErrResponse.responseCode} ${smfErrResponse.responseString})`)
              : '';
          const responseCode = smfErrResponse ? smfErrResponse.responseCode : null;

          LOG_DEBUG(`HandleRxData Bad Session ID received in message. Expected: ${strToByteArray(this._sid)
                        }, Received: ${strToByteArray(tsmsg.sessionId)}${responseErrStr}`);

          this._state = TransportSessionState.CONNECTION_FAILED;
          this._eventCB(new TransportSessionEvent(TransportSessionEventCode.PARSE_FAILURE,
                        `Session ID mismatch in data message, expected: ${formatHexString(this._sid)}, got: ${
                        formatHexString(tsmsg.sessionId)}, ${responseErrStr}`,
                        responseCode,
                        ErrorSubcode.PROTOCOL_ERROR, this._sid));
          return;
        }

        // pass-through encapsulated data to parent
        if (TotalPayloadToRead > 0) {
          this._client.rxDataBuffer(data);
        }
        break;
      default:
        // Unexpected message type
        this.handleSmfParseError(`Unexpected message type (${
                    tsmsg.messageType}) on ReceiveData connection`);
    }
  }

  handleSmfParseError() {
    this._eventCB(new TransportSessionEvent(TransportSessionEventCode.DATA_DECODE_ERROR,
        'Received data decode error', null,
        ErrorSubcode.DATA_DECODE_ERROR, this._sid));
  }

  // Called when data is received on the connection
  handleRxData(tsRc, data) {
    if (this._httpReceiveConn === null || this._rxChannelClient === null) {
      if (this._state === TransportSessionState.DOWN) {
        LOG_INFO('Transport session is down, ignore data from receive connection');
      } else {
        LOG_ERROR(`Transport session is not in working state, state: ${this._state}`);
      }
      return;
    }

    if (this._state === TransportSessionState.WAITING_FOR_DESTROY) {
      LOG_DEBUG('Transport session is being destroyed, ignore data from receive connection, ' +
               `dump first 64 bytes (or fewer) of data:\n${
                 DebugLib.Debug.formatDumpBytes(data.substring(0, 64), true, 0)}`);
      return;
    }

    this._httpReceiveConn.recStat('GotData');
    if (tsRc !== TransportReturnCode.OK) {
      this.handleRxError(tsRc, data);
      return;
    }

    if (data.length === 0) {
      LOG_DEBUG('Send write token to router');
      this._httpReceiveConn.send(this._smfDataTokenTSHeader);
    } else {
      this._rxChannelClient.rxDataString(data);
    } // end have data to process
  }

  // Called when data is received on a HTTP_BINARY_STREAMING connection
  handleRxStreaming(tsRc, data) {
    if (this._httpReceiveConn === null) {
      if (this._state === TransportSessionState.DOWN) {
        LOG_DEBUG('Transport session is down, ignore data from receive connection');
      } else {
        LOG_ERROR(`Transport session is not in working state, state: ${this._state}`);
      }
      return;
    }

    if (this._state === TransportSessionState.WAITING_FOR_DESTROY) {
      LOG_DEBUG('Transport session is being destroyed, ignore data from streaming receive ' +
               `connection, dump first 64 bytes (or fewer) of data:\n${
               DebugLib.Debug.formatDumpBytes(data.substring(0, 64), true, 0)}`);
      return;
    }

    this._httpReceiveConn.recStat('GotData');
    if (tsRc !== TransportReturnCode.OK) {
      this.handleRxError(tsRc, data);
      return;
    }

    if (data.length === 0) {
      LOG_TRACE('Send write token to router');
      this._packetReadState = PacketReadState.READING_HEADER;
      this._httpReceiveConn.send(this._smfDataTokenTSHeader);
      return;
    }

    // pass-through encapsulated data to parent
    if (this._packetReadState === PacketReadState.STREAMING) {
      this._client.rxDataString(data);
      return;
    }

    this._incomingBuffer += data;
    const smfheader = SMFLib.Codec.ParseSMF.parseSMFAt(BufferImpl.from(this._incomingBuffer, 'latin1'),
                                                       0, true);
    if (smfheader) {
        // we have a valid smf header, see if there is a transport header and session-id
      const tsmsg = SMFLib.Codec.Transport.parseTsSmfHdrAt(BufferImpl.from(this._incomingBuffer, 'latin1'),
                                                           smfheader.headerLength,
                                                           smfheader);
      if (!tsmsg) {
        // Not tsMsg but there is an smf header, just return until more data arrives
        return;
      }

      // We have the transport message header too, if it is a DATA message enter
      // STREAMING state
      switch (tsmsg.messageType) {
        case SMFLib.SMFTransportSessionMessageType.DESTROY_RESP:
          this.handleDestroyResponse(tsmsg);
          return;

        case SMFLib.SMFTransportSessionMessageType.DATA:
          if (tsmsg.sessionId !== this._sid) {
            // The router may have given us an error code, if so, include in the error message.
            const smfErrResponse = tsmsg.getResponse();
            const responseErrStr = smfErrResponse
                ? (` (${smfErrResponse.responseCode} ${smfErrResponse.responseString})`)
                : '';
            const responseCode = smfErrResponse ? smfErrResponse.responseCode : null;

            LOG_DEBUG(`HandleRxData Bad Session ID received in message.  Expected: ${strToByteArray(this._sid)
                        }, Received: ${strToByteArray(tsmsg.sessionId)}${responseErrStr}`);
            LOG_DEBUG(`First 64 bytes (or fewer) of message: ${strToByteArray(data.substr(0, 64))}`);

            this._state = TransportSessionState.CONNECTION_FAILED;
            this._eventCB(
              new TransportSessionEvent(TransportSessionEventCode.PARSE_FAILURE,
                                        `Session ID mismatch in data message, expected: ${formatHexString(this._sid)}, got: ${
                                        formatHexString(tsmsg.sessionId)}, ${responseErrStr}`,
                                        responseCode,
                                        ErrorSubcode.PROTOCOL_ERROR, this._sid));
            return;
          }
          // all is good. We can now STREAM the rest of the data until a empty message is received.
          this._packetReadState = PacketReadState.STREAMING;
          // pass-through any remaining data
          if (this._incomingBuffer.length > (smfheader.headerLength + tsmsg.tsHeaderLength)) {
            this._client.rxDataString(
              this._incomingBuffer.substr(smfheader.headerLength + tsmsg.tsHeaderLength));
          }
          this._incomingBuffer = '';
          return;

        default:
          // Unexpected message type
          throw new TransportError(`Unexpected message type (${tsmsg.messageType}) on ReceiveData connection`, 0);
      }
    } else if (SMFLib.Codec.ParseSMF.isSMFHeaderAvailable(BufferImpl.from(this._incomingBuffer, 'latin1'), 0) &&
               !SMFLib.Codec.ParseSMF.isSMFHeaderValid(BufferImpl.from(this._incomingBuffer, 'latin1'), 0)) {
      // Probably lost framing
      LOG_ERROR(`Couldn't decode message due to invalid smf header, dump first 64 bytes (or fewer) of buffer content:\n${
                 DebugLib.Debug.formatDumpBytes(this._incomingBuffer.substring(0, 64), true, 0)}`);

      const errorInfo = 'Error parsing incoming message - invalid SMF header detected';
      this._state = TransportSessionState.CONNECTION_FAILED;
      this._eventCB(
        new TransportSessionEvent(TransportSessionEventCode.PARSE_FAILURE,
                                  errorInfo, null,
                                  ErrorSubcode.PROTOCOL_ERROR,
                                  null));
    }
  }

  // Called when data is received on the httpDataSend
  handleRxDataToken(tsRc, data) {
    if (tsRc !== TransportReturnCode.OK) {
      this.handleRxError(tsRc, data);
      return;
    }

    if (data.length === 0) {
      return; // handle End of Stream
    }

    const parsedResponse = SMFLib.Codec.Decode.decodeCompoundMessage(BufferImpl.from(data, 'latin1'), 0);
    if (!parsedResponse) {
      if (this._state !== TransportSessionState.WAITING_FOR_DESTROY) {
        this._state = TransportSessionState.CONNECTION_FAILED;
        this._eventCB(new TransportSessionEvent(TransportSessionEventCode.PARSE_FAILURE,
                'Failed to parse received data message', null,
                ErrorSubcode.PROTOCOL_ERROR, this._sid));
      } else {
        this.destroyCleanup('Failed to parse received data message', ErrorSubcode.PROTOCOL_ERROR);
      }
      return;
    }

    if (parsedResponse.messageType === SMFLib.SMFTransportSessionMessageType.DESTROY_RESP) {
      this.handleDestroyResponse(parsedResponse);
      return;
    }

    if (parsedResponse.sessionId !== this._sid) {
        // The router may have given us an error code, if so, include in the error message.
      const smfErrResponse = parsedResponse.getResponse();
      const responseErrStr = smfErrResponse ?
            (` (${smfErrResponse.responseCode} ${smfErrResponse.responseString})`) :
            '';
      const responseCode = smfErrResponse ? smfErrResponse.responseCode : null;

      LOG_DEBUG(`HandleRxDataToken Bad SID received in message.  Expected: ${strToByteArray(this._sid)
            }, Received: ${strToByteArray(parsedResponse.sessionId)}${responseErrStr}`);
      LOG_DEBUG(`First 64 bytes (or fewer) of message: ${strToByteArray(data.substr(0, 64))}`);

      if (this._state !== TransportSessionState.WAITING_FOR_DESTROY) {
        this._state = TransportSessionState.CONNECTION_FAILED;
        this._eventCB(new TransportSessionEvent(TransportSessionEventCode.PARSE_FAILURE,
                `Session ID mismatch in response message, expected: ${formatHexString(this._sid)}, got: ${formatHexString(parsedResponse.sessionId)}, ${responseErrStr}`,
                responseCode, ErrorSubcode.PROTOCOL_ERROR, this._sid));
      } else {
        this.destroyCleanup('Session ID mismatch in response message', ErrorSubcode.PROTOCOL_ERROR);
      }
      return;
    }

    if (parsedResponse.messageType ===
        SMFLib.SMFTransportSessionMessageType.DATA_TOKEN ||
        parsedResponse.messageType ===
        SMFLib.SMFTransportSessionMessageType.DATA_STREAM_TOKEN) {
      this._haveToken = true;
      this._httpSendConn.recStat('GotToken');
      // this._eventCB(
      //    new TransportSessionEvent(TransportSessionEventCode.NOTIFY_GOT_TOKEN, "", null, null));
      this.sendQueuedData();
    } else {
        // Unexpected message type
      throw (new TransportError(`Unexpected message type (${
            parsedResponse.messageType}) on SendData connection`, 0));
    }
  }

  handleRxError(tsRc) {
    LOG_INFO(`handleRxError, transport return code ${TransportReturnCode.name(tsRc)}`);
    this._state = TransportSessionState.CONNECTION_FAILED;
    if (tsRc === TransportReturnCode.DATA_DECODE_ERROR) {
      this._eventCB(new TransportSessionEvent(TransportSessionEventCode.DATA_DECODE_ERROR,
            'Received data decode error', null,
            ErrorSubcode.DATA_DECODE_ERROR, this._sid));
    } else {
      this._eventCB(new TransportSessionEvent(TransportSessionEventCode.SEND_ERROR,
            'Connection error',
            ErrorSubcode.CONNECTION_ERROR, this._sid));
    }
  }

  // Called when there is an error on a connection or the connection is aborted
  handleSendFailure(status, msg) {
    // failed to send message, if it is a destroy message, just complete the destroy process\
    if (this._state === TransportSessionState.WAITING_FOR_DESTROY) {
      LOG_INFO(`Connection destroy failure (${msg}) while in state ${this._state}`);
      this.destroyCleanup(`Connection destroy failure: ${msg}`, ErrorSubcode.CONNECTION_ERROR);
    } else {
      // Failed to send message, return error to upper layer which may  tear the session down
      LOG_INFO(`Connection failure (${msg}) while in state ${this._state}`);
      this._eventCB(new TransportSessionEvent(TransportSessionEventCode.SEND_ERROR,
            `Connection error: ${msg}`, status,
            ErrorSubcode.CONNECTION_ERROR, this._sid));
    }
  }

  // Called when there is an error on a connection for a session create request
  handleCreateConnFailure(status, msg) {
    if (this._state === TransportSessionState.DOWN) {
      return;
    }

    LOG_INFO(`Connection create failure (${msg}) while in state ${this._state}`);
    this.destroyCleanup(`Connection create failure: ${msg}`, ErrorSubcode.CONNECTION_ERROR);
  }

  // Called when the destroy timer expires
  destroyTimerExpiry() {
    this.destroyCleanup('Destroy request timeout', ErrorSubcode.CONNECTION_ERROR);
  }

  cancelDestroyTimeout() {
    if (this._destroyTimer) {
      clearTimeout(this._destroyTimer);
      this._destroyTimer = null;
    }
  }

  /**
   * Called after receiving ts destroy response from router
   * @param {String} infoStr The informational string to pass along
   * @param {ErrorSubcode} subcode The subcode associated with the event
   * @param {Boolean} asyncSendEvent If true, always send the event asynchronously.
   */
  destroyCleanup(infoStr, subcode, asyncSendEvent) {
    LOG_DEBUG(`Destroy cleanup: ${infoStr}`);

    // Abort any current requests for this session
    if (this._createConn) {
      LOG_DEBUG('Destroy cleanup: Abort createConn');
      this._createConn.abort();
    }
    if (this._httpSendConn) {
      LOG_DEBUG('Destroy cleanup: Abort sendConn');
      this._httpSendConn.abort();
    }
    if (this._httpReceiveConn) {
      LOG_DEBUG('Destroy cleanup: Abort receiveConn');
      this._httpReceiveConn.abort();
    }

    // Clear most internal state
    this._createUrl = null;
    this._routerUrl = null;
    this._createConn = null;
    this._httpSendConn = null;
    this._httpReceiveConn = null;
    this._smfDataTokenTSHeader = null;
    this._rxChannelClient = null;
    this._routerTag = '';
    this._queuedData = [];
    this._queuedDataSize = 0;
    this._canSendNeeded = false;

    // Clear timers.
    this.cancelDestroyTimeout();
    this.cancelConnectTimeout();

    // Set final state
    this._state = TransportSessionState.DOWN;

    // Send the event to the application letting it know that the session is down
    const finalize = () => {
      // Check whether the callback was cleared before the timeout completes.
      if (this._eventCB) {
        this._eventCB(
            new TransportSessionEvent(TransportSessionEventCode.DESTROYED_NOTICE,
                                      infoStr || 'Session is destroyed',
                                      null,
                                      subcode || 0,
                                      this._sid));
      }

      // release reference to smf client object
      this._client = null;
      // release reference to session object
      this._eventCB = null;
    };

    if (asyncSendEvent) {
      setTimeout(finalize, 0); // opportunity to use setImmediate instead
    } else {
      finalize();
    }
  }

  getInfoStr() {
    const str = `HTTPTransportSession; sid=${
        formatHexString(this._sid)
        }; routerTag=${this._routerTag}`;
    return str;
  }

}

module.exports.HTTPTransportSession = HTTPTransportSession;
