const { Enum } = require('solclient-eskit');

/**
 * Represents an enumeration of session properties that can be modified by
 * {@link solace.Session.updateProperty} after the {@link solace.Session} is originally
 * created.
 *
 * These correspond to session properties in {@link solace.SessionProperties}.
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const MutableSessionProperty = {
  /**
   * Client name: {@link solace.SessionProperties#clientName}
   * @type {Number}
   */
  CLIENT_NAME:        1,
  /**
   * Application description: {@link solace.SessionProperties#applicationDescription}
   * @type {Number}
   */
  CLIENT_DESCRIPTION: 2,
};

module.exports.MutableSessionProperty = Enum.new(MutableSessionProperty);
