const { TransportProtocol } = require('../transport-protocols');
const { TSHState } = require('../tsh-state');

/**
 * @classdesc
 * HTTP Base64 transport
 * @memberof solace
 * @private
 */
class StateBase64 extends TSHState {
  /**
   * @constructor
   * @extends TSHState
   * @param {Boolean} useSsl True if SSL should be used
   * @param {Function} exitCb Callback to notify on exit
   * @param {?TSHState} nextState Downgrade target
   */
  constructor(useSsl, exitCb, nextState) {
    super(useSsl, TransportProtocol.HTTP_BASE64, exitCb, nextState);
  }
}

module.exports.StateBase64 = StateBase64;

