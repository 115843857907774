/**
 * @classdesc
 * <b>This class is not exposed for construction by API users.</b>
 * The base class for all errors thrown by the API.
 * @hideconstructor
 * @extends {Error}
 *
 * @memberof solace
 */
class SolaceError extends Error {
  constructor(type, message, constructor) {
    super(message || '');
    /**
     * Error Message.
     * @name solace.SolaceError#message
     * @type {String}
     */
    this.message = message; // force set
    /**
     * The name of the error.
     * @name solace.SolaceError#name
     * @type {String}
     */
    this.name = type;

    // ES6 error semantics are disappointing
    // Cleaner stack capture for V8-based VMs
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, constructor);
    } else {
      this.stack = new Error().stack;
    }
  }

}

module.exports.SolaceError = SolaceError;
