const DebugLib = require('solclient-debug');
const MessageDumpStandardProviderLib = require('./message-dump-standard-provider');
const {
  SDTFieldType,
  SDTMapContainer,
  SDTStreamContainer,
  SDTUnsupportedValueError,
  SDTValueErrorSubcode,
} = require('solclient-sdt');
const { Check } = require('solclient-validate');
const { ErrorSubcode, OperationError } = require('solclient-error');
const { MessageDumpFlag } = require('./message-dump-flags');
const { StringBuffer, StringUtils } = require('solclient-util');

const MessageDumpState = {
  get dumpProviders() {
    const providers = MessageDumpStandardProviderLib.MessageDumpStandardProvider;
    return Object.keys(providers).map(k => providers[k]);
  },
};

const MessageDumpUtil = {
  getOutOfRangeValue(rawData) {
    if (typeof rawData === 'string') {
      return `<out of range>\n${DebugLib.Debug.formatDumpBytes(rawData)}`;
    }
    return `<out of range>\n${DebugLib.Debug.formatDumpBytes(rawData.toString('latin1'))}`;
  },

  getValue(sdtField) {
    let value = null;
    try {
      value = sdtField.getValue();
      return value;
    } catch (e) {
      if (e instanceof SDTUnsupportedValueError) {
        if (e.getSubcode() === SDTValueErrorSubcode.VALUE_OUTSIDE_SUPPORTED_RANGE) {
          return this.getOutOfRangeValue(e.getSourceData());
        }
      } else if (e instanceof OperationError && e.subcode === ErrorSubcode.PARAMETER_INVALID_TYPE) {
        return '<invalid type>';
      }
      throw e;
    }
  },

  printMap(sdtMap, indent) {
    if (Check.nothing(sdtMap) || !(sdtMap instanceof SDTMapContainer)) {
      return null;
    }
    const lines = [];
    const strIndent = StringUtils.padRight('', indent, ' ');
    const keys = sdtMap.getKeys().sort();
    keys.forEach((key) => {
      const sdtFieldValue = sdtMap.getField(key);
      const type = sdtFieldValue.getType();
      const value = this.getValue(sdtFieldValue);
      let strValue;
      switch (type) {
        case SDTFieldType.MAP:
          strValue = `\n${this.printMap(value, indent + 2)}`;
          break;
        case SDTFieldType.STREAM:
          strValue = `\n${this.printStream(value, indent + 2)}`;
          break;
        case SDTFieldType.BYTEARRAY:
          strValue = DebugLib.Debug.formatDumpBytes(value.toString('latin1'), false, 0);
          if (strValue !== null && strValue.substr(-1) === '\n') {
            strValue = strValue.substring(0, strValue.length - 1);
          }
          break;
        default:
          strValue = (value !== null) ? value.toString() : null;
      }
      lines.push(`${strIndent}Key '${key}' (${SDTFieldType.nameOf(type)}): ${strValue}`);
    });
    return lines.join('\n');
  },

  printStream(sdtStream, indent) {
    if (Check.nothing(sdtStream) || !(sdtStream instanceof SDTStreamContainer)) {
      return null;
    }
    sdtStream.rewind();
    const lines = [];
    const strIndent = StringUtils.padRight('', indent, ' ');
    while (sdtStream.hasNext()) {
      const sdtFieldValue = sdtStream.getNext();
      const type = sdtFieldValue.getType();
      const value = this.getValue(sdtFieldValue);

      let strValue;
      switch (type) {
        case SDTFieldType.MAP:
          strValue = `\n${this.printMap(value, indent + 2)}`;
          break;
        case SDTFieldType.STREAM:
          strValue = `\n${this.printStream(value, indent + 2)}`;
          break;
        case SDTFieldType.BYTEARRAY:
          strValue = DebugLib.Debug.formatDumpBytes(value.toString('latin1'), false, 0);
          if (strValue !== null && strValue.substr(-1) === '\n') {
            strValue = strValue.substring(0, strValue.length - 1);
          }
          break;
        case SDTFieldType.DESTINATION:
          strValue = value.toString();
          break;
        default:
          strValue = (value !== null) ? value.toString() : null;
      }
      lines.push(`${strIndent}(${SDTFieldType.nameOf(type)}): ${strValue}`);
    }
    sdtStream.rewind();
    return lines.join('\n');
  },

  countItems(sdtStream) {
    if (Check.nothing(sdtStream) || (!(sdtStream instanceof SDTStreamContainer))) {
      return 0;
    }
    sdtStream.rewind();
    let count = 0;
    while (sdtStream.hasNext()) {
      sdtStream.getNext();
      count++;
    }
    sdtStream.rewind();
    return count;
  },

  formatDate(timeStamp) {
    return new Date(timeStamp).toString();
  },

  dump(message, flags, separator, colPadding) {
    const sb = new StringBuffer();
    let theSeparator = '\n';
    let needSeparator = false;
    let theColPadding = 40;
    if (separator !== undefined && separator !== null && typeof separator === 'string') {
      theSeparator = separator;
    }
    if (colPadding !== undefined && colPadding !== null && typeof colPadding === 'number') {
      theColPadding = colPadding;
    }

    MessageDumpState.dumpProviders.forEach((provider, index) => {
      const [key, isPresent, value, detailValue] = provider(message, flags);
      if (!isPresent) {
        return;
      }
      if (needSeparator) {
        sb.append(theSeparator);
      }

      if (value === null || value.length === 0) {
        // If we have no VALUE field, this is probably a boolean flag
        // and we just end up displaying the key and a newline.
        sb.append(key);
      } else {
        sb.append(StringUtils.padRight(`${key}:`, theColPadding, ' '));
        sb.append(value);
      }

      if (detailValue !== null && (flags & MessageDumpFlag.MSGDUMP_FULL)) {
        sb.append('\n');
        if (detailValue.indexOf('  ') !== 0) {
          sb.append('  ');
        }
        sb.append(detailValue);
        if (detailValue.substr(-1) !== '\n' && index < (MessageDumpState.dumpProviders.length - 1)) {
          sb.append('\n');
        }
      }
      needSeparator = true;
    });
    return sb.toString();
  },
};

module.exports.MessageDumpUtil = MessageDumpUtil;
