const DebugLib = require('solclient-debug');
const SMFLib = require('solclient-smf');
const { BaseSMFClient } = require('./base-smf-client');
const { BufferQueue } = require('./buffer-queue');
const { Convert, Hex } = require('solclient-convert');
const { LogFormatter } = require('solclient-log');

const BufferImpl = require('buffer').Buffer;

const { stringToUint8Array } = Convert;
const { formatHexString } = Hex;

const SMF_LOST_FRAMING_THRESHOLD = 80000000;

const { LOG_ERROR } = new LogFormatter('[buffer-smf-client]');

function logPeekBuffer(buffer) {
  const bufPair = buffer.peekView(Math.min(buffer.remaining(), 64));
  LOG_ERROR(`First 64 bytes (or fewer) of incoming buffer: \n${
    DebugLib.Debug.formatDumpBytes(bufPair[0].toString('latin1', bufPair[1]), true, 0)}`);
}

/**
 * Interact with underlying transport to send and receive SMF messages
 * @private
 */
class BufferSMFClient extends BaseSMFClient {

  /**
   * @constructor
   * @param {Function} rxSmfCB The callback to notify on SMF binary data received
   * @param {Function} rxMessageErrorCB The callback to notify on message errors
   * @param {Session} session The session owning this client
   */
  constructor(rxSmfCB, rxMessageErrorCB, session) {
    super(rxSmfCB, rxMessageErrorCB, session);
    this._incomingBuffer = new BufferQueue(SMF_LOST_FRAMING_THRESHOLD);
  }

  reset() {
    super.reset();
    // Called from super constructor, so guard this
    if (this._incomingBuffer) this._incomingBuffer.reset();
  }

  /**
   * @param {String} data Incoming data as binary string
   */
  rxDataString(data) {
    this._rxDataCB(BufferImpl.from(stringToUint8Array(data)));
  }

  /**
   * @param {ArrayBuffer} data Incoming data
   */
  rxDataArrayBuffer(data) {
    this._rxDataCB(BufferImpl.from(data));
  }

  /**
   * @param {Buffer} data Incoming data
   */
  rxDataBuffer(data) {
    this._rxDataCB(data);
  }

  /**
   * Invoked by transport session. Handles multiple SMF messages in input, as well as defragmenting
   * partial SMF messages. The state we keep is in this._incomingBuffer.
   * @param {Buffer} data The binary data to decode
   */
  _rxDataCB(data) {
    if (this._session) {
      // each incoming data chunk resets KA counter
      this._session.resetKeepAliveCounter();
    }
    const buffer = this._incomingBuffer;
    const putSuccess = buffer.put(data);
    let remaining = buffer.remaining();

    if (!putSuccess) {
      // 80 megabytes - lost SMF framing: may never complete
      logPeekBuffer(buffer);
      this._rxMessageErrorCB(`Buffer overflow (length: ${remaining})`);
      this._incomingBuffer.reset();
    }

    while (remaining > 12) {
      const version = buffer.readUInt8(0) & 0x7;
      if (version !== 3) {
        LOG_ERROR(`Invalid smf version in smf header, version=${version}`);
        // Throw away the buffer and bail out
        LOG_ERROR("BufferSMFClient._rxDataCB(): couldn't decode message due to invalid smf header");
        logPeekBuffer(buffer);
        this._incomingBuffer.reset();
        this._rxMessageErrorCB('Error parsing incoming SMF - invalid SMF header detected');
        return;
      }

      const messageLen = buffer.readUInt32BE(8);
      if (messageLen > buffer.remaining()) {
        break; // no full message
      }
      const msgBuffer = buffer.peekView(messageLen);

      //const binaryString = messageBuffer.toString('binary');
      const incomingMsg = SMFLib.Codec.Decode.decodeCompoundMessage(msgBuffer[0], msgBuffer[1]);
      if (incomingMsg && incomingMsg.smfHeader) {
        buffer.advance(incomingMsg.smfHeader.messageLength);
        this._rxSmfCB(incomingMsg); // hand over to core API callback
      } else {
        // couldn't decode! Lost SMF framing.
        // throw away the buffer and bail out
        const sessionId = this._session ? this._session._sessionId : null;
        const sessionIdHex = sessionId ? formatHexString(sessionId) : 'N/A';
        LOG_ERROR(`BufferSMFClient._rxDataCB(): couldn't decode message (sessionId=${sessionIdHex})`);
        logPeekBuffer(buffer);
        this._incomingBuffer.reset();
        this._rxMessageErrorCB('Error parsing incoming SMF');
        return;
      }
      remaining = buffer.remaining();
    }

    if (remaining) {
      // We have a partial header, or a valid header and a partial message.
      // partial message remaining: keep it in incoming buffer
    } else {
      // clear incoming buffer
      this._incomingBuffer.reset();
    }
  }
}

module.exports.BufferSMFClient = BufferSMFClient;
