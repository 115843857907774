/**
 * An object managing a traversal of an Array-like object.
 *
 * @memberof solace
 * @property {function} deref Returns the value the iterator is
 *      referencing.
 * @property {function} incr Increments the iterator's reference in the
 *      iterator's data set.
 * @property {function} end Tests whether the iterator is at the end of
 *      the data set.  This MUST return false when calling deref() or
 *      incr().
 * @private
 */
class Iterator {

  /**
   * Creates an iterator to forward iterate over a specific contiguous
   * section of an array, specifically in the range [start, end)
   * @param {Array} arr The array to iterate over
   * @param {Number} [start=0] The first item the iterator should
   *  dereference
   * @param {Number} [end=arr.length] One beyond the last item in the array
   *  that the iterator should dereference.
   * @constructor
   */
  constructor(arr, start = 0, end = arr.length) {
    /**
     * The array being iterated.
     * @type {Array}
     * @private
     */
    this._arr = arr;
    /**
     * The current index of the enumeration.
     * @private
     * @type {Number}
     */
    this._index = start;
    /**
     * The exclusive end index, or the length, of the array.
     * @type {Number}
     */
    this._end = end;
  }

  /**
   * Returns the element at the iterator's position.
   * @returns {Object} The array element at the current position.
   */
  deref() {
    return this._arr[this._index];
  }

  /**
   * Increments the iterator.
   * @returns {Number} The new position of the iterator.
   */
  incr() {
    return ++this._index;
  }

  /**
   * Reports whether the iterator has completed its iteration.
   * @returns {Boolean} True if the iterator has reached the end of the iteration.
   */
  end() {
    return this._index >= this._end;
  }

  /**
   * Creates an iterator to forward iterate over a specific contiguous
   * section of an array, specifically in the range [start, end)
   *
   * @param {Array} arr The array to iterate over
   * @param {Number} [start=0] The first item the iterator should
   *  dereference
   * @param {Number} [end=arr.length] One beyond the last item in the array
   *  that the iterator should dereference.
   * @returns {solace.Iterator} An iterator to the specified set of data.
   */
  static makeIterator(arr, start = 0, end = arr.length) {
    return new Iterator(arr, start, end);
  }

}

// Expose both the static constructor function and the class.
module.exports.Iterator = Iterator;
