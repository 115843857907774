const Long = require('long');

const { ErrorSubcode, OperationError } = require('solclient-error');

// eslint-disable-next-line global-require
const BufferImpl = require('buffer').Buffer;

/**
 * @module
 * ===========================================================================
 * Convert
 *
 * This collection of functions performs all required string to number and number to string
 * conversions
 * ============================================================================
 * @private
 */

const TWO_ZEROES_STR = String.fromCharCode(0, 0);
const THREE_ZEROES_STR = String.fromCharCode(0, 0, 0);
const FOUR_ZEROES_STR = String.fromCharCode(0, 0, 0, 0);

const BYTEARRAY_CONVERT_CHUNK = 8192;
const UNSIGNED_LSHIFT_24 = (256 * 256 * 256);

const ARRAY_BUFFER_CONVERT_CHUNK = 32768;

function stringToUint8Array(data) {
  const dataLength = data.length;
  const arrayBuf = new ArrayBuffer(dataLength);
  const uint8Array = new Uint8Array(arrayBuf, 0, dataLength);
  for (let i = 0; i < dataLength; i++) {
    uint8Array[i] = data.charCodeAt(i);
  }
  return uint8Array;
}

function arrayBufferToString(ab) {
  if (!ab) return '';
  const len = ab.byteLength;
  const u8 = new Uint8Array(ab);
  if (len < ARRAY_BUFFER_CONVERT_CHUNK) {
    return String.fromCharCode.apply(null, u8);
  }

  let k = 0;
  let r = '';
  while (k < len) {
    // slice is clamped, inclusive of startIndex, exclusive of lastIndex
    r += String.fromCharCode.apply(null, u8.subarray(k, k + ARRAY_BUFFER_CONVERT_CHUNK));
    k += ARRAY_BUFFER_CONVERT_CHUNK;
  }
  return r;
}

function stringToArrayBuffer(str) {
  return stringToUint8Array(str).buffer;
}

function int8ToStr(int8) {
  return String.fromCharCode(int8 & 0xff);
}

function int16ToStr(int16) {
  return (
      String.fromCharCode((int16 >> 8) & 0xff) +
      String.fromCharCode(int16 & 0xff)
  );
}

function int24ToStr(int24) {
  return (
      String.fromCharCode((int24 >> 16) & 0xff) +
      String.fromCharCode((int24 >> 8) & 0xff) +
      String.fromCharCode(int24 & 0xff)
  );
}

function int32ToStr(int32) {
  // It is expected that there are a lot of small numbers
  // being converted, so it is worth doing a few checks for
  // efficiency (on firefox it is about 3 times quicker for small numbers
  // to do the check - it is 2 times quicker for chrome)

  if (int32 === 0) return FOUR_ZEROES_STR;
  if (int32 > 0) {
    if (int32 < 256) {
      return THREE_ZEROES_STR + String.fromCharCode(int32);
    }
    if (int32 < 65536) {
      return TWO_ZEROES_STR + String.fromCharCode(int32 >> 8) + String.fromCharCode(int32 & 0xff);
    }
  }

  return (
      String.fromCharCode((int32 >> 24) & 0xff) +
      String.fromCharCode((int32 >> 16) & 0xff) +
      String.fromCharCode((int32 >> 8) & 0xff) +
      String.fromCharCode(int32 & 0xff)
  );
}

function int64ToStr(int64) {
  if (typeof int64 !== 'number') {
    return int32ToStr(int64.high) + int32ToStr(int64.low);
  }

  // It is expected that there are a lot of small numbers
  // being converted, so it is worth doing a few checks for
  // efficiency (on firefox it is about 3 times quicker for small numbers
  // to do the check - it is 2 times quicker for chrome)
  if (int64 >= 0) {
    if (int64 < 256) {
      return FOUR_ZEROES_STR + THREE_ZEROES_STR + String.fromCharCode(int64);
    }
    if (int64 < 65536) {
      return FOUR_ZEROES_STR + TWO_ZEROES_STR +
        String.fromCharCode(int64 >> 8) + String.fromCharCode(int64 & 0xff);
    }
    if (int64 < 4294967296) {
      return FOUR_ZEROES_STR + (String.fromCharCode((int64 >> 24) & 0xff) +
        String.fromCharCode((int64 >> 16) & 0xff) +
        String.fromCharCode((int64 >> 8) & 0xff) +
        String.fromCharCode(int64 & 0xff));
    }
  }
  return String.fromCharCode((int64 >> 56) & 0xFF) +
        String.fromCharCode((int64 >> 48) & 0xFF) +
        String.fromCharCode((int64 >> 40) & 0xFF) +
        String.fromCharCode((int64 >> 32) & 0xFF) +
        String.fromCharCode((int64 >> 24) & 0xff) +
        String.fromCharCode((int64 >> 16) & 0xff) +
        String.fromCharCode((int64 >> 8) & 0xff) +
        String.fromCharCode(int64 & 0xff);
}

function byteArrayToStr(byteArray) {
  const len = byteArray.length;
  if (len < BYTEARRAY_CONVERT_CHUNK) {
    return String.fromCharCode.apply(null, byteArray);
  }

  let k = 0;
  let r = '';

  while (k < len) {
    // slice is clamped, inclusive of startIndex, exclusive of lastIndex
    r += String.fromCharCode.apply(null, byteArray.slice(k, k + BYTEARRAY_CONVERT_CHUNK));
    k += BYTEARRAY_CONVERT_CHUNK;
  }

  return r;
}

function strToByteArray(str) {
  const result = [];
  let i;
  for (i = 0; i < str.length; i++) {
    result[i] = str.charCodeAt(i);
  }
  return result;
}

function strToHexArray(str) {
  function toHex(c) {
    return c.charCodeAt(0).toString(16);
  }
  return Array.prototype.map.call(str.split(''), toHex);
}

function strToInt8(data) {
  return data.charCodeAt(0) & 0xff;
}

function strToInt16(data) {
  return (
      (data.charCodeAt(0) << 8) +
      (data.charCodeAt(1))
  );
}

function strToInt24(data) {
  return (
      (data.charCodeAt(0) << 16) +
      (data.charCodeAt(1) << 8) +
      (data.charCodeAt(2))
  );
}

function strToInt32(data) {
    // SIGNED integer
  return (
      (data.charCodeAt(0) << 24) +
      (data.charCodeAt(1) << 16) +
      (data.charCodeAt(2) << 8) +
      (data.charCodeAt(3))
  );
}

function strToUInt32(data) {
  // WARNING: you cannot use a << 24 to shift a byte into
  // a 32-bit string, because all shifts in JS are signed
  return (
      (data.charCodeAt(0) * UNSIGNED_LSHIFT_24) +
      (data.charCodeAt(1) << 16) +
      (data.charCodeAt(2) << 8) +
      (data.charCodeAt(3))
  );
}

function strToUInt64(data) {
  return Long.fromBits(strToUInt32(data.substr(4, 4)),
                       strToUInt32(data.substr(0, 4)),
                       true);
}

function ucs2ToUtf8(ucs2) {
  return unescape(encodeURIComponent(ucs2));
}

function utf8ToUcs2(utf8) {
  return decodeURIComponent(escape(utf8));
}

function anythingToBuffer(value) {
  if (BufferImpl.isBuffer(value)) {
    return value;
  }
  if (typeof value === 'string') {
    return BufferImpl.from(value, 'latin1');
  }
  if (value instanceof ArrayBuffer) {
    return BufferImpl.from(value);
  }
  //TypedArrays and DataView:
  if (value.buffer instanceof ArrayBuffer &&
    typeof value.byteLength === 'number' &&
    typeof value.byteOffset === 'number') {
    if (value.byteOffset === 0 && value.byteLength === value.buffer.byteLength) {
      // "full sice", no actual offset: just use the raw buffer.
      return BufferImpl.from(value.buffer);
    }
    return BufferImpl.from(value.buffer, value.byteOffset, value.byteLength);
  }
  throw new OperationError('Parameter value failed validation',
    ErrorSubcode.PARAMETER_OUT_OF_RANGE,
    'Expecting Buffer/Uint8Array, also accepting string, ArrayBuffer, any TypedArray, or DataView.');
}

const Convert = {
  arrayBufferToString,
  stringToArrayBuffer,
  stringToUint8Array,

  int8ToStr,
  strToInt8,

  int16ToStr,
  strToInt16,

  int24ToStr,
  strToInt24,

  int32ToStr,
  strToInt32,
  strToUInt32,

  int64ToStr,
  strToUInt64,

  byteArrayToStr,
  strToByteArray,

  strToHexArray,

  ucs2ToUtf8,
  utf8ToUcs2,
  anythingToBuffer,
};

module.exports.Convert = Convert;
