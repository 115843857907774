const { OperationError } = require('solclient-error');

/**
 * @classdesc
 * This is a base class for various FSM-related objects.
 * @private
 */
class FsmObject {

  /**
   * @constructor
   * @param {Object} spec The object specifier used to implement the named
   *      parameter idiom.
   * @param {String} spec.name The name of the object.
   */
  constructor(spec) {
    if (!spec) {
      throw new OperationError('No spec provided');
    }
    if (!spec.name) {
      throw new OperationError('No name provided for spec');
    }
    this.impl = {
      name: spec.name,
    };
  }

  /**
   * @returns {String} A string representation of the object.
   */
  toString() {
    let extraStringInfo = this.getExtraStringInfo();
    if (extraStringInfo.length > 0) {
      extraStringInfo = `; ${extraStringInfo}`;
    }
    return `{${this.constructor.name}: ${this.getName()}${extraStringInfo}}`;
  }

  /**
   * This is overridden by classes wanting to add more data, typically in the
   * form of 'name: value; name: value'.
   * @returns {String} Extra string information to be included in the object's
   *      toString() output.
   */
  getExtraStringInfo() { // eslint-disable-line class-methods-use-this
    return '';
  }

  /**
   * @returns {?String} The name passed into the object specifier.
   */
  getName() {
    return this.impl.name;
  }

}

module.exports.FsmObject = FsmObject;
