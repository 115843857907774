const SolclientFactoryLib = require('solclient-factory');
const { Convert } = require('solclient-convert');
const { DestinationType } = require('./destination-type');
const { LOG_ERROR } = require('solclient-log');
const { SubscriptionInfo } = require('./subscription-info');
const { UUID, StringUtils } = require('solclient-util');

const { ucs2ToUtf8, utf8ToUcs2 } = Convert;
const { ProfileBinding } = SolclientFactoryLib;

const { toSafeChars, stripNullTerminate } = StringUtils;
const { ErrorSubcode, OperationError } = require('solclient-error');

const DESTINATION_PREFIX_FROM_TYPE = {
  [DestinationType.QUEUE]:           '#P2P/QUE/',
  [DestinationType.TEMPORARY_QUEUE]: '#P2P/QTMP/',
};

function createTemporaryName(type, vrid, name) {
  const id = name || UUID.generateUUID();
  switch (type) {
    case DestinationType.TOPIC:
      return `#P2P/TTMP/${vrid}/${id}`;
    case DestinationType.TEMPORARY_QUEUE:
      return `#P2P/QTMP/${vrid}/${id}`;
    default:
      LOG_ERROR('Unknown/invalid destination type', DestinationType.describe(type));
  }
  return undefined;
}

function createPrefix(type) {
  return DESTINATION_PREFIX_FROM_TYPE[type] || '';
}

function createOperationError(type, errorStr) {
  return new OperationError(`Invalid ${type}: ${errorStr}`, ErrorSubcode.INVALID_TOPIC_SYNTAX);
}

// This function validates topics in all code paths -- legacy code paths that relied on validating
// topics as they were used, and newer code paths that validate Destinations on construction.  We
// must not add validation here that could break legacy apps that used the deprecated 'new Topic'
// interface that doesn't perform validation, and subsequently rely on validate-on-use.
//
// More strict checks done during Destination construction should be placed in validateAndEncode.
// @return { error, isWildcarded }.  isWildcarded will not be defined if erorr is defined.
function legacyValidate(type, bytes, name,
                        exceptionCreator = createOperationError.bind(null, type)) {
  let error;

  /*
    * TRB topics can contain any utf-8 character and must be <= 250 bytes
    * in length.
    * '*', if present in a level, must be the last character in that level.
    * May not have empty levels.
    */

  // Check minimum length using name, since the bytes may include a destination type prefix.
  // e.g. #P2P/QUE/ should fail on length, not empty level.
  const nameLength = name.length;
  if (nameLength < 1) {
    error = exceptionCreator('Too short (must be >= 1 character).');
    return { error };
  }

  // Check maximum length using encoded bytes, since UTF-8 is a variable length encoding.
  const bytesLength = bytes.length;
  if (bytesLength > 251) { // null terminator doesn't count
    error = exceptionCreator(`Too long (encoding must be <= 250 bytes); name is ${
                             bytesLength - 1} bytes: '${name}'`);
    return { error };
  }

  let isWildcarded = false;
  if (name.charAt(nameLength - 1) === '>') {
    isWildcarded = true;
  }

  for (let i = 0; i < nameLength; ++i) {
    switch (name.charAt(i)) {
      case '/':
        if (i === 0 || i === (nameLength - 1) || name.charAt(i - 1) === '/') {
          error = exceptionCreator(`Empty level(s) in '${name}'@${i}.`);
          return { error };
        }
        break;

      case '*':
        if ((i < (nameLength - 1)) && (name.charAt(i + 1) !== '/')) {
          // must not have something other than '/' to the right
          error = exceptionCreator(`Illegal wildcard(s) in '${name}'@${i}.`);
          return { error };
        }

        isWildcarded = true;
        break;

      default:
        break;
    }
  }

  return { isWildcarded };
}

function encodeBytes(bytes) {
  return ProfileBinding.value.topicUtf8Encode
    ? `${ucs2ToUtf8(bytes)}\u0000`
    : `${bytes}\u0000`;
}

function decodeBytes(bytes) {
  return stripNullTerminate(ProfileBinding.value.topicUtf8Encode
    ? utf8ToUcs2(bytes)
    : bytes);
}

/**
 * @param {DestinationType} type The type of destination
 * @param {String} name The name of the destination
 * @returns {Object} {bytes, offset, networkName} The result of the encoding.
 *
 * @private
 */
function encode(type, name) {
  const prefix = createPrefix(type);
  const offset = prefix.length;
  const networkName = prefix + name;
  const bytes = encodeBytes(networkName);
  return { bytes, offset, networkName };
}

/**
 * @param {DestinationType} type The type of destination
 * @param {String} name The name of the destination
 * @param {Function} [exceptionCreator=createOperationError] A function to create an exception if an
 *   error is encountered.
 * @returns {Object} {bytes, error, offset, isWildcarded} The result of the encoding and any
 *      validation error
 *
 * @private
 */
function validateAndEncode(type, name, exceptionCreator = createOperationError.bind(null, type)) {
  const { bytes, offset } = encode(type, name);
  const { error: constError, isWildcarded } = legacyValidate(type, bytes, name, exceptionCreator);
  let error = constError;
  let subscriptionInfo = {};
  subscriptionInfo.isWildcarded = isWildcarded;

  // If there was no 'legacy' error, perform an additional check to see if the provided name starts
  // with a reserved prefix.  It shouldn't.  Prefixes should always be added by us by encode().
  if (!error) {
    Object.keys(DESTINATION_PREFIX_FROM_TYPE).some((prefixType) => {
      const prefix = DESTINATION_PREFIX_FROM_TYPE[prefixType];
      if (!name.startsWith(prefix)) {
        return false; // keep processing more array elements.
      }

      error = exceptionCreator(`Reserved prefix '${prefix}' found at start of '${name}'`);
      return true;
    });
  }

  if (!error) {
    // parse subscription information from destination name
    const { error: errorConst, subInfo: subInfoConst } =
      SubscriptionInfo.parseFromName(name, type);
    error = errorConst;
    subscriptionInfo = subInfoConst;
  }

  return { bytes, offset, error, isWildcarded, subscriptionInfo };
}

const DestinationUtil = {
  createPrefix,
  createTemporaryName,
  decodeBytes,
  encode,
  encodeBytes,
  legacyValidate,
  toSafeChars,
  validateAndEncode,
};

module.exports.DestinationUtil = DestinationUtil;
