const { Process } = require('solclient-util');

const C_2_32 = Math.pow(2, 32);

function leftPad(str, len) {
  if (len > str.length) {
    return '0'.repeat(len - str.length) + str;
  }
  return str;
}

function generateRandomId() {
  const rand = (Math.random() * C_2_32).toFixed(0);
  return leftPad(rand.toString(), 10);
}

/**
 * @private
 */
const GlobalContext = {

  sessionCounter: 0,
  idCounter:      0,

  RandId: generateRandomId(),

  NextSessionCounter() {
    const count = ++this.sessionCounter;
    return leftPad(count.toString(), 4);
  },

  NextId() {
    return ++this.idCounter;
  },

  GenerateClientName() {
    const { product, platform } = Process;
    const result = `${product}/${platform}/${this.RandId}/${this.NextSessionCounter()}`;
    return result;
  },

  GenerateUserIdentification() {
    const { product, platform } = Process;
    return `${product}/${platform}/${this.RandId}`;
  },

  GenerateClientDescription() {
    return `solclientjs/${Process.description}`.substring(0, 254);
  },
};

module.exports.GlobalContext = GlobalContext;
