const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SMFClientCtrlParam = {
  SOFTWAREVERSION:              0x00,
  SOFTWAREDATE:                 0x01,
  PLATFORM:                     0x02,
  USERID:                       0x03,
  CLIENTDESC:                   0x04,
  CLIENTNAME:                   0x05,
  MSGVPNNAME:                   0x06,
  DELIVERTOONEPRIORITY:         0x07,
  P2PTOPIC:                     0x08,
  ROUTER_CAPABILITIES:          0x09,
  VRIDNAME:                     0x0a,
  PHYSICALROUTERNAME:           0x0c,
  BRIDGE_MSG_VPN_NAME:          0x0d,
  BRIDGE_ROUTER_NAME:           0x0e,
  NO_LOCAL:                     0x0f,
  BRIDGE_VERSION:               0x10,
  AUTHENTICATION_SCHEME:        0x11,
  CONNECTION_TYPE:              0x12,
  ROUTER_CAPABILITIES_EXTENDED: 0x13,
  REQUIRES_RELEASE_7:           0x14, // not supported.
  SSL_DOWNGRADE:                0x15,
  CLIENT_CAPABILITIES:          0x17,
  KEEP_ALIVE_INTERVAL:          0x18,
};

// Internal use only
const SMFClientCtrlAuthType = {
  CLIENT_CERTIFICATE: '\x01',
  OAUTH2:             '\x0a',
};

module.exports.SMFClientCtrlAuthType = Enum.new(SMFClientCtrlAuthType);
module.exports.SMFClientCtrlParam = Enum.new(SMFClientCtrlParam);
