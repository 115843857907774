const { Enum } = require('solclient-eskit');

/**
 * Enumeration of {@link solace.SDTUnsuportedValueError} causes.
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const SDTValueErrorSubcode = {
  /**
   * @type {Number}
   * @description
   * The value for this field may be valid on other platforms, but is outside the
   * range that is supported on this platform for the given type.
   */
  VALUE_OUTSIDE_SUPPORTED_RANGE: 1,
};

module.exports.SDTValueErrorSubcode = Enum.new(SDTValueErrorSubcode);
