const { DestinationType } = require('./destination-type');
const { ErrorSubcode, OperationError } = require('solclient-error');

/**
 * determines if subscription has the #noexport/ prefix
 *
 * @param {DestinationType} type The type of destination
 * @param {String} name The name of the destination
 * @param {ByteArray} bytes Encoded bytes of name
 * @param {Number} offset The index offset into the name to parse
 * @param {solace.SubscriptionInfo} result The attribute collector object for the parsed
 *   subscription
 * @param {Function} exceptionCreator The function to create an error for
 *   parsing
 * @returns {Object} {error, index, result} returns the parsing index and result
 *   where the result is a {solace.SubscriptionInfo} that contains information about the
 *   subscription like {Boolean} isNoExport
 * @private
 */
function subscriptionParseNoExport(type, name, bytes, offset, result) {
  const NOEXPORT_PREFIX = '#noexport/';
  const NOEXPORT_PREFIX_LEN = NOEXPORT_PREFIX.length;
  let index = offset;
  let error;
  if (name.length - index > NOEXPORT_PREFIX_LEN && !result.isNoExport) {
    if (name.startsWith(NOEXPORT_PREFIX, index)) {
      index += NOEXPORT_PREFIX_LEN;
      result.isNoExport = true;
    } else {
      result.isNoExport = false;
    }
  } else {
    result.isNoExport = false;
  }
  return { error, index, result };
}

/**
 * @param {DestinationType} type The type of destination
 * @param {String} name The name of the destination
 * @param {ByteArray} bytes Encoded bytes of name
 * @param {Number} offset The index offset into the name to parse
 * @param {solace.SubscriptionInfo} result The attribute collector object for the parsed
 *   subscription
 * @param {Function} exceptionCreator The function to create an error for
 *   parsing
 * @returns {Object} {error, index, result} returns the parsing index and result
 *   where the result is a {solace.SubscriptionInfo} that contains information about the
 *   subscription like {Boolean} isShared or {String} shareGroup or {Number}
 *   dispatchTopicIndex
 * @private
 */
function subscriptionParseShare(type, name, bytes, offset,
                                result, exceptionCreator) {
  const SHARE_PREFIX = '#share/';
  const SHARE_PREFIX_LEN = SHARE_PREFIX.length;
  const LEVEL_DELIMETER = '/';
  const LEVEL_DELIMETER_LEN = LEVEL_DELIMETER.length;
  let index = offset;
  let error;
  let groupIndex = -1;
  let shareGroup;
  if (name.length - index > SHARE_PREFIX_LEN && !result.isShare) {
    /* parse '#share/' prefix */
    /*
     * name starts with '#share/' prefix
     * and has enough room for at least one character for share group
     */
    if (name.startsWith(SHARE_PREFIX, offset)
        && (name.length - (index + SHARE_PREFIX_LEN)) > LEVEL_DELIMETER_LEN + 1) {
      index += SHARE_PREFIX_LEN;
      /* parse share group to next '/' */
      groupIndex = index;
      index = name.indexOf(LEVEL_DELIMETER, groupIndex);
      if (index > 0) {
        shareGroup = name.substring(groupIndex, index);
        index += LEVEL_DELIMETER_LEN;
        result.isShare = true;
        result.shareGroup = shareGroup;
        result.dispatchTopicIndex = index;
      } else {
        // error
        error = exceptionCreator(`Illegal share Group in '${name}'@${groupIndex}.`);
        result.isShare = true;
      }
    } else {
      // not share subscription
      result.isShare = false;
    }
  } else {
    // not share subscription
    result.isShare = false;
  }
  return { error, index, result };
}

/**
 * @param {Array.Function} layers The parsing functions executed from 0 to layers.length
 * @param {DestinationType} type The type of destination
 * @param {String} name The name of the destination
 * @param {ByteArray} bytes Encoded bytes of name
 * @param {solace.SubscriptionInfo} subInfo The attribute collector object for the parsed
 *   subscription
 * @param {Function} exceptionCreator The function to create an error for
 *   parsing
 * @returns {Object} {error, result} returns the parsed information in the SubscriptionInfo
 * object and error, when error is set result's values may be partially set
 * @private
 */
function subscriptionStringParse(layers, type, name, bytes, subInfo, exceptionCreator) {
  const parseLayers = layers.length || 0;
  let offset = 0;
  let error;
  let result = subInfo || {};
  for (let i = 0; i < parseLayers; ++i) {
    const { error: constError, index: constOffset, result: constResult } =
      layers[i](type, name, bytes, offset, result, exceptionCreator);
    offset = constOffset;
    result = constResult;
    error = constError;
    if (error) break;
  }
  return { error, result };
}

// Subscription parser map by DestinationType
// Currently only Topics have subscription information to parse, but more can be added as needed
// The _layers attribute for the parser defines the order of parsing destination names
// The _layers are made up of functions that can execute with the signature:
//   { error, index, result } function funcName(type, name, bytes, result[, exceptionCreator])
// To add more subscription information parsing add more layers to _layers of the DestinationType
const SUBSCRIPTION_LAYER_PARSER_FROM_TYPE = {
  [DestinationType.TOPIC]: {
    _layers: [subscriptionParseNoExport, subscriptionParseShare],
    parse:   function subParse(type, name, bytes, subInfo, exceptionCreator) {
      const { error, result } =
        subscriptionStringParse(this._layers, type, name, bytes, subInfo, exceptionCreator);
      return { error, result };
    },
  },
};

function createOperationError(type, errorStr) {
  return new OperationError(`Invalid ${type}: ${errorStr}`, ErrorSubcode.INVALID_TOPIC_SYNTAX);
}

/**
 * @classdesc
 * <b> This class is not exposed. This object contains useful subscription information about
 * a {solace.Destination#name}</b>
 * @hideconstructor
 * @memberof solace
 * @private
 */
class SubscriptionInfo {
  /**
   * @constructor
   * @param {String} name The name of the destination to be used as a subscription
   * @private
   */
  constructor(name) {
    this._name = name;
    // set default values
    this._isShare = false;
    this._isNoExport = false;
    this._dispatchTopicIndex = -1;
    this._shareGroup = null;
  }

  /**
   * @returns {String} The destination name specified at creation time
   */
  getName() {
    return this._name;
  }

  /**
   * @returns {String} subscription name
   * @readonly
   */
  get name() {
    return this.getName();
  }

  /**
   * @name solace.SubscriptionInfo#isShare
   * @type {Boolean}
   * @description Boolean Flag to indicate the {solace.SubscriptionInfo#name} is a shared
   * subscription
   * @default false
   */
  get isShare() {
    return this._isShare;
  }
  set isShare(value) {
    this._isShare = value;
  }

  /**
   * @name solace.SubscriptionInfo#isNoExport
   * @type {Boolean}
   * @description Boolean Flag to indicate the {solace.SubscriptionInfo#name} is a no export
   * subscription
   * @default false
   */
  get isNoExport() {
    return this._isNoExport;
  }
  set isNoExport(value) {
    this._isNoExport = value;
  }

  /**
   * @name solace.SubscriptionInfo#dispatchTopicIndex
   * @type {Number}
   * @description String index of the topic filter after subscription prefixes
   * @default 0, the whole name as the topic filter
   */
  get dispatchTopicIndex() {
    return this._dispatchTopicIndex < 0 ? 0 : this._dispatchTopicIndex;
  }
  set dispatchTopicIndex(value) {
    this._dispatchTopicIndex = value < 0 ? -1 /* uninitialized*/ : value;
  }

  /**
   * @name solace.SubscriptionInfo#shareGroup
   * @type {?String}
   * @description The Share group of a shared subscription, should be null if
   * {solace.SubscriptionInfo#isShare} is false
   */
  get shareGroup() {
    return this.isShare ? this._shareGroup : null;
  }
  set shareGroup(value) {
    if (this.isShare) {
      this._shareGroup = value;
    }
  }

  /**
   * @returns {String} A generic description of the SubscriptionInfo
   */
  toString() {
    return util_inspect(this);
  }
  /**
   * @static
   * @type {Object}
   * @param {String} name The name of the destination
   * @param {DestinationType} type The Destination type
   * @returns {Object} the subscription infomation from the destination name
   * @private
   */
  static parseFromName(name, type = DestinationType.TOPIC) {
    let subInfo = new SubscriptionInfo(name);
    let error = null;
    const parser = SUBSCRIPTION_LAYER_PARSER_FROM_TYPE[type];
    if (parser) {
      const { error: errorConst, result: subInfoConst } =
      parser.parse(type, name, null, subInfo, createOperationError.bind(null, type));
      subInfo = subInfoConst;
      error = errorConst;
    }
    return { error, subInfo };
  }
}
module.exports.SubscriptionInfo = SubscriptionInfo;
