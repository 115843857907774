const { Enum } = require('solclient-eskit');

/**
 * Represents an enumeration of user Class of Service (COS) levels. The COS is set
 * on a Message with {@link solace.Message#setUserCos}
 * The Class of Service has different semantics for direct and guaranteed messages.
 *
 * For messages published with {@link solace.MessageDeliveryModeType.DIRECT}, the
 * class of service selects the weighted round-robin delivery queue when the
 * message is forwarded to a consumer.  {@link solace.MessageUserCosType.COS1} are the
 * lowest priority messages and will use the Solace Message Router D-1 delivery queues.
 *
 * For messages published as guaranteed messages
 * ({@link solace.MessageDeliveryModeType.PERSISTENT} or
 * {@link solace.MessageDeliveryModeType.NON_PERSISTENT}), messages published
 * with {@link solace.MessageUserCosType.COS1} can be rejected by the Solace Message Router if
 * that message would cause any queue or topic-endpoint to exceed its configured
 * low-priority-max-msg-count.
 *
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const MessageUserCosType = {
  /**
   * Direct Messages: Lowest priority, use Solace Message Router client D-1 queues for delivery.
   *
   * Guaranteed Messages: Messages can be rejected if the message would cause any
   * queue or topic-endpoint to exceed its configured <i>low-priority-max-msg-count</i>.
   * @type {Number}
   */
  COS1: 0,
  /**
   * Direct Messages: Medium priority, use Solace Message Router client D-2 queues for delivery.
   *
   * Guaranteed Messages: N/A (same as COS3)
   * @type {Number}
   */
  COS2: 1,
  /**
   * Direct Messages: Highest priority, use Solace Message Router client D-3 queues for delivery.
   *
   * Guaranteed Messages: Messages are not rejected for exceeding <i>low-priority-max-msg-count</i>.
   * Messages may still be rejected for other reasons such as Queue 'Spool Over Quota'.
   * @type {Number}
   */
  COS3: 2,
};

module.exports.MessageUserCosType = Enum.new(MessageUserCosType);
