const { APIProperties } = require('solclient-util');
const { QueueAccessType } = require('./queue-access-types');
const { QueueDiscardBehavior } = require('./queue-discard-behaviors');
const { QueuePermissions } = require('./queue-permissions');

const DEFAULTS = {
  permissions:          undefined,
  accessType:           undefined,
  quotaMB:              undefined,
  maxMessageSize:       undefined,
  respectsTTL:          undefined,
  discardBehavior:      undefined,
  maxMessageRedelivery: undefined,
};

/**
 * @classdesc
 * Represents a queue properties object. May be passed in to
 * {@link solace.Session#createMessageConsumer} when creating a
 * {@link solace.MessageConsumer} object.  Upon creation of a queue, undefined queue
 * properties are set to default values chosen by the router.
 *
 * @memberof solace
 */
class QueueProperties extends APIProperties {

  constructor(options) {
    super(DEFAULTS, options);
  }

  /**
   * Gets/sets permissions for this queue.
   *
   * When creating a temporary queue, these are the permissions that apply
   * to all other users; the user creating the temporary queue is always
   * granted DELETE permissions.
   *
   * @type {solace.QueuePermissions}
   * @default undefined
   */
  get permissions() {
    return this._permissions;
  }
  set permissions(newValue) {
    this._permissions = newValue;
  }

  /**
   * Gets/sets the access type for this queue.
   *
   * This parameter must NOT be set when creating a temporary queue via
   * {@link solace.Session#createMessageConsumer}. Such a queue has its
   * access type determined by the remote message router.
   *
   * @type {solace.QueueAccessType}
   * @default undefined
   */
  get accessType() {
    return this._accessType || DEFAULTS.accessType;
  }
  set accessType(newValue) {
    this._accessType = newValue;
  }

  /**
   * Gets/sets the quota, in megabytes, for this queue.
   *  * The allowed values are (0 <= quotaMB) || undefined.
   *  * A value of 0 configures the queue to act as a Last-Value-Queue (LVQ), where the router
   *    enforces a Queue depth of one, and only the most current message is spooled by the
   *    queue. When a new message is received, the current queued message is first
   *    automatically deleted from the queue, then the new message is spooled.
   * @type {?Number}
   * @default undefined
   */
  get quotaMB() {
    return this._quotaMB;
  }
  set quotaMB(newValue) {
    this._quotaMB = newValue;
  }

  /**
   * Gets/sets the maximum message size, in bytes, for any single message spooled on this queue.
   * @type {Number}
   * @default undefined
   */
  get maxMessageSize() {
    return this._maxMessageSize;
  }
  set maxMessageSize(newValue) {
    this._maxMessageSize = newValue;
  }

  /**
   * Gets/sets whether this queue respects Time To Live on messages.
   * @type {Boolean}
   * @default false
   */
  get respectsTTL() {
    return this._respectsTTL;
  }
  set respectsTTL(newValue) {
    this._respectsTTL = newValue;
  }

  /**
   * Gets/sets the discard behavior for this queue.
   * @type {solace.QueueDiscardBehavior}
   * @default {solace.QueueDiscardBehavior.NOTIFY_SENDER_OFF}
   */
  get discardBehavior() {
    return this._discardBehavior;
  }
  set discardBehavior(newValue) {
    this._discardBehavior = newValue;
  }

  /**
   * Gets/sets the maximum number of times to attempt message redelivery for this queue.
   *  * The valid range is 0 <= maxMessageRedelivery <= 255
   *  * A value of 0 means retry forever.
   * @type {Number}
   * @default undefined
   */
  get maxMessageRedelivery() {
    return this._maxMessageRedelivery;
  }
  set maxMessageRedelivery(newValue) {
    this._maxMessageRedelivery = newValue;
  }

  [util_inspect_custom]() {
    return {
      'permissions':     QueuePermissions.describe(this.permissions),
      'accessType':      QueueAccessType.describe(this.accessType),
      'quotaMB':         this.quotaMB,
      'maxMessageSize':  this.maxMessageSize,
      'respectsTTL':     this.respectsTTL,
      'discardBehavior': this.discardBehavior
                            ? QueueDiscardBehavior.describe(this.discardBehavior)
                            : 'undefined',
      'maxMessageRedelivery': this.maxMessageRedelivery,
    };
  }

  toString() {
    return util_inspect(this);
  }
}

module.exports.QueueProperties = QueueProperties;
