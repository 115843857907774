const { Enum } = require('solclient-eskit');

/**
 * Represents an enumeration of message payload types
 * (see {@link solace.Message#getBinaryAttachment})
 *
 * A message may contain unstructured byte data, or a structured container.
 *
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const MessageType = {
  /**
   * Binary message (unstructured bytes stored in the binary attachment message part).
   * @type {Number}
   */
  BINARY: 0,
  /**
   * Structured map message.
   * @type {Number}
   */
  MAP:    1,
  /**
   * Structured stream message.
   * @type {Number}
   */
  STREAM: 2,
  /**
   * Structured text message.
   * @type {Number}
   */
  TEXT:   3,
};

module.exports.MessageType = Enum.new(MessageType);
