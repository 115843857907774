const { SessionRequestType } = require('./session-request-types');

module.exports = {
  SubscriptionUpdateTimeoutMessages: {
    [SessionRequestType.ADD_SUBSCRIPTION]:        'Add subscription request timeout',
    [SessionRequestType.REMOVE_SUBSCRIPTION]:     'Remove subscription request timeout',
    [SessionRequestType.ADD_P2PINBOX]:            'Add P2P inbox subscription timeout',
    [SessionRequestType.REMOVE_P2PINBOX]:         'Remove P2P inbox subscription timeout',
    [SessionRequestType.REMOVE_DTE_SUBSCRIPTION]: 'Remove endpoint topic subscription',
    default:                                      'Request timeout',
  },
};
