const { CapabilityType } = require('./capability-types');

module.exports.DefaultCapabilities = {
  createDefaultCapabilities(sessionProperties) {
    const defaultCapabilities = {
      [CapabilityType.GUARANTEED_MESSAGE_CONSUME]: true,
      [CapabilityType.GUARANTEED_MESSAGE_PUBLISH]: true,
      [CapabilityType.SHARED_SUBSCRIPTIONS]:       true,
      // The others are set TRUE by CCSMP, but not relevant for us yet
      // [CapabilityType.TEMPORARY_ENDPOINT]:   true,
      // [CapabilityType.GUARANTEED_MESSAGE_BROWSE]:         true,
      // [CapabilityType.ENDPOINT_MGMT]:        true,
      // [CapabilityType.SELECTOR]:             true,
      // [CapabilityType.COMPRESSION]:          true,
      // [CapabilityType.CUT_THROUGH]:          true,
      // [CapabilityType.QUEUE_SUBSCRIPTIONS]:  true,
      // [CapabilityType.SUBSCRIPTION_MANAGER]: true,
      [CapabilityType.MAX_GUARANTEED_MSG_SIZE]: sessionProperties.assumedMaxAdSize,
    };
    return defaultCapabilities;
  },
};
