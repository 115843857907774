const { assert } = require('solclient-eskit');
const { BaseMessage } = require('./base-message');
const { Destination, DestinationUtil } = require('solclient-destination');
const { SMFHeader } = require('./smf-header');
const { SMFProtocol } = require('../smf-protocols');
const { SMFSMPMessageType } = require('../smf-smp-message-types');
const { SMFSMPMessageTypeFlags } = require('../smf-smp-message-type-flags');

/**
 * Represents an SMP request or reply message
 * @memberof solace
 * @private
 */
class SMPMessage extends BaseMessage {

  /**
   * Creates an instance of SMPMessage.
   *
   * @memberOf SMPMessage
   */
  constructor() {
    super(new SMFHeader(SMFProtocol.SMP, 1));

    // Field: msgtype
    this.msgType = 0;

    // Field: subscription string
    this.encodedUtf8Subscription = null;
    this.encodedUtf8QueueName = null;

    this.smpFlags = (0 | SMFSMPMessageTypeFlags.SMF_SMP_FLAG_TOPIC); // default flags

    this._encodedQueueName = null; // unused in solclientjs
    this._encodedClientName = null; // unused in solclientjs
  }

  isFlag(flagMask) {
    return (this.smpFlags & flagMask);
  }

  setFlag(flagMask, value) {
    if (value) {
      this.smpFlags |= flagMask;
    } else {
      this.smpFlags &= (~flagMask);
    }
  }

  static getSubscriptionMessage(correlationTag, topic, add, requestConfirm) {
    assert(topic instanceof Destination, 'Topics are not UCS-2 strings. Pass a Topic object.');
    const smp = new SMPMessage();
    smp.msgType = add
      ? SMFSMPMessageType.ADDSUBSCRIPTION
      : SMFSMPMessageType.REMSUBSCRIPTION;
    smp.encodedUtf8Subscription = topic.getBytes();
    assert(smp.encodedUtf8Subscription, 'Topic had no encoding');
    smp.setFlag(SMFSMPMessageTypeFlags.SMF_SMP_FLAG_TOPIC, true);
    if (requestConfirm) {
      smp.setFlag(SMFSMPMessageTypeFlags.SMF_SMP_FLAG_RESPREQUIRED, true);
    }

    // Always put a correlation tag
    smp._smfHeader.pm_corrtag = correlationTag;
    return smp;
  }

  static getQueueSubscriptionMessage(correlationTag, topic, queue, add) {
    assert(topic instanceof Destination, 'Topics are not UCS-2 strings. Pass a Topic object.');
    const smp = new SMPMessage();
    smp.msgType = add
      ? SMFSMPMessageType.ADDQUEUESUBSCRIPTION
      : SMFSMPMessageType.REMQUEUESUBSCRIPTION;
    smp.encodedUtf8QueueName = DestinationUtil.encodeBytes(queue.getName());
    smp.encodedUtf8Subscription = topic.getBytes();
    assert(smp.encodedUtf8Subscription, 'Topic had no encoding');
    assert(smp.encodedUtf8QueueName, 'Queue had no encoding');
    smp.setFlag(SMFSMPMessageTypeFlags.SMF_SMP_FLAG_TOPIC, true);
    smp.setFlag(SMFSMPMessageTypeFlags.SMF_SMP_FLAG_RESPREQUIRED, true);
    // TODO: true for remove, false for add? Typo in the spec?
    smp.setFlag(SMFSMPMessageTypeFlags.SMF_SMP_FLAG_PERSIST, true);

    // Always put a correlation tag
    smp._smfHeader.pm_corrtag = correlationTag;
    return smp;
  }


}


module.exports.SMPMessage = SMPMessage;
