const NodeSslConstants = require('constants');
const PublisherLib = require('solclient-message-publisher');
const { APIProperties } = require('solclient-util');
const { AuthenticationScheme } = require('./authentication-schemes');
const { Check } = require('solclient-validate');
const { LOG_WARN } = require('solclient-log');
const { SslDowngrade } = require('./ssl-downgrades');

function makeDefaults() {
  // Defer binding. Publisher constructor may rely on profile.
  const { MessagePublisherProperties } = PublisherLib;
  return {
    _vpnNameInUse:        '',
    _virtualRouterName:   '',
    _p2pInboxInUse:       '',
    _p2pInboxBase:        '',
    _userIdentification:  '',
    _tpProtocolInUse:     null,
    _tpContentType:       'text/plain',
    _publisherProperties: new MessagePublisherProperties(),
  };
}

const DEFAULT_CIPHER_SUITES = BUILD_ENV.TARGET_NODE ? [
  'ECDHE-RSA-AES256-GCM-SHA384',
  'ECDHE-RSA-AES256-SHA384',
  'ECDHE-RSA-AES256-SHA',
  'AES256-GCM-SHA384',
  'AES256-SHA256',
  'AES256-SHA',
  'ECDHE-RSA-DES-CBC3-SHA',
  'DES-CBC3-SHA',
  'ECDHE-RSA-AES128-GCM-SHA256',
  'ECDHE-RSA-AES128-SHA256',
  'ECDHE-RSA-AES128-SHA',
  'AES128-GCM-SHA256',
  'AES128-SHA256',
  'AES128-SHA',
].join(',') : null;

const SUPPORTED_CIPHER_SUITES = BUILD_ENV.TARGET_NODE ? [
  'AES128-GCM-SHA256',
  'AES128-SHA',
  'AES128-SHA256',
  'AES256-GCM-SHA384',
  'AES256-SHA',
  'AES256-SHA256',
  'DES-CBC3-SHA',
  'ECDHE-RSA-AES128-GCM-SHA256',
  'ECDHE-RSA-AES128-SHA',
  'ECDHE-RSA-AES128-SHA256',
  'ECDHE-RSA-AES256-GCM-SHA384',
  'ECDHE-RSA-AES256-SHA',
  'ECDHE-RSA-AES256-SHA384',
  'ECDHE-RSA-DES-CBC3-SHA',
] : null;

const SUPPORTED_SSL_PROTOCOLS = BUILD_ENV.TARGET_NODE
? [
  'tlsv1',
  'tlsv1.1',
  'tlsv1.2',
]
: null;

/**
 * @lends SessionProperties
 *
 */
class SessionPropertiesBrowser extends APIProperties {
  /**
   * This property is deprecated.  It is recommended to use
   * {@link solace.SessionProperties#webTransportProtocolList} instead, which explicitly lists all
   * web transport protocols that may be used when establishing a session.
   *
   * This property specifies the web transport protocol that will initially be selected by the
   * session for its connection attempt. If this protocol fails, the session will attempt other
   * protocols in accordance with its transport protocol connect policy.
   *
   * If {@link solace.FactoryProfile#cometEnabled} is `true`, the selection of
   * any {@link solace.TransportProtocol} will result in the inclusion of
   * fallback protocols in {@link solace.SessionProperties#webTransportProtocolList}.
   * This makes the session incompatible with Guaranteed Messaging.
   *
   * To create a Guaranteed Messaging compatible session with
   * {@link solace.FactoryProfile#cometEnabled} set to `true`, ensure the session's
   * {@link solace.SessionProperties#webTransportProtocoList} is set to only
   * {@link solace.TransportProtocol.WS_BINARY}.
   *
   *  * Mutually exclusive to property webTransportProtocolList
   *
   * @name solace.SessionProperties#transportProtocol
   * @type {solace.TransportProtocol}
   * @default null
   * @deprecated
   * @target browser
   */
  get transportProtocol() {
    return Check.nothing(this._tpProtocol) ? null : this._tpProtocol;
  }
  set transportProtocol(newValue) {
    this._tpProtocol = newValue;
  }


  /**
   * @name solace.SessionProperties#transportDowngradeTimeoutInMsecs
   * @type {Number}
   * @description The timeout, in milliseconds, that must elapse before the session will abandon a
   * connection attempt with the current transport protocol if no response is received, and begin
   * a new connection attempt with a downgraded transport protocol. If no remaining downgrades
   * exist, the session will continue the current connection attempt until the connection timeout
   * expires. Note that the WS_BINARY transport will also attempt a downgrade if the underlying
   * WebSocket fails to connect before this period elapses.
   *  * The valid range is > 0.
   * @default 3000
   * @target browser
   */
  get transportDowngradeTimeoutInMsecs() {
    return Check.nothing(this._tpDowngradeTimeout) ? 3000 : this._tpDowngradeTimeout;
  }
  set transportDowngradeTimeoutInMsecs(newValue) {
    this._tpDowngradeTimeout = newValue;
  }

  /**
   * @name solace.SessionProperties#webTransportProtocolList
   * @type {Array.<solace.TransportProtocol>}
   * @description The user provided web transport protocol list indicating the initial protocol
   * to be used by the session for its connection attempt, and the protocols to try
   * subsequently if the attempt fails.
   *  * Mutually exclusive to property transportProtocol
   * @default null
   * @target browser
   */
  get webTransportProtocolList() {
    return Check.nothing(this._transportProtocolList)
             ? null
             : this._transportProtocolList;
  }
  set webTransportProtocolList(newValue) {
    this._transportProtocolList = newValue;
  }

  /**
   * @private
   * @name SessionProperties#bufferedAmountQueryIntervalInMsecs
   * @type {Number}
   * @description When WebSocket transport protocol is used, SolClient uses this property
   * and {@link solace.SessionProperties.maxWebPayload} to throttle the publishing rate in order to
   * avoid network saturation.
   *  * The valid range is >=4.
   * @default 100
   */
  get bufferedAmountQueryIntervalInMsecs() {
    return Check.nothing(this._bufferedAmountQueryInterval)
    ? 100
    : this._bufferedAmountQueryInterval;
  }
  set bufferedAmountQueryIntervalInMsecs(newValue) {
    this._bufferedAmountQueryInterval = newValue;
  }


  /**
   *
   * @name solace.SessionProperties#transportProtocolInUse
   * @type {String}
   * @description This property is deprecated.
   * Use {@link solace.SessionProperties#webTransportProtocolInUse} instead.
   * @readonly
   * @target browser
   * @deprecated
   */
  get transportProtocolInUse() {
    return this._tpProtocolInUse || null;
  }
  _setTransportProtocolInUse(value) {
    this._tpProtocolInUse = value;
  }

  /**
   *
   * @name solace.SessionProperties#webTransportProtocolInUse
   * @type {String}
   * @description A read-only property about the web transport protocol that is currently being
   * used by the session for its current connection or connection attempt. To determine which
   * web transport protocol was successfully used by the API, interrogate this property after the
   * session event UP_NOTICE is dispatched.
   * @readonly
   * @target browser
   */
  get webTransportProtocolInUse() {
    return this._tpProtocolInUse || null;
  }
  _setWebTransportProtocolInUse(value) {
    this._tpProtocolInUse = value;
  }

  /**
   *
   * @name solace.SessionProperties#transportContentType
   * @type {String}
   * @description Transport content-type override for HTTP transports
   * @default 'text/plain'
   * @internal
   */
  get transportContentType() {
    return this._tpContentType || 'text/plain';
  }
  set transportContentType(newValue) {
    this._tpContentType = newValue;
  }

  _lendsInspect() {
    return {
      'bufferedAmountQueryIntervalInMsecs': this.bufferedAmountQueryIntervalInMsecs,
      'transportContentType':               this.transportContentType,
      'transportDowngradeTimeoutInMsecs':   this.transportDowngradeTimeoutInMsecs,
      'transportProtocol':                  this.transportProtocol,
      'transportProtocolInUse':             this.transportProtocolInUse,
      'webTransportProtocolList':           this.webTransportProtocolList,
    };
  }
}

class SessionPropertiesNode extends APIProperties {
  /**
   * @internal
   */
  get transportProtocol() {
    return this._tpProtocol;
  }
  set transportProtocol(value) {
    this._tpProtocol = value;
  }

  /**
   * @internal
   */
  get webTransportProtocolList() {
    return this._transportProtocolList;
  }
  set webTransportProtocolList(value) {
    this._transportProtocolList = value;
  }

  /**
   * @internal
   */
  get transportDowngradeTimeoutInMsecs() {
    return this._tpDowngradeTimeout || 86400000; // All day
  }
  set transportDowngradeTimeoutInMsecs(value) {
    this._tpDowngradeTimeout = value;
  }

  /**
   * @internal
   * @deprecated
   */
  get transportProtocolInUse() {
    return this._tpProtocolInUse;
  }
  _setTransportProtocolInUse(value) {
    this._tpProtocolInUse = value;
  }

  /**
   * @internal
   */
  get webTransportProtocolInUse() {
    return this._tpProtocolInUse;
  }
  _setWebTransportProtocolInUse(value) {
    this._tpProtocolInUse = value;
  }

  /**
   * @internal
   */
  get transportContentType() {
    return this._tpContentType;
  }
  set transportContentType(value) {
    this._tpContentType = value;
  }

  // TLS connection options

  /**
   * @name solace.SessionProperties#sslExcludedProtocols
   * @type {Array.<String>}
   * @description An array of TLS protocols to be excluded when negotiating which protocol
   * to use.
   *  * Allowed values are: TLSv1, TLSv1.1, TLSv1.2
   *  * Note: when a protocol version is excluded without excluding all of its
   *    previous protocol versions, the effect is to also exclude all subsequent
   *    protocol versions.
   * @default null
   * @target node
   */
  get sslExcludedProtocols() {
    return Check.nothing(this._sslExcludedProtocols)
      ? null
      : this._sslExcludedProtocols;
  }
  set sslExcludedProtocols(newValue) {
    this._sslExcludedProtocols = newValue;
  }

  /**
   * @name solace.SessionProperties#sslCipherSuites
   * @type {String}
   * @description A comma separated list of cipher suites in order of preference used for TLS
   * connections.
   *  * Allowed values:
   *     * AES128-GCM-SHA256
   *     * AES128-SHA
   *     * AES128-SHA256
   *     * AES256-GCM-SHA384
   *     * AES256-SHA
   *     * AES256-SHA256
   *     * DES-CBC3-SHA
   *     * ECDHE-RSA-AES128-GCM-SHA256
   *     * ECDHE-RSA-AES128-SHA
   *     * ECDHE-RSA-AES128-SHA256
   *     * ECDHE-RSA-AES256-GCM-SHA384
   *     * ECDHE-RSA-AES256-SHA
   *     * ECDHE-RSA-AES256-SHA384
   *     * ECDHE-RSA-DES-CBC3-SHA
   *     * RC4-SHA
   *     * RC4-MD5
   * @default {@link solace.SessionProperties.DEFAULT_CIPHER_SUITES}
   * @target node
   */
  get sslCipherSuites() {
    return Check.nothing(this._sslCipherSuites)
      ? DEFAULT_CIPHER_SUITES
      : this._sslCipherSuites;
  }
  set sslCipherSuites(newValue) {
    this._sslCipherSuites = newValue;
  }

  /**
   *
   * @name solace.SessionProperties#sslValidateCertificate
   * @type {Boolean}
   * @description Whether the server certificate shall be verified against the list of
   * certificates in the trust stores. If set to false, all certificate validation is disabled,
   * including date, hostname and common name validation.
   * @default true
   * @target node
   */
  get sslValidateCertificate() {
    // if the value is undefined, then use environment variable
    if (this._sslValidateCert === undefined) return (process.env.NODE_TLS_REJECT_UNAUTHORIZED !== '0');
    if (this._sslValidateCert === null) return true;
    return this._sslValidateCert;
  }
  set sslValidateCertificate(newValue) {
    this._sslValidateCert = newValue;
  }

  /**
   * @name solace.SessionProperties#sslTrustStores
   * @type {Array.<String>}
   * @description An array of file names of trusted certificates in PEM format.
   * If not set, and {@link solace.SessionProperties#sslValidateCertificate} is set to true,
   * the server certificate will be validated against well known "root" CAs.
   *    * Mutually exclusive to sslPfx property when
   *      {@link solace.SessionProperties#sslValidateCertificate} is set
   * @default null
   * @target node
   */
  get sslTrustStores() {
    return Check.nothing(this._sslTrustStores)
      ? null
      : this._sslTrustStores;
  }
  set sslTrustStores(newValue) {
    this._sslTrustStores = newValue;
  }

  /**
   * @name solace.SessionProperties#sslTrustedCommonNameList
   * @type {Array.<String>}
   * @description An array of acceptable common names for matching with the server certificate.
   * If set to a non-empty array, the API will override the default hostname validation logic
   * provided by Node.js with its own implemenation; if set to empty array, no hostname
   * validation will be performed.
   *    * Only relevant when {@link solace.SessionProperties#sslValidateCertificate} is set
   *      to true
   *    * Note that leading and trailing whitespaces are considered to be part of the common
   *      names and are not ignored
   * @default null
   * @target node
   */
  get sslTrustedCommonNameList() {
    return Check.nothing(this._sslTrustedCNList)
      ? null
      : this._sslTrustedCNList;
  }
  set sslTrustedCommonNameList(newValue) {
    this._sslTrustedCNList = newValue;
  }

  // Client certificates related

  /**
   * @name solace.SessionProperties#sslPfx
   * @type {String}
   * @description The file name of a file containing private key, certificate and optional
   * CA certificates of the client in PFX or PKCS12 format.
   *    * Only relevant when
   *      {@link solace.AuthenticationScheme.CLIENT_CERTIFICATE} is used
   *    * Mutually exclusive to sslPrivateKey, sslCertificate and sslTrustStores properties
   * @default ""
   * @target node
   */
  get sslPfx() {
    return Check.nothing(this._sslPfx)
      ? ''
      : this._sslPfx;
  }
  set sslPfx(newValue) {
    this._sslPfx = newValue;
  }

  /**
   * @name solace.SessionProperties#sslPfxPassword
   * @type {String}
   * @description A string containing password for the client pfx file.
   *    * Only relevant when
   *      {@link solace.AuthenticationScheme.CLIENT_CERTIFICATE} is used
   * @default empty string
   * @target node
   */
  get sslPfxPassword() {
    return Check.nothing(this._sslPfxPasswd) ? '' : this._sslPfxPasswd;
  }
  set sslPfxPassword(newValue) {
    this._sslPfxPasswd = newValue;
  }

  /**
   * @name solace.SessionProperties#sslPrivateKey
   * @type {String}
   * @description The file name of a file containing private key of the client in PEM format.
   *    * Only relevant when
   *      {@link solace.AuthenticationScheme.CLIENT_CERTIFICATE} is used
   *    * Mutually exclusive to sslPfx property
   * @default empty string
   * @target node
   */
  get sslPrivateKey() {
    return Check.nothing(this._sslPrivateKey) ? '' : this._sslPrivateKey;
  }
  set sslPrivateKey(newValue) {
    this._sslPrivateKey = newValue;
  }

  /**
   * @name solace.SessionProperties#sslPrivateKeyPassword
   * @type {String}
   * @description A string containg password for the client private key.
   *    * Only relevant when
   *      {@link solace.AuthenticationScheme.CLIENT_CERTIFICATE} is used
   * @default empty string
   * @target node
   */
  get sslPrivateKeyPassword() {
    return Check.nothing(this._sslPrivateKeyPasswd) ? '' : this._sslPrivateKeyPasswd;
  }
  set sslPrivateKeyPassword(newValue) {
    this._sslPrivateKeyPasswd = newValue;
  }

  /**
   * @name solace.SessionProperties#sslCertificate
   * @type {String}
   * @description The file name of a file containing certificate key of the client in PEM
   * format.
   *    * Only relevant when
   *      {@link solace.AuthenticationScheme.CLIENT_CERTIFICATE} is used
   *    * Mutually exclusive to sslPfx property
   * @default empty string
   * @target node
   */
  get sslCertificate() {
    return Check.nothing(this._sslCertificate) ? '' : this._sslCertificate;
  }
  set sslCertificate(newValue) {
    this._sslCertificate = newValue;
  }

  // TLS connection options

  /**
   * @name SessionProperties#sslProtocol
   * @type {String}
   * @description The SSL protocols to use.
   *    * Allowed values are: SSLv3, TLSv1, TLSv1.1, TLSv1.2
   * @target node
   */
  get sslProtocol() {
    return this._sslProtocol;
  }
  set sslProtocol(newValue) {
    this._sslProtocol = newValue;
  }

  /**
   * @name solace.SessionProperties#compressionLevel
   * @type {Number}
   * @description zlib compression level (1-9) or no compression (0)
   *
   * When this property is set to a valid, non-zero value (1-9):
   *
   * * tcp:// connections are established compressed.
   * This usually requires connecting to a different tcp port on the router,
   * 55003 by default.
   *
   * * tcps:// connections are established uncompressed,
   * but then negotiate compression on login.
   * Compression before encryption allows inference of similarities between messages
   * from observing packet sizes on the network.
   * This could lead to chosen Plaintext attacks.
   * Can be combined with sslDowngradeConnectionTo for no encryption beyond authentication.
   *
   * * ws(s):// and http(s):// transports do not support compression and are considered invalid.
   *
   * @default 0
   * @target node
   */

  get compressionLevel() {
    return Check.nothing(this._compressionLevel) ? 0 : this._compressionLevel;
  }
  set compressionLevel(newValue) {
    this._compressionLevel = newValue;
  }

  /**
   * @name solace.SessionProperties#sslConnectionDowngradeTo
   * @type {solace.SslDowngrade}
   * @description Disable encryption after authentication
   *
   * When set to {@link solace.SslDowngrade.PLAINTEXT},
   * all message traffic beyond the initial login is unencrypted.
   * A TLS connection is negotiated on the regular TLS port (55443 by default),
   * TLS authentication schemes can be used, same as without this option.
   * After a successful login however, a TLS shutdown is performed,
   * and the same socket is then used for unencrypted message traffic.
   *
   * Please note this way plain text traffic passes on a port
   * usually associated with encryption (55443 by default)
   *
   * This downgrade is only supported for tcps:// connections,
   * all other URL schemes ignore this option.
   *
   * Can be combined with compressionLevel for
   * non-encrypted, compressed message transfer after login.
   *
   * @default {@link solace.SslDowngrade.NONE}
   * @target node
   */
  get sslConnectionDowngradeTo() {
    return Check.nothing(this._sslConnectionDowngradeTo) ?
      SslDowngrade.NONE : this._sslConnectionDowngradeTo;
  }

  set sslConnectionDowngradeTo(newValue) {
    this._sslConnectionDowngradeTo = newValue;
  }

  _lendsInspect() {
    return {
      'sslExcludedProtocols':     this.sslExcludedProtocols,
      'sslCipherSuites':          this.sslCipherSuites,
      'sslValidateCertificate':   this.sslValidateCertificate,
      'sslTrustStores':           this.sslTrustStores,
      'sslTrustedCommonNameList': this.sslTrustedCommonNameList,
      'sslPfx':                   this.sslPfx,
      'sslPfxPassword':           this.sslPfxPassword ? '*****' : this.sslPfxPassword,
      'sslPrivateKey':            this.sslPrivateKey,
      'sslPrivateKeyPassword':    this.sslPrivateKeyPassword ? '*****' : this.sslPrivateKeyPassword,
      'sslCertificate':           this.sslCertificate,
      'sslProtocol':              this.sslProtocol,
      'compressionLevel':         this.compressionLevel,
      'sslConnectionDowngradeTo': this.sslConnectionDowngradeTo,
    };
  }
}

const SessionPropertiesBase = BUILD_ENV.TARGET_NODE
  ? SessionPropertiesNode
  : SessionPropertiesBrowser;

/**
 * @classdesc
 * Represents a session properties object. Passed in to
 * {@link solace.SolclientFactory.createSession} when creating a {@link solace.Session} instance.
 * @memberof solace
 * @extends APIProperties
 */
class SessionProperties extends SessionPropertiesBase {

  /**
   * @constructor
   * @param {Object} options Properties to apply to the newly constructed object.
   */
  constructor(options) {
    super(makeDefaults(), options);
  }

  /**
   * The authentication scheme used when establishing the session.
   * @name solace.SessionProperties#authenticationScheme
   * @type {solace.AuthenticationScheme}
   * @default {@link solace.AuthenticationScheme.BASIC}
   */
  get authenticationScheme() {
    return Check.nothing(this._authScheme)
        ? AuthenticationScheme.BASIC
        : this._authScheme;
  }
  set authenticationScheme(newValue) {
    this._authScheme = newValue;
  }

  /**
   * @name solace.SessionProperties#accessToken
   * @type {String}
   * @description The access token required for OAUTH2 authentication.
   *    * This is only relevant if the
   *    {@link solace.AuthenticationScheme.OAUTH2}
   *    authentication scheme is being used.
   * @default ""
   */
  get accessToken() {
    return Check.empty(this._accessToken)
        ? ''
        : this._accessToken;
  }

  set accessToken(newValue) {
    this._accessToken = newValue;
  }

  /**
   * @name solace.SessionProperties#idToken
   * @type {String}
   * @description The ID token required for OIDC authentication.
   *    * This is only relevant if the
   *    {@link solace.AuthenticationScheme.OAUTH2}
   *    authentication scheme is being used.
   * @default ""
   */
  get idToken() {
    return Check.empty(this._idToken)
        ? ''
        : this._idToken;
  }

  set idToken(newValue) {
    this._idToken = newValue;
  }

  /**
   * @name solace.SessionProperties#issuerIdentifier
   * @type {String}
   * @description The issuer identifier is optional for OAUTH2 authentication.
   *    * This is only relevant if the
   *    {@link solace.AuthenticationScheme.OAUTH2}
   *    authentication scheme is being used.
   * @default ""
   */
  get issuerIdentifier() {
    return Check.empty(this._issuerIdentifier)
        ? ''
        : this._issuerIdentifier;
  }

  set issuerIdentifier(newValue) {
    if (!Check.type(newValue, 'string')) {
      LOG_WARN(`Failed to set issuer identifier because the pased value ${''
                }was not of type String. The passed issuer identifier ${''
                }must be of type String. Setting issuer identifier to ${''
                }default value empty string.`);
      this._issuerIdentifier = '';
    } else if (Check.empty(newValue)) {
      LOG_WARN(`Failed to set the issuer identifier because the passed string was empty ${''
                }or null. The passed issuer identifier must not be empty or null. Setting ${''
                }issuer identifier to default value empty string.`);
    } else {
      this._issuerIdentifier = newValue;
    }
  }

  /**
   * The URL or URLs of the messaging service to connect to.  The URL is typically of the form
   * `<protocol>://<host[:port]>`, where:
   *  * `protocol` is one of `ws`, `wss`, `http`, `https`, `tcp` or `tcps`.
   *  (Note to developers who also use the browser variant of this SDK:
   *  Browsers do not support the `tcp` and `tcps` protocols.)
   *  * `host` is a hostname or IP address of the router to connect to.
   *  * `port` is the port on which the messaging service is listening. The default is the
   *    well-known port for the service associated with the given protocol, if any.
   *
   * Additionally, note:
   *  * When an Array is provided, each element is expected to be a string of the above format.
   *    The API will attempt to connect to these URLs in the specified order.
   *  * Numerical IPv6 addresses must be enclosed in square brackets, e.g. tcp://[2001:db8::1]
   * @name solace.SessionProperties#url
   * @type {String|Array.<String>}
   * @default ""
   * @target node
   */
  /**
   * The URL or URLs of the messaging service to connect to.  The URL is typically of the form
   * `<protocol>://<host[:port]>`, where:
   *  * `protocol` is one of `ws`, `wss`, `http`, `https'.
   *  (Note to developers who also ise the NodeJS variant of this SDK:
   *  NodeJS also supports the 'tcp' and 'tcps' protocols, but browsers do not.)
   *  * `host` is a hostname or IP address of the router to connect to.
   *  * `port` is the port on which the messaging service is listening. The default is the
   *    well-known port for the service associated with the given protocol, if any.
   *
   * Additionally, note:
   *  * When an Array is provided, each element is expected to be a string of the above format.
   *    The API will attempt to connect to these URLs in the specified order.
   *  * Cross-domain restrictions should be taken into consideration when deploying web
   *    applications with messaging capabilities. See the API User Guide for more
   *    information.
   *  * Numerical IPv6 addresses must be enclosed in square brackets, e.g. ws://[2001:db8::1]
   * @name solace.SessionProperties#url
   * @type {String|Array.<String>}
   * @default ""
   * @target browser
   */
  get url() {
    return Check.nothing(this._url) ? '' : this._url;
  }

  set url(newValue) {
    this._url = newValue;
  }

  //  ======================== Credentials  ========================

  /**
   * @name solace.SessionProperties#password
   * @type {String}
   * @description The password required for authentication.
   * @default ""
   */
  get password() {
    return Check.nothing(this._password) ? '' : this._password;
  }

  set password(newValue) {
    this._password = newValue;
  }

  /**
   * @name solace.SessionProperties#userName
   * @type {String}
   * @description  The client username required for authentication.
   * @default ""
   */
  get userName() {
    return Check.nothing(this._userName) ? '' : this._userName;
  }

  set userName(newValue) {
    this._userName = newValue;
  }

  /**
   * @name solace.SessionProperties#clientName
   * @type {String}
   * @default '' (automatically generated)
   * @description The client name that is used during login as a unique identifier for the session
   * on the Solace Message Router.
   *  * An empty string causes a unique client name to be generated
   *     automatically.
   *  * If specified, it must be a valid Topic name, and a maximum of 160 bytes in length.
   *  * This property is also used to uniquely identify the sender in
   *    a message's senderId field if {@link solace.SessionProperties.includeSenderId}
   *    is set.
   * @default ""
   */
  get clientName() {
    return Check.nothing(this._clientName) ? '' : this._clientName;
  }

  set clientName(newValue) {
    this._clientName = newValue;
  }

  /**
   * A string that uniquely describes the application instance.
   *  * If left blank, the API will generate a description string
   *    using the current user-agent string.
   * @default ""
   * @name solace.SessionProperties#applicationDescription
   * @type {String}
   */
  get applicationDescription() {
    return Check.nothing(this._appDesc) ? '' : this._appDesc;
  }
  set applicationDescription(newValue) {
    this._appDesc = newValue;
  }

  /**
   * The Message VPN name that the client is requesting for this session.
   * @default ""
   * @name solace.SessionProperties#vpnName
   * @type {String}
   */
  get vpnName() {
    return Check.nothing(this._vpnName) ? '' : this._vpnName;
  }
  set vpnName(newValue) {
    this._vpnName = newValue;
  }

  /**
   * A read-only session property that indicates which Message
   * VPN the session is connected to. When not connected, or when not in client mode,
   * an empty string is returned.
   * @default ""
   * @name solace.SessionProperties#vpnNameInUse
   * @type {String}
   * @readonly
   */
  get vpnNameInUse() {
    return Check.nothing(this._vpnNameInUse) ? '' : this._vpnNameInUse;
  }
  /**
   * @private
   * @param {String} value The vpn name currently being used.
   */
  _setVpnNameInUse(value) {
    this._vpnNameInUse = value;
  }

  /**
   * @name solace.SessionProperties#virtualRouterName
   * @type {String}
   * @description A read-only property that indicates the connected Solace Message Router's
   * virtual router name.
   * @default ""
   * @readonly
   */
  get virtualRouterName() {
    return Check.nothing(this._virtualRouterName) ? '' : this._virtualRouterName;
  }
  /**
   * @private
   * @param {String} value The current virtual router name.
   */
  _setVirtualRouterName(value) {
    this._virtualRouterName = value;
  }

  //  ======================== Connection Strategies ========================
  /**
   * @name solace.SessionProperties#connectTimeoutInMsecs
   * @type {Number}
   * @description The timeout period (in milliseconds) for a connect operation to a given host.
   *  If no value is provided, the default is 8000.
   *   * The valid range is > 0.
   * @default 8000
   * @target node
   */
  /**
   * @name solace.SessionProperties#connectTimeoutInMsecs
   * @type {Number}
   * @description The timeout period (in milliseconds) for a connect operation to a given host.
   *  If no value is provided, the default is calculated as shown below.
   *   * The valid range is > 0.
   * @default max(8000, 1000 + webTransportProtocolList.length * transportDowngradeTimeoutInMsecs)
   * @target browser
   */
  get connectTimeoutInMsecs() {
    if (Check.nothing(this._connectTimeout)) {
      return this.defaultConnectTimeoutInMsecs;
    }
    return this._connectTimeout;
  }
  set connectTimeoutInMsecs(newValue) {
    this._connectTimeout = newValue;
  }

  /**
   * @internal
   */
  get defaultConnectTimeoutInMsecs() {
    const minTimeout = 8000;
    const { webTransportProtocolList, transportDowngradeTimeoutInMsecs } = this;
    const transportCount = webTransportProtocolList ? webTransportProtocolList.length : 1;
    const margin = transportCount > 1 ? 1000 : 0;
    const connectDowngradeDefault = transportCount * transportDowngradeTimeoutInMsecs + margin;
    return Math.max(minTimeout, connectDowngradeDefault);
  }

  /**
   * @name solace.SessionProperties#connectRetries
   * @type {Number}
   * @description The number of times to retry connecting during initial connection setup.
   *
   * When using a host list, each traversal of the list is considered a try; therefore, if
   * `connectRetries === 2`, the host list will be traversed up to three times: once
   * for the initial try, and twice more for the retries. Each retry begins with the first host
   * listed. After each unsuccessful attempt to connect to a host, the API waits for the amount
   * of time set for {@link solace.SessionProperties#reconnectRetryWaitInMsecs} before attempting
   * another connection. The next connection attempt may be to the same host,
   * see {@link solace.SessionProperties#connectRetriesPerHost}.
   *
   * If an established connection fails, the reconnection is attempted with
   * {@link solace.SessionProperties#reconnectRetries} retries instead.
   *
   *  * The valid range is connectRetries >= -1.
   *  * -1 means try to connect forever.
   *  * 0 means no automatic connection retries; the API will try once and then give up.
   *  * connectRetries >= 1 means reattempt connection n times.
   * @default 20
   */
  get connectRetries() {
    return Check.nothing(this._connectRetries) ? 20 : this._connectRetries;
  }
  set connectRetries(newValue) {
    this._connectRetries = newValue;
  }

  /**
   * @name solace.SessionProperties#connectRetriesPerHost
   * @type {Number}
   * @description When using a host list, this property defines how many times to
   * try to connect to a single host before moving to the next host in the list.
   *
   *  * The valid range is connectRetriesPerHost >= -1.
   *  * -1 means attempt an infinite number of connection retries. The API will only
   *    attempt to connect to the first host in the list.
   *  * 0 means make a single connection attempt per host, with no retries.
   * @default 0
   */
  get connectRetriesPerHost() {
    return Check.nothing(this._connectRetriesPerHost) ? 0 : this._connectRetriesPerHost;
  }
  set connectRetriesPerHost(newValue) {
    this._connectRetriesPerHost = newValue;
  }

  /**
   * @name solace.SessionProperties#reconnectRetryWaitInMsecs
   * @type {Number}
   * @description How much time to wait (in ms) between each attempt to connect to
   * a host.
   * If a connect attempt is not successful, the API waits for the amount of time
   * specified, and then makes another attempt to connect.
   * {@link solace.SessionProperties#connectRetriesPerHost} sets how many connection
   * attempts will be made before moving on to the next host in the list.
   * The valid range is >= 0 and <= 60000.
   * @default 3000
   */
  get reconnectRetryWaitInMsecs() {
    return Check.nothing(this._reconnectRetryWaitInMsecs) ? 3000 : this._reconnectRetryWaitInMsecs;
  }
  set reconnectRetryWaitInMsecs(newValue) {
    this._reconnectRetryWaitInMsecs = newValue;
  }

  /**
   * @name solace.SessionProperties#reconnectRetries
   * @type {Number}
   * @description The number of times to retry connecting after a connected session goes down.
   *
   * When using a host list, each traversal of the list is considered a try; therefore, if
   * `reconnectRetries === 2`, the host list will be traversed up to three times: once
   * for the initial try, and twice more for the retries. Each retry begins with the first host
   * listed. After each unsuccessful attempt to connect to a host, the API waits for the amount
   * of time set for {@link solace.SessionProperties#reconnectRetryWaitInMsecs} before attempting
   * another connection. The next reconnect attempt may be to the same host,
   * see {@link solace.SessionProperties#connectRetriesPerHost}.
   *
   *  * The valid range is reconnectRetries >= -1.
   *  * -1 means try to reconnect forever.
   *  * 0 means no automatic reconnect retries; the API will try once and then give up.
   *  * reconnectRetries >= 1 means reattempt reconnect n times.
   * @default 20
   */
  get reconnectRetries() {
    return Check.nothing(this._reconnectRetries) ? 20 : this._reconnectRetries;
  }
  set reconnectRetries(newValue) {
    this._reconnectRetries = newValue;
  }

  //  ======================== message properties ========================
  /**
   * @name solace.SessionProperties#generateSendTimestamps
   * @type {Boolean}
   * @description When enabled, a send timestamp is automatically included
   * (if not already present) in the Solace-defined fields for
   * each message sent.
   * @default  false
   */
  get generateSendTimestamps() {
    return Check.nothing(this._genSendTimestamps) ? false : this._genSendTimestamps;
  }
  set generateSendTimestamps(newValue) {
    this._genSendTimestamps = newValue;
  }

  /**
   * @name solace.SessionProperties#generateReceiveTimestamps
   * @type {Boolean}
   * @description When enabled, a receive timestamp is recorded for
   * each message and passed to the session's message callback receive handler.
   * @default  false
   */
  get generateReceiveTimestamps() {
    return Check.nothing(this._genReceiveTimestamps) ? false : this._genReceiveTimestamps;
  }
  set generateReceiveTimestamps(newValue) {
    this._genReceiveTimestamps = newValue;
  }

  /**
   * @name solace.SessionProperties#includeSenderId
   * @type {Boolean}
   * @description When enabled, a sender ID is automatically included
   * (if not already present) in the Solace-defined fields for each message
   * sent.
   * @default  false
   */
  get includeSenderId() {
    return Check.nothing(this._includeSenderId) ? false : this._includeSenderId;
  }
  set includeSenderId(newValue) {
    this._includeSenderId = newValue;
  }

  /**
   * @name solace.SessionProperties#generateSequenceNumber
   * @type {Boolean}
   * @description When enabled, a sequence number is automatically
   * included (if not already present) in the Solace-defined fields
   * for each message sent.
   * @default  false
   */
  get generateSequenceNumber() {
    return Check.nothing(this._genSequenceNumber) ? false : this._genSequenceNumber;
  }
  set generateSequenceNumber(newValue) {
    this._genSequenceNumber = newValue;
  }

  //  ======================== Keep Alive ========================
  /**
   * @name solace.SessionProperties#keepAliveIntervalInMsecs
   * @type {Number}
   * @description The amount of time (in milliseconds) to wait between sending
   * out keep-alive messages to the Solace Message Router.
   *  * The valid range is > 0.
   * @default  3000
   */
  get keepAliveIntervalInMsecs() {
    return Check.nothing(this._kaInterval) ? 3000 : this._kaInterval;
  }
  set keepAliveIntervalInMsecs(newValue) {
    this._kaInterval = newValue;
  }

  /**
   * @name solace.SessionProperties#keepAliveIntervalsLimit
   * @type {Number}
   * @description The maximum number of consecutive Keep-Alive messages that
   * can be sent without receiving a response before the session is declared down
   * and the connection is closed by the API.
   *  * The valid range is >= 3.
   * @default 3
   */
  get keepAliveIntervalsLimit() {
    return Check.nothing(this._kaIntervalsLimit) ? 3 : this._kaIntervalsLimit;
  }
  set keepAliveIntervalsLimit(newValue) {
    this._kaIntervalsLimit = newValue;
  }

  // ======================== P2P Inbox ========================

  /**
   * @name solace.SessionProperties#p2pInboxInUse
   * @type {String}
   * @description A read-only string that indicates the default
   * reply-to destination used for any request messages sent from this session.
   * See {@link solace.Session#sendRequest}.
   * This parameter is only valid when the session is connected.
   * @default ""
   * @readonly
   */
  get p2pInboxInUse() {
    return Check.nothing(this._p2pInboxInUse) ? '' : this._p2pInboxInUse;
  }
  /**
   * @private
   * @param {String} value The current P2P subscription.
   */
  _setP2pInboxInUse(value) {
    this._p2pInboxInUse = value;
  }

  /**
   * @private
   *
   * @name solace.SessionProperties#p2pInboxBase
   * @description A read-only information string that stores the P2P topic subscription
   * obtained from the Solace Message Router.
   * This parameter is only valid when the session is connected.
   * @default  ""
   * @readonly
   */
  get p2pInboxBase() {
    return Check.nothing(this._p2pInboxBase) ? '' : this._p2pInboxBase;
  }
  /**
   * @private
   * @param {String} value The current P2P inbox root subscription. The subscription on the router
   *   additionally contains '/>', so extra topic levels can be added to this root and messages to
   *   those topics will be attracted with the subscription.
   */
  _setP2pInboxBase(value) {
    this._p2pInboxBase = value;
  }

  /**
   * @name solace.SessionProperties#userIdentification
   * @type {String}
   * @description A read-only string providing information
   * about the application, such as the name of operating system
   * that is running the application.
   * @default  ""
   * @readonly
   */
  get userIdentification() {
    return Check.nothing(this._userIdentification) ? '' : this._userIdentification;
  }
  /**
   * @private
   * @param {String} value The current userId
   */
  _setUserIdentification(value) {
    this._userIdentification = value;
  }

  // ================== Subscriptions ========================
  /**
   *
   * @name solace.SessionProperties#subscriberLocalPriority
   * @type {Number}
   * @description Subscriber priorities are used by the Solace Message Router to distribute messages
   * that have the {@link solace.Message#setDeliverToOne} flag set to true. These messages are sent
   * to the subscriber with the highest priority. Subscribers have two priorities; this
   * priority is for messages published locally.
   *  * The valid range is 1..4
   * @default 1
   * @deprecated Use Shared Subscriptions instead
   */
  get subscriberLocalPriority() {
    return Check.nothing(this._subLocalPriority) ? 1 : this._subLocalPriority;
  }
  set subscriberLocalPriority(newValue) {
    this._subLocalPriority = newValue;
  }

  /**
   * @name solace.SessionProperties#subscriberNetworkPriority
   * @type {Number}
   * @description Subscriber priorities are used by the Solace Message Router to distribute messages
   * that have the {@link solace.Message#setDeliverToOne} flag set to true. These messages are sent
   * to the subscriber with the highest priority.
   *
   * Subscribers have two priorities; this priority is for messages published on Solace Message
   * Routers other than the one that the client is connected to.
   *  * The valid range is 1..4
   * @default  1
   * @deprecated Use Shared Subscriptions instead
   */
  get subscriberNetworkPriority() {
    return Check.nothing(this._subNetworkPriority) ? 1 : this._subNetworkPriority;
  }
  set subscriberNetworkPriority(newValue) {
    this._subNetworkPriority = newValue;
  }

  /**
   * @name solace.SessionProperties#ignoreDuplicateSubscriptionError
   * @type {Boolean}
   * @description Used to ignore duplicate subscription errors on subscribe.
   * @default  true
   */
  get ignoreDuplicateSubscriptionError() {
    return Check.nothing(this._ignoreDupSubError) ? true : this._ignoreDupSubError;
  }
  set ignoreDuplicateSubscriptionError(newValue) {
    this._ignoreDupSubError = newValue;
  }

  /**
   * @name solace.SessionProperties#ignoreSubscriptionNotFoundError
   * @type {Boolean}
   * @description Used to ignore subscription not found errors on unsubscribe.
   * @default  true
   */
  get ignoreSubscriptionNotFoundError() {
    return Check.nothing(this._ignoreSubNotFoundError) ? true : this._ignoreSubNotFoundError;
  }
  set ignoreSubscriptionNotFoundError(newValue) {
    this._ignoreSubNotFoundError = newValue;
  }

  /**
   *
   * @name solace.SessionProperties#reapplySubscriptions
   * @type {Boolean}
   * @description Set to 'true' to have the API remember subscriptions and reapply them upon
   * calling {@link solace.Session#connect} on a disconnected session.
   * @default  false
   */
  get reapplySubscriptions() {
    return Check.nothing(this._reapplySubcriptions) ? false : this._reapplySubcriptions;
  }
  set reapplySubscriptions(newValue) {
    this._reapplySubcriptions = newValue;
  }

  // ================== AD configuration ========================
  /**
   * Sets the guaranteed messaging publisher properties for the session.
   * If the supplied value is not a {@link solace.MessagePublisherProperties},
   * one will be constructed using the supplied value as an argument.
   *
   * @name solace.SessionProperties#publisherProperties
   * @type {solace.MessagePublisherProperties}
   */
  get publisherProperties() {
    return this._publisherProperties;
  }
  set publisherProperties(val) {
    const { MessagePublisherProperties } = PublisherLib;
    this._publisherProperties = val instanceof MessagePublisherProperties
      ? val
      : new MessagePublisherProperties(val);
  }

  // ================== Transport configuration ========================

  /**
   *
   * @name solace.SessionProperties#noLocal
   * @type {Boolean}
   * @description Set to 'true' to signal the Solace Message Router that messages published on the
   * session should not be received on the same session even if the client has a subscription that
   * matches the published topic. If this restriction is requested, and the Solace Message Router
   * does not have No Local support, the session connect will fail.
   * @default  false
   */
  get noLocal() {
    return Check.nothing(this._noLocal) ? false : this._noLocal;
  }
  set noLocal(newValue) {
    this._noLocal = newValue;
  }

  /**
   * @name solace.SessionProperties#readTimeoutInMsecs
   * @type {Number}
   * @description The timeout period (in milliseconds) for a reply to
   * come back from the Solace Message Router. This timeout serves as the default
   * request timeout for {@link solace.Session#subscribe},
   * {@link solace.Session#unsubscribe}, {@link solace.Session#updateProperty}.
   *  * The valid range is >= 0.
   * @default 10000
   */
  get readTimeoutInMsecs() {
    return Check.nothing(this._readTimeout) ? 10000 : this._readTimeout;
  }
  set readTimeoutInMsecs(newValue) {
    this._readTimeout = newValue;
  }

  /**
   * @name solace.SessionProperties#sendBufferMaxSize
   * @type {Number}
   * @description The maximum buffer size for the transport session. This size must be bigger
   * than the largest message an application intends to send on the session.
   *
   * The session buffer size configured using the sendBufferMaxSize
   * session property controls SolClient buffering of transmit messages. When
   * sending small messages, the session buffer size should be set to multiple times
   * the typical message size to improve the performance. Regardless of the buffer
   * size, SolClient always accepts at least one message to transmit. So even if a
   * single message exceeds sendBufferMaxSize, it is accepted and
   * transmitted as long as the current buffered data is zero. However, no more
   * messages are accepted until the amount of data buffered is reduced
   * enough to allow room below sendBufferMaxSize.
   *  * The valid range is > 0.
   *
   * @default 65536 (64KB)
   */
  get sendBufferMaxSize() {
    return Check.nothing(this._sendBufferMaxSize) ? (64 * 1024) : this._sendBufferMaxSize;
  }
  set sendBufferMaxSize(newValue) {
    this._sendBufferMaxSize = newValue;
  }

  /**
   * @name solace.SessionProperties#assumedMaxAdSize
   * @type {Number}
   * @description The assumed maximum AD message payload size before the session is established.
   * This value is irrelevant after session connection establishment,
   * because at that point the broker-reported AD size limit takes precedence.
   *
   * Before the session is connected, messages with payloads larger than this number
   * are rejected upon send().
   * The default value of 30000000 is appropriate for appliances, whereas for VMRs it should be set to 10000000.
   *
   *  * The valid range is > 0.
   *
   * @default 30000000 
   */
  get assumedMaxAdSize() {
    return Check.nothing(this._assumedMaxAdSize) ? 30000000 : this._assumedMaxAdSize;
  }

  set assumedMaxAdSize(newValue) {
    this._assumedMaxAdSize = newValue;
  }

  /**
   * @name solace.SessionProperties#maxWebPayload
   * @type {Number}
   * @description The maximum payload size (in bytes) when sending data using the Web transport
   * protocol.  Large messages may fail to be sent to the Solace Message Router when the maximum web
   * payload is set to a small value. To avoid this, use a large maximum web payload.
   *  * The valid range is >= 100.
   * @default 1048576 (1MB)
   */
  get maxWebPayload() {
    return Check.nothing(this._maxWebPayload) ? (1024 * 1024) : this._maxWebPayload;
  }
  set maxWebPayload(newValue) {
    this._maxWebPayload = newValue;
  }

  /**
   * @private
   */
  get nonHTTPTransportPropsSet() {
      // Calculate on demand based on presence of properties.
      // Currently not tracking this so no property names listed.
    return [].filter(k => Check.something(this[k]));
  }

  /**
   * @returns {String} A brief description of this object
   * @private
   */
  [util_inspect_custom]() {
    return Object.assign(this._lendsInspect(), {
      'authenticationScheme':             AuthenticationScheme.describe(this.authenticationScheme),
      'accessToken':                      this.accessToken ? '*****' : 'Not Set',
      'idToken':                          this.idToken ? '*****' : 'Not Set',
      'issuerIdentifier':                 this.issuerIdentifier ? '*****' : 'Not Set',
      'url':                              this.url,
      'password':                         this.password ? '*****' : this.password,
      'userName':                         this.userName,
      'clientName':                       this.clientName,
      'applicationDescription':           this.applicationDescription,
      'vpnName':                          this.vpnName,
      'vpnNameInUse':                     this.vpnNameInUse,
      'virtualRouterName':                this.virtualRouterName,
      'connectTimeoutInMsecs':            this.connectTimeoutInMsecs,
      'connectRetries':                   this.connectRetries,
      'connectRetriesPerHost':            this.connectRetriesPerHost,
      'reconnectRetryWaitInMsecs':        this.reconnectRetryWaitInMsecs,
      'reconnectRetries':                 this.reconnectRetries,
      'generateSendTimestamps':           this.generateSendTimestamps,
      'generateReceiveTimestamps':        this.generateReceiveTimestamps,
      'includeSenderId':                  this.includeSenderId,
      'generateSequenceNumber':           this.generateSequenceNumber,
      'keepAliveIntervalInMsecs':         this.keepAliveIntervalInMsecs,
      'keepAliveIntervalsLimit':          this.keepAliveIntervalsLimit,
      'p2pInboxInUse':                    this.p2pInboxInUse,
      'p2pInboxBase':                     this.p2pInboxBase,
      'userIdentification':               this.userIdentification,
      'subscriberLocalPriority':          this.subscriberLocalPriority,
      'subscriberNetworkPriority':        this.subscriberNetworkPriority,
      'ignoreDuplicateSubscriptionError': this.ignoreDuplicateSubscriptionError,
      'reapplySubscriptions':             this.reapplySubscriptions,
      'publisherProperties':              this.publisherProperties,
      'noLocal':                          this.noLocal,
      'readTimeoutInMsecs':               this.readTimeoutInMsecs,
      'sendBufferMaxSize':                this.sendBufferMaxSize,
      'maxWebPayload':                    this.maxWebPayload,
    });
  }

  /**
   * @name solace.SessionProperties#toString
   * @method
   * @description Returns a human-readable representation of this Session, subject to change.
   * @returns {String} A brief description of this object
   */
  toString() {
    return super.toString(); // only here for the docs
  }

}

// Don't try to evaluate these constants in browser mode
if (BUILD_ENV.TARGET_NODE) {
  /**
   * @description The default comma separated list of cipher suites in
   * order of preference used for SSL connections.
   * @constant
   * @type {String}
   */
  SessionProperties.DEFAULT_CIPHER_SUITES = DEFAULT_CIPHER_SUITES;

  /**
   * A list of cipher suites supported by the API when using SSL connections
   * @constant
   * @type {string[]}
   * @private
   */
  SessionProperties.SUPPORTED_CIPHER_SUITES = SUPPORTED_CIPHER_SUITES;

  /**
   * SSL protocols supported by the API when using SSL connections
   * @type {string[]}
   * @private
   */
  SessionProperties.SUPPORTED_SSL_PROTOCOLS = SUPPORTED_SSL_PROTOCOLS;

  /* eslint-disable */ // don't mangle import
  SessionProperties.SslProtocolExcludeConstantMap = {
    ['sslv2']:   NodeSslConstants['SSL_OP_NO_SSLv2'] || 0,
    ['sslv3']:   NodeSslConstants['SSL_OP_NO_SSLv3'] || 0,
    ['tlsv1']:   NodeSslConstants['SSL_OP_NO_TLSv1'] || 0,
    ['tlsv1.1']: NodeSslConstants['SSL_OP_NO_TLSv1_1'] || 0,
    ['tlsv1.2']: NodeSslConstants['SSL_OP_NO_TLSv1_2'] || 0,
  };
  /* eslint-enable */ // don't mangle import
}


module.exports.SessionProperties = SessionProperties;
