const SolclientFactoryLib = require('solclient-factory');
const { Convert } = require('solclient-convert');
const { SDTFieldType } = require('./sdt-field-types');
const { validateSdtField } = require('./validate-sdt-field');

const { anythingToBuffer } = Convert;
const { ProfileBinding } = SolclientFactoryLib;

/**
 * @classdesc
 * <b>This class is not exposed for construction by API users.</b>
 *
 * Represents a SDT (Structured Data Type) field. To create an instance of an <code>SDTField</code>,
 * call {@link solace.SDTField.create}.
 *
 * SDTField objects are used in Solace Containers ({@link solace.SDTMapContainer}
 * and {@link solace.SDTStreamContainer}). The <b>deprecated</b> usage of
 * {@link solace.SDTMapContainer#addField} and {@link solace.SDTStreamContainer#addField}
 * take a SDTField object as an argument. The preferred usage is to pass a
 * {@link solace.SDTFieldType} and value as arguments.
 *
 * SDTField objectts must be used as an argument to {@link solace.Message#setSdtContainer}.
 * The only valid SDTField objects for {@link solace.Message#setSdtContainer} are:
 * * {@link solace.SDTFieldType.STREAM}
 * * {@link solace.SDTFieldType.MAP}
 * * {@link solace.SDTFieldType.STRING}
 * @hideconstructor
 * @memberof solace
 */
class SDTField {

  /*
   * @constructor
   * @param {SDTFieldType} [type=SDTFieldType.NULLTYPE] The field type to construct
   * @param {*} value The value to be encapsulated
   * @throws {solace.OperationError} if value does not match type
   * @private
   */
  constructor(type = SDTFieldType.NULLTYPE, value = null) {
    const err = validateSdtField(type, value);
    if (err !== null) {
      throw (err);
    }
    this._type = type;
    if (type === SDTFieldType.BYTEARRAY) {
      this._value = anythingToBuffer(value);
    } else {
      this._value = value;
    }
    this._error = undefined;
  }

  /**
   * Gets the type of field represented.
   * @returns {solace.SDTFieldType} The type of field represented.
   */
  getType() {
    return this._type;
  }

  /**
   * Gets the field value.
   * @returns {*} Field value (as one of the supported data types).
   * @throws {solace.SDTUnsupportedValueError} if value found in the field
   * is not in range supported by the platform/runtime.
   */
  getValue() {
    if (this._error !== undefined) {
      throw (this._error);
    }
    return this.getValueNoThrow();
  }

  /**
   * Gets the field value or error object.
   * @returns {*} Field value (as one of the supported data types) or
   * {solace.SDTUnsupportedValueError} if value found in the field
   * is not in range supported by the platform/runtime.
   * @private
   */
  getValueNoThrow() {
    if (this._error !== undefined) {
      return this._error;
    }
    if (this._type === SDTFieldType.BYTEARRAY && ProfileBinding.value.byteArrayAsString) {
      return this._value.toString('latin1');
    }
    return this._value;
  }

  /**
   * Sets an error on the object to be thrown on getValue().
   * Used only by parse-integer, as it is possible to receive
   * 64 bit integers that cannot be represented in a javaScript number.
   * JavaScript numbers are floats and can only hold a 48 bit integer.
   * @private
   * @param {solace.SDTUnsupportedValueError} err error object.
   */
  setError(err) {
    this._error = err;
  }

  toString() {
    return `[SDTField type:${this._type} value:${this._value}]`;
  }

  /**
   * Create a new SDTField instance representing a Value of a given Type.
   *
   * @param {solace.SDTFieldType} type The type of field represented.
   * @param {*} value The corresponding value to store in the field.
   * @returns {solace.SDTField} The new SDT field with the given type and value
   * @throws {solace.OperationError} if value does not match type
   * @static
   */
  static create(type, value) {
    return new SDTField(type, value);
  }

}

module.exports.SDTField = SDTField;
