const { LogImpl } = require('./log-impl');

const STUB = () => undefined;

function generateLogHeader(levelStr) {
  const date = new Date();
  const levelStrPadding = ' '.repeat(6 - levelStr.length);
  let YY = String(date.getFullYear());
  let MM = String(date.getMonth() + 1);
  let DD = String(date.getDate());
  let hh = String(date.getHours());
  let mm = String(date.getMinutes());
  let ss = String(date.getSeconds());
  let ms = String(date.getMilliseconds());

  YY = '0'.repeat(4 - YY.length) + YY;
  MM = MM.length < 2 ? `0${MM}` : MM;
  DD = DD.length < 2 ? `0${DD}` : DD;
  hh = hh.length < 2 ? `0${hh}` : hh;
  mm = mm.length < 2 ? `0${mm}` : mm;
  ss = ss.length < 2 ? `0${ss}` : ss;
  ms = ms.length < 3 ? `0${ms}` : ms;
  ms = ms.length < 3 ? `0${ms}` : ms; // twice: pad to 3

  return [`${YY}-${MM}-${DD} ${hh}:${mm}:${ss}.${ms}`, `${levelStr}${levelStrPadding}`];
}

/**
 * @classdesc
 * A log implementation that uses the global or window console.
 *
 * This is the default log implementation used by the API if no logger was
 * supplied via {@link solace.SolclientFactoryProperties}.
 *
 * @memberof solace
 * @private
 */
class ConsoleLogImpl extends LogImpl {
  /**
   * @constructor
   * @param {Object} [consoleIn] The console to which to apply this implementation. If
   *  not specified, uses the global or window console.
   */
  constructor(consoleIn) {
    /**
     * Logs the given arguments at TRACE level. This is bound to the first valid method of the
     * following on the global or window console object, in priority order:
     *  * console.log
     *  * console.debug
     *
     * @name solace.ConsoleLogImpl#trace
     * @method
     * @param {...*} args Arguments to be logged
     */
    let _trace = STUB;

    /**
     * Logs the given arguments at DEBUG level. This is bound to the first valid method of the
     * following on the global or window console object, in priority order:
     *  * console.log
     *  * console.debug
     *
     * @name solace.ConsoleLogImpl#debug
     * @method
     * @param {...*} args Arguments to be logged
     */
    let _debug = STUB;

    /**
     * Logs the given arguments at INFO level. This is bound to the first valid method of the
     * following on the global or window console object, in priority order:
     *  * console.info
     *  * console.log
     *
     * @name solace.ConsoleLogImpl#info
     * @method
     * @param {...*} args Arguments to be logged
     */
    let _info = STUB;

    /**
     * Logs the given arguments at WARN level. This is bound to the first valid method of the
     * following on the global or window console object, in priority order:
     *  * console.warn
     *  * console.log
     *
     * @name solace.ConsoleLogImpl#warn
     * @method
     * @param {...*} args Arguments to be logged
     */
    let _warn = STUB;


    /**
     * Logs the given arguments at ERROR level. This is bound to the first valid method of the
     * following on the global or window console object, in priority order:
     *  * console.info
     *  * console.log
     *
     * @name solace.ConsoleLogImpl#error
     * @method
     * @param {...*} args Arguments to be logged
     */
    let _error = STUB;

    /**
     * Logs the given arguments at FATAL level. This is bound to the first valid method of the
     * following on the global or window console object, in priority order:
     *  * console.info
     *  * console.log
     *
     * @name solace.ConsoleLogImpl#fatal
     * @method
     * @param {...*} args Arguments to be logged
     */
    let _fatal = STUB;

    const console = consoleIn || (
      typeof window === 'undefined'
        /* eslint-env node */ ? global
        /* eslint-env browser */ : window
      ).console;
    /* eslint-env shared-node-browser */

    if (console && (console.log || console.warn)) {
      /* eslint-disable no-console */
      // Where console.log is supported, it is preferred over console.debug
      // https://developer.mozilla.org/en-US/docs/DOM/console
      // console methods in IE9 are object, not function, unfortunately
      //
      // Also, console.trace outputs a stack trace on some platforms.  So we
      // use the same underlying logger as debug for trace to avoid the
      // excessive verbosity that would result.
      if (console.log && console.log !== undefined) {
        _trace = Function.prototype.bind.call(console.log, console);
        _debug = Function.prototype.bind.call(console.log, console);
      } else if (console.debug && typeof console.debug === 'function') {
        _trace = Function.prototype.bind.call(console.debug, console);
        _debug = Function.prototype.bind.call(console.debug, console);
      }

      // Where console.info exists, it is preferred. Otherwise, fall back to
      // console.log.
      if (console.info && console.info !== undefined) {
        _info = Function.prototype.bind.call(console.info, console);
      } else {
        _info = Function.prototype.bind.call(console.log, console);
      }

      // Where console.warn exists, it is preferred. Otherwise, fall back to
      // console.log.
      if (console.warn && console.warn !== undefined) {
        _warn = Function.prototype.bind.call(console.warn, console);
      } else {
        _warn = Function.prototype.bind.call(console.log, console);
      }

      // Where console.error exists, it is preferred. Otherwise, fall back to
      // console.log.
      //
      // console.fatal is not present in any known console implementation. Use
      // console.error or console.log.
      if (console.error && console.error !== undefined) {
        _error = Function.prototype.bind.call(console.error, console);
        _fatal = Function.prototype.bind.call(console.error, console);
      } else {
        _error = Function.prototype.bind.call(console.log, console);
        _fatal = Function.prototype.bind.call(console.log, console);
      }

      /* eslint-enable no-console */
    } // else no console implementation was found, so the default (stub) is used for each level.

    super((...args) => { _trace(...generateLogHeader('TRACE'), ...args); },
          (...args) => { _debug(...generateLogHeader('DEBUG'), ...args); },
          (...args) => { _info(...generateLogHeader('INFO'), ...args); },
          (...args) => { _warn(...generateLogHeader('WARN'), ...args); },
          (...args) => { _error(...generateLogHeader('ERROR'), ...args); },
          (...args) => { _fatal(...generateLogHeader('FATAL'), ...args); });
  }


}

module.exports.ConsoleLogImpl = ConsoleLogImpl;
