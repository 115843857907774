const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SessionRequestType = {
  ADD_SUBSCRIPTION:        0,
  REMOVE_SUBSCRIPTION:     1,
  ADD_P2PINBOX:            2,
  REMOVE_P2PINBOX:         3,
  REMOVE_DTE_SUBSCRIPTION: 100,
};

module.exports.SessionRequestType = Enum.new(SessionRequestType);
