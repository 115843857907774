function enumName(instance, keys, value) {
  const matches = keys.filter(k => instance[k] === value);
  return matches.length ? matches[0] : null;
}

function enumValues(instance, keys) {
  return Array.from(new Set(keys.map(k => instance[k])));
}

/**
 * The base for all enumerated types. Provides common functionality such as
 * collections of keys and values, reverse mapping, and readable descriptions. Also
 * supports overriding the canonical key-value mapping to maintain readability in
 * minified environments.
 *
 * @mixin
 * @memberof solace
 */
class Enum {

  /**
   * Creates an Enum with the given key-value mapping.
   *
   * @param {Object} values The key-value mapping to use. This mapping is set as the initial
   * canonical key-value mapping. To override, use {@link solace.Enum#_setCanonical}.
   */
  constructor(values) {
    Object.defineProperties(this, {
      _canonical: {
        value:        null,
        enumerable:   false,
        writable:     true,
        configurable: true,
      },
    });
    this._setCanonical(values);
  }

  /**
   * Resets the canonical key value mapping for the enumeration. Using this method,
   * you add additional sets of keys that map to the same values, and specify that
   * these new keys take precedence for reverse mapping. Any older set of keys is made
   * non-canonical and non-enumerable.
   *
   * @example
   * const c = new Enum({ HELLO: 'world' });
   * c._setCanonical({ MAD: 'world' });
   * c.nameOf('world') === 'MAD'; // true
   *
   * @param {Object} values The new key-value mapping to add to the object and set as canonical.
   * @param {Boolean} final Whether this enumeration should accept further reconfiguration.
   * @private
   */
  _setCanonical(values, final = false) {
    this._canonical = Object.assign({}, values);
    Object.keys(this).forEach((key) => {
      const descriptor = Object.getOwnPropertyDescriptor(this, key);
      if (descriptor.value !== undefined) {
        Object.defineProperty(this, key, {
          enumerable:   false,
          writable:     true,
          configurable: true,
          value:        descriptor.value,
        });
      }
    });
    Object.keys(values).forEach((key) => {
      Object.defineProperty(this, key, {
        enumerable:   true,
        writable:     !final,
        configurable: !final,
        value:        values[key],
      });
    });
  }

  /**
   * Create a human-readable string describing the given enumeration value. This
   * result is informational and may vary between SDK versions.
   *
   * Currently, it either returns the value provided (if the associated key is
   * the same), or a string of the form `key (value)`.
   *
   * @example
   * console.log(new Enum({ ANSWER: 42 }).describe(42)); // ANSWER (42)
   * console.log(new Enum({ ECHO: 'ECHO' })).describe('ECHO'); // ECHO
   *
   * @param {any} enumValue The value on which to perform reverse mapping.
   * @param {String} [noneValue=''] The string to return if the enumeration value is
   *    undefined or null.
   * @param {String} [unknownValue='<unknown>'] The string to return if the enumeration
   *    value was not found in any of the canonical keys.
   * @returns {String} A human-readable description of the reverse mapping for the value.
   */
  describe(enumValue, noneValue = '<none>', unknownValue = '<unknown>') {
    if (enumValue === null || enumValue === undefined) return noneValue;
    const name = enumName(this._canonical,
                          Object.keys(this._canonical || {}),
                          enumValue)
                 || unknownValue;
    return name === enumValue ? name : `${name} (${enumValue})`;
  }

  /**
   * Returns the canonical name in this enumeration for the given value. This function is
   * designed to facilitate reflection, whereas for display
   *
   * @param {any} enumValue The value for which to get the enumeration key.
   * @returns {String} The name for the given value.
   */
  nameOf(enumValue) {
    return enumName(this._canonical, Object.keys(this._canonical || {}), enumValue);
  }

  /**
   * Returns all canonical names/keys in this enumeration.
   *
   * @readonly
   * @returns {Array.<String>} All canonical names in this enumeration.
   */
  get names() {
    return Object.keys(this._canonical || {});
  }

  /**
   * Returns all values assigned to canonical keys in this enumeration.
   *
   * @readonly
   * @returns {Array} All values defined for enumeration keys.
   */
  get values() {
    return enumValues(this._canonical, Object.keys(this._canonical || {}));
  }

  /**
   * A property returning true to faciliate duck-typing with {solace.Enum} objects.
   *
   * @readonly
   * @returns {Boolean} true This is an enumeration.
   */
  get isEnum() { // eslint-disable-line class-methods-use-this
    return true;
  }

  /**
   * Returns the name of an enuerated value given the
   * enumeration and the value.
   *
   * @param {Object} instance The enumeration instance.
   * @param {*} value The value for which to return the key name.
   * @returns {String} The key name for the given enumeration value.
   */
  static nameOf(instance, value) {
    return instance.nameOf(value);
  }

  /**
   * Returns values defined on this enumeration for the given keys. To get the enumeration keys,
   * use {@link Object#keys}.
   * @param {Object} instance The enumeration on which to return values.
   * @returns {Array} The values defined on the given enumeration.
   **/
  static values(instance) {
    return instance.values();
  }

  /**
   * Alternate construction method for enumerations.
   *
   * @static
   * @param {Object} values Initial key-value mapping
   * @returns {solace.Enum} A new enumeration instance
   * @private
   */
  static new(values) {
    return new Enum(values);
  }

  /**
   * Constructs a private enumeration in which the values are equal to the keys.
   *
   * @static
   * @param {Array.<String>} strings Key names. The values will be equal to the keys.
   * @returns {solace.Enum} An enumeration instance of the given strings
   * @private
   */
  static ofStrings(strings) {
    const map = {};
    strings.forEach((s) => {
      map[s] = s;
    });
    return Enum.new(map);
  }

  /**
   * Constructs an enumeration in which each key is assigned a distinct integer value.
   * Enums created this way are subject to renumbering if keys are reordered.
   *
   * @static
   * @param {Array.<String>} keys Key names. The values will be sequential from zero.
   * @returns {solace.Enum} An enumeration instance of the given values
   * @private
   */
  static ofNumbers(keys) {
    const map = {};
    keys.forEach((key, index) => {
      map[key] = index;
    });
    return Enum.new(map);
  }
}

module.exports.Enum = Enum;
