const QueueLib = require('solclient-queue');
const { APIProperties } = require('solclient-util');
const { Check } = require('solclient-validate');
const { MessageConsumerAcknowledgeMode } = require('./message-consumer-acknowledge-modes');
const { Topic } = require('solclient-destination');

const DEFAULTS = {
  queueDescriptor:                         undefined,
  queueProperties:                         undefined,
  connectTimeoutInMsecs:                   10000,
  connectAttempts:                         3,
  topicEndpointSubscription:               undefined,
  // selector: undefined,
  acknowledgeMode:                         MessageConsumerAcknowledgeMode.AUTO,
  transportAcknowledgeTimeoutInMsecs:      1000,
  transportAcknowledgeThresholdPercentage: 60,
  activeIndicationEnabled:                 false,
  noLocal:                                 false,
  windowSize:                              255,
  _browser:                                false,
  replayStartLocation:                     undefined,
  reconnectAttempts:                       -1,
  reconnectIntervalInMsecs:                3000,
  createIfMissing:                         false,
};

/**
 * @classdesc
 * Defines the properties for a {@link solace.MessageConsumer}.
 *
 * @memberof solace
 */
class MessageConsumerProperties extends APIProperties {
  constructor(options) {
    super(DEFAULTS, options);
  }

  /**
   * Defines the queue from which to consume.
   *  * For durable queues and durable topic endpoints, this must be a
   *    {@link solace.QueueDescriptor} unless
   *    {@link solace.MessageConsumerProperties#createIfMissing} is set.
   *  * When an {@link solace.AbstractQueueDescriptor} is used, the name is generated when
   *    the {@link solace.MessageConsumer} is connected. The generated descriptor can be queried
   *    from the consumer after it has successfully connected by calling
   *    {@link solace.MessageConsumer#getProperties}.
   * @type {solace.QueueDescriptor}
   */
  get queueDescriptor() {
    return Check.something(this._queueDescriptor)
      ? this._queueDescriptor
      : DEFAULTS.queueDescriptor;
  }
  set queueDescriptor(value) {
    if (value instanceof QueueLib.AbstractQueueDescriptor) {
      this._queueDescriptor = value;
    } else if (value) {
      this._queueDescriptor = value.name
        ? new QueueLib.QueueDescriptor(value)
        : new QueueLib.AbstractQueueDescriptor(value);
    } else {
      this._queueDescriptor = value;
    }
  }

  /**
   * Gets the properties of the remote queue.
   *  * For temporary queues and temporary topic endpoints,
   *    or if {@link solace.MessageConsumerProperties#createIfMissing} is true,
   *    these properties define the endpoint that is created.
   *  * For durable queues, these must be unset on consumer creation
   *    unless {@link solace.MessageConsumerProperties#createIfMissing} is set.
   *    The values will be populated
   *    after the queue is connected and can be retrieved by calling
   *    {@link solace.MessageConsumer#getProperties}.
   * @type {solace.QueueProperties}
   */
  get queueProperties() {
    return Check.something(this._queueProperties)
      ? this._queueProperties
      : DEFAULTS.queueProperties;
  }
  set queueProperties(value) {
    if (value) {
      this._queueProperties = new QueueLib.QueueProperties(value);
    } else {
      this._queueProperties = value;
    }
  }

  // ------------ Properties controlling connection of the consumer ----------------
  /**
   * The bind timeout in milliseconds when creating a connection to the Solace Message Router.
   *  * The valid range is >= 50.
   * @type {Number}
   * @default 10000
   */
  get connectTimeoutInMsecs() {
    return Check.something(this._bindTimeoutInMsecs)
      ? this._bindTimeoutInMsecs
      : DEFAULTS.connectTimeoutInMsecs;
  }
  set connectTimeoutInMsecs(value) {
    this._bindTimeoutInMsecs = value;
  }

  /**
   * Gets and sets the maximum number of bind attempts when creating a connection to the
   * Solace Message Router.
   *  * The valid range is >= 1.
   * @type {Number}
   * @default 3
   */
  get connectAttempts() {
    return Check.something(this._connectAttempts)
      ? this._connectAttempts
      : DEFAULTS.connectAttempts;
  }
  set connectAttempts(val) {
    this._connectAttempts = val;
  }

  // ------------ Properties applied to the queue when connecting ----------------

  /**
   * This must be undefined if the type of the
   * {@link solace.MessageConsumerProperties#queueDescriptor} is not
   * {@link solace.QueueType.TOPIC_ENDPOINT}.
   *
   * If {@link solace.MessageConsumerProperties#queueDescriptor} is
   * not durable, or {@link solace.MessageConsumerProperties#createIfMissing} is true,
   * this may be left undefined to generate the topic endpoint's
   * destination. When generated, the destination can be obtained from
   * the {@link solace.MessageConsumer} after it is connected by calling
   * {@link solace.MessageConsumer#getDestination}.
   *
   * @type {solace.Destination}
   * @default undefined
   */
  get topicEndpointSubscription() {
    return this._topicEndpointSubscription;
  }
  set topicEndpointSubscription(val) {
    // Avoid instanceof check failing on Node 4?
    if (typeof val === 'string') {
      this._topicEndpointSubscription = Topic.createFromName(val);
    } else {
      this._topicEndpointSubscription = val;
    }
  }

  // ----------- Properties controlling an established connection to a queue --------------

  /**
   * The Application Acknowledgement mode for the Message Consumer.
   *
   * When the acknowledgement mode is {@link solace.MessageConsumerAcknowledgeMode.CLIENT},
   * a message is Application Acknowledged when the application calls
   * {@link solace.Message#acknowledge} on that message.
   *
   * When the acknowledge mode is {@link solace.MessageConsumerAcknowledgeMode.AUTO}, a message is
   * Application Acknowledged by the API after all
   * {@link solace.MessageConsumerEventName#event:MESSAGE}
   * listeners are called and none throw an exception. If a message handler throws, the message
   * can still be acknowledged by calling {@link solace.Message#acknowledge}, but this would not be
   * a recommended practice.
   *
   * When received messages are Application Acknowledged they are removed from the Guaranteed
   * Message storage on the Solace Message Router. Message Consumer Application Acknowledged,
   * <b>only</b> remove messages from the Solace Message Router.
   *
   * In particular, withholding Message Consumer Acknowledgemnts does not stop
   * message delivery. For Message Consumer flow control (aka transport acknowledgemeent) see
   * {@link solace.MessageConsumer#stop}/{@link solace.MessageConsumer#start}. Message Consumer
   * flow control may also be imlpemented by removing the
   * {@link solace.MessageConsumerEventName#event:MESSAGE} listener.
   *
   * Flow control and transport acknowledgements characteristics are defined by
   * {@link solace.MessageConsumerProperties#transportAcknowledgeThresholdPercentage} and
   * {@link solace.MessageConsumerProperties#transportAcknowledgeTimeoutInMsecs}
   *
   * @type {solace.MessageConsumerAcknowledgeMode}
   * @default solace.MessageConsumerAcknowledgeMode.AUTO
   */
  get acknowledgeMode() {
    return Check.something(this._acknowledgeMode)
      ? this._acknowledgeMode
      : DEFAULTS.acknowledgeMode;
  }
  set acknowledgeMode(value) {
    this._acknowledgeMode = value;
  }

  /**
   * The transport acknowledgement timeout for guaranteed messaging.
   * When the {@link solace.MessageConsumerProperties#transportAcknowledgeTimeoutInMsecs}
   * is not exceeded, acknowledgements will be returned to the router at intervals not
   * less than this value.
   *   * The valid range is 20 <= transportAcknowledgeTimeoutInMsecs <= 1500.
   * @type {Number}
   * @default 1000
   * @deprecated
   */
  get acknowledgeTimeoutInMsecs() {
    return Check.something(this._transportAcknowledgeTimeoutInMsecs)
      ? this._transportAcknowledgeTimeoutInMsecs
      : DEFAULTS.transportAcknowledgeTimeoutInMsecs;
  }
  set acknowledgeTimeoutInMsecs(val) {
    this._transportAcknowledgeTimeoutInMsecs = val;
  }

  /**
   * The threshold for sending an acknowledgement, as a percentage.
   * The API sends a transport acknowledgment every
   * N messages where N is calculated as this percentage of the transport
   * window size if the endpoint's max-delivered-unacked-msgs-per-flow
   * setting at bind time is greater than or equal to the transport
   * window size. Otherwise, N is calculated as this percentage of the
   * endpoint's max-delivered-unacked-msgs-per-flow setting at bind time.
   * * The valid range is 1 <= transportAcknowledgeThresholdPercentage <= 75.
   * @type {Number}
   * @default 60
   * @deprecated
   */
  get acknowledgeThreshold() {
    return Check.something(this._transportAcknowledgeThresholdPercentage)
      ? this._transportAcknowledgeThresholdPercentage
      : DEFAULTS.transportAcknowledgeThresholdPercentage;
  }
  set acknowledgeThreshold(value) {
    this._transportAcknowledgeThresholdPercentage = value;
  }

  /**
   * The transport acknowledgement timeout for guaranteed messaging.
   * When the {@link solace.MessageConsumerProperties.transportAcknowledgeTimeoutInMsecs}
   * is not exceeded, acknowledgements will be returned to the router at intervals not less than
   * this value.
   *   * The valid range is 20 <= transportAcknowledgeTimeoutInMsecs <= 1500.
   * @type {Number}
   * @default 1000
   */
  get transportAcknowledgeTimeoutInMsecs() {
    return Check.something(this._transportAcknowledgeTimeoutInMsecs)
      ? this._transportAcknowledgeTimeoutInMsecs
      : DEFAULTS.transportAcknowledgeTimeoutInMsecs;
  }
  set transportAcknowledgeTimeoutInMsecs(val) {
    this._transportAcknowledgeTimeoutInMsecs = val;
  }

  /**
   * The threshold for sending an acknowledgement, as a percentage.
   * The API sends a transport acknowledgment every
   * N messages where N is calculated as this percentage of the transport
   * window size if the endpoint's max-delivered-unacked-msgs-per-flow
   * setting at bind time is greater than or equal to the transport
   * window size. Otherwise, N is calculated as this percentage of the
   * endpoint's max-delivered-unacked-msgs-per-flow setting at bind time.
   * * The valid range is 1 <= transportAcknowledgeThresholdPercentage <= 75.
   * @type {Number}
   * @default 60
   */
  get transportAcknowledgeThresholdPercentage() {
    return Check.something(this._transportAcknowledgeThresholdPercentage)
      ? this._transportAcknowledgeThresholdPercentage
      : DEFAULTS.transportAcknowledgeThresholdPercentage;
  }
  set transportAcknowledgeThresholdPercentage(value) {
    this._transportAcknowledgeThresholdPercentage = value;
  }

  /**
   * @description When enabled, a Guaranteed Messaging Consumer requests Active and Inactive
   * events from the router and emits them to interested listeners.
   * @type {Boolean}
   * @default false
   * @see {@link solace.MessageConsumerEventName.ACTIVE}
   * @see {@link solace.MessageConsumerEventName.INACTIVE}
   */
  get activeIndicationEnabled() {
    return Check.something(this._activeIndicationEnabled)
      ? this._activeIndicationEnabled
      : DEFAULTS.activeIndicationEnabled;
  }
  set activeIndicationEnabled(newValue) {
    this._activeIndicationEnabled = newValue;
  }

  /**
   * When enabled, a Guaranteed Messaging Consumer does not receive messages published
   * in the same Session, even if the endpoint contains a subscription that matches the published
   * message.
   * @type {Boolean}
   * @default false
   */
  get noLocal() {
    return Check.something(this._noLocal)
      ? this._noLocal
      : DEFAULTS.noLocal;
  }
  set noLocal(newValue) {
    this._noLocal = newValue;
  }

  /**
   * The window size for Guaranteed Message delivery.  This is the maximum number of messages that
   * will be prefetched from the Solace Messaging Router and queued internally by the API while
   * waiting for the application to accept delivery of the messages.
   *   * The valid range is 1 <= windowSize <= 255.
   * @type {Number}
   * @default 255
   */
  get windowSize() {
    return Check.something(this._windowSize)
      ? this._windowSize :
      DEFAULTS.windowSize;
  }
  set windowSize(val) {
    this._windowSize = val;
  }

  /**
   * When enabled, a Guaranteed Messaging Consumer will connect as a queue browser
   * @type {Boolean}
   * @default false
   * @private
   * @internal
   */
  get browser() {
    return Check.something(this._browser)
      ? this._browser :
      DEFAULTS._browser;
  }
  set browser(newValue) {
    this._browser = newValue;
  }
  /**
   * When a Flow is created, the application may request replay of messages from the replay log,
   * even messages that have been previously delivered and removed the from topic endpoint or queue.
   * The default is undefined, and indicates that no replay is requested.
   *
   * When defined the replay start location must be a {@link solace.ReplayStartLocation} object
   * as returned by
   * {@link solace.SolClientFactory.createReplayStartLocationBeginning} or
   * {@link solace.SolClientFactory.createReplayStartLocationDate}.
   *
   * The {@link solace.ReplayStartLocation} returned by
   * {@link solace.SolClientFactory.createReplayStartLocationBeginning}
   * indicate that all messages available should be replayed.
   *
   * The replay start location returned by
   * {@link solace.SolClientFactory.createReplayStartLocationDate}
   * indicates that all messages logged since a given date must be retrieved.
   * @type {solace.ReplayStartLocation}
   * @default undefined.
   */
  get replayStartLocation() {
    return Check.something(this._replayStartLocation)
      ? this._replayStartLocation :
        DEFAULTS.replayStartLocation;
  }
  set replayStartLocation(newValue) {
    this._replayStartLocation = newValue;
  }

  /**
   * When a connected flow receives an unsolicited unbind event with subcode
   * REPLAY_STARTED or GM_UNAVAILABLE, the SDK can reconnect the flow automatically.
   * This property controls the flow auto reconnect feature:
   * 0: Disable flow auto reconnect for this consumer flow.
   * -1: Enable flow auto reconnect for this consumer flow, infiinite retries (default)
   * <n, positive number>: Enable flow auto reconnect for this consumer flow, n retries.
   *
   * When the flow auto rebind is enabled, DOWN_ERRORs with REPLAY_STARTED and GM_UNAVAILABLE
   * are handled internally, and not (immediately) emitted to the application.
   * A RECONNECTING event (with the same subcode) is emitted instead,
   * ideally followed by a RECONNECTED event when the reconnect succeedes.
   * In case of REPLAY_STARTED, the window of message IDs and acknowledgements are reset
   * to allow replay packets to be passed to the application without marking them as duplicates.
   * In case of GM_UNAVAILABLE, flow state is preserved.
   *
   * If reconnecting fails after exhausting the number of retries, a DOWN_ERROR is emitted
   * with the details of the last retry.
   *
   *
   * @type {Number}
   * @default -1
   */
  get reconnectAttempts() {
    return Check.something(this._reconnectAttempts)
      ? this._reconnectAttempts :
        DEFAULTS.reconnectAttempts;
  }
  set reconnectAttempts(newValue) {
    this._reconnectAttempts = newValue;
  }

  /**
   * Time to wait between flow auto reconnect attempts, in milliseconds.
   * See {@link solace.MessageConsumerProperties.reconnectAttempts}
   * Defaults to 3 seconds (3000)
   *  * The valid range is >= 50.
   *
   * @type {Number}
   * @default 3000
   */
  get reconnectIntervalInMsecs() {
    return Check.something(this._reconnectIntervalInMsecs)
      ? this._reconnectIntervalInMsecs :
        DEFAULTS.reconnectIntervalInMsecs;
  }
  set reconnectIntervalInMsecs(newValue) {
    this._reconnectIntervalInMsecs = newValue;
  }

  /**
   * If the endpoint is durable, it won't be auto-created unless this flag is set.
   * This flag has no effect for temporary endpoints, those are always created if missing.
   * This flag has no effect for existing endpoints.
   *
   * Off by default for backwards compatibility.
   *
   * @type {Boolean}
   * @default false
   *
   */
  get createIfMissing() {
    return Check.something(this._createIfMissing)
      ? this._createIfMissing :
        DEFAULTS.createIfMissing;
  }

  set createIfMissing(newValue) {
    this._createIfMissing = newValue;
  }

}
module.exports.MessageConsumerProperties = MessageConsumerProperties;
