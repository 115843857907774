const { ErrorSubcode } = require('./error-subcodes');
const { makeMap } = require('solclient-eskit');

/* eslint-disable max-len */

const SC = ErrorSubcode;

const ROOT_MAPPING = makeMap(
  400, makeMap(
    'client name parse error', SC.CLIENT_NAME_INVALID,
    'document is too large', SC.MESSAGE_TOO_LARGE,
    'inactivity timeout', SC.INACTIVITY_TIMEOUT,
    'max num subscriptions exceeded', SC.SUBSCRIPTION_TOO_MANY,
    'message too long', SC.MESSAGE_TOO_LARGE,
    'nolocal discard', SC.NOLOCAL_DISCARD,
    'not enough space', SC.OUT_OF_RESOURCES,
    'subscription already exists', SC.SUBSCRIPTION_ALREADY_PRESENT,
    'subscription attributes conflict with existing subscription', SC.SUBSCRIPTION_ATTRIBUTES_CONFLICT,
    'subscription not found', SC.SUBSCRIPTION_NOT_FOUND,
    'subscription parse error', SC.SUBSCRIPTION_INVALID,
    'topic parse error', SC.INVALID_TOPIC_SYNTAX,
    'unknown transport session identifier', SC.UNKNOWN_TRANSPORT_SESSION_ID,
    'xml parse error', SC.XML_PARSE_ERROR,
    'unsupported ssl downgrade value', SC.LOGIN_FAILURE
  ),
  401, makeMap(
    '', SC.LOGIN_FAILURE
  ),
  403, makeMap(
    'basic authentication is shutdown', SC.BASIC_AUTHENTICATION_IS_SHUTDOWN,
    'client certificate authentication is shutdown', SC.CLIENT_CERTIFICATE_AUTHENTICATION_IS_SHUTDOWN,
    'client name already in use', SC.CLIENT_NAME_ALREADY_IN_USE,
    'client username is shutdown', SC.CLIENT_USERNAME_IS_SHUTDOWN,
    'dynamic clients not allowed', SC.DYNAMIC_CLIENTS_NOT_ALLOWED,
    'invalid virtual router address', SC.INVALID_VIRTUAL_ADDRESS,
    'forbidden', SC.CLIENT_ACL_DENIED,
    'message vpn not allowed', SC.MESSAGE_VPN_NOT_ALLOWED,
    'publish acl denied', SC.PUBLISH_ACL_DENIED,
    'replication is standby', SC.REPLICATION_IS_STANDBY,
    'selector does not match', SC.SELECTOR_DOES_NOT_MATCH,
    'subscription acl denied', SC.SUBSCRIPTION_ACL_DENIED,
    'subscription does not match', SC.SUBSCRIPTION_DOES_NOT_MATCH,
    'compression is shutdown', SC.LOGIN_FAILURE,
    'shared subscriptions not supported on topic endpoints', SC.SHARED_SUBSCRIPTIONS_ENDPOINT_NOT_ALLOWED,
    'shared subscriptions not supported on queues', SC.SHARED_SUBSCRIPTIONS_ENDPOINT_NOT_ALLOWED,
    'shared subscription permission denied', SC.SHARED_SUBSCRIPTIONS_NOT_ALLOWED
  ),
  404, makeMap(
    '', SC.LOGIN_FAILURE
  ),
  503, makeMap(
    'low priority msg congestion', SC.LOW_PRIORITY_MSG_CONGESTION,
    'message vpn unavailable', SC.MESSAGE_VPN_UNAVAILABLE,
    'replication is standby', SC.REPLICATION_IS_STANDBY,
    'service unavailable', SC.GM_UNAVAILABLE,
    'spool over quota', SC.SPOOL_OVER_QUOTA,
    'subscriber delete in progress', SC.CLIENT_DELETE_IN_PROGRESS,
    'too many clients', SC.TOO_MANY_CLIENTS,
    'too many connections for vpn', SC.TOO_MANY_CLIENTS,
    'max message usage exceeded', SC.MAX_MESSAGE_USAGE_EXCEEDED
  ),
  507, makeMap(
    'ad not ready', SC.GM_NOT_READY
  )
);

const AD_MAPPING = makeMap(
  'PARENT', ROOT_MAPPING,
  400, makeMap(
    'already bound', SC.ALREADY_BOUND,
    'endpoint already exists', SC.ENDPOINT_ALREADY_EXISTS,
    'subscription already exists', SC.SUBSCRIPTION_ALREADY_PRESENT,
    'already exists', SC.ENDPOINT_ALREADY_EXISTS,
    'endpoint property mismatch', SC.ENDPOINT_PROPERTY_MISMATCH,
    'invalid durable topic endpoint name', SC.INVALID_DTE_NAME,
    'invalid selector', SC.INVALID_SELECTOR,
    'invalid topic name', SC.INVALID_TOPIC_NAME_FOR_TOPIC_ENDPOINT,
    'queue not found', SC.QUEUE_NOT_FOUND,
    'quota out of range', SC.QUOTA_OUT_OF_RANGE,
    'unknown flow name', SC.UNKNOWN_FLOW_NAME,
    'unsubscribe not allowed', SC.UNSUBSCRIBE_NOT_ALLOWED_CLIENTS_BOUND
  ),
  403, makeMap(
    'permission not allowed', SC.PERMISSION_NOT_ALLOWED,
    'client initiated replay not allowed on non-exclusive topic endpoint', SC.CLIENT_INITIATED_REPLAY_NON_EXCLUSIVE_NOT_ALLOWED,
    'client initiated replay not allowed on non-exclusive queue', SC.CLIENT_INITIATED_REPLAY_NON_EXCLUSIVE_NOT_ALLOWED,
    'client initiated replay from inactive flow not allowed', SC.CLIENT_INITIATED_REPLAY_INACTIVE_FLOW_NOT_ALLOWED,
    'client initiated replay from browser flow not allowed', SC.CLIENT_INITIATED_REPLAY_BROWSER_FLOW_NOT_ALLOWED,
    'replay not supported on temporary topic endpoint', SC.REPLAY_TEMPORARY_NOT_SUPPORTED,
    'replay not supported on temporary queue', SC.REPLAY_TEMPORARY_NOT_SUPPORTED,
    'unknown start location type', SC.UNKNOWN_START_LOCATION_TYPE,
    'mismatched endpoint error id', SC.MISMATCHED_ENDPOINT_ERROR_ID,
    'replay start message unavailable', SC.REPLAY_START_MESSAGE_NOT_AVAILABLE
  ),
  503, makeMap(
    'durable topic endpoint shutdown', SC.TOPIC_ENDPOINT_SHUTDOWN,
    'endpoint shutdown', SC.TOPIC_ENDPOINT_SHUTDOWN,
    'max clients exceeded for durable topic endpoint', SC.MAX_CLIENTS_FOR_TE,
    'max clients exceeded for queue', SC.MAX_CLIENTS_FOR_QUEUE,
    'no more non-durable queue or topic endpoint', SC.NO_MORE_NON_DURABLE_QUEUE_OR_TOPIC_ENDPOINT,
    'no subscription match', SC.NO_SUBSCRIPTION_MATCH,
    'queue shutdown', SC.QUEUE_SHUTDOWN,
    'te shutdown', SC.TOPIC_ENDPOINT_SHUTDOWN,
    'unknown durable topic endpoint', SC.UNKNOWN_TOPIC_ENDPOINT_NAME,
    'unknown queue', SC.UNKNOWN_QUEUE_NAME,
    'replay disabled', SC.REPLAY_DISABLED,
    'replay cancelled', SC.REPLAY_CANCELLED,
    'replay message unavailable', SC.REPLAY_MESSAGE_UNAVAILABLE,
    'replay started', SC.REPLAY_STARTED,
    'replayed message rejected by topic endpoint', SC.REPLAY_MESSAGE_REJECTED,
    'replayed message rejected by queue"', SC.REPLAY_MESSAGE_REJECTED,
    'replay log modified', SC.REPLAY_LOG_MODIFIED,
    'mismatched endpoint error id', SC.MISMATCHED_ENDPOINT_ERROR_ID,
    'out of replay resources', SC.OUT_OF_REPLAY_RESOURCES,
    'topic or selector modified on durable topic endpoint', SC.TOPIC_OR_SELECTOR_MODIFIED_ON_DURABLE_TOPIC_ENDPOINT,
    'replay failed', SC.REPLAY_FAILED,
    'replay start time not available', SC.REPLAY_START_TIME_NOT_AVAILABLE,
    'replay start message unavailable', SC.REPLAY_START_MESSAGE_NOT_AVAILABLE
  )
);

function getSubcodeMappingInner(root, respErrorCode, respStr) {
  if (respErrorCode === 200) {
    // success response, error subcode is 0 -  transport session use 0 as OK
    return 0;
  }
  const codeMap = root[respErrorCode] || {};
  const searchKey = (respStr || '').toLowerCase();
  const foundKey = Object.keys(codeMap)
    .find(mapKey => mapKey === searchKey || searchKey.indexOf(mapKey) >= 0);

  if (foundKey) {
    return codeMap[foundKey];
  }

  if (codeMap['']) {
    return codeMap[''];
  }

  /* eslint-disable dot-notation */
  // Don't access string keys via dot properties
  if (root['PARENT']) {
    return getSubcodeMappingInner(root['PARENT'], respErrorCode, respStr);
  }
  /* eslint-enable dot-notation */

  return undefined;
}

function getSubcodeMapping(root, respErrorCode, respStr) {
  const result = getSubcodeMappingInner(root, respErrorCode, respStr);
  if (result === undefined) {
    return SC.UNKNOWN_ERROR;
  }
  return result;
}

/**
 * @classdesc
 * Return {ErrorSubcode} based on response code and response string
 * from the router.
 * @private
 */
const ErrorResponseSubcodeMapper = {
  /**
   * @param {Number} respErrorCode The response code (an HTTP-like integer) to use in the lookup.
   * @param {String} respStr The response string to use in the lookup.
   * @returns {solace.ErrorSubcode} The subcode found, or ErrorSubcode.UNKNOWN_ERROR if not found.
   * @static
   * @private
   */
  getErrorSubcode(respErrorCode, respStr) {
    return getSubcodeMapping(ROOT_MAPPING, respErrorCode, respStr);
  },

  getADErrorSubcode(respErrorCode, respStr) {
    return getSubcodeMapping(AD_MAPPING, respErrorCode, respStr);
  },
};

module.exports.ErrorResponseSubcodeMapper = ErrorResponseSubcodeMapper;
