const { Enum } = require('solclient-eskit');

/**
 * Internal session state
 * @enum {string}
 * @memberof solace
 * @private
 */
const SessionStateName = {
  DISCONNECTED:                     'SessionDisconnected',
  CONNECTING:                       'SessionConnecting',
  WAITING_FOR_TRANSPORT:            'WaitingForTransport',
  WAITING_FOR_TRANSPORT_UP:         'WaitingForTransportUp',
  WAITING_FOR_LOGIN:                'WaitingForLogin',
  TRANSPORT_UP:                     'SessionTransportUp',
  FULLY_CONNECTED:                  'SessionFullyConnected',
  SESSION_ERROR:                    'SessionError',
  DISCONNECTING:                    'SessionDisconnecting',
  REAPPLYING_SUBSCRIPTIONS:         'ReapplyingSubscriptions',
  WAITING_FOR_PUBFLOW:              'WaitingForMessagePublisher',
  DISPOSED:                         'SessionDisposed',
  WAITING_FOR_SUBCONFIRM:           'WaitForSubConfirm',
  WAITING_FOR_CAN_ACCEPT_DATA:      'WaitForCanAcceptData',
  DISCONNECTING_FLOWS:              'DisconnectingFlows',
  FLUSHING_TRANSPORT:               'FlushingTransport',
  DESTROYING_TRANSPORT:             'DestroyingTransport',
  RECONNECTING:                     'Reconnecting',
  TRANSPORT_FAIL:                   'TransportFail',
  WAITING_FOR_INTERCONNECT_TIMEOUT: 'WaitingForInterconnectTimeout',
  WAITING_FOR_DNS:                  'WaitingForDNS',
  WAITING_FOR_TRANSPORT_CHANGE:     'WaitingForTransportChange',
};

module.exports.SessionStateName = Enum.new(SessionStateName);
