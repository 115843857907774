const { LOG_TRACE } = require('solclient-log');
const { TcpRawTransport, CompressedTransport, TlsOnlyTransport, TcpTlsTransport } = BUILD_ENV.TARGET_NODE ? require('./tcp') : {};
const { WebTransport } = require('./web');

const TransportFactory = {
  createTransport(host, eventCB, client, props, getId) {
    const url = host;
    Object.assign(props, {
      connectTimeoutInMsecs: 100000,
    });
    // TCP transport is dictated by the URL (tcp:// or tcps://)
    // It is unaffected by the transportProtocol config parameter.
    if (TcpRawTransport && url.trim().startsWith('tcp')) {
      if (!url.trim().startsWith('tcps')) {
        if (props.compressionLevel === 0) {
          LOG_TRACE('createTransport raw tcp');
          return new TcpRawTransport(url, eventCB, client, props);
        } // else { //compressed plaintext right off the bat.
        LOG_TRACE('createTransport compressed plaintex.');
        const compressingLayer = new CompressedTransport(eventCB, client, props);
        const plaintextTransport =
          new TcpRawTransport(url,
                              compressingLayer.eventCB.bind(compressingLayer),
                              compressingLayer, props);
        compressingLayer.setClientStats(plaintextTransport.getClientStats());
        plaintextTransport.setClientStats(null);
        compressingLayer.setUnderlyingTransport(plaintextTransport);
        return compressingLayer;
      } //else { //tcps
      if (props.sslConnectionDowngradeTo !== 'PLAIN_TEXT') { //no downgrade
        LOG_TRACE('createTransport monolithic tls');
        return new TcpTlsTransport(url, eventCB, client, props);
      } //else { // Composite TLS transport anticipating downgrade.
      LOG_TRACE('createTransport composite tls (anticipating downgrade)');
      // passing URL for SNI.
      const tlsLayer = new TlsOnlyTransport(url, eventCB, client, props);
      const plaintextTransport =
        new TcpRawTransport(url,
                            tlsLayer.eventCB.bind(tlsLayer),
                            tlsLayer, props);
      tlsLayer.setClientStats(plaintextTransport.getClientStats());
      plaintextTransport.setClientStats(null);
      tlsLayer.setUnderlyingTransport(plaintextTransport);
      return tlsLayer;
    }
    LOG_TRACE('createTransport WebTransport');
    return new WebTransport(url, eventCB, client, props, getId);
  },

  startCompression(uncompressedTransport) {
    const eventCB = uncompressedTransport._eventCB;
    const client = uncompressedTransport._client;
    const props = uncompressedTransport._props;

    const compressingLayer = new CompressedTransport(eventCB, client, props);
    uncompressedTransport.rehome(compressingLayer.eventCB.bind(compressingLayer),
                                 compressingLayer);
    compressingLayer.setClientStats(uncompressedTransport.getClientStats());
    uncompressedTransport.setClientStats(null);
    compressingLayer.setUnderlyingTransport(uncompressedTransport);
    compressingLayer.connect();
    return compressingLayer;
  },

  severTls(encryptedTransport, compress, callback) {
    if (!compress) {
      encryptedTransport.tlsShutdown(callback);
    } else {
      const precallback = plaintextTransport => callback(this.startCompression(plaintextTransport));
      //encryptedTransport.tlsShutdown(precallback.bind(this));
      encryptedTransport.tlsShutdown(precallback);
    }
  },
};

module.exports.TransportFactory = TransportFactory;
