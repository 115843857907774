const NodeEventEmitter = require('events').EventEmitter;
const { ArrayUtils } = require('solclient-util');
const { ErrorSubcode, OperationError } = require('solclient-error');
const { LOG_WARN } = require('solclient-log');

const { flatten, includes } = ArrayUtils;
const NODE_DEFAULT_EVENTS = ['error', 'newListener', 'removeListener'];
const BLACKLIST_DIRECT = ['newListener', 'removeListener'];

function buildFilter(emits) {
  if (typeof emits === 'function') return k => includes(NODE_DEFAULT_EVENTS, k) || emits(k);
  if (!Array.isArray(emits)) return null;
  const emitSet = new Set(flatten(emits));
  NODE_DEFAULT_EVENTS.forEach(el => emitSet.add(el));
  const emitArray = Array.from(emitSet);
  return k => includes(emitArray, k);
}

const DISABLED_ACTIONS = {
  ignore() {},
  fail() {
    throw new Error('Emitter disabled');
  },
};

class EventEmitter extends NodeEventEmitter {
  /**
   * Creates an instance of EventEmitter.
   *
   * An EventEmitter is an object that periodically emits events that cause function objects, known
   * as listeners, to be called. It exposes functions to attach passed functions to named events.
   *
   * Typically, event names are camel-cased strings, but any valid JavaScript property key can be
   * used.
   *
   * @param {?Object} options Options for the emitter
   * @param {String} [options.direct] An event that is to be directly dispatched when possible.
   *  Direct dispatch is a performance optimization that minimizes EventEmitter overhead. Direct
   *  dispatch skips the safe error handling path; use _formatErrorEvent if required.
   * @param {Array.<string>} [options.emits] Valid event names. If specified, listeners for other
   *  events are rejected. Array can be nested, e.g. `['foo', ['bar1', 'bar2']]`
   * @param {Boolean} [options.unsafe] Determines whether dispatch is less exception-safe.
   *  * When `false`, if an event listener throws an error, the error is thrown all the way back
   *    to the emitting stack frame. This makes it the emitter's responsibility to catch exceptions
   *    in listeners. This avoids a try-catch frame and may be more performant.
   *  * When `true`, if an event listener throws an error, and an `error` listener exists, the
   *    exception is dispatched to that listener wrapped in an {@link solace.OperationError},
   *    `subcode={@link solace.ErrorSubcode.CALLBACK_ERROR}`. The originating event and the error
   *    thrown by the listener are available on `event` and `error` fields respectively.
   *
   *  In all cases, throwing an exception in a listener prevents the event being received by
   *  later listeners.
   *
   *  This option does not affect any event selected for `options.direct`, which always uses
   *  unsafe dispatch.
   *
   * @memberof solace
   * @extends {EventEmitter}
   * @private
   */
  constructor(options) {
    super();
    const { direct, emits, unsafe, formatEventName } = options || {};
    this.formatEventName = formatEventName || (name => name);

    // Direct mode path:
    //  emit --> (direct emit || bareEmit).
    const bareEmit = this.emit.bind(this);
    this._installDirectFilter(direct, bareEmit);

    // Indirect mode path:
    //  verifier -> error handlers -> emit --> bareEmit
    this._installErrorHandlers(unsafe);
    this._installEmitVerifier();

    this._listenerVerificationFilter = buildFilter(emits);
    this._emits = emits;
  }

  _installDirectFilter(direct, bareEmit) {
    if (!direct) return;
      // Direct mode class instance modifications

    if (includes(BLACKLIST_DIRECT, direct)) {
      throw new OperationError(
        `Cannot configure listener collection events [${BLACKLIST_DIRECT.join(', ')}] as direct`,
        ErrorSubcode.INTERNAL_ERROR);
    }

    // The default direct emitter is the base emitter. Skip any overrides we installed.
    this._defaultEmitDirect = (...args) => bareEmit(direct, ...args);

    // Initally not direct for 0 listeners
    this.emitDirect = this._defaultEmitDirect;
    this._directEventName = direct;

    this.on = (eventName, listener) => {
      this._verifyListenerEvent(eventName);
      const ret = super.on(eventName, listener);
      this._setEmitDirect(eventName, true, listener);
      return ret;
    };

    this.addListener = (eventName, listener) => this.on(eventName, listener);

    this.once = (eventName, listener) => {
      this._verifyListenerEvent(eventName);
      const ret = super.once(eventName, listener);
      this._setEmitDirect(eventName, false);
      return ret;
    };

    this.prependListener = (eventName, listener) => {
      this._verifyListenerEvent(eventName);
      const ret = super.prependListener(eventName, listener);
      this._setEmitDirect(eventName, true, listener);
      return ret;
    };

    this.prependOnceListener = (eventName, listener) => {
      this._verifyListenerEvent(eventName);
      const ret = super.prependOnceListener(eventName, listener);
      this._setEmitDirect(eventName, false);
      return ret;
    };

    this.removeAllListeners = (eventName) => {
      const ret = super.removeAllListeners(eventName);
      if ((eventName === this._directEventName) || (eventName === undefined)) {
        this.emitDirect = this._defaultEmitDirect;
      }
      return ret;
    };

    this.removeListener = (eventName, listener) => {
      const ret = super.removeListener(eventName, listener);
      if ((eventName === this._directEventName) && (this.listenerCount(eventName) === 0)) {
        this.emitDirect = this._defaultEmitDirect;
      }
      return ret;
    };

    this.directListenerCount = () => this.listenerCount(this._directEventName);

    this.setOnFirstDirectListener = (firstDirect) => {
      this._onFirstDirectListener = firstDirect;
    };
  }

  _setEmitDirect(eventName, onListener, listener) {
    if (eventName !== this._directEventName) {
      return;
    }

    if (onListener && (this.directListenerCount() === 1)) {
      this.emitDirect = listener;
    } else {
      this.emitDirect = this._defaultEmitDirect;
    }

    if ((this.directListenerCount() === 1) && (this._onFirstDirectListener !== undefined)) {
      this._onFirstDirectListener();
    }
  }

  _verifyListenerEvent(event) {
    if (!this._listenerVerificationFilter) return;

    if (event === undefined || event === null) {
      this.throwInternal(
          new OperationError(`Emitter rejects listener for no-name event: ${event}`,
                              ErrorSubcode.PARAMETER_OUT_OF_RANGE));
    }
    if (!this._listenerVerificationFilter(event)) {
      this.throwInternal(
        new OperationError(`Emitter rejects listeners for ${event}, emits ${this._emits}`,
                            ErrorSubcode.PARAMETER_OUT_OF_RANGE));
    }
  }

  _installEmitVerifier() {
    if (BUILD_ENV.MODE_DEBUG) {
      // Debug mode: always add assert for empty event name
      const emitBase = this.emit.bind(this);
      this.emit = (name, ...args) => {
        if (name === undefined || name === null) {
          this.throwInternal(new OperationError(`Emitter rejects no-name event: ${name}`));
        }
        emitBase(name, ...args);
      };
    }
  }

  _installErrorHandlers(unsafe) {
    if (unsafe) {
      // If unsafe, there's no try/catch/emit so throwInternal just throws
      this.throwInternal = (err) => { throw err; };
      return;
    }

    // Install try/catch/emit-as-event if not unsafe mode.
    // Introduces throwInternal, which sets the _internalError flag restore
    // orignal throw-to-emitter functionality in case of internal error

    const emitBase = this.emit.bind(this);

    this.throwInternal = function throwInternal(err) {
      this._internalError = true;
      throw err;
    };

    this.emit = (name, ...args) => {
      try {
        emitBase(name, ...args);
      } catch (ex) {
        if (this._internalError) {
          this._internalError = undefined;
          throw ex; // rethrow
        }

        const err = this.formatErrorEvent(ex, name, ...args);
        try {
          LOG_WARN(`Listener for '${err.info.event.formattedName}' threw exception, dispatching to 'error'`);
          emitBase('error', err);
        } catch (innerEx) {
          LOG_WARN("Listener for 'error' threw exception:", innerEx, '\nOriginal exception:', ex);
        }
      }
    };
  }

  get isDirect() {
    return this.emitDirect && (this.emitDirect !== this._defaultEmitDirect);
  }

  formatErrorEvent(ex, name, ...args) {
    const formattedName = this.formatEventName(name);
    return Object.assign(new OperationError(
      `Unhandled error in event handler for '${formattedName}'`,
      ErrorSubcode.CALLBACK_ERROR,
      `On event: ${[name, ...args]} ${ex}`
    ), {
      stack: ex.stack,
      info:  {
        event: { name, formattedName, args },
        error: ex,
      },
    });
  }

  disableEmitter() {
    this._defaultEmitDirect = DISABLED_ACTIONS.ignore;
    // If this is a direct-enabled emitter, this will also cause emitDirect to be set to
    // _defaultEmitDirect, which is now DISALBED_ACTIONS.ignore.
    this.removeAllListeners();
    this.emit = DISABLED_ACTIONS.ignore;
    // Freeze listeners by first disabling remove, then add.
    this.addListener('removeListener', DISABLED_ACTIONS.fail);
    this.addListener('newListener', DISABLED_ACTIONS.fail);
  }

}

module.exports.EventEmitter = EventEmitter;
