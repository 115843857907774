/* eslint-disable global-require */
let parseURL;
if (BUILD_ENV.TARGET_NODE) {
  /* eslint-env node */
  parseURL = require('url').parse;
} else {
  /* eslint-env browser */
  const scope = typeof window !== 'undefined' ? window : this;
  require('./browser-url-polyfill')(scope);
  parseURL = function makeURL(...args) {
    return new URL(...args);
  };
}

module.exports.parseURL = parseURL;
