const { StringBuffer } = require('./string-buffer');

const PAD_LEFT = 0;
const PAD_RIGHT = 1;

const LUT_PRINTABLE = (() => {
  const tmp = [];
  for (let c = 0; c < 256; ++c) {
    tmp[c] = (c < 33 || c > 126) ? '.' : String.fromCharCode(c);
  }
  return tmp;
})();

function padLeftRight(str, minLen, padSide, padChar = ' ') {
  if (typeof str !== 'string') {
    return str;
  }
  if (str.length >= minLen) {
    return str;
  }

  const buf = new StringBuffer();
  for (let i = 0; i < (minLen - str.length); i++) {
    buf.append(padChar.charAt(0));
  }
  switch (padSide) {
    case PAD_LEFT:
      return `${buf}${str}`;
    case PAD_RIGHT:
      return `${str}${buf}`;
    default:
      return str;
  }
}

function leastSpaces(length, line) {
  if (!line.length) return length;
  const spaces = line.match(/^\s*/)[0].length;
  return spaces < length ? spaces : length;
}

function capitalizeWord(str) {
  return `${str.charAt(0).toUpperCase()}${str.substr(1)}`;
}

function isEmptyFun(str) {
  return (
    str === undefined ||
    str === null ||
    str.length === 0
  );
}

/**
 * @private
 * @type {StringUtils}
 */
const StringUtils = {};

StringUtils.capitalize = function capitalize(str) {
  if (!(str && str.length)) {
    return str;
  }

  return str.split(' ').map(capitalizeWord).join(' ');
};

StringUtils.isEmpty = isEmptyFun;

StringUtils.notEmpty = function notEmpty(str) {
  return !(isEmptyFun(str));
};

StringUtils.toSafeChars = function toSafeChars(str) {
  return str.replace(/[^a-zA-Z0-9_/.]/g, '');
};

StringUtils.padLeft = function padLeft(str, minLen, padChar) {
  return padLeftRight(str, minLen, PAD_LEFT, padChar);
};

StringUtils.padRight = function padRight(str, minLen, padChar) {
  return padLeftRight(str, minLen, PAD_RIGHT, padChar);
};

StringUtils.nullTerminate = function nullTerminate(str) {
  if (str === null || str === undefined) {
    throw new Error('non str in nullTerminate');
  }
  const lastChar = str.charCodeAt(str.length - 1);
  if (lastChar === 0) {
    return str;
  }
  return str + String.fromCharCode(0x00);
};

StringUtils.stripNullTerminate = function stripNullTerminate(str) {
  if (str === null || str === undefined) {
    throw new Error('null str in stripNullTerminate');
  }
  const lastChar = str.charCodeAt(str.length - 1);
  if (lastChar === 0) {
    return str.substr(0, str.length - 1);
  }
  return str;
};

StringUtils.hexdump = function hexdump(s) {
  const output = new StringBuffer();
  const printable = new StringBuffer();
  const spacer = pos => (pos === 8 || pos === 16 ? '  ' : ' ');
  let linelen = 0;
  for (let i = 0, sLength = s.length; i < sLength; i++) {
    const ccode = s.charCodeAt(i);
    output.append(padLeftRight(ccode.toString(16), 2, PAD_LEFT));
    printable.append(LUT_PRINTABLE[ccode] || '.');
    output.append(spacer(++linelen));

    if (i === s.length - 1) {
      // input finished: complete the line
      while (linelen < 16) {
        output.append(`  ${spacer(++linelen)}`);
      }
    }

    if (linelen === 16) {
      output.append(printable.join(''));
      output.append('\n');
      linelen = 0;
      printable.clear();
    }
  }
  return output.toString();
};

/**
 * Use heredoc`....` to create multi-line heredoc strings. Leading blank lines are removed, as are
 * leading spaces, up to the number of spaces on the least-indented line.
 * @param {String} literals The document template
 * @param {...String} substitutions The substitutions to make in the document
 * @returns {String} The interpolated representation of the document
 */
StringUtils.heredoc = function heredoc(literals, ...substitutions) {
  const subst = [...substitutions, ''];
  const lines = literals.map(k => k + subst.shift())
    .join('')
    .split(/\r?\n/);
  const spaces = lines.length === 1 ? 0 : lines.reduce(leastSpaces, Infinity);
  while (lines[0] === '') {
    lines.shift();
  }
  return lines.map(line => line.substring(spaces)).join('\n');
};


module.exports.StringUtils = StringUtils;
