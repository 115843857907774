const { Enum } = require('solclient-eskit');

/**
 * Represents authentication scheme enumeration.
 * @enum {string}
 * @namespace
 * @memberof solace
 */
const MessagePublisherAcknowledgeMode = {
  /**
   * @description Applications receive an acknowledgement for every
   * message.
   * @type {String}
   */
  PER_MESSAGE: 'PER_MESSAGE',
  /**
   * @description Applications receive a windowed acknowledgement that
   * acknowledges the returned correlation identifier and every message sent prior.
   * @type {String}
   */
  WINDOWED:    'WINDOWED',
};

module.exports.MessagePublisherAcknowledgeMode = Enum.new(MessagePublisherAcknowledgeMode);
