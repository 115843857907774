const { ErrorResponseSubcodeMapper } = require('./lib/error-response-subcode-mapper');
const { ErrorSubcode } = require('./lib/error-subcodes');
const { NotImplementedError } = require('./lib/not-implemented-error');
const { OperationError } = require('./lib/operation-error');
const { RequestError } = require('./lib/request-error');
const { RequestEventCode } = require('./lib/request-event-codes');
const { SolaceError } = require('./lib/solace-error');

module.exports.ErrorResponseSubcodeMapper = ErrorResponseSubcodeMapper;
module.exports.ErrorSubcode = ErrorSubcode;
module.exports.NotImplementedError = NotImplementedError;
module.exports.OperationError = OperationError;
module.exports.RequestError = RequestError;
module.exports.RequestEventCode = RequestEventCode;
module.exports.SolaceError = SolaceError;
