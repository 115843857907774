const { Enum } = require('solclient-eskit');

/**
 * Represents the permissions applicable to a queue.
 *
 * The corresponding endpoint property is
 * {@link solace.QueueProperties#permissions}.
 *
 * The access controls:
 *  * the permissions for all other users of the queue, this only applies to
 *  non-durable queues {@link solace.QueueProperties#permissions};
 *  * for the current Message Consumer  on a queue or endpoint,
 *    {@link solace.MessageConsumer.permissions}
 *
 * For example, creating a temporary topic endpoint with MODIFY_TOPIC will allow
 * other users to modify the topic subscribed to that endpoint.
 *
 * @enum {string}
 * @namespace
 * @memberof solace
 */
const QueuePermissions = {
  /**
   * @description No client other than the queue's owner may access the endpoint.
   */
  NONE:         'NONE',
  /**
   * @description Client may read messages but not consume them.
   * @type {String}
   */
  READ_ONLY:    'READ_ONLY',
  /**
   * @description  Client may read and consume messages.
   * @type {String}
   */
  CONSUME:      'CONSUME',
  /**
   * @description Client may read and consume messages, and modify topic(s) associated with the
   * queue.
   * @type {String}
   */
  MODIFY_TOPIC: 'MODIFY_TOPIC',
  /**
   * @description Client may read and consume messages, modify topic(s) associated with the
   * queue, and delete the queue.
   * @type {String}
   */
  DELETE:       'DELETE',
};

module.exports.QueuePermissions = Enum.new(QueuePermissions);
