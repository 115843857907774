let assert;

/**
 * Browsers and Node share a common console.assert interface, console.assert(assertion, ...);
 * In Node, console.assert throws AssertionError if its assertion is falsy.
 * In browsers, they print to the console.
 * Extend browser behaviour to also throw.
 */

if (BUILD_ENV.MODE_DEBUG) {
  let internalAssert;
  if (BUILD_ENV.TARGET_NODE) {
    // _eslint-disable-next-line no-console
    internalAssert = console.assert;
  } else {
    internalAssert = function browserAssert(assertion, ...args) {
      if (!assertion) {
        throw new Error(`Assertion error: ${typeof assertion} ${assertion} (${args.join(' ')})`);
      }
    };
  }
  // Now, wrap the assert in a catch and rethrow to give us a single place
  // to set assert breakpoints.
  assert = function outerAssert(...args) {
    // eslint-disable-next-line no-useless-catch
    try {
      internalAssert(...args);
    } catch (e) {
      // if (BUILD_ENV.MODE_DEBUG) debugger;
      throw (e);
    }
  };
} else {
  // In production mode, asserts are generally disabled.
  assert = () => {};
}

module.exports.assert = assert;
