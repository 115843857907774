const { Enum } = require('solclient-eskit');


/**
 * @callback solace.SessionEventCodes.sessionEvent
 * @function
 * @this {solace.Session} The message consumer for the event
 * @param {solace.SessionEvent|solace.OperationError} event The event. If the event is an
 *  error, the event will be an instance of {@link solace.OperationError} but will also be
 *  interface-compatible with {@link solace.SessionEvent}.
 */


/**
 * An attribute of {@link SessionEvent}. This enumeration represents the
 * different events emitted by {@link Session} through the session event
 * callback.
 *
 * When a session is no longer in a usable state, the API tears down the underlying
 * connection and notifies the application with one of the following session events:
 *  * {@link solace.SessionEventCode#event:DOWN_ERROR}
 *  * {@link solace.SessionEventCode#event:CONNECT_FAILED_ERROR}
 *
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const SessionEventCode = {
  /**
   * The Session is ready to send/receive messages and perform control operations.
   *
   * At this point the transport session is up, the Session has logged in, and the
   * P2PInbox subscription is added.
   *
   * The session is established.
   * @event solace.SessionEventCode#UP_NOTICE
   */
  UP_NOTICE:                         0,
  /**
   * The session was established and then went down.
   * @event solace.SessionEventCode#DOWN_ERROR
   * @param {solace.OperationError} error The details related to the session failure.
   */
  DOWN_ERROR:                        1,
  /**
   * The session attempted to connect but was unsuccessful.
   * @event solace.SessionEventCode#CONNECT_FAILED_ERROR
   * @param {solace.OperationError} error The details related to the failed connection attempt.
   */
  CONNECT_FAILED_ERROR:              2,
  /**
   * The Solace Message Router rejected a published message.
   * @event solace.SessionEventCode#REJECTED_MESSAGE_ERROR
   * @param {solace.RequestError} error The details related to the rejected message.
   */
  REJECTED_MESSAGE_ERROR:            4,
  /**
   * The Solace Message Router rejected a subscription (add or remove).
   * @event solace.SessionEventCode#SUBSCRIPTION_ERROR
   * @param {solace.RequestError} error The details related to the failed subscription update.
   */
  SUBSCRIPTION_ERROR:                5,
  /**
   * The subscribe or unsubscribe operation succeeded.
   * @event solace.SessionEventCode#SUBSCRIPTION_OK
   * @param {solace.SessionEvent} event The details related to the successful subscription update.
   */
  SUBSCRIPTION_OK:                   6,
  /**
   * The Solace Message Router's Virtual Router Name changed during a reconnect operation.
   * @event solace.SessionEventCode#VIRTUALROUTER_NAME_CHANGED
   * @param {solace.SessionEvent} event Information related to the event.
   */
  VIRTUALROUTER_NAME_CHANGED:        7,
  /**
   * @deprecated
   * A request was aborted because the session is being disconnected.
   * Use {@link solace.RequestFailEvent} instead.
   */
  REQUEST_ABORTED:                   8,
  /**
   * @deprecated
   * The event represents a timed-out request API call.
   * Use {@link solace.RequestFailEvent} instead.
   */
  REQUEST_TIMEOUT:                   9,
  /**
   * The event represents a successful update of a mutable session property.
   * @event solace.SessionEventCode#PROPERTY_UPDATE_OK
   * @param {solace.SessionEvent} event Information related to the successful property update.
   */
  PROPERTY_UPDATE_OK:                10,
  /**
   * The event represents a failed update of a mutable session property.
   * @event solace.SessionEventCode#PROPERTY_UPDATE_ERROR
   * @param {solace.RequestError} error The details related to the failed property update.
   */
  PROPERTY_UPDATE_ERROR:             11,
  /**
   * The session transport can accept data again.  This event will occur after an
   * {@link solace.OperationError} is thrown from an API call with a subcode of
   * {@link solace.ErrorSubcode.INSUFFICIENT_SPACE} to indicate the operation can be retried.
   * This event is used both after session-level transport buffer exhaustion,
   * and Guaranteed Messaging Window exhaustion.
   * @event solace.SessionEventCode#CAN_ACCEPT_DATA
   */
  CAN_ACCEPT_DATA:                   13,
  /**
   * The session connect operation failed, or the session that was once up,
   * is now disconnected.
   * @event solace.SessionEventCode#DISCONNECTED
   */
  DISCONNECTED:                      14,
  /**
   * The session has gone down, and an automatic reconnection attempt is in progress.
   * @event solace.SessionEventCode#RECONNECTING_NOTICE
   * @param {solace.SessionEvent} event The details related to the cause of the connection
   *    interruption.
   */
  RECONNECTING_NOTICE:               22,
  /**
   * The automatic reconnect of the Session was successful, and the session is established again.
   * @event solace.SessionEventCode#RECONNECTED_NOTICE
   * @param {solace.SessionEvent} event The details related to the re-establishment of the
   *    connection.
   */
  RECONNECTED_NOTICE:                23,
  /**
   * The session has automatically recovered after the Guaranteed Message publisher
   * failed to reconnect.
   * Messages sent but not acknowledged are being renumbered and retransmitted.
   * Some messages may be duplicated in the system.
   * @event solace.SessionEventCode#REPUBLISHING_UNACKED_MESSAGES
   * @param {solace.SessionEvent} event The details related to the republishing of messages on the
   *    session.  {@link solace.SessionEvent#infoStr} will indicate the number of messages being
   *    republished, which is an upper bound on the number of messages that could be duplicated due
   *    to this action.
   */
  REPUBLISHING_UNACKED_MESSAGES:     24,
  /**
   * A message was acknowledged by the router.
   * @event solace.SessionEventCode#ACKNOWLEDGED_MESSAGE
   * @param {solace.SessionEvent} event Allows the acknowledgement to be correlated to the sent
   *    message.
   */
  ACKNOWLEDGED_MESSAGE:              25,
  /**
   * Unsubscribing the topic from the Durable Topic Endpoint succeeded.
   * @event solace.SessionEventCode#UNSUBSCRIBE_TE_TOPIC_OK
   */
  UNSUBSCRIBE_TE_TOPIC_OK:           26,
  /**
   * Unsubscribing the topic from the Durable Topic Endpoint failed.
   * @event solace.SessionEventCode#UNSUBSCRIBE_TE_TOPIC_ERROR
   * @param {solace.OperationError} error The details related to the failed attempt to remove the
   *    subscription from a topic endpoint.
   */
  UNSUBSCRIBE_TE_TOPIC_ERROR:        27,
  /**
   * A Direct message was received on the session. This event code is only used
   * on the <b>EventEmitter</b> session interface.  If using the deprecated callback interface,
   * messages are received via the callback provided in {@link solace.MessageRxCBInfo}.
   * @event solace.SessionEventCode#MESSAGE
   * @param {solace.Message} message The message received on the session.
   */
  MESSAGE:                           28,
  /**
   * Guaranteed Messaging Publisher has been closed by the Solace
   * message router. This usually indicates an operator has disabled the
   * message spool.
   * @event solace.SessionEventCode#GUARANTEED_MESSAGE_PUBLISHER_DOWN
   * @param {solace.OperationError} error Information related to the error.
   */
  GUARANTEED_MESSAGE_PUBLISHER_DOWN: 29,
};

module.exports.SessionEventCode = Enum.new(SessionEventCode);
