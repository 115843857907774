const StateLib = require('./state');
const { FsmObject } = require('./object');

/**
 * @classdesc
 * This object type represents an exitPoint for a particular FSM state.
 *
 * Note this initial implementation isn't particularly efficient, but it is
 * very simple by making use of two state objects (one just inside of and
 * one just outside of) the state the exitPoint belongs to.  If necessary, we
 * could optimize this if we wish.
 * @private
 */
class ExitPoint extends FsmObject {

  /**
  * @constructor
  * @param {Object} spec The object specifier used to implement the named
  *      parameter idiom.
  * @param {fsm.State} spec.state The state that the exitPoint belongs
  *      to.
  * @param {String} spec.exitPointName The name of the exitPoint.
  * @param {fsm.StateContext~reactionCallback} spec.func The reaction
  *      function for the exitPoint, which defines where to transition to
  *      after state has been exited.
  */
  constructor(spec) {
    super({ name: spec.exitPointName });

    let outerState;
    // The inner portion of the exitPoint accepts incoming transitions before
    // exiting the exitPoint's state.  Then the exitPoint's state is exited
    // through the use of the innerExitPoint's initial transition.
    this.impl.innerState = new StateLib.State({
      name:          `${spec.state.getName()} innerExitPoint: ${spec.exitPointName}`,
      parentContext: spec.state,
    }).initial(() => spec.state.transitionTo(outerState));

        // The job of the outer portion of the exitPoint is to follow a
        // transition as specified by the application's reaction function.
    outerState = new StateLib.State({
      name:          `${spec.state.getName()} outerExitPoint: ${spec.exitPointName}`,
      parentContext: spec.state.getParent(),
    }).initial(spec.func);
  }

  getDestState() {
    return this.impl.innerState;
  }
}

module.exports.ExitPoint = ExitPoint;
