const { EventEmitter } = require('solclient-events');
const { LogFormatter } = require('solclient-log');
const { MessageConsumerEventName } = require('./message-consumer-event-names');
const { QueueBrowserEventName } = require('./queue-browser-event-names');

function formatEventName(eventName) {
  return `QueueBrowserEventName.${QueueBrowserEventName.describe(eventName)}`;
}

/**
 * @classdesc
 * <b>This class is not exposed for construction by API users.</b>
 * A Queue Browser is created by calling {@link solace.Session#createQueueBrowser}.
 *
 * A Queue Browser allows client applications to look at messages spooled on Endpoints
 * without removing them. Messages are browsed from oldest to newest.
 * After being browsed, messages are still available for consumption over normal flows.
 * However, it is possible to selectively remove messages from the persistent store of an Endpoint.
 * In this case, these removed messages will no longer be available for consumption.

 * Note: If browsing a queue with an active consumer, no guarantee is made that the browser will
 * receive all messages published to the queue. The consumer can receive and acknowledge messages
 * before they are delivered to the browser.
 *
 * One typical application is to use Browsers to allow message bus administrators to remove “stuck”
 * Guaranteed messages from an Endpoint without having to modify or disrupt existing applications.
 * A message can get stuck if:
 *
 *  1) It has been received by an application, but for some reason, that application has failed to
 *     acknowledge it.
 *  2) All active message selectors have failed to match this particular message and therefore the
 *     message bus has not delivered it to any client yet. The current release only supports
 *     browsing Endpoints of type Queue.
 *
 * Note that the delivery restrictions imposed by the queue’s Access type
 * (exclusive or non-exclusive), do not apply when browsing messages with a Browser.
 *
 * Browser characteristics and behavior are defined by {@link solace.QueueBrowserProperties}.
 * The properties can also be supplied as a simple key-value {Object}. The queue descriptor,
 * {@link solace.QueueBrowserProperties#queueDescriptor} must be specified to identify the
 * Guaranteed Message Queue on the Solace Message Router.
 *
 * The Browser is an EventEmitter, and will emit events to which the application may choose to
 * subscribe, such as the connection to the Solace Message Router going up or down.
 *
 * If a registered listener for an emitted event throws an exception, this is caught and emitted as
 * an 'error'.
 *
 * @fires solace.QueueBrowserEventName#CONNECT_FAILED_ERROR
 * @fires solace.QueueBrowserEventName#DISPOSED
 * @fires solace.QueueBrowserEventName#DOWN
 * @fires solace.QueueBrowserEventName#DOWN_ERROR
 * @fires solace.QueueBrowserEventName#GM_DISABLED
 * @fires solace.QueueBrowserEventName#MESSAGE
 * @fires solace.QueueBrowserEventName#UP
 *
 * @hideconstructor
 * @memberof solace
 */
class QueueBrowser extends EventEmitter {
  constructor(messageConsumer) {
    super({
      direct: QueueBrowserEventName.MESSAGE,
      emits:  QueueBrowserEventName.values,
      formatEventName,
    });

    this._messageConsumer = messageConsumer;
    this.logger = new LogFormatter((...args) =>
      ['[queue-browser]', ...args]);

    this._setupEventListers();
  }

  _setupEventListers() {
    // Listen to message consumer events, redispatch as queue browser events
    this._messageConsumer.on(MessageConsumerEventName.UP, this._onConsumerUp.bind(this));

    this._messageConsumer.on(MessageConsumerEventName.CONNECT_FAILED_ERROR,
                             this._onConsumerConnectFailed.bind(this));

    this._messageConsumer.on(MessageConsumerEventName.DOWN, this._onConsumerDown.bind(this));

    this._messageConsumer.on(MessageConsumerEventName.DOWN_ERROR,
                             this._onConsumerDownError.bind(this));

    this._messageConsumer.on(MessageConsumerEventName.MESSAGE, this._onConsumerMessage.bind(this));

    this._messageConsumer.on(MessageConsumerEventName.DISPOSED,
                             this._onConsumerDisposed.bind(this));

    this._messageConsumer.on(MessageConsumerEventName.GM_DISABLED,
                             this._onConsumerGMDisabled.bind(this));
  }

  _onConsumerMessage(event) {
    this.emit(QueueBrowserEventName.MESSAGE, event);
  }

  _onConsumerUp(event) {
    this.emit(QueueBrowserEventName.UP, event);
  }

  _onConsumerConnectFailed(event) {
    this.emit(QueueBrowserEventName.CONNECT_FAILED_ERROR, event);
  }

  _onConsumerDown(event) {
    this.emit(QueueBrowserEventName.DOWN, event);
  }

  _onConsumerDownError(event) {
    this.emit(QueueBrowserEventName.DOWN_ERROR, event);
  }

  _onConsumerDisposed(event) {
    this.emit(QueueBrowserEventName.DISPOSED, event);
  }

  _onConsumerGMDisabled(event) {
    this.emit(QueueBrowserEventName.GM_DISABLED, event);
  }

  /**
   * Connects the queue browser immediately. The application should add event listeners (see
   * {@link solace.QueueBrowserEventName}). If there is no listener added for
   * {@link solace.QueueBrowserEventName#event:MESSAGE} then up to a window
   * {@link solace.QueueBrowserProperties.windowSize} of messages can be queued internally.
   * before calling this method.
   *
   * @throws {solace.OperationError}
   *  * if consumer is not supported by router for this client.
   *  subcode = {@link solace.ErrorSubcode.INVALID_OPERATION}
   *
   */
  connect() {
    const { LOG_DEBUG, LOG_ERROR } = this.logger;
    try {
      LOG_DEBUG('Connecting the queue browser\'s message consumer');
      this._messageConsumer.connect();
    } catch (error) {
      LOG_ERROR(error.toString());
      throw error;
    }
  }

  /**
   * Initiates an orderly disconnection of the queue browser. The API will send an unbind request.
   * Any messages subsequently received are discarded silently.
   * When the unbind message is acknowledged, the application
   * receives a {@link solace.QueueBrowserEventName#event:DOWN} event if it has set a listener
   * for that event.
   *
   * @throws {solace.OperationError}
   * * if the Message Consumer is disconnected.
   *   subcode = {@link solace.ErrorSubcode.INVALID_OPERATION}
   */
  disconnect() {
    const { LOG_DEBUG, LOG_ERROR } = this.logger;

    try {
      LOG_DEBUG('Disconnecting the queue browser\'s message consumer');
      this._messageConsumer.disconnect();
    } catch (error) {
      LOG_ERROR(error.toString());
      throw error;
    }
  }

  /**
   * Begins delivery of messages to this queue browser. This method opens the protocol window
   * to the Solace Message Router so further messages can be received.
   *
   * A newly created queue browser is in started state.
   *
   * If the queue browser was already started, this method has no effect.
   *
   * A consumer is stopped by calling {@link solace.QueueBrowser.stop}
   *
   * @throws {solace.OperationError}
   * * if the Queue BrowserMessage Consumer is disposed.
   *   subcode = {@link solace.ErrorSubcode.INVALID_OPERATION}
   * * if the Message Consumer is disconnected.
   *   subcode = {@link solace.ErrorSubcode.INVALID_OPERATION}
   */
  start() {
    const { LOG_DEBUG, LOG_ERROR } = this.logger;
    try {
      LOG_DEBUG('Starting the queue browser\'s message consumer');
      this._messageConsumer.start();
    } catch (error) {
      LOG_ERROR(error.toString());
      throw error;
    }
  }

  /**
   * Stops messages from being delivered to this queue browser from the Solace Message Router.
   * Messages may continue to be prefetched by the API and queued internally
   * until {@link solace.QueueBrowser#start} is called.
   *
   * If the queue browser was already stopped, this method has no effect.
   *
   * @throws {solace.OperationError}
   * * if the Queue Browser is disconnected.
   *   subcode = {@link solace.ErrorSubcode.INVALID_OPERATION}
   */
  stop() {
    const { LOG_DEBUG, LOG_ERROR } = this.logger;
    try {
      LOG_DEBUG('Stopping the queue browser\'s message consumer');
      this._messageConsumer.stop();
    } catch (error) {
      LOG_ERROR(error.toString());
      throw error;
    }
  }

  /**
   * Removes a message from the queue by acknowledging it.
   *
   * The {@link solace.QueueBrowser} does not automatically acknowledge messages.
   * once they have been received.
   *
   * The API does not send acknowledgments immediately. It stores the state for
   * acknowledged messages internally and acknowledges messages, in bulk, when a
   * threshold or timer is reached.
   *
   * @param {Message} message The message to remove
   */
  removeMessageFromQueue(message) {
    // ack the message to delete it
    this._messageConsumer.applicationAck(message._guaranteedMsgId);
    message._acked = true;
  }

}

module.exports.QueueBrowser = QueueBrowser;
