const EncodeSingleElementLib = require('./encode-single-element');
const { SDTStreamContainer } = require('../sdt-stream-container');

const EncodeStream = {};
EncodeStream.encodeStream = function encodeStream(sdtstream) {
  const buf = [];
  if (!(sdtstream instanceof SDTStreamContainer)) {
    return null; // skip!
  }
  let sdtfield = null;
  while (sdtstream.hasNext()) {
    sdtfield = sdtstream.getNext();
    if (sdtfield) {
      EncodeSingleElementLib.EncodeSingleElement.encodeSingleElementToBuf(sdtfield, buf);
    }
  } // end iter over stream entries
  return buf.join('');
};

module.exports.EncodeStream = EncodeStream;
