const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SMFSMPMessageTypeFlags = {
  FLAG_FILTER:                1,
  FLAG_PERSIST:               2,
  SMF_SMP_FLAG_TOPIC:         4,
  SMF_SMP_FLAG_RESPREQUIRED:  8,
  SMF_SMP_FLAG_DELIVERALWAYS: 16,
};

module.exports.SMFSMPMessageTypeFlags = Enum.new(SMFSMPMessageTypeFlags);
