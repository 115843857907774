/**
 * This class is used to efficiently concatenate strings.
 * @private
 */
class StringBuffer {
  /**
   * @constructor
   * @param {...*} args Initial buffer contents
   * @private
   */
  constructor(...args) {
    this.clear();
    this.append(...args);
  }

  /**
   * Appends the given strings to the buffer
   *
   * @param {...*} args Objects to add to the buffer. These are immediately
   *                    coerced to strings.
   * @returns {StringBuffer} this
   */
  append(...args) {
    [...args].forEach((arg) => {
      this.buffer[this.index++] = String(arg);
    });
    return this;
  }

  /**
   * Resets the buffer.
   */
  clear() {
    this.buffer = [];
    this.index = 0;
  }

  /**
   * Returns the buffer as a string. Additional concatenations and renderings
   * can be performed.
   * @returns {String} The buffer's contents concatenated to a string.
   */
  toString() {
    return this.buffer.join('');
  }
}

module.exports.StringBuffer = StringBuffer;
