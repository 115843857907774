const ParseSingleElementLib = require('./parse-single-element');
const { ParseFieldHeader } = require('./parse-field-header');
const { SDTField } = require('../sdt-field');
const { SDTFieldType } = require('../sdt-field-types');
const { SDTStreamContainer } = require('../sdt-stream-container');

const ParseStream = {};
ParseStream.parseStreamAt = function parseStreamAt(dataBuf, offset, datalen) {
  const streamObj = new SDTStreamContainer();
  let pos = offset;
  while (pos < offset + datalen) {
    const valueFieldHeader = ParseFieldHeader.parseFieldHeader(dataBuf, pos);
    const valueField = ParseSingleElementLib.ParseSingleElement.parseSingleElement(dataBuf, pos);
    pos += valueFieldHeader[1]; // declared field length
    if (valueField) {
      streamObj.addField(valueField);
    }
  }
  return SDTField.create(SDTFieldType.STREAM, streamObj);
};

module.exports.ParseStream = ParseStream;
