/**
 * @constructor BaseMessage
 * @private
 */
class BaseMessage {
  constructor(header = null, params = []) {
    this._smfHeader = header;
    this._parameters = params;
  }

  addParameter(param) {
    this._parameters[param.getType()] = param;
  }

  getParameter(paramType) {
    return this._parameters[paramType];
  }

  getParameterArray() {
    return this._parameters;
  }

  get smfHeader() {
    return this._smfHeader;
  }
  set smfHeader(val) {
    this._smfHeader = val;
  }

  getResponse() {
    const smf = this.smfHeader;
    if (!(smf && smf.pm_respcode && smf.pm_respstr)) {
      return null;
    }
    return {
      responseCode:   smf.pm_respcode,
      responseString: smf.pm_respstr,
    };
  }
}

module.exports.BaseMessage = BaseMessage;
