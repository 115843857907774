const {
  LOG_INFO,
  LOG_WARN,
} = require('solclient-log');
const { Process } = require('solclient-util');

/**
 * @classdesc
 * Base class for transports
 * @memberof solace
 * @private
 */
class TSHState {
  /**
   * @constructor
   * @param {Boolean} useSsl True if SSL should be used
   * @param {TransportProtocol} tpProtocol The protocol to use for this state
   * @param {Function} exitCallback The callback to notify on exit
   * @param {?TSHState} nextState The TSH state to use on downgrade
   */
  constructor(useSsl, tpProtocol, exitCallback, nextState) {
    this._ssl = !!useSsl;
    this._transportProtocol = tpProtocol;
    this._exitCallback = exitCallback;
    this._nextState = nextState;
    this._unsupportedRuntimeMessage =
      `${this._transportProtocol} not supported by this runtime: ${Process.userAgent}`;
  }

  getNextState() {
    return this._nextState;
  }

  setNextState(nextState) {
    this._nextState = nextState;
  }

  getTransportProtocol() {
    return this._transportProtocol;
  }

  getUseSsl() {
    return this._ssl;
  }

  // Override me
  validateLegal() { // eslint-disable-line class-methods-use-this
    return true;
  }

  onEnter() {
    if (!this.validateLegal()) {
      if (this._nextState && this._exitCallback) {
        this._exitCallback(this._nextState, this._unsupportedRuntimeMessage);
      } else {
        LOG_WARN(`${this._unsupportedRuntimeMessage}, no next state.`);
      }
    }
  }

  completeDowngrade(err) {
    if (this._nextState && this._exitCallback) {
      LOG_INFO(`Connect failed (${err}), try next state.`);
      this._exitCallback(this._nextState, 'Connect failed');
      return true;
    }
    LOG_WARN(`Connect failed (${err}), no next state.`);
    return false;
  }

  toString() {
    return this._transportProtocol + (this._ssl ? ' (SSL)' : '');
  }

}

module.exports.TSHState = TSHState;
