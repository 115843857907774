const { SolaceError } = require('solclient-error');

/**
 * @classdesc
 * An error thrown when an error occurs on the transport session.
 * <p>
 * Applications are not expected to instantiate this type.
 *
 * @memberof solace
 * @private
 */
class TransportError extends SolaceError {
  /**
   * Creates an instance of TransportError.
   * @constructor
   * @param {String} message The message associated with this error
   * @param {ErrorSubcode} subcode The subcode for this error
   * @private
   */
  constructor(message, subcode) {
    super('TransportError', message);
    this.subcode = subcode;
  }

  toString() {
    return `${super.toString()}, subcode=${this.subcode}`;
  }
}

module.exports.TransportError = TransportError;
