// Coverity scanned is configured to run against the generated monolithic
// solclient.js file (debug) version. On the loadbuild server BUILD_ENV.VERSION is
// substituted and never null (it is null in developer workspace) so
// coverity will complain that the " || '0.0.0-source' " is DEADCODE
// coverity[dead_error_line]
const VERSION = BUILD_ENV.VERSION || '0.0.0-source';
// Parse the date if provided. Was seeing NaN formatted dates in source mode, so add fallback
const DATE = BUILD_ENV.DATE
  ? new Date(Date.parse(BUILD_ENV.DATE))
  : new Date();
const MODE = BUILD_ENV.MODE_NAME;

// These property names are all for export, so make sure they're not mangled
const TARGET = {
  'name':    BUILD_ENV.TARGET_NAME,
  'node':    BUILD_ENV.TARGET_NODE,
  'browser': BUILD_ENV.TARGET_BROWSER,
};

const FORMATTED_DATE = (() => {
  const pad = n => (n < 10 ? `0${n}` : n);
  const d = DATE;
  const YYYY = d.getFullYear();
  const MM = pad(d.getMonth() + 1);
  const DD = pad(d.getDate());
  const hh = pad(d.getHours());
  const mm = pad(d.getMinutes());
  return `${YYYY}/${MM}/${DD} ${hh}:${mm}`;
})();
const SUMMARY = ['SolclientJS', BUILD_ENV.VERSION, MODE, FORMATTED_DATE].join(', ');

/**
 * Returns the API version. Use version, date and mode properties for build details.
 * Use the summary property or the .toString() method to return a summary.
 * @static
 * @type {Object}
 * @namespace
 * @memberof solace
 */
const Version = {
  /**
   * The API version, as an informational string. The format of this string is subject to change.
   * @type {String}
   */
  'version':       VERSION,
  /**
   * The API build date.
   * @type {Date}
   */
  'date':          DATE,
  /**
   * The API build date, as a formatted string.
   * The format of this date is:
   * `YYYY-MM-DD hh:mm`
   * where
   *  * `YYYY` is the 4-digit year,
   *  * `MM` is the 2-digit month   (01-12),
   *  * `DD` is the 2-digit day     (01-31),
   *  * `hh` is the 2-digit hour    (00-23),
   *  * `mm` is the 2-digit minute  (00-59)
   *
   * @type {String}
   */
  'formattedDate': FORMATTED_DATE,
  /**
   * Information about the build target. This object is informational; its type, structure and
   * content are subject to change.
   * @type {Object}
   */
  'target':        TARGET,
  /**
   * The build mode. This may be one of 'debug' or 'release'. Other build modes may be added
   * in the future.
   * @type {String}
   */
  'mode':          MODE,
  /**
   * If `true`, this is an unoptimized debug build.
   * @type {Boolean}
   */
  'debug':         BUILD_ENV.MODE_DEBUG,
  /**
   * If `true`, this is an optimized release build. Note that there may be multiple release
   * builds in a distribution, with varying degrees of optimization.
   * @type {Boolean}
   */
  'release':       BUILD_ENV.MODE_RELEASE,
  /**
   * An informational string summarizing the API name, version and build date.
   * @type {String}
   */
  'summary':       SUMMARY,

  toString() {
    return SUMMARY;
  },
};
module.exports.Version = Version;
