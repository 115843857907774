const { APIPropertiesValidators } = require('solclient-util');
const { MessagePublisherAcknowledgeMode } = require('./message-publisher-acknowledge-modes');

const {
  validateInstance,
  valBoolean,
  valIsMember,
  valNumber,
  valRange,
} = APIPropertiesValidators;


const MessagePublisherPropertiesValidator = {
  validate(instance) {
    const v = validateInstance.bind(null, 'MessagePublisherProperties', instance);
    v('enabled', [valBoolean]);
    v('windowSize', [valNumber], [valRange, 1, 255]);
    v('acknowledgeTimeoutInMsecs', [valNumber], [valRange, 20, 60000]);
    v('acknowledgeMode', [valIsMember, MessagePublisherAcknowledgeMode, 'MessagePublisherAcknowledgeMode']);
    v('connectRetryCount', [valNumber], [valRange, 0, Number.MAX_VALUE]);
    v('connectTimeoutInMsecs', [valNumber], [valRange, 50, Number.MAX_VALUE]);
  },
};

module.exports.MessagePublisherPropertiesValidator = MessagePublisherPropertiesValidator;
