const { FsmEvent } = require('solclient-fsm');

/**
 *
 * PublisherFSMEvent extends {@link FsmEvent}.  This object should be used for all events
 * passed to the SessionFSM.  This extension provides members to carry information specfic to
 * the SessionFSM transitions.
 * @extends {FsmEvent}
 * @private
 */
class PublisherFSMEvent extends FsmEvent {
  /**
   * @constructor
   * @param {Object} spec The specification for this event
   * @param {?Object} details The details to assign to this event object
   * @param {Message|BaseMessage} smfmessage The message associated with this event
   */
  constructor(spec, details, smfmessage) {
    super(spec);
    Object.assign(this, details);
    Object.assign(this, smfmessage);
  }
  getEventText() {
    return this._eventText;
  }
}

module.exports.PublisherFSMEvent = PublisherFSMEvent;
