const { Convert } = require('solclient-convert');
const { LOG_DEBUG } = require('solclient-log');
const { ParseDestination } = require('./parse-destination');
const { ParseFieldHeader } = require('./parse-field-header');
const { ParseFloat } = require('./parse-float');
const { ParseInteger } = require('./parse-integer');
const { ParseMap } = require('./parse-map');
const { ParseStream } = require('./parse-stream');
const { SDTDataTypes } = require('../sdt-data-types');
const { SDTField } = require('../sdt-field');
const { SDTFieldType } = require('../sdt-field-types');

const {
  utf8ToUcs2,
} = Convert;
const { parseFieldHeader } = ParseFieldHeader;
const { parseFloatField } = ParseFloat;
const { parseIntegerField } = ParseInteger;
const { parseMapAt } = ParseMap;
const { parseStreamAt } = ParseStream;
const { parseDestination } = ParseDestination;

const ParseSingleElement = {
  // Parse single SDT element, returns SDTField
  parseSingleElement(dataBuf, offset) {
    const fieldHeader = parseFieldHeader(dataBuf, offset);
    if (!fieldHeader) {
      LOG_DEBUG(`parseSingleElement return false, fieldHeader=${fieldHeader}`);
      return null;
    }

    const pos = offset + fieldHeader[3];

    // For use inside switch
    const elemValLen = fieldHeader[2];

    switch (fieldHeader[0]) {
      case SDTDataTypes.Null:
        return SDTField.create(SDTFieldType.NULLTYPE, null);
      case SDTDataTypes.Boolean:
        return SDTField.create(SDTFieldType.BOOL, dataBuf.readUInt8(pos) !== 0);
      case SDTDataTypes.Integer:
        return parseIntegerField(true, dataBuf, pos, elemValLen);
      case SDTDataTypes.UnsignedInteger:
        return parseIntegerField(false, dataBuf, pos, elemValLen);
      case SDTDataTypes.Float:
        return parseFloatField(dataBuf, pos, elemValLen);
      case SDTDataTypes.Char:
        return SDTField.create(SDTFieldType.WCHAR,
                               String.fromCharCode(dataBuf.readUInt16BE(pos)));
      case SDTDataTypes.ByteArray:
        return SDTField.create(SDTFieldType.BYTEARRAY,
                               dataBuf.slice(pos, pos + elemValLen));
      case SDTDataTypes.String:
        // strip last byte (null-terminator)
        return SDTField.create(SDTFieldType.STRING,
                               utf8ToUcs2(dataBuf.toString('latin1', pos, pos + elemValLen - 1)));
      case SDTDataTypes.Destination:
        return parseDestination(dataBuf, pos, elemValLen);
      case SDTDataTypes.SMFMessage:
        return SDTField.create(SDTFieldType.SMF_MESSAGE, dataBuf.slice(pos, pos + elemValLen));
      case SDTDataTypes.Map:
        return parseMapAt(dataBuf, pos, elemValLen);
      case SDTDataTypes.Stream:
        return parseStreamAt(dataBuf, pos, elemValLen);
      default:
        // removed toString conversion as a test
        return SDTField.create(SDTFieldType.UNKNOWN,
                               dataBuf.toString('latin1', pos, pos + elemValLen));
    }
  },
};

const StringToBuffer = {
  stringToBuffer(str) {
    const BufferImpl = require('buffer').Buffer;
    return BufferImpl.from(str, 'latin1');
  },
};

module.exports.ParseSingleElement = ParseSingleElement;
module.exports.StringToBuffer = StringToBuffer;
