/* eslint-disable global-require */
// Do late binding for these debug utilities to break cyclic dependencies.

const PRINTABLE_LUT = (() => {
  const tmp = [];
  for (let c = 0; c < 256; ++c) {
    tmp[c] = (c < 33 || c > 126) ? '.' : String.fromCharCode(c);
  }
  return tmp;
})();

const SPACER = '   ';
const UNPRINTABLE = '.';


function formatDumpBytes(data, showDecode, leftPadding) {
  const { StringBuffer, StringUtils } = require('solclient-util');
  const { isEmpty, padLeft, padRight } = StringUtils;

  if (isEmpty(data)) {
    return null;
  }

  const output = new StringBuffer();
  const ascii = new StringBuffer();
  const line = new StringBuffer();
  let lineBytes = 0;
  const asciiOffset = 54;

  for (let i = 0, dataLen = data.length; i < dataLen; ++i) {
    const ccode = data.charCodeAt(i);
    //const ccode = dataBuf.readInt8(i);
    line.append(padLeft(ccode.toString(16), 2, '0'), ' ');
    ascii.append(PRINTABLE_LUT[ccode] || UNPRINTABLE);
    lineBytes++;

    if (lineBytes === 8) {
      line.append(SPACER);
    }

    if (lineBytes === 16 || i === data.length - 1) {
      if (leftPadding > 0) {
        output.append(padRight('', leftPadding, ' '));
      }

      output.append(padRight(line.toString(), asciiOffset, ' '));

      if (showDecode) {
        output.append(ascii);
      }

      output.append('\n');
      line.clear();
      ascii.clear();
      lineBytes = 0;
    }
  }

  return output.toString();
}

function parseSMFStream(data) {
  const { Codec: { Decode: { decodeCompoundMessage } } } = require('solclient-smf');
  const { LOG_WARN, LOG_ERROR } = require('solclient-log');

  if (data === null) {
    LOG_ERROR('data null in debugParseSmfStream');
    return;
  }
  let pos = 0;
  LOG_WARN(`parseSMFStream(): Starting parse, length ${data.length}`);
  while (pos < data.length) {
    const incomingMsg = decodeCompoundMessage(data, pos);
    const smf = incomingMsg ? incomingMsg.smfHeader : null;
    if (!(incomingMsg && smf)) {
      // couldn't decode! Lost SMF framing.
      LOG_WARN("parseSMFStream(): couldn't decode message.");
      LOG_WARN(`Position: ${pos} length: ${data.length}`);
      return;
    }

    LOG_WARN(`>> Pos(${pos}) Protocol ${smf.smf_protocol}, Length: ${smf.messageLength}`);
    pos += smf.messageLength;
  }
}

const Debug = {
  formatDumpBytes,
  parseSMFStream,
};

module.exports.Debug = Debug;
