const { APIProperties } = require('solclient-util');
const { Check } = require('solclient-validate');

function getDefaultLogLevel() {
  // break dependency loop
  // eslint-disable-next-line global-require
  const { LogLevel } = require('solclient-log');
  return LogLevel.INFO;
}

/**
 * @classdesc
 * Properties used during initialization of {@link solace.SolclientFactory}.
 *
 * @memberof solace
 */
class SolclientFactoryProperties extends APIProperties {
  /**
   * Creates an instance of SolclientFactoryProperties.
   *
   * @param {Object} [options] The property names and values to apply to this instance
   * @param {solace.LogLevel} [options.logLevel]  logLevel (default={@link solace.LogLevel.INFO}
   * @param {solace.LogImpl} [options.logger]     log implementation (default=NULL)
   * @param {solace.SolclientFactoryProfiles} [options.profile] Javascript profile
   *  (default={@link solace.SolclientFactoryProfiles.version7})
   * @memberOf SolclientFactoryProperties
   */
  constructor(options) {
    super({
      logLevel: getDefaultLogLevel(),
      logger:   null,
    }, options);
  }

  /**
   * The factory profile to use. The following factory profiles are available:
   * * {@link solace.SolclientFactoryProfiles.version7}, a backwards-compatible profile
   *      for existing solClientJS 7.x applications
   * * {@link solace.SolclientFactoryProfiles.version10}, the recommended profile
   *      for new applications
   *
   * @type {solace.SolclientFactoryProfiles}
   */
  get profile() {
    return this._profile;
  }
  set profile(profile) {
    this._profile = profile;
  }

  /**
   * The logging level to use for filtering log events. Messages with a level of lesser importance
   * than this will be filtered out and not logged.
   * @type {solace.LogLevel}
   */
  get logLevel() {
    return Check.number(this._logLevel) ? this._logLevel : getDefaultLogLevel();
  }
  set logLevel(val) {
    this._logLevel = val;
  }

  /**
   * The logging implementation to use. In the debug API, the log implementation will be called
   * for every log statement not filtered out by the log level. If no implementation is supplied,
   * the default implementation will be used, which logs to the global console object.
   * @type {solace.LogImpl}
   */
  get logger() {
    return this._logger || null;
  }
  set logger(val) {
    this._logger = val;
  }

  [util_inspect_custom]() {
    // break dependency loop
    // eslint-disable-next-line global-require
    const { LogLevel } = require('solclient-log');
    return {
      'logLevel': LogLevel.describe(this._logLevel),
      'profile':  this._profile,
    };
  }

  toString() {
    return util_inspect(this);
  }
}

module.exports.SolclientFactoryProperties = SolclientFactoryProperties;
