const SMFLib = require('solclient-smf');
const { Flow, PrivateFlowEventName } = require('solclient-flow');
const { LogFormatter } = require('solclient-log');
const { MessagePublisherEventName } = require('./message-publisher-event-names');
const { MessagePublisherProperties } = require('./message-publisher-properties');
const { PublisherFSM } = require('./publisher-fsm');
const { PublisherFSMEvent } = require('./publisher-fsm-event');
const { PublisherFSMEventNames } = require('./publisher-fsm-event-names');

const { LOG_WARN } = new LogFormatter();

class MessagePublisher extends Flow {
  /**
   * Represents a Guaranteed Message Publisher.  This object must be used to publish
   * Guaranteed Messages on a session.
   *
   * Publishers are created on sessions.  In this implementation the session functions are provided
   * by the sessionFSM.  Publishers require the session to provide
   * basic communications functions:
   *  * send - send a message to the transport
   *  * getCorrelationTag - return a unique transport correlation tag
   *  * enqueueOutstandingCorrelatedReq - enqueue a callback for a received message
   *    on a correlation tag
   *
   * @constructor
   * @param {Object} properties Expected schema is
   *  {@link solace.MessagePublisherProperties}
   * @param {Object} sessionInterfaceFactory The session for the Guaranteed Message Publisher
   * @private
   */
  constructor({ properties, sessionInterfaceFactory } = {}) {
    const applyProperties = new MessagePublisherProperties(properties);
    super(applyProperties, sessionInterfaceFactory, {
      direct: MessagePublisherEventName.ACKNOWLEDGED_MESSAGE,
      emits:  MessagePublisherEventName.values, // super adds MessageConsumerEventName
    });
    this._fsm = this._makeFSM();
    const logger = new LogFormatter();
    logger.formatter = function formatter(...args) {
      return [
        '[message-publisher]',
        ...args,
      ];
    };
    this.log = logger.wrap(this.log, this);

    // Doesn't emit anything unless started; won't start unless bindWaiting
    this._bindWaiting = true;

    this.on(PrivateFlowEventName.BIND_WAITING, this._onBindWaiting.bind(this));
    this.on(MessagePublisherEventName.CONNECT_FAILED_ERROR, this._onBindFailed.bind(this));
    this.on(MessagePublisherEventName.DOWN, this._onDown.bind(this));
    this.on(MessagePublisherEventName.UP, this._onUp.bind(this));
  }

  _onBindFailed() {
    this._bindWaiting = false;
  }

  _onBindWaiting() {
    this._bindWaiting = true;
  }

  _onDown() {
    this._bindWaiting = false;
  }

  _onUp() {
    this._bindWaiting = false;
  }

  /**
   * @override
   * @private
   */
  _makeFSM() {
    return new PublisherFSM({
      publisher:        this,
      name:             'PublisherFSM',
      sessionInterface: this._sessionInterface,
      properties:       this._properties,
    });
  }

  /**
   * @returns {Long} The flow ID of this flow
   */
  get flowId() {
    return this._flowId;
  }
  /**
   * @param {Long} value The flow ID for this flow
   * @private
   */
  set flowId(value) {
    this._flowId = value;
  }

  /**
   * @returns {String} The publisher name set for this flow
   */
  get name() {
    return this._flowName;
  }
  /**
   * @param {String} value The name to set on this flow
   * @private
   */
  set name(value) {
    this._flowName = value;
  }

  /**
   * @returns {Number} The publisher ID set on this flow
   */
  get publisherId() {
    return this._publisherId;
  }
  /**
   * @param {Number} value The publisher ID to set on this flow
   */
  set publisherId(value) {
    this._publisherId = value;
  }

  /**
   * @readonly
   * @returns {solace.MessagePublisherProperties} A clone of the publisher's properties.
   */
  get properties() {
    return this._properties.clone();
  }

  /**
   *
   * @override
   * @memberof MessagePublisher
   */
  connect() {
    super.connect();
    if (!this._fsm.getCurrentState()) {
      this._fsm.start();
    }
  }

  /**
   * Application has disconnected the session, so
   * disconnects the Guaranteed Message Publisher.
   * @private
   */
  _disconnectSession() {
    super._disconnectSession();
    this.processFSMEvent(new PublisherFSMEvent({ name: PublisherFSMEventNames.FLOW_CLOSE }));
  }

  /**
   * @returns {solace.MessagePublisherEventName} The name of the disposed event for this flow
   * @private
   */
  getDisposedEvent() { // eslint-disable-line class-methods-use-this
    return MessagePublisherEventName.DISPOSED;
  }

  /**
   * Handles an incoming ACK for the given message ID.
   * @param {Long} id The message id for acknowledgement.
   * @private
   */
  handleAck(id) {
    this.processFSMEvent(new PublisherFSMEvent(
      { name: PublisherFSMEventNames.ACK },
      { ack: id }
    ));
  }

  /**
   * Handles an incoming NACK for the given message ID.
   * @param {Long} id The message id for acknowledgement.
   * @param {AdCtrlMessage} ctrlMessage The NACK message
   * @private
   */
  handleNack(id, ctrlMessage) {
    this.processFSMEvent(new PublisherFSMEvent(
      { name: PublisherFSMEventNames.ACK },
      { nack: id, ctrlMessage }
    ));
  }

  /**
   * @override
   */
  handleUncorrelatedControlMessage(message) {
    const msgType = message.msgType;
    const { SMFAdProtocolMessageType } = SMFLib;
    switch (msgType) {
      case SMFAdProtocolMessageType.CLIENTACK:
        {
          const id = message.getLastMsgIdAcked();
          if (message.smfHeader.pm_respcode > 299) {
            this.handleNack(id, message);
          } else {
            this.handleAck(id);
          }
          break;
        }
      case SMFAdProtocolMessageType.CLIENTNACK:
        {
          const id = message.getLastMsgIdAcked();
          this.handleNack(id, message);
          break;
        }
      case SMFAdProtocolMessageType.CLOSEPUBFLOW:
        this.processFSMEvent(new PublisherFSMEvent(
          { name: PublisherFSMEventNames.FLOW_UNBOUND })
        );
        break;
      default:
        LOG_WARN(`Dropping unhandled AD control message for ${this}`,
                 SMFAdProtocolMessageType.describe(msgType));
    }
  }

  /**
   * Prepares an AD message for publishing on this flow.
   * @param {Message} dataMsg The message to be prepared for publishing.
   * @returns {TransportReturnCode} transport level returnCode
   * @private
   */
  prepareAdMessageAndSend(dataMsg) {
    return this._fsm.prepareAdMessageAndSend(dataMsg);
  }

  isBindWaiting() {
    return this._bindWaiting;
  }

  /**
   * @returns {String} Formatted inspector output
   * @private
   */
  [util_inspect_custom]() {
    return Object.assign(super[util_inspect_custom](), {
      'name':        this.name,
      'publisherId': this.publisherId,
    });
  }

  /**
   * @override
   */
  toString() {
    return util_inspect(this);
  }

  /**
   * Disposes the FSM associated with this flow.
   *
   * @private
   */
  _disposeFSM() {
    this.processFSMEvent(new PublisherFSMEvent({ name: PublisherFSMEventNames.DISPOSE }));
  }

  _isDisconnected() {
    return this._fsm.isDisconnected();
  }

}

module.exports.MessagePublisher = MessagePublisher;
