const { Enum } = require('solclient-eskit');

/**
 * Type maching SMF encoding value
 * @enum {number}
 * @memberof solace
 * @private
 */
const ContentSummaryType = {
  XML_META:          0,
  XML_PAYLOAD:       1,
  BINARY_ATTACHMENT: 2,
  CID_LIST:          3,
  BINARY_METADATA:   4,
};

module.exports.ContentSummaryType = Enum.new(ContentSummaryType);
