const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SessionEventName = {
  CONNECT:                       'SessionConnect',
  DISCONNECT:                    'SessionDisconnect',
  DISPOSE:                       'SessionDispose',
  CONNECT_TIMEOUT:               'SessionConnectTimeout',
  CONNECT_WAIT_TIMEOUT:          'SessionConnectWaitTimeout',
  DOWNGRADE_TIMEOUT:             'SessionDowngradeTimeout',
  TRANSPORT_UP:                  'SessionTransportUp',
  TRANSPORT_DESTROYED:           'SessionTransportDestroyed',
  TRANSPORT_CAN_ACCEPT_DATA:     'SessionTransportCanAcceptData',
  TRANSPORT_PARSE_ERROR:         'SessionTransportParseError',
  TRANSPORT_PROTOCOL_SMP:        'SessionSMPMessage',
  TRANSPORT_PROTOCOL_CLIENTCTRL: 'SessionClientCtrlMessage',
  EXCEPTION:                     'SessionException',
  SUBSCRIBE_TIMEOUT:             'SessionSubscribeTimeout',
  CREATE_SUBSCRIBER:             'SessionCreateSubscriber',
  FLOW_UP:                       'SessionFlowUp',
  FLOW_FAILED:                   'SessionFlowFailed',
  SEND_ERROR:                    'SessionSendError',
  FLOWS_DISCONNECTED:            'SessionFlowsDisconnected',
  TRANSPORT_FLUSHED:             'SessionTransportFlushed',
  DNS_RESOLUTION_COMPLETE:       'SessionDNSResolutionComplete',
  TRANSPORT_CHANGE_DONE:         'SessionTransportChangeDone',
};

module.exports.SessionEventName = Enum.new(SessionEventName);
