const SDTFieldLib = require('./sdt-field');
const { ErrorSubcode, OperationError } = require('solclient-error');

/**
 * @classdesc
 * Defines a Structured Data Type (SDT) stream container. A stream is an iterable collection of
 * {@link solace.SDTField}s.
 * @memberof solace
 */
class SDTStreamContainer {
  /**
   * @constructor
   */
  constructor() {
    this._stream = [];
    this._writable = true;
    this._readPt = 0;
  }

  /**
   * Returns true if the stream has at least one more {@link solace.SDTField}
   * at the current position.
   * @returns {Boolean} true, if there is an available field at the read pointer; false, otherwise.
   */
  hasNext() {
    return (this._stream.length > this._readPt);
  }

  /**
   * Returns the next field in the stream and advances the read pointer.
   * If the end of the stream is reached, it returns undefined.
   * @returns {solace.SDTField} The next field in the stream.
   */
  getNext() {
    return (this._readPt < this._stream.length) ? this._stream[this._readPt++] : undefined;
  }

  /**
   * Rewinds the read pointer to the beginning of the stream. Normally when {@link hasNext}
   * returns false, a client application must call rewind() to reiterate over the stream's fields.
   * @throws {@link solace.OperationError} if the stream cannot be rewound.
   */
  rewind() {
    this._readPt = 0;
  }

  /**
   * Appends a SDTField to the stream.
   * <p>
   * If <code>field</code> is a {@link solace.SDTField}, this field is appended to the stream.
   * <br>
   * If <code>field</code> is a {@link solace.SDTFieldType},
   * then the API will create a SDTField of this
   * type with a value of <code>optValue</code> and append this new SDTField to
   * the stream.
   *
   * @param {solace.SDTField|solace.SDTFieldType} field The field to append to the stream.
   * @param {*} [optValue] The value to wrap as an SDTField.
   */
  /**
   * Appends a field to this stream.
   * <p>
   * @deprecated If <code>typeOrField</code> is a {@link solace.SDTField} instance,
   * it is appended to the stream.
   *
   * The preferred usage is to pass a {@link solace.SDTFieldType}, then the API will create a
   * SDTField of this type using <code>value</code> and append this new SDTField
   * to the stream.
   *
   * @param {solace.SDTField|solace.SDTFieldType} typeOrField A SDTField instance or SDTFieldType.
   * @param {*} [value] The value to wrap as an SDTField.
   * @throws {@link solace.OperationError} if value does not match type
   * @throws {@link solace.SDTUnsupportedValueError} if value is not in range
   *  supported by the platform/runtime
   */
  addField(typeOrField, value = undefined) {
    if (!this._writable) return;

    if (typeOrField instanceof SDTFieldLib.SDTField) {
      this._stream.push(typeOrField);
      return;
    }

    if (typeof value !== 'undefined') {
      this._stream.push(SDTFieldLib.SDTField.create(typeOrField, value));
      return;
    }

    throw new OperationError('Invalid parameters to addField: expected SDTField, or type and value',
      ErrorSubcode.PARAMETER_CONFLICT);
  }
}

module.exports.SDTStreamContainer = SDTStreamContainer;
