const DEFAULT_PROPERTY_OPTIONS = {
  enumerable: true,
};

function setPropertyValue(target, key, value, options = null) {
  Object.defineProperty(
          target,
          key,
          Object.assign({ value }, DEFAULT_PROPERTY_OPTIONS, options));
}


function lazyProperty(target, key, evalFn) {
  Object.defineProperty(
    target,
    key,
    Object.assign({
      configurable: true, // Allow replacing this property with resolved value.
      get:          () => {
        // The getter evaluates the function provided.
        // It replaces itself with the value it resolves.
        const result = evalFn(target, key);
        setPropertyValue(target, key, result);
        return result;
      },
      set: (value) => {
        setPropertyValue(target, key, value);
      },
    }, DEFAULT_PROPERTY_OPTIONS));
  return target;
}

function lazyProperties(target, obj) {
  Object.keys(obj).forEach((k) => {
    lazyProperty(target, k, obj[k]);
  });
  return obj;
}

function lazyValue(evalFn) {
  return lazyProperty({}, 'value', evalFn);
}

const Lazy = {
  lazyProperties,
  lazyProperty,
  lazyValue,
};

module.exports.Lazy = Lazy;
