const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SMFTransportSessionMessageType = {
  CREATE:            0,
  CREATE_RESP:       1,
  DESTROY:           2,
  DESTROY_RESP:      3,
  DATA:              4,
  DATA_TOKEN:        5,
  DATA_STREAM_TOKEN: 6,
};

module.exports.SMFTransportSessionMessageType = Enum.new(SMFTransportSessionMessageType);
