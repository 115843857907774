const EPSILON = 1e6;

/**
 * @param {Array} arr The array to flatten
 * @returns {Array} A maximally flattened array. The original array is not modified.
 * @private
 */
function flatten(arr) {
  return arr.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);
}

/**
 * @private
 */
const BaseChecks = (() => {
  const result = {
    /**
     * Checks whether a value is undefined or null.
     *
     * @param   {*} value The value to check.
     *
     * @returns {Boolean} True if the value is undefined or null.
     *
     * @private
     */
    nothing(value) {
      return (value === undefined || value === null);
    },

    /**
     * Checks whether a value is anything, i.e. not undefined or null.
     *
     * @param   {*} value The value to check.
     *
     * @returns {Boolean} True unless the value is undefined or null.
     *
     * @private
     */
    anything(value) {
      return !BaseChecks.nothing(value);
    },

    /**
     * Checks whether a value is undefined. Uses the conservative
     * typeof check for compatibility with particularly ill-behaved
     * libraries that redefine <code>undefined</code>.
     *
     * @param   {*} value The value to check.
     *
     * @returns {Boolean} True if the value is undefined.
     *
     * @private
     */
    undefined(value) {
      return typeof value === 'undefined';
    },

    /**
     * Checks whether a value is defined. This is true for any value with
     * a type that is not 'undefined'.
     *
     * @param {any} value The value to check
     * @returns {Boolean} True if the value is defined.
     *
     * @private
     */
    defined(value) {
      return !BaseChecks.undefined(value);
    },

    /**
     * Checks whether a value is an array.
     *
     * @param   {*} value The value to check.
     *
     * @returns {Boolean} True if the value is an array.
     *
     * @private
     */
    array(value) {
      return BaseChecks.anything(value) && Array.isArray(value);
    },

    /**
     * Checks whether a value is an object. If a value is an array, it is not an object.
     * If a value is null, it is not an object.
     *
     * @param   {*} value The value to check.
     *
     * @returns {Boolean} True if the value is an object (and not an array).
     *
     * @private
     */
    object(value) {
      return (!BaseChecks.array(value) &&
              value !== null &&
              (typeof value === 'object' || value instanceof Object));
    },

    /**
     * Checks whether a value is an instance of the given type.
     *
     * @param   {*} val The value to check.
     * @param   {Object} type The type to check.
     *
     * @returns {Boolean} True if type is in value's prototype chain.
     *
     * @private
     */
    instanceOf(val, type) {
      return BaseChecks.object(val) && val instanceof type;
    },

    /**
     * Checks whether a value's typeof is equal to the given type string.
     *
     * @param {*} val The value to check.
     * @param {String} type The type string for comparison.
     *
     * @returns {Boolean} True if typeof(val) == type
     *
     * @private
     */
    type(val, type) {
      return typeof val === type; /* dynamic */ // eslint-disable-line valid-typeof
    },

    /**
     * Checks whether a value is an instance of any of the given types.
     *
     * @param   {*} val The value to check.
     * @param   {Object} types The types to check.
     *
     * @returns {Boolean} True if any element of types is in val's prototype chain.
     *
     * @private
     */
    instanceOfAny(val, ...types) {
      return (BaseChecks.array(types) &&
              flatten(types).some(type => BaseChecks.instanceOf(val, type)));
    },

    /**
     * Checks whether the given value is empty.
     *
     * Emptiness is determined using the following checks:
     *
     * <ol>
     *  <li>If an object is nothing, it is empty.</li>
     *  <li>If it is an object, and it has no keys of its own, it is empty.</li>
     *  <li>If it has a length property, it is empty if length === 0.</li>
     *  <li>If none of the above apply, the object is not empty.</li>
     * </ol>
     *
     * @param   {*} val The value to check.
     *
     * @returns {Boolean} True if the value is empty.
     *
     * @private
     */
    empty(val) {
      if (BaseChecks.nothing(val)) return true;
      if (BaseChecks.object(val)) {
        if (Object.keys(val).length === 0) {
          return true;
        }
      }
      if (val.length === 0) {
        return true;
      }
      return false;
    },

    /**
     * A user-defined check.
     *
     * @param   {*} val The value to check
     * @param   {Function} fn The function(val) to use.
     *
     * @returns {Boolean} The result of the function, coerced to a boolean.
     *
     * @private
     */
    truthy(val, fn) {
      return !!fn(val);
    },

    rangeGe(val, rangeMinInclusive) {
      return val >= rangeMinInclusive;
    },

    rangeGt(val, rangeMin) {
      return val > rangeMin;
    },

    rangeLe(val, rangeMaxInclusive) {
      return val <= rangeMaxInclusive;
    },

    rangeLt(val, rangeMax) {
      return val < rangeMax;
    },

    rangeCompare(val, operator, operand, ...args) {
      switch (operator) {
        case '=':
        case '==':
        case '===':
          return val === operand;
        case '~=':
        case '=~':
          {
            const epsilon = args[0] || EPSILON;
            return Math.abs(val - operand) < epsilon;
          }
        case '<':
          return val < operand;
        case '<=':
          return val <= operand;
        case '>':
          return val > operand;
        case '>=':
          return val >= operand;
        default:
          throw new Error(`Illegal operator for rangeCompare: ${operator}`);
      }
    },

    NaN(val) {
      return Number.isNaN(val);
    },

    /**
     * Checks whether val is included in the object. If obj is an array, val is included if
     * val is an element of the array. Otherwise, if obj is an object, val is included if
     * val is one of the keys of obj.
     *
     * @param   {*} val The value to test
     * @param   {*} obj The object to test
     *
     * @returns {Boolean} True if the value is included in the object
     */
    included(val, obj) {
      if (BaseChecks.nothing(obj)) {
        return false;
      }
      if (obj.includes) {
        return obj.includes(val);
      }
      if (Array.isArray(obj)) {
        // Not exactly Array.prototype.includes, which uses SameValueZero (+0 == -0)
        return obj.indexOf(val) >= 0;
      }
      if (BaseChecks.object(obj)) {
        const keys = Object.keys(obj);
        return BaseChecks.included(val, keys);
      }
      return false;
    },

    /**
     * Checks whether val is equal to expected.
     *
     * @param {any} val The value to check.
     * @param {any} expected The expected value.
     * @returns {Boolean} True if the value was equal to the expected value.
     */
    equal(val, expected) {
      return val === expected;
    },

    /**
     * Checks whether the given value appears as an attribute value in the given attributes object,
     * e.g. whether an enumeration contains the given value. If the attributes object is an array,
     * the array membership check {@link #included} is used instead.
     *
     * @param   {*} val The value to check.
     * @param   {Object} obj The object with attributes to check.
     *
     * @returns {Boolean} True if attrs has any attribute equal to val.
     */
    member(val, obj) {
      return (BaseChecks.anything(obj) &&
              (BaseChecks.array(obj)
                ? BaseChecks.included(val, obj)
                : Object.keys(obj).some(k => obj[k] === val))
      );
    },

    boolean(v) {
      return BaseChecks.type(v, 'boolean');
    },

    number(v) {
      return BaseChecks.type(v, 'number');
    },

    string(v) {
      return BaseChecks.type(v, 'string');
    },

    function(v) {
      return BaseChecks.type(v, 'function');
    },
  };

  /**
   * Checks whether an object is none. This is the same check as <code>nothing</code>
   * @see {@link nothing}
   */
  result.none = result.nothing;

  /**
   * Checks whether an object is something. This is the same check as <code>anything</code>
   */
  result.something = result.anything;

  // Add check-loosening suffixes, (t | null), (t | undefined) and (t | null | undefined).
  // Do the t check last, just in case it is not (null | undefined) safe.
  Object.keys(result).forEach((key) => {
    /**
     * .orNull checks. These are equivalent to the similarly named check, but also pass if the value
     * supplied was equal to null.
     *
     * @param {*} val The value to check.
     * @param {*} args The arguments to forward to the base validator.
     *
     * @returns {Boolean} True if the value was null or passed the similarly named validator.
     */
    result[key].orNull = function orNull(val, ...args) {
      return val === null || result[key](val, ...args);
    };

    /**
     * .orUndefined checks. These are equivalent to the similarly named check, but also pass if the
     * value supplied was equal to null.
     *
     * @param {*} val The value to check.
     * @param {*} args The arguments to forward to the base validator.
     *
     * @returns {Boolean} True if the value was null or passed the similarly named validator.
     */
    result[key].orUndefined = function orUndefined(val, ...args) {
      return val === undefined || result[key](val, ...args);
    };

    /**
     * .orNothing checks. These are equivalent to the similarly named check, but also pass if the
     * value supplied was null or undefined.
     *
     * @param {*} val The value to check.
     * @param {*} args The arguments to forward to the base validator.
     *
     * @returns {Boolean} True if the value was null or passed the similarly named validator.
     */
    result[key].orNothing = function orNothing(val, ...args) {
      return result.nothing(val) || result[key](val, ...args);
    };
  });

  return result;
})();

module.exports.BaseChecks = BaseChecks;
