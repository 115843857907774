const QueueLib = require('solclient-queue');
const { APIProperties } = require('solclient-util');
const { Check } = require('solclient-validate');

const DEFAULTS = {
  queueDescriptor:                         undefined,
  connectTimeoutInMsecs:                   10000,
  connectAttempts:                         3,
  windowSize:                              255,
  transportAcknowledgeTimeoutInMsecs:      1000,
  transportAcknowledgeThresholdPercentage: 60,
};

/**
 * @classdesc
 * Defines the properties for a {@link solace.QueueBrowser}.
 *
 * @memberof solace
 */
class QueueBrowserProperties extends APIProperties {
  constructor(options) {
    super(DEFAULTS, options);
  }

  /**
   * Defines the queue from which to consume.
   *  * For durable queues and durable topic endpoints, this must be a
   *    {@link solace.QueueDescriptor}.
   * @type {solace.QueueDescriptor}
   */
  get queueDescriptor() {
    return Check.something(this._queueDescriptor)
      ? this._queueDescriptor
      : DEFAULTS.queueDescriptor;
  }
  set queueDescriptor(value) {
    if (value) {
      this._queueDescriptor = new QueueLib.QueueDescriptor(value);
    } else {
      this._queueDescriptor = value;
    }
  }

  // ------------ Properties controlling connection of the consumer ----------------
  /**
   * The bind timeout in milliseconds when creating a connection to the Solace Message Router.
   *  * The valid range is >= 50.
   * @type {Number}
   * @default 10000
   */
  get connectTimeoutInMsecs() {
    return Check.something(this._bindTimeoutInMsecs)
      ? this._bindTimeoutInMsecs
      : DEFAULTS.connectTimeoutInMsecs;
  }
  set connectTimeoutInMsecs(value) {
    this._bindTimeoutInMsecs = value;
  }

  /**
   * Gets and sets the maximum number of bind attempts when creating a connection to the
   * Solace Message Router.
   *  * The valid range is >= 1.
   * @type {Number}
   * @default 3
   */
  get connectAttempts() {
    return Check.something(this._connectAttempts)
      ? this._connectAttempts
      : DEFAULTS.connectAttempts;
  }
  set connectAttempts(val) {
    this._connectAttempts = val;
  }

  // ----------- Properties controlling an established connection to a queue --------------
  /**
   * The window size for Guaranteed Message delivery.  This is the maximum number of messages that
   * will be prefetched from the Solace Messaging Router and queued internally by the API while
   * waiting for the application to accept delivery of the messages.
   *   * The valid range is 1 <= windowSize <= 255.
   * @type {Number}
   * @default 255
   */
  get windowSize() {
    return Check.something(this._windowSize)
      ? this._windowSize :
      DEFAULTS.windowSize;
  }
  set windowSize(val) {
    this._windowSize = val;
  }

  /**
   * The transport acknowledgement timeout for guaranteed messaging in milliseconds.
   * When the {@link solace.QueueBrowserProperties.transportAcknowledgeTimeoutInMsecs} is not
   * exceeded, acknowledgements will be returned to the router at intervals not less than
   * this value.
   *   * The valid range is 20 <= transportAcknowledgeTimeoutInMsecs <= 1500.
   * @type {Number}
   * @default 1000
   */
  get transportAcknowledgeTimeoutInMsecs() {
    return Check.something(this._transportAcknowledgeTimeoutInMsecs)
      ? this._transportAcknowledgeTimeoutInMsecs
      : DEFAULTS.transportAcknowledgeTimeoutInMsecs;
  }
  set transportAcknowledgeTimeoutInMsecs(val) {
    this._transportAcknowledgeTimeoutInMsecs = val;
  }

  /**
   * The threshold for sending an acknowledgement, as a percentage.
   * The API sends a transport acknowledgment every
   * N messages where N is calculated as this percentage of the transport
   * window size if the endpoint's max-delivered-unacked-msgs-per-flow
   * setting at bind time is greater than or equal to the transport
   * window size. Otherwise, N is calculated as this percentage of the
   * endpoint's max-delivered-unacked-msgs-per-flow setting at bind time.
   * * The valid range is 1 <= transportAcknowledgeThresholdPercentage <= 75.
   * @type {Number}
   * @default 60
   */
  get transportAcknowledgeThresholdPercentage() {
    return Check.something(this._transportAcknowledgeThresholdPercentage)
      ? this._transportAcknowledgeThresholdPercentage
      : DEFAULTS.transportAcknowledgeThresholdPercentage;
  }
  set transportAcknowledgeThresholdPercentage(value) {
    this._transportAcknowledgeThresholdPercentage = value;
  }

}

module.exports.QueueBrowserProperties = QueueBrowserProperties;
