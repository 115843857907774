const {
  AbstractQueueDescriptor,
  QueueDescriptor,
  QueueDescriptorValidator,
  QueueProperties,
  QueuePropertiesValidator,
  QueueType,
} = require('solclient-queue');
const { APIPropertiesValidators } = require('solclient-util');
const { Check } = require('solclient-validate');
const { MessageConsumerAcknowledgeMode } = require('./message-consumer-acknowledge-modes');
const { OperationError, ErrorSubcode } = require('solclient-error');
const { ReplayStartLocation } = require('solclient-replaystart');

const {
  validateInstance,
  valBoolean,
  valInstance,
  valIsMember,
  valNumber,
  valRange,
  valTopicStringOrEmpty,
} = APIPropertiesValidators;

function valTopicEndpointSubscription(typeDesc, instance) {
  if (instance.queueDescriptor.getType() === QueueType.TOPIC_ENDPOINT) {
    if (instance.queueDescriptor.isDurable() && !instance.createIfMissing) {
      if (!instance.topicEndpointSubscription) {
        throw new OperationError('topicEndpointSubscription must be set when queueDescriptor ' +
                                'refers to a durable topic endpoint and not allowed to create.',
                                ErrorSubcode.PARAMETER_CONFLICT);
      }
    }
  } else if (instance.topicEndpointSubscription) {
    // is QueueType.QUEUE
    throw new OperationError('topicEndpointSubscription is set, but queueDescriptor ' +
                              'refers to a queue that is not of type QueueType.TOPIC_ENDPOINT',
                              ErrorSubcode.PARAMETER_CONFLICT);
  }
}

const MessageConsumerPropertiesValidator = {
  validate(prefix, instance, rawProperties) {
    if (Object.prototype.hasOwnProperty.call(rawProperties, 'transportAcknowledgeTimeoutInMsecs') &&
        Object.prototype.hasOwnProperty.call(rawProperties, 'acknowledgeTimeoutInMsecs')) {
      throw new OperationError(`${prefix} validation: transportAcknowledgeTimeoutInMsecs and acknowledgeTimeoutInMsecs are mutually exclusive`,
                                ErrorSubcode.PARAMETER_CONFLICT);
    }

    if (Object.prototype.hasOwnProperty.call(rawProperties, 'transportAcknowledgeThresholdPercentage') &&
        Object.prototype.hasOwnProperty.call(rawProperties, 'acknowledgeThreshold')) {
      throw new OperationError(`${prefix} validation: transportAcknowledgeThresholdPercentage and acknowledgeThreshold are mutually exclusive`,
          ErrorSubcode.PARAMETER_CONFLICT);
    }


    const v = validateInstance.bind(null, prefix, instance);
    if (!(instance.queueDescriptor instanceof AbstractQueueDescriptor ||
          instance.queueDescriptor instanceof QueueDescriptor)) {
      throw new OperationError(`${prefix} validation: queue descriptor must be ` +
                                'an AbstractQueueDescriptor or a QueueDescriptor',
                                ErrorSubcode.PARAMETER_INVALID_TYPE);
    }
    QueueDescriptorValidator.validate(instance.queueDescriptor);

    if (instance.queueProperties) {
      if (instance.queueDescriptor.durable && !instance.createIfMissing) {
        throw new OperationError(`${prefix} validation: queueProperties cannot be set unless ` +
          'queueDescriptor refers to a temporary queue, or createIfMissing is set.',
          ErrorSubcode.PARAMETER_CONFLICT);
      }
      v('queueProperties', [valInstance, QueueProperties, 'QueueProperties']);
      QueuePropertiesValidator.validate(instance.queueProperties);
      if (!instance.queueDescriptor.durable &&
        Check.something(instance.queueProperties.accessType)) {
        throw new OperationError(`${prefix} validation: queueProperties cannot specify accessType ` +
                                 'in creation of a temporary queue',
                                 ErrorSubcode.PARAMETER_CONFLICT);
      }
    }

    // Validate TE subscription

    if (instance.queueDescriptor.type === QueueType.TOPIC_ENDPOINT) {
      // QueueType.TOPIC_ENDPOINT
      if (instance.queueDescriptor.durable &&
        !instance.createIfMissing &&
        !instance.topicEndpointSubscription) {
        throw new OperationError(`${prefix} validation: topicEndpointSubscription must be set for durable ` +
          'topic endpoints unless creation is allowed.',
          ErrorSubcode.PARAMETER_CONFLICT);
      }
    } else if (instance.topicEndpointSubscription) {
      throw new OperationError(`${prefix} validation: topicEndpointSubscription cannot be set unless ` +
        'descriptor.type is TOPIC_ENDPOINT',
        ErrorSubcode.PARAMETER_CONFLICT);
    }

    v('connectTimeoutInMsecs', [valNumber], [valRange, 50, Number.MAX_VALUE]);
    v('connectAttempts', [valNumber], [valRange, 1, Number.MAX_VALUE]);
    v('topicEndpointSubscription', [valTopicEndpointSubscription], [valTopicStringOrEmpty]);

    v('acknowledgeMode', [valIsMember, MessageConsumerAcknowledgeMode, 'MessageConsumerAcknowledgeMode']);
    v('transportAcknowledgeTimeoutInMsecs', [valNumber], [valRange, 20, 1500]);
    v('transportAcknowledgeThresholdPercentage', [valNumber], [valRange, 1, 75]);

    v('activeIndicationEnabled', [valBoolean]);
    v('noLocal', [valBoolean]);
    v('windowSize', [valNumber], [valRange, 1, 255]);
    v('reconnectIntervalInMsecs', [valNumber], [valRange, 50, Number.MAX_VALUE]);

    if (instance.activeIndicationEnabled &&
      instance.queueDescriptor.type !== QueueType.QUEUE) {
      throw new OperationError(
        `${prefix} validation: activeIndicationEnabled may only be true for ` +
        'QUEUE destinations',
        ErrorSubcode.PARAMETER_CONFLICT
      );
    }
    if (instance.replayStartLocation &&
      !(instance.replayStartLocation instanceof ReplayStartLocation)) {
      throw new OperationError(`${prefix} validation: replayStartLocation must be ` +
        'an instance of ReplayStartLocation',
        ErrorSubcode.PARAMETER_INVALID_TYPE);
    }
  },
};

module.exports.MessageConsumerPropertiesValidator = MessageConsumerPropertiesValidator;
