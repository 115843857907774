const { AuthenticationScheme } = require('./lib/authentication-schemes');
const { CapabilityType, ClientCapabilityType } = require('./lib/capability-types');
const { MessageRxCBInfo } = require('./lib/message-rx-cb-info');
const { MutableSessionProperty } = require('./lib/mutable-session-properties');
const { Session } = require('./lib/session');
const { SessionEvent } = require('./lib/session-event');
const { SessionEventCBInfo } = require('./lib/session-event-cb-info');
const { SessionEventCode } = require('./lib/session-event-codes');
const { SessionEventName } = require('./lib/session-event-names');
const { SessionProperties } = require('./lib/session-properties');
const { SessionState } = require('./lib/session-states');

const { SolclientFactory } = require('solclient-factory');
const { SslDowngrade } = require('./lib/ssl-downgrades');

/**
 * Creates a session instance.
 * @param {solace.SessionProperties} sessionProperties Properties to configure the session.
 * @param {solace.MessageRxCBInfo} [messageCallbackInfo] <b>Deprecated:</b>
 *    Callback method for messages.  Instead applications should receive message events via
 *    `session.on(solace.SessionEventCode.MESSAGE, (message) => { ... });`
 * @param {solace.SessionEventCBInfo} [eventCallbackInfo] <b>Deprecated:</b> Callback method for
 *    events on the <i>Session</i>. Instaead applications should receive session events via
 *    `session.on(solace.SessionEventCode.<code>, (event) => { ... });`
 * @returns {solace.Session} The newly-created session.
 * @throws {solace.OperationError} if the parameters have an invalid type or value.
 *                          Subcode: {@link ErrorSubcode.PARAMETER_INVALID_TYPE}.
 * @method
 * @name solace.SolclientFactory.createSession
 */
SolclientFactory.createSession = SolclientFactory.createFactory(
  (sessionProperties, messageCallbackInfo, eventCallbackInfo) =>
    new Session(sessionProperties, messageCallbackInfo, eventCallbackInfo));

module.exports.AuthenticationScheme = AuthenticationScheme;
module.exports.CapabilityType = CapabilityType;
module.exports.ClientCapabilityType = ClientCapabilityType;
module.exports.MessageRxCBInfo = MessageRxCBInfo;
module.exports.MutableSessionProperty = MutableSessionProperty;
module.exports.Session = Session;
module.exports.SessionEventCBInfo = SessionEventCBInfo;
module.exports.SessionEventCode = SessionEventCode;
module.exports.SessionEvent = SessionEvent;
module.exports.SessionEventName = SessionEventName;
module.exports.SessionProperties = SessionProperties;
module.exports.SessionState = SessionState;
module.exports.SslDowngrade = SslDowngrade;
