const { LOG_TRACE } = require('solclient-log');
const { parseURL } = require('solclient-util');

let hostListDNSFilter;
if (BUILD_ENV.TARGET_NODE) {
  /* eslint-env node */
  /* eslint-disable global-require */
  const dns = require('dns');
  /* eslint-enable global-require */

  // util.promisify is Node 8. This converts an (err, result) callback to a promise.
  // The purpose is to allow us to use Promise.all, which deals with waiting until
  // all promises are resolved, and returning the results in correctly ordered array.
  const dnsLookupPromise = (url, options) => new Promise((resolve) => {
    const host = parseURL(url).hostname;
    LOG_TRACE('looking up', url, '=>', host);
    dns.lookup(host, options, (error, result) => {
      LOG_TRACE('DNS result', error, result);
      return error === null
        ? resolve({ url, host, address: result, resolved: true })
        : resolve(
          { url,
            host,
            resolved: true,
            error:    {
              code:     error['code'], // eslint-disable-line dot-notation
              errno:    error['errno'], // eslint-disable-line dot-notation
              hostname: error['hostname'], // eslint-disable-line dot-notation
              syscall:  error['syscall'], // eslint-disable-line dot-notation
            },
          });
    });
  });

  /**
   * Resolution calls back with an array of the following:
   * `{ url: 'http://example.com/foo', host: 'example.com', address: '10.0.0.1', resolved: true }`
   * or, on error
   * `{ url: 'http://example.com/foo', host: 'example.com', resolved: true, error: {
   *    code: 'ENOTFOUND', errno: 'ENOTFOUND', syscall: 'getaddrinfo'
   *  } }`
   *
   * If resolution could not be performed, because of platform limitations, the callback receives
   * the following:
   * `{ url: 'http://example.com/foo', host: 'example.com', address: 'example.com', resolved: false }`
   *
   * Even if no DNS lookup is performed, the function can still fail if an invalid URL is provided.
   *
   * The function always calls back asynchronously.
   *
   * @param {Array.<String>} urls Array of URLs to resolve
   * @param {function} cb A function called back with results as described above
   * @private
   */
  hostListDNSFilter = function hostListDNSFilterNode(urls, cb) {
    const promises = urls.map(url => dnsLookupPromise(
      url,
      { 'verbatim': true }
    ));
    // Call back (null, [{host:, address:}, ...]) if all successful
    // Call back (err, undefined) fast if any error
    Promise.all(promises).then(resolved => cb(null, resolved)).catch(err => cb(err));
  };
} else {
  /* eslint-env browser */
  // Create an always-async callback that allows all hosts.
  //hostListDNSFilter = (urls, cb) => setImmediate(() => {
  hostListDNSFilter = (urls, cb) => setTimeout(() => {
    try {
      const result = urls.map((url) => {
        const host = parseURL(url).host;
        return { url, host, address: host, resolved: false };
      });
      return cb(null, result);
    } catch (e) {
      return cb(e);
    }
  }, 0);
}

module.exports.hostListDNSFilter = hostListDNSFilter;
