const DestinationLib = require('solclient-destination');
const { Convert } = require('solclient-convert');
const { EncodeHeader } = require('./encode-header');
const { EncodeInteger } = require('./encode-integer');
const { EncodeMap } = require('./encode-map');
const { EncodeStream } = require('./encode-stream');
const { IEEE754LIB } = require('./ieee754lib');
const { SDTDataTypes } = require('../sdt-data-types');
const { SDTDestType } = require('../sdt-destination-types');
const { SDTField } = require('../sdt-field');
const { SDTFieldType } = require('../sdt-field-types');
const { StringUtils } = require('solclient-util');

const { encodeHeader } = EncodeHeader;
const { int48ToStr } = EncodeInteger;
const { encodeMap } = EncodeMap;
const { encodeStream } = EncodeStream;

const { nullTerminate } = StringUtils;

const C_2_48 = Math.pow(2, 48);

// UCS-2 --> UTF-8 conversion
function strencode(data) {
  return unescape(encodeURIComponent(data));
}

// Encode an SDTField into provided buffer buf
function encodeSingleElementToBuf(sdtfield, buf) {
  if (!(sdtfield instanceof SDTField)) {
    return false;
  }
  // we write the header at the end, once we know the size
  const value = sdtfield.getValue();
  let fieldVal = null;
  let tag = 0; // SMF TAG
  switch (sdtfield.getType()) {
    case SDTFieldType.BOOL:
      tag = SDTDataTypes.Boolean;
      fieldVal = Convert.int8ToStr(value ? 1 : 0);
      break;
    case SDTFieldType.UINT8:
      tag = SDTDataTypes.UnsignedInteger;
      fieldVal = Convert.int8ToStr(value);
      break;
    case SDTFieldType.INT8:
      tag = SDTDataTypes.Integer;
      fieldVal = Convert.int8ToStr(value);
      break;
    case SDTFieldType.UINT16:
      tag = SDTDataTypes.UnsignedInteger;
      fieldVal = Convert.int16ToStr(value);
      break;
    case SDTFieldType.INT16:
      tag = SDTDataTypes.Integer;
      fieldVal = Convert.int16ToStr(value);
      break;
    case SDTFieldType.UINT32:
      tag = SDTDataTypes.UnsignedInteger;
      fieldVal = Convert.int32ToStr(value);
      break;
    case SDTFieldType.INT32:
      tag = SDTDataTypes.Integer;
      fieldVal = Convert.int32ToStr(value);
      break;
    case SDTFieldType.UINT64:
      tag = SDTDataTypes.UnsignedInteger;
      fieldVal = String.fromCharCode(0) + String.fromCharCode(0) + int48ToStr(value);
      break;
    case SDTFieldType.INT64:
      tag = SDTDataTypes.Integer;
      if (value >= 0) {
        fieldVal = String.fromCharCode(0) + String.fromCharCode(0) + int48ToStr(value);
      } else {
        fieldVal = (String.fromCharCode(0xFF) +
                    String.fromCharCode(0xFF) +
                    int48ToStr(C_2_48 + value));
      }
      break;
    case SDTFieldType.WCHAR:
      tag = SDTDataTypes.Char;
      fieldVal = Convert.int16ToStr(value.charCodeAt(0));
      break;
    case SDTFieldType.STRING:
      tag = SDTDataTypes.String;
      fieldVal = nullTerminate(strencode(value));
      break;
    case SDTFieldType.BYTEARRAY:
      tag = SDTDataTypes.ByteArray;
      fieldVal = value.toString('latin1');
      break;
    case SDTFieldType.FLOATTYPE:
      tag = SDTDataTypes.Float;
      fieldVal = IEEE754LIB.toIEEE754Single(value);
      break;
    case SDTFieldType.DOUBLETYPE:
      tag = SDTDataTypes.Float;
      fieldVal = IEEE754LIB.toIEEE754Double(value);
      break;
    case SDTFieldType.MAP:
      tag = SDTDataTypes.Map;
      fieldVal = encodeMap(value);
      break;
    case SDTFieldType.STREAM:
      tag = SDTDataTypes.Stream;
      fieldVal = encodeStream(value);
      break;
    case SDTFieldType.DESTINATION:
      tag = SDTDataTypes.Destination;
      if (value instanceof DestinationLib.Destination) {
        fieldVal = Convert.int8ToStr(SDTDestType[value.getType()])
          + value.getBytes();
      }
      break;
    case SDTFieldType.NULLTYPE:
      tag = SDTDataTypes.Null;
      fieldVal = '';
      break;
    case SDTFieldType.UNKNOWN:
      fieldVal = null;
      break;
    default:
  }
  if (fieldVal !== null) {
    const hdr = encodeHeader(tag, fieldVal.length);
    buf.push(hdr);
    buf.push(fieldVal);
    return true;
  }

  return false;
}

function encodeSingleElement(sdtfield) {
  const buf = [];
  encodeSingleElementToBuf(sdtfield, buf);
  return buf.join('');
}

const EncodeSingleElement = {
  encodeSingleElement,
  encodeSingleElementToBuf,
};

module.exports.EncodeSingleElement = EncodeSingleElement;
