const MessageLib = require('solclient-message');
const { Base64, Bits, Convert } = require('solclient-convert');
const { BinaryMetaBlock, SMFHeader, SMPMessage } = require('../message-objects');
const { Check } = require('solclient-validate');
const { ClientCtrlMessage, KeepAliveMessage, AdProtocolMessage } = require('../message-objects');
const { Codec: SDTCodec } = require('solclient-sdt');
const { ContentSummaryElement } = require('./content-summary-element');
const { ContentSummaryType } = require('./content-summary-types');
const { DestinationType } = require('solclient-destination');
const { encAdp } = require('./adprotocol');
const { encCC } = require('./client-ctrl');
const { ErrorSubcode, OperationError } = require('solclient-error');
const { Lazy } = require('solclient-eskit');
const { LOG_TRACE, LOG_INFO } = require('solclient-log');
const { ParamParse } = require('./param-parse');
const { PriorityUserCosMap } = require('./priority-user-cos-map');
const { SDTField, SDTFieldType, SDTMapContainer, SDTStreamContainer } = require('solclient-sdt');
const { SMFParameterType, SMFExtendedParameterType } = require('../smf-parameter-types');
const { SMFProtocol } = require('../smf-protocols');
const { SMP } = require('./smp');

const { encode: base64Encode } = Base64;
const { set: setBits } = Bits;
const {
  int8ToStr,
  int16ToStr,
  int24ToStr,
  int32ToStr,
  int64ToStr,
} = Convert;
const { lazyValue } = Lazy;
const {
  encContentSummary,
  encDeliveryMode,
  encLightSMFParam,
  encodeSMFParam,
  encodeSMFExtendedParam,
} = ParamParse;
const { encodeSingleElement } = SDTCodec;
const { encSmp } = SMP;

const priorityForUserCos = lazyValue(() => new PriorityUserCosMap().forward);

function addContentElementToArrays(csumm, payloadArray, dataChunk, cstype) {
  if (Check.anything(dataChunk) && dataChunk.length > 0) {
    const cse = new ContentSummaryElement(cstype, NaN, dataChunk.length);
    csumm.push(cse);
    payloadArray.push(dataChunk);
  }
}

function addToMapIfPresent(headerMap, key, type, value) {
  if (Check.anything(value)) {
    headerMap.addField(key, SDTField.create(type, value));
  }
}

// Return the binary attachment as string, sets the binaryMetaData on the message.
// Not nice, but fast.
function adaptMessageToBinaryMeta(message) {
  let result;
  // solace header map
  const headerMap = new SDTMapContainer();
  addToMapIfPresent(headerMap, 'ci', SDTFieldType.STRING, message.getCorrelationId());
  addToMapIfPresent(headerMap, 'mi', SDTFieldType.STRING, message.getApplicationMessageId());
  addToMapIfPresent(headerMap, 'mt', SDTFieldType.STRING, message.getApplicationMessageType());
  addToMapIfPresent(headerMap, 'rt', SDTFieldType.DESTINATION, message.getReplyTo());
  addToMapIfPresent(headerMap, 'si', SDTFieldType.STRING, message.getSenderId());
  addToMapIfPresent(headerMap, 'sn', SDTFieldType.INT64, message.getSequenceNumber());
  addToMapIfPresent(headerMap, 'ts', SDTFieldType.INT64, message.getSenderTimestamp());
  addToMapIfPresent(headerMap, 'ex', SDTFieldType.INT64, message.getGMExpiration());

  // container map: solace headers + user prop map
  const sdtMap = new SDTMapContainer();
  if (message.getUserPropertyMap()) {
    sdtMap.addField('p', SDTField.create(SDTFieldType.MAP, message.getUserPropertyMap()));
  }
  if (headerMap.getKeys().length > 0) {
    sdtMap.addField('h', SDTField.create(SDTFieldType.MAP, headerMap));
  }

  let preambleByte0 = 0;
  switch (message.getType()) {
    case MessageLib.MessageType.BINARY:
      preambleByte0 |= 0x80;
      break;
    case MessageLib.MessageType.MAP:
      preambleByte0 |= 0x0A;
      result = encodeSingleElement(message._structuredContainer);
      break;
    case MessageLib.MessageType.STREAM:
      preambleByte0 |= 0x0B;
      result = encodeSingleElement(message._structuredContainer);
      break;
    case MessageLib.MessageType.TEXT:
      preambleByte0 |= 0x07;
      result = encodeSingleElement(message._structuredContainer);
      break;
    default:
      LOG_INFO(`Unhandled messageType: ${message.getType()}`);
      break;
  }
  const preambleByte1 = message.isReplyMessage() ? 0x80 : 0;
  const sdtPreamble = SDTField.create(SDTFieldType.BYTEARRAY,
                                      String.fromCharCode(preambleByte0, preambleByte1));

  // Putting it all together: a stream with the preamble and map
  const sdtStreamContainer = new SDTStreamContainer();
  sdtStreamContainer.addField(sdtPreamble);
  sdtStreamContainer.addField(SDTField.create(SDTFieldType.MAP, sdtMap));

  const binaryMeta = new BinaryMetaBlock();
  binaryMeta.type = 0;
  binaryMeta.payload = encodeSingleElement(SDTField.create(SDTFieldType.STREAM,
                                                           sdtStreamContainer));
  message.binaryMetadataChunk = binaryMeta;
  return result;
}


function adaptMessageToSmf_nonPayload(message, smfHeaderIn) {
  const smfHeader = smfHeaderIn;
  const deliveryMode = message.getDeliveryMode();

  smfHeader.smf_dto = message.isDeliverToOne();
  smfHeader.pm_deliverymode = deliveryMode;
  smfHeader.smf_adf = deliveryMode === MessageLib.MessageDeliveryModeType.DIRECT ? 0 : 1;
  smfHeader.smf_di = message.isDiscardIndication();
  smfHeader.smf_elidingEligible = message.isElidingEligible();
  smfHeader.smf_deadMessageQueueEligible = message.isDMQEligible();
  smfHeader.pm_ad_flowid = message.getFlowId();
  smfHeader.pm_ad_publisherid = message.getPublisherId();
  smfHeader.pm_ad_publishermsgId = message.getPublisherMessageId();
  smfHeader.pm_ad_msgid = message.getGuaranteedMessageId();
  smfHeader.pm_ad_prevmsgid = message.getGuaranteedPreviousMessageId();
  smfHeader.pm_ad_ttl = message.getTimeToLive();
  smfHeader.pm_ad_ackimm = message.isAcknowledgeImmediately();
  smfHeader.pm_ad_redelflag = message.isRedelivered();

  const dest = message.getDestination();
  if (dest) {
    smfHeader.pm_tr_topicname_bytes = dest.getBytes();
    if (dest.type === DestinationType.QUEUE ||
        dest.type === DestinationType.TEMPORARY_QUEUE) {
      const { offset } = dest;
      smfHeader.pm_queue_len = smfHeader.pm_tr_topicname_bytes.length - offset;
      smfHeader.pm_queue_offset = offset;
    }
  }

  smfHeader.smf_priority = priorityForUserCos.value.get(message.getUserCos());

  if (message.getPriority() !== undefined
    && typeof message.getPriority() === 'number'
    && message.getPriority() <= 255
    && message.getPriority() >= 0) {
    smfHeader.pm_msg_priority = message.getPriority();
  } else {
    smfHeader.pm_msg_priority = null;
  }

  const userData = message.getUserData();
  smfHeader.pm_userdata = (userData === null || userData === undefined)
    ? null
    : message.getUserData();
}

function adaptMessageToSmf_payloadMemoize(message) {
  let encodedSdtPayload;

  // Setup user properties, header properties, msgtype
  if (message.getCorrelationId() ||
      message.getApplicationMessageId() ||
      message.getApplicationMessageType() ||
      message.getReplyTo() ||
      message.getSenderId() ||
      message.getSequenceNumber() ||
      message.getSenderTimestamp() ||
      message.getUserPropertyMap() ||
      message.isReplyMessage() ||
      (message.getType() !== MessageLib.MessageType.BINARY)) {
    // add SDT binary metadata
    encodedSdtPayload = adaptMessageToBinaryMeta(message);
  }

  // Build array of ContentSummaryElements
  const csumm = [];
  const payload = [];
  addContentElementToArrays(csumm, payload,
                            message.getXmlMetadata(), ContentSummaryType.XML_META);
  addContentElementToArrays(csumm, payload,
                            message.getXmlContent(), ContentSummaryType.XML_PAYLOAD);
  if (encodedSdtPayload) {
    addContentElementToArrays(csumm, payload,
                              encodedSdtPayload, ContentSummaryType.BINARY_ATTACHMENT);
  } else {
    addContentElementToArrays(csumm, payload,
                              message._binaryAttachment ? message._binaryAttachment.toString('latin1') : '', ContentSummaryType.BINARY_ATTACHMENT);
  }
  const binaryMeta = message.binaryMetadataChunk;
  if (binaryMeta !== null) {
    const binaryMetaSMF = binaryMeta.asEncodedSmf();
    const MAX_24BITS = 16777215;
    if (binaryMetaSMF.length > MAX_24BITS) {
      LOG_TRACE(`binary-meta data (${binaryMetaSMF.length}) over the ${MAX_24BITS} limit`);
      throw new OperationError(`binary-meta data (${binaryMetaSMF.length}) over the ${MAX_24BITS} limit`, ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    } else {
      addContentElementToArrays(csumm, payload, binaryMetaSMF, ContentSummaryType.BINARY_METADATA);
    }
  }
  message._memoized_csumm = csumm;
  message._memoized_payload = payload.join(''); 
  message._payload_is_memoized = true;
  return message._memoized_payload ? message._memoized_payload.length : 0;
}

function adaptMessageToSmf_payloadFinalize(message, smfHeaderIn) {
  const smfHeader = smfHeaderIn;
  if (!message._payload_is_memoized) {
    adaptMessageToSmf_payloadMemoize(message);
  }
  const csumm = message._memoized_csumm;
  const payloadBytes = message._memoized_payload;
  if (csumm.length === 0 ||
      (csumm.length === 1 && csumm[0].type === ContentSummaryType.BINARY_ATTACHMENT)) {
    // NULL or RAW payload (no content-summary)
    //  Was this here to invert the condition?
    // LOG_TRACE('NULL or RAW payload (no content-summary)');
  } else {
    smfHeader.pm_content_summary = csumm;
  }

  smfHeader.payload = payloadBytes;
}

function adaptMessageToSmf(message, smfHeaderIn) {
  adaptMessageToSmf_payloadFinalize(message, smfHeaderIn);
  adaptMessageToSmf_nonPayload(message, smfHeaderIn);
}

/**
 * Creates an array of all values that fit in the given number of bits.
 * e.g. bitRange(1) => [0, 1], bitRange(2) => [0, 1, 2, 3]
 * @param {Number} bits The number of bits in the range
 * @returns {Number} All values that fit in that number of bits
 * @private
 */
const bitRange = bits => Array.from(Array(Math.pow(2, bits))).map((el, i) => i);
const maskValues = (shift, bits) => bitRange(bits).map(val => setBits(0, val, shift, bits));
const DI_BIT = maskValues(31, 1);
const ELIDING_ELIGIBLE_BIT = maskValues(30, 1);
const DTO_BIT = maskValues(29, 1);
const ADF_BIT = maskValues(28, 1);
const DMQE_BIT = maskValues(27, 1);
const VERSION_BITS = maskValues(24, 3);
const UH_BITS = maskValues(22, 2);
const PROTOCOL_BITS = maskValues(16, 6);
const PRIORITY_BITS = maskValues(12, 4);
const TTL_BITS = maskValues(0, 8);
const QT_OFFSET_BYTES = maskValues(8, 8);
const QT_LEN_BYTES = maskValues(0, 8);

function encodeSMF(header) {
  // First 4 bytes: protocol, ttl, etc
  let w1 = 0;

  // PERF: single expression to make w1 const
  w1 |= DI_BIT[header.smf_di && 1 || 0];
  w1 |= ELIDING_ELIGIBLE_BIT[header.smf_elidingEligible && 1 || 0];
  w1 |= DTO_BIT[header.smf_dto && 1 || 0];
  w1 |= ADF_BIT[header.smf_adf && 1 || 0];
  w1 |= DMQE_BIT[header.smf_deadMessageQueueEligible && 1 || 0];
  w1 |= VERSION_BITS[header.smf_version || 0];
  w1 |= UH_BITS[header.smf_uh || 0];
  w1 |= PROTOCOL_BITS[header.smf_protocol || 0];
  w1 |= PRIORITY_BITS[header.smf_priority || 0];
  w1 |= TTL_BITS[header.smf_ttl || 0]; // PERF: or set w1 to ttl initially.

  const params = [];
  // Encode all standard SMF parameters
  // Topic name and queue/topic offsets are supposed to come first
  if (header.pm_tr_topicname_bytes) {
    params.push(encodeSMFParam(2, SMFParameterType.TR_TOPICNAME,
                               `${header.pm_tr_topicname_bytes}`));
  }
  if (header.pm_queue_len) {
    params.push(encLightSMFParam(0, SMFParameterType.LIGHT_QUEUE_NAME_OFFSET,
                                 int16ToStr(QT_OFFSET_BYTES[header.pm_queue_offset] |
                                            QT_LEN_BYTES[header.pm_queue_len])));
  }
  if (header.pm_topic_len) {
    params.push(encLightSMFParam(0, SMFParameterType.LIGHT_TOPIC_NAME_OFFSET,
                                 int16ToStr(QT_OFFSET_BYTES[header.pm_topic_offset] |
                                            QT_OFFSET_BYTES[header.pm_topic_len])));
  }

  if (header.pm_corrtag !== null && header.pm_corrtag !== undefined) {
    params.push(encLightSMFParam(0, SMFParameterType.LIGHT_CORRELATION,
                                 int24ToStr(header.pm_corrtag)));
  }
  if (header.pm_ad_ackimm) {
    params.push(encLightSMFParam(0, SMFParameterType.LIGHT_ACK_IMMEDIATELY,
                                 ''));
  }

  if (header.pm_msg_priority !== null) {
    params.push(encodeSMFParam(0, SMFParameterType.MESSAGEPRIORITY,
                               int8ToStr(header.pm_msg_priority)));
  }
  if (header.pm_userdata !== null && header.pm_userdata !== '') {
    params.push(encodeSMFParam(0, SMFParameterType.USERDATA,
                               header.pm_userdata));
  }
  if (header.pm_username) {
    // do a sloppy base64 (no newlines)
    params.push(encodeSMFParam(0, SMFParameterType.USERNAME,
                               base64Encode(header.pm_username)));
  }
  if (header.pm_password) {
    // do a sloppy base64 (no newlines)
    params.push(encodeSMFParam(0, SMFParameterType.PASSWORD,
                               base64Encode(header.pm_password)));
  }
  if (header.pm_respcode) {
    // not useful API->router
    params.push(encodeSMFParam(0, SMFParameterType.RESPONSE,
                               int32ToStr(header.pm_respcode) + header.pm_respstr));
  }

  if (header.pm_deliverymode !== null) {
    params.push(encodeSMFParam(0, SMFParameterType.DELIVERY_MODE,
                               encDeliveryMode(header.pm_deliverymode)));
  }

  if (header.pm_ad_msgid !== undefined) {
    params.push(encodeSMFParam(2, SMFParameterType.ASSURED_MESSAGE_ID,
                               int64ToStr(header.pm_ad_msgid)));
    params.push(encodeSMFParam(2, SMFParameterType.ASSURED_PREVMESSAGE_ID,
                               int64ToStr(header.pm_ad_prevmsgid)));
  }

  if (header.pm_ad_flowid) {
    params.push(encodeSMFParam(0, SMFParameterType.ASSURED_FLOWID,
                               int32ToStr(header.pm_ad_flowid)));
  }

  // header.pm_ad_redelflag
  // Ad redelivered
  if (header.pm_ad_redelflag) {
    params.push(encodeSMFParam(0, SMFParameterType.ASSURED_REDELIVERED_FLAG, undefined));
  }
  // header.pm_ad_flowredelflag

  if (header.pm_ad_ttl !== undefined) {
    params.push(encodeSMFParam(0, SMFParameterType.AD_TIMETOLIVE,
                               int64ToStr(header.pm_ad_ttl)));
  }

  // sequence number?

  if (header.pm_ad_publisherid) {
    params.push(encodeSMFParam(0, SMFParameterType.PUBLISHER_ID,
                               int32ToStr(header.pm_ad_publisherid)));
  }

  if (header.pm_ad_publisherMsgId) {
    params.push(encodeSMFParam(0, SMFParameterType.PUBLISHER_MSGID,
                               int64ToStr(header.pm_ad_publisherMsgId)));
  }

  // transactions: ackmessageid, transactionid, transactionflags

  if (header.pm_content_summary) {
    params.push(encodeSMFParam(2, SMFParameterType.MESSAGE_CONTENT_SUMMARY,
                               encContentSummary(header.pm_content_summary)));
  }
  // done common SMF parameters!

  // pre-collect and push extended parameters (once we have any)

  let extendedStreamContents = '';
  let extendedUH = 0;

  if (header.pm_oauth2_access_token) {
    extendedStreamContents += encodeSMFExtendedParam(0,
                                                     SMFExtendedParameterType.OAUTH2_ACCESS_TOKEN,
                                                     header.pm_oauth2_access_token);
    extendedUH = extendedUH || 0;
  }

  if (header.pm_oidc_id_token) {
    extendedStreamContents += encodeSMFExtendedParam(0,
                                                     SMFExtendedParameterType.OIDC_ID_TOKEN,
                                                     header.pm_oidc_id_token);
    extendedUH = extendedUH || 0;
  }

  if (header.pm_oauth2_issuer_identifier) {
    extendedStreamContents += encodeSMFExtendedParam(0,
                                                     SMFExtendedParameterType
                                                     .OAUTH2_ISSUER_IDENTIFIER,
                                                     header.pm_oauth2_issuer_identifier);
    extendedUH = extendedUH || 0;
  }

  if (extendedStreamContents.length > 0) {
    params.push(encodeSMFParam(extendedUH,
                               SMFParameterType.EXTENDED_TYPE_STREAM,
                               extendedStreamContents));
  }

  // compute header size and full message size
  const encodedParams = params.join('');
  const hdrlen = 12 + encodedParams.length;
  const msglen = hdrlen + header.payloadLength;

  // ? Already encoded. Why bother?
  header.setMessageSizes(hdrlen, header.payloadLength);

  return (
    int32ToStr(w1) +
    int32ToStr(hdrlen) +
    int32ToStr(msglen) +
    encodedParams
  );
}

function encodeCompoundMessage(msg) {
  let payload = '';
  if (msg instanceof MessageLib.Message) {
    if (!msg.smfHeader) {
      msg.smfHeader = new SMFHeader(SMFProtocol.TRMSG, 255);
    }
    adaptMessageToSmf(msg, msg._smfHeader);
    payload = msg._smfHeader.payload;
  } else if (msg instanceof ClientCtrlMessage) {
    payload = encCC(msg);
  } else if (msg instanceof SMPMessage) {
    payload = encSmp(msg);
  } else if (msg instanceof KeepAliveMessage) {
    LOG_TRACE('Skipping retrieve payload as there is none in a KeepAliveMessage');
  } else if (msg instanceof AdProtocolMessage) {
    payload = encAdp(msg);
  }
  const header = msg.smfHeader;
  header.setPayloadSize(payload.length);
  const encodedHeader = encodeSMF(header);
  return encodedHeader + payload;
}

const Encode = {
  encodeCompoundMessage,
  encodeSMF,
  adaptMessageToSmf_payloadMemoize,
};

module.exports.Encode = Encode;
